import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';

import { setIsDataLoading, setMenuAccessibility, setSalesPages } from 'actions';
import { IStore } from 'types';
import {
  APP_TITLE,
  CHILD_ROUTES,
  ROUTES,
  USER_OWNERSHIP,
  VALID_APP_URL_TITLES,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT,
  SUPER_ADMIN_MENU_AVAILABLITY,
  MAIN_PAGES_MENU_AVAILABILITY,
  MEDIA_INITIAL_MENU_VALUE,
  SIDE_NAV_NAME
} from 'utils/constants';
import { getCurrentDate, getDateBasedOnGivenFormat, getTimezone, hasFranFullAccess } from 'utils/helpers';
import { useMenuAccessibleAccountSwitcherData } from 'utils/hooks';
import { configCDNUrlToBrand, hideIntercomBot, imageMotionAnimation, intercomBotSettings } from 'utils/helpers/external-script';
import { dateFormatter, dateHelperForMonth } from 'analytics/utils/helpers';
import { DateFormatType } from 'analytics/utils/constants';
import { configCDNImgUrlToBrand } from 'config';

export const useMenuAccessibility = () => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const { userOwnership, id, isAdminScreenEnabled } = useMenuAccessibleAccountSwitcherData();

  const isAuthenticated = useSelector((state: IStore) => state.login.isAuthenticated);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const error = useSelector((state: IStore) => state.errorBoundary.error);
  const isFBAdsTargetingFetching = useSelector((state: IStore) => state.fbAdsTargeting.isFetching);
  const facebookAdAccountId = useSelector((state: IStore) => state.fbAdsTargeting.fbTargetingChoice?.facebook_ad_account?.id) || 0;
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const activeUserFirstName = useSelector((state: IStore) => state.login.activeUser?.first_name);
  const activeUserLastName = useSelector((state: IStore) => state.login.activeUser?.last_name);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const activeUserToken = useSelector((state: IStore) => state.login.activeUser?.token);
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor);
  const activeUserProfilePhotoUrl = useSelector((state: IStore) => state.login.activeUser?.profile_photo_url);
  const isRevvAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const isRevvFetching = useSelector((state: IStore) => state.revv.login.isFetching);
  const contentWriters = useSelector((state: IStore) => state.login.activeUser?.content_writers || []);
  const isContentWriter = useSelector((state: IStore) => state.login.activeUser?.content_writer || false);
  const isEnvFetching = useSelector((state: IStore) => state.common.isEnvFetching);
  const brandConfig = useSelector((state: IStore) => state.accountSwitcher.brand_config || null);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter?.isRevvConnectedToCurrentFranchisor || false);

  const franchisorId = useSelector((state: IStore) => state.franchisors.franchisorDetails?.id && state.franchisors.currentFranDetails?.id);
  const franHasCoupon = useSelector((state: IStore) => state.franchisors.franchisorDetails?.has_coupons);
  const franEmpLeaderboardEnabled = useSelector((state: IStore) => state.franchisors.franchisorDetails?.employee_leaderboard_feature);
  const franLocationProfileAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.locations_profile_access);
  const franContentAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.content_access);
  const franPhotosAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.photos_access);
  const franQueueAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.queue_access);
  const franAccountPicturesAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.account_pictures_access);
  const franAnalyticsOverviewAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.analytics_overview_access);
  const franRollupAnalyticsAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.rollup_analytics_access);
  const franConnectionGridAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.connection_grid_access);
  const franActivityFeedSandboxAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.access?.activity_feed_sandbox_access);
  const franAnalyticsAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.access?.analytics_access);
  const franDatabaseAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.access?.database_access);
  const franActivityFeedAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.activity_feed_access);
  const franSettingsAccess = useSelector((state: IStore) => state.franchisors.currentFranDetails?.access?.settings_access);

  const accountId = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.id);
  const accEmpLeaderboardFeatureEnabled = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.employee_leaderboard_feature_enabled);
  const accQueueAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.queue_access);
  const accNewContentAnalyticsAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.analytics_new_content_analytics_access);
  const accContentAnalyticsAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.analytics_content_analytics_access);
  const accProfileAnalyticsAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.analytics_profile_analytics_access);
  const accLocationProfileAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.location_profile_access);
  const accHasFacebookMedium = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.has_facebook_medium);
  const accHasGooglePlusMedium = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.has_google_plus_medium);
  const accCurrentUserHasFbAdAccountChoice = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.current_user_has_facebook_ad_account_choice);
  const accActivityFeedReviewsAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.activity_feed_reviews_access);
  const accActivityFeedOutboxAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.activity_feed_outbox_access);
  const accActivityFeedInboxAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.activity_feed_inbox_access);
  const accActivityFeedSandboxAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.activity_feed_sandbox_access);
  const accFranchisorId = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.franchisor_id);
  const accDirectoryListingVisible = useSelector((state: IStore) => state.accounts?.accountDetails?.account?.features?.directory_listings_visible);
  const accAnalyticsAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.analytics_access);
  const accDatabaseAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.database_access);
  const accActivityFeedAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.activity_feed_access);
  const accSettingsAccess = useSelector((state: IStore) => state.accounts?.accountDetails?.account_profiles[0]?.access?.settings_access);
  const showRevv = useSelector((state: IStore) => state.accountSwitcher.showRevv);
  const isCampaign = useSelector((state: IStore) => state.accounts.accountDetails?.account.campaign_feature || state.franchisors.franchisorDetails?.campaign_feature || false);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isRallioProfileDisabled = useSelector((state: IStore) => state.accountSwitcher?.rallio_profile_disabled || false);
  const locationsHubList = useSelector((state: IStore) => state.operationsList.locationsGroupList);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier || false);
  const isBannerPartner = useSelector((state: IStore) => state.franchisors.franchisorDetails?.banner_partner || false);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const isAIAssistant = useSelector((state: IStore) => state.accountSwitcher?.currentAccountOrFranchisor?.ai_assistant || false);
  const isSchedulerFeature = useSelector((state: IStore) => state.franchisors.franchisorDetails?.scheduler_feature || state.accounts?.accountDetails?.account?.features?.scheduler_feature || false);
  const hideMarketingMenu = useSelector((state: IStore) => state.accountSwitcher.hideMarketingMenu || false);

  // const franchisorSyndication = useSelector((state: IStore) => state.franchisors.currentFranDetails?.franchisors_for_syndication);
  const currentId = useMemo(() => (isAdminScreenEnabled ? 0 : userOwnership === USER_OWNERSHIP.FRANCHISOR ? franchisorId : accountId), [isAdminScreenEnabled, userOwnership, franchisorId, accountId]);

  const franchisorFullAccess = useMemo(() => {
    return userOwnership === USER_OWNERSHIP.FRANCHISOR ? hasFranFullAccess(isAdmin, id, contentWriters) : false;
  }, [userOwnership, isAdmin, id, contentWriters]);

  const isLocationContentFeedEnable = useMemo(
    () =>
      userOwnership === USER_OWNERSHIP.ACCOUNT && locationsHubList?.length
        ? true
        : userOwnership === USER_OWNERSHIP.ACCOUNT && localStorage.getItem('SSOPageName') && localStorage.getItem('SSOPageName') === SIDE_NAV_NAME.CONTENT_FEED
        ? true
        : false,
    [userOwnership, locationsHubList]
  );

  const hasAIAccess = Boolean(isAISubscribed && isRallioai);

  const exclusions = useMemo(() => {
    const pageExclusions = menuAccess?.page_exclusions;
    let subPages: Array<string> = [];
    let pages: Array<string> = [];
    let salesPages: Array<string> = [];
    if (pageExclusions) {
      if (pageExclusions.hub_user && userOwnership === USER_OWNERSHIP.FRANCHISOR && pageExclusions.hub_user?.length > 0) {
        salesPages = pageExclusions.hub_user.filter((it: string) => it.indexOf('-sales') > -1);
        subPages = pageExclusions.hub_user.filter((it: string) => it.split('/')?.length > 1 && salesPages.indexOf(it) === -1).map((it: string) => it.split('/')[1]);
        pages = pageExclusions.hub_user.filter((it: string) => it.split('/')?.length === 1 && salesPages.indexOf(it) === -1);
      } else if (pageExclusions.location_user && userOwnership === USER_OWNERSHIP.ACCOUNT && pageExclusions.location_user?.length > 0) {
        salesPages = pageExclusions.location_user.filter((it: string) => it.indexOf('-sales') > -1);
        subPages = pageExclusions.location_user.filter((it: string) => it.split('/')?.length > 1 && salesPages.indexOf(it) === -1).map((it: string) => it.split('/')[1]);
        /* subPages = pageExclusions.location_user
          .filter((it: string) => it.split('/')?.length > 1 && salesPages.indexOf(it) === -1)
          .map((it: string) => (it.split('/')[2] === '1' && (isAdmin || isFranchisor || isContentWriter) && !isRallioProfileDisabled ? '' : it.split('/')[1])); */
        pages = pageExclusions.location_user.filter((it: string) => it.split('/')?.length === 1 && salesPages.indexOf(it) === -1);
      }
    }
    dispatch(setSalesPages(salesPages));
    const restrictions = {
      isShowIntercom: false
    };
    const siteRestrictions = menuAccess?.site_restrictions;
    if (siteRestrictions) {
      if (siteRestrictions?.hub_user && userOwnership === USER_OWNERSHIP.FRANCHISOR && siteRestrictions?.hub_user.length > 0) {
        restrictions.isShowIntercom = siteRestrictions?.hub_user?.includes('intercom');
      } else if (siteRestrictions?.location_user && userOwnership === USER_OWNERSHIP.ACCOUNT && siteRestrictions?.location_user.length > 0) {
        restrictions.isShowIntercom = siteRestrictions?.location_user?.includes('intercom');
      }
    }
    return {
      subPages,
      pages,
      salesPages,
      restrictions
    };
  }, [menuAccess, userOwnership, dispatch]);

  const dateRange = useMemo(() => {
    return dateHelperForMonth(true);
  }, []);

  const location = window.location.pathname;
  const isValidRoute = useMemo(() => {
    const routeMatch = (path: string) => matchPath(path, location)?.pathname;
    const routesArray = Object.values(ROUTES).slice(7);
    const childRouteKeys = Object.keys(CHILD_ROUTES);
    const routes: any = ROUTES;
    const childRoute: any = CHILD_ROUTES;

    const childRoutes = childRouteKeys.reduce((acc: string[], curr) => {
      const parentRoute: any = routes[curr];
      const childRouteObj = childRoute[curr];
      const routesArr = Object.values(childRouteObj).map((it) => `${parentRoute}/${it}`);
      acc = acc.concat(routesArr);
      return acc;
    }, []);
    return routesArray.concat(childRoutes).filter((it) => routeMatch(it))?.length > 0;
  }, [location]);

  useEffect(() => {
    configCDNUrlToBrand(brandConfig);
    configCDNImgUrlToBrand(brandConfig);
  }, [brandConfig]);

  useEffect(() => {
    if (isAuthenticated) {
      imageMotionAnimation();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const isIntercomShow = !exclusions?.restrictions?.isShowIntercom;
    if (isAuthenticated && !isEnvFetching) {
      if (error || !isValidRoute) hideIntercomBot(true);
      else if (isAdminScreenEnabled) hideIntercomBot(true);
      else if (VALID_APP_URL_TITLES.includes(APP_TITLE)) hideIntercomBot(true);
      else {
        if (!isIntercomShow) hideIntercomBot(true);
        else if (id && userOwnership && isIntercomShow && activeUserId && currentId) {
          hideIntercomBot(false);
          intercomBotSettings({
            rallioUserEmail: activeUserEmail,
            admin: isAdmin,
            franchisor: isFranchisor,
            name: `${activeUserFirstName} ${activeUserLastName}`,
            rallioUserId: activeUserId,
            token: activeUserToken,
            userOwnership,
            id,
            dateRange,
            topLevelFranchisorId,
            profilePhotoUrl: activeUserProfilePhotoUrl,
            browserTimezone: getTimezone(),
            browserDateTime: getDateBasedOnGivenFormat(getCurrentDate(), YEAR_MONTH_DAY_WITH_TIME_FORMAT),
            fromDate: dateFormatter(dateRange.fromDate.selected, DateFormatType.API),
            toDate: dateFormatter(dateRange.toDate.selected, DateFormatType.API),
            formTimestamp: dateRange.fromDate.timeStamp,
            toTimestamp: dateRange.toDate.timeStamp
          });
        }
      }
    }
  }, [
    error,
    activeUserId,
    activeUserEmail,
    isAdmin,
    isFranchisor,
    activeUserFirstName,
    activeUserLastName,
    activeUserToken,
    activeUserProfilePhotoUrl,
    userOwnership,
    id,
    topLevelFranchisorId,
    isAuthenticated,
    dateRange,
    exclusions?.restrictions?.isShowIntercom,
    isValidRoute,
    isAdminScreenEnabled,
    isEnvFetching,
    currentId
  ]);

  const validMainPages = useMemo(() => {
    if ((userOwnership && currentId) || isAdminScreenEnabled) {
      return {
        ...(isAdminScreenEnabled
          ? {
              admin_area: isAdmin
            }
          : {
              analytics: userOwnership === USER_OWNERSHIP.FRANCHISOR && franAnalyticsAccess ? true : userOwnership === USER_OWNERSHIP.ACCOUNT && accAnalyticsAccess ? true : false,
              content: userOwnership === USER_OWNERSHIP.FRANCHISOR && franDatabaseAccess ? true : userOwnership === USER_OWNERSHIP.ACCOUNT && accDatabaseAccess ? true : false,
              community: userOwnership === USER_OWNERSHIP.FRANCHISOR && franActivityFeedAccess ? true : userOwnership === USER_OWNERSHIP.ACCOUNT && accActivityFeedAccess ? true : false,
              marketing: userOwnership === USER_OWNERSHIP.FRANCHISOR && hideMarketingMenu ? false : userOwnership === USER_OWNERSHIP.ACCOUNT ? false : true,
              reputation: userOwnership === USER_OWNERSHIP.FRANCHISOR && franActivityFeedAccess ? true : userOwnership === USER_OWNERSHIP.ACCOUNT && accActivityFeedAccess ? true : false,
              revv: true,
              employee_advocacy: true,
              directory_listings: userOwnership === USER_OWNERSHIP.ACCOUNT && accDirectoryListingVisible ? true : false,
              team_management: true,
              settings: userOwnership === USER_OWNERSHIP.FRANCHISOR && franSettingsAccess && franchisorFullAccess ? true : userOwnership === USER_OWNERSHIP.ACCOUNT && accSettingsAccess ? true : false,
              admin_area: isAdmin,
              video_tutorials: true,
              ai_assistant: true
            })
      };
    }
    return MAIN_PAGES_MENU_AVAILABILITY;
    // eslint-disable-next-line
  }, [userOwnership, currentId, isAdmin, isAdminScreenEnabled, hideMarketingMenu]);

  const showPartnersScreen = useMemo(() => {
    if ((!isContentSupplier && !isBannerPartner) || isBannerPartner) return true;
    return false;
  }, [isContentSupplier, isBannerPartner]);

  useEffect(() => {
    const isRemove = (page: string) => exclusions.subPages.includes(page) || exclusions.pages.includes(page);
    const isRevvOnlyLeaderboard =
      menuAccess?.site_restrictions?.hub_user?.includes('analytics/analytics_leaderboard/social') && (!isRevvAuthenticated || !showRevv || !isRevvConnectedToCurrentFranchisor);
    if (!userOwnership && isAuthenticated) {
      dispatch(
        setMenuAccessibility({
          ...MEDIA_INITIAL_MENU_VALUE,
          ...('admin_area' in validMainPages && validMainPages?.admin_area && { admin_area: SUPER_ADMIN_MENU_AVAILABLITY })
        })
      );
    } else if (currentId && !isFBAdsTargetingFetching && userOwnership && isAuthenticated) {
      dispatch(
        setMenuAccessibility({
          ...('ai_assistant' in validMainPages &&
            validMainPages?.ai_assistant &&
            isRallioai &&
            isAIAssistant && {
              ai_assistant: {}
            }),
          ...('analytics' in validMainPages &&
            validMainPages?.analytics && /* !franchisorSyndication?.length && */ {
              analytics: {
                analytics_revv: false,
                // analytics_revv: isRemove('revv') ? false : isRevvAuthenticated,
                survey_status: false,
                // survey_status: isRemove('revv') ? false : isRevvAuthenticated,
                sentiment: false,
                // sentiment: isRemove('revv') ? false : isRevvAuthenticated,
                review_change: false,
                // review_change: isRemove('revv') ? false : isRevvAuthenticated,
                average_rating: false,
                // average_rating: isRemove('revv') ? false : isRevvAuthenticated,
                analytics_social:
                  isRemove('analytics_social') || isRemove('analytics')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && franLocationProfileAccess
                    ? true
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && accLocationProfileAccess && !isContentSupplier
                    ? true
                    : false,
                analytics_content:
                  isRemove('analytics_content') || isRemove('analytics')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && franRollupAnalyticsAccess
                    ? true
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && (accNewContentAnalyticsAccess || accContentAnalyticsAccess)
                    ? true
                    : false,
                analytics_page:
                  isRemove('analytics_page') || isRemove('analytics')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && !isContentSupplier
                    ? true
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && !isContentSupplier && (accHasFacebookMedium || accHasGooglePlusMedium) && accProfileAnalyticsAccess
                    ? true
                    : false,
                analytics_reviews: isRemove('analytics_reviews') || isRemove('analytics') ? false : !isContentSupplier ? true : false,
                // analytics_leaderboard:
                //   isRemove('analytics_leaderboard') || isRemove('analytics') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT ? false : franAnalyticsOverviewAccess ? true : false
                analytics_leaderboard: isRevvOnlyLeaderboard
                  ? false
                  : isRemove('analytics_leaderboard') || isRemove('analytics')
                  ? false
                  : /* : userOwnership === USER_OWNERSHIP.ACCOUNT
                  ? isRevvAuthenticated && (isRevvConnectedToCurrentFranchisor || showRevv) && !isRemove('revv') */
                    !!franAnalyticsOverviewAccess
              }
            }),
          ...('content' in validMainPages &&
            validMainPages?.content && {
              content: {
                creator: isRemove('creator') || isRemove('content') ? false : true,
                posts:
                  isRemove('posts') || isRemove('content')
                    ? false
                    : userOwnership === USER_OWNERSHIP.ACCOUNT
                    ? true
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && (franContentAccess || franPhotosAccess)
                    ? true
                    : false,
                media:
                  isRemove('media') || isRemove('content') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT ? true : userOwnership === USER_OWNERSHIP.FRANCHISOR && franPhotosAccess ? true : false,
                feed: isRemove('feed') || isRemove('content') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR ? false : !isContentSupplier && isLocationContentFeedEnable,
                scheduler: isRemove('scheduler') || isRemove('content') ? false : !isContentSupplier && (isContentSupplierFranchisor || isSchedulerFeature) ? true : false,
                campaigns: isRemove('campaigns') || isRemove('content') ? false : /* !franchisorSyndication?.length && */ !isContentSupplier && isCampaign,
                coupons:
                  isRemove('coupons') || isRemove('content')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && franHasCoupon && franchisorFullAccess && !isContentSupplier
                    ? /* !franchisorSyndication?.length && */ true
                    : false,
                rss_feeds: isRemove('rss_feeds') || isRemove('content') ? false : /* !franchisorSyndication?.length && */ !isContentSupplier && true,
                calendar:
                  isRemove('calendar') || isRemove('content')
                    ? false
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && accQueueAccess && !isContentSupplier
                    ? true
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && franQueueAccess && !isContentSupplier
                    ? /* !franchisorSyndication?.length && */ true
                    : false,
                profile_imagery:
                  isRemove('profile_imagery') || isRemove('content')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && franAccountPicturesAccess && !isContentSupplier
                    ? /* !franchisorSyndication?.length && */ true
                    : false
              }
            }),
          ...('community' in validMainPages &&
            !isContentSupplier &&
            validMainPages?.community && /* !franchisorSyndication?.length && */ {
              community: {
                inbox:
                  isRemove('inbox') || isRemove('community')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR
                    ? true
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && accActivityFeedInboxAccess
                    ? true
                    : false,
                sandbox:
                  isRemove('sandbox') || isRemove('community')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && franActivityFeedSandboxAccess
                    ? true
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && accFranchisorId && accActivityFeedSandboxAccess
                    ? true
                    : false
              }
            }),
          ...('marketing' in validMainPages &&
            !isContentSupplier &&
            validMainPages?.marketing && /* !franchisorSyndication?.length && */ {
              marketing: {
                prospects_clients: isRemove('prospects_clients') || isRemove('marketing') ? false : false,
                fb_ads_marketing: isRemove('fb_ads_marketing') || isRemove('marketing') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR ? true : false,
                targeting: isRemove('targeting') || isRemove('marketing') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR && facebookAdAccountId && franchisorFullAccess ? true : false
              }
            }),
          ...('reputation' in validMainPages &&
            !isContentSupplier &&
            validMainPages?.reputation && /* !franchisorSyndication?.length && */ {
              reputation: {
                reviews:
                  isRemove('reviews') || isRemove('reputation')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR
                    ? true
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && accActivityFeedReviewsAccess
                    ? true
                    : false,
                outbox:
                  isRemove('outbox') || isRemove('reputation')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR
                    ? true
                    : userOwnership === USER_OWNERSHIP.ACCOUNT && accActivityFeedOutboxAccess
                    ? true
                    : false,
                ai_review_responder: isRemove('ai_review_responder') || isRemove('reputation') ? false : hasAIAccess ? true : false
              }
            }),
          ...('revv' in validMainPages &&
            !isContentSupplier &&
            validMainPages?.revv && /* !franchisorSyndication?.length && */ {
              revv: {
                login: isRemove('revv') ? false : true,
                dashboard: isRemove('revv') ? false : isRevvAuthenticated && (isRevvConnectedToCurrentFranchisor || showRevv),
                surveys: isRemove('revv') ? false : isRevvAuthenticated && (isRevvConnectedToCurrentFranchisor || showRevv),
                // reports: isRemove('revv') ? false : isRevvAuthenticated
                reports: false,
                customers: isRemove('revv') ? false : isRevvAuthenticated && (isRevvConnectedToCurrentFranchisor || showRevv),
                revv_settings: isRemove('revv') ? false : isRevvFetching ? true : isRevvAuthenticated && !isContentSupplier && (isRevvConnectedToCurrentFranchisor || showRevv),
                locations: isRemove('revv') ? false : isRevvFetching ? true : isRevvAuthenticated && !isContentSupplier && (isRevvConnectedToCurrentFranchisor || showRevv),
                employees: isRemove('revv') ? false : isRevvFetching ? true : isRevvAuthenticated && !isContentSupplier && (isRevvConnectedToCurrentFranchisor || showRevv)
              }
            }),
          ...('employee_advocacy' in validMainPages &&
            !isContentSupplier &&
            validMainPages?.employee_advocacy && /* !franchisorSyndication?.length && */ {
              employee_advocacy: {
                overview: isRemove('overview') || isRemove('employee_advocacy') ? false : true,
                reward_programs: isRemove('reward_programs') || isRemove('employee_advocacy') ? false : true,
                advocacy_leaderboard:
                  isRemove('advocacy_leaderboard') || isRemove('employee_advocacy')
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR
                    ? franEmpLeaderboardEnabled && !isContentWriter
                      ? true
                      : false
                    : userOwnership === USER_OWNERSHIP.ACCOUNT
                    ? accEmpLeaderboardFeatureEnabled && !isContentWriter
                      ? true
                      : false
                    : false
              }
            }),
          ...('directory_listings' in validMainPages &&
            !isContentSupplier &&
            validMainPages?.directory_listings && /* !franchisorSyndication?.length && */ {
              directory_listings: {
                profile: isRemove('profile') || isRemove('directory_listings') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT && accDirectoryListingVisible ? true : false,
                listings: isRemove('listings') || isRemove('directory_listings') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT && accDirectoryListingVisible ? true : false
                // rankings: isRemove('rankings') || isRemove('directory_listings') ? false : true
              }
            }),
          ...('team_management' in validMainPages &&
            !isContentSupplier &&
            validMainPages?.team_management && /* !franchisorSyndication?.length && */ {
              team_management: {
                // revv_teams: isRemove('revv') ? false : isRevvAuthenticated,
                revv_teams: false,
                logins: isRemove('logins') || isRemove('team_management') ? false : true,
                connections:
                  isRemove('connections') || isRemove('team_management')
                    ? false
                    : userOwnership === USER_OWNERSHIP.ACCOUNT
                    ? false
                    : userOwnership === USER_OWNERSHIP.FRANCHISOR && franConnectionGridAccess
                    ? true
                    : false,
                lists: isRemove('lists') || isRemove('team_management') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR && franchisorFullAccess ? true : false,
                permissions: isRemove('permissions') || isRemove('team_management') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR && franchisorFullAccess ? true : false
              }
            }),
          ...('settings' in validMainPages &&
            validMainPages?.settings && /* !franchisorSyndication?.length && */ {
              settings: {
                brand_profile: isRemove('brand_profile') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR ? true : false, // old cond - userOwnership === USER_OWNERSHIP.FRANCHISOR && (isContentSupplier || isContentSupplierFranchisor)
                partner_banners: isRemove('partner_banners') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR && showPartnersScreen,
                reviews_settings:
                  isRemove('reviews_settings') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR && franchisorFullAccess && !isContentSupplier ? true : false,
                release_form: isRemove('release_form') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.FRANCHISOR && franchisorFullAccess && !isContentSupplier ? true : false,
                rallio_profile: isRemove('rallio_profile') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT && !isRallioProfileDisabled ? true : false,
                social_profile: isRemove('social_profile') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT ? true : false,
                ai_playbook: isRemove('ai_playbook') || isRemove('settings') ? false : hasAIAccess ? true : false,
                hashtags: isRemove('hashtags') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT ? true : false,
                fb_ads_settings:
                  isRemove('fb_ads_settings') || isRemove('settings') ? false : userOwnership === USER_OWNERSHIP.ACCOUNT && (accHasFacebookMedium || accCurrentUserHasFbAdAccountChoice) ? true : false
              }
            }),
          ...('admin_area' in validMainPages && validMainPages?.admin_area && { admin_area: SUPER_ADMIN_MENU_AVAILABLITY })
        })
      );
      dispatch(setIsDataLoading(false));
    }
    // eslint-disable-next-line
  }, [
    currentId,
    isCampaign,
    isFBAdsTargetingFetching,
    facebookAdAccountId,
    userOwnership,
    isContentWriter,
    exclusions,
    isAuthenticated,
    validMainPages,
    isRevvAuthenticated,
    isRevvConnectedToCurrentFranchisor,
    showRevv,
    isRevvFetching,
    isAISubscribed,
    hasAIAccess,
    accHasFacebookMedium,
    accHasGooglePlusMedium,
    isLocationContentFeedEnable,
    isBannerPartner,
    dispatch
  ]);
};
