import { IConfiguration, IAccountConfigParams } from 'types/targeting';
import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from '../action-types';

interface ITargetingParams {
  id: number;
  account_list_id?: number;
  ancestor_franchisor_id?: number;
  type?: string;
}
export const targetingListRequest = (payload: ITargetingParams) => createAction(actionTypes.TARGETING_LIST_REQUEST, payload);
export const targetingListRequestFail = (payload: { error: string }) => createAction(actionTypes.TARGETING_LIST_REQUEST_FAIL, payload);
export const targetingListResponse = (payload: { data: any }) => createAction(actionTypes.TARGETING_LIST_RESPONSE, payload);

export const getAccountConfigDataRequest = (payload: IAccountConfigParams) => createAction(actionTypes.GET_ACCOUNT_CONFIG_DATA_REQUEST, payload);
export const getAccountConfigDataResponse = (data: null | IConfiguration) => createAction(actionTypes.GET_ACCOUNT_CONFIG_DATA_RESPONSE, data);
export const getAccountConfigDataRequestFail = (error: null | string) => createAction(actionTypes.GET_ACCOUNT_CONFIG_DATA_FAILURE, error);

export const onChangeConfiguration = (payload: null | IConfiguration) => createAction(actionTypes.ON_CHANGE_CONFIGURATION, payload);
export const updateAccountConfiguration = (payload: any) => createAction(actionTypes.UPDATE_ACCOUNT_CONFIGURATION, payload);

// GET - ACCOUNT CONFIF TIME REACH
export const getAccountConfigReachEstimateRequest = (payload: any) => createAction(actionTypes.GET_ACCOUNT_CONFIG_REACH_ESTIMATE_REQUEST, payload);
export const getAccountConfigReachEstimateResponse = (data: { reach: number }) => createAction(actionTypes.GET_ACCOUNT_CONFIG_REACH_ESTIMATE_RESPONSE, data);
export const getAccountConfigReachEstimateFailure = (error: null | string) => createAction(actionTypes.GET_ACCOUNT_CONFIG_REACH_ESTIMATE_FAILURE, error);

export const marketingTargetingResetAll = () => createAction(actionTypes.MARKETING_TARGETING_RESET_ALL);

const TARGETING_ACTIONS = {
  targetingListRequest,
  targetingListRequestFail,
  targetingListResponse,
  getAccountConfigDataRequest,
  getAccountConfigDataResponse,
  getAccountConfigDataRequestFail,
  getAccountConfigReachEstimateRequest,
  getAccountConfigReachEstimateResponse,
  getAccountConfigReachEstimateFailure,
  onChangeConfiguration,
  updateAccountConfiguration,
  marketingTargetingResetAll
};

export type TargetingAction = ActionsUnion<typeof TARGETING_ACTIONS>;
