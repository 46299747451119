import { useEffect, useMemo, useState } from 'react';
import { Modal, Accordion, Card, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import { commonModalPopup, IStore, loginReducerType } from 'types';
import { ToggleSwitch } from 'widgets/Switch';
import { updateUserDetailRequest } from 'actions';
import { alertBoxCall, Loading } from 'components';
import { CustomDropdown } from 'widgets/CustomDropdown';
import {
  ALERT_BOX_TYPES,
  VALID_PHONE_NUMBER_REGEX,
  ALLOW_ONLY_NUMBERS_REGEX,
  ACCORDIAN_EVENTS,
  RIPPLE_COLOR,
  USER_OWNERSHIP,
  NS_ENTER_PHONE_NUMBER_WARN_MSG,
  NS_INVALID_PHONE_NUMBER_WARN_MSG,
  NOTIFICATION_DEFAULT_DATE,
  USER_NOTIFICATION_SETTINGS_FIELD_TYPE,
  HELP_URL,
  APP_TITLE,
  VALID_APP_URL_TITLES
} from 'utils/constants';
import { compareTwoObjects, formatPhoneNumber, parsePhoneNumbers, handleLinkNavigation } from 'utils/helpers';
import { useAccountSwitcherData, useShowDefaultNotificationSettings } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { surveyResultDailySummary, surveyResultEmailOptions } from 'revv/utils';
import { userProfileSettingsRequest } from 'revv/actions';

export const NotificationSettingsModalPopup = ({ isModalShow, handleModalClose, isNeedHelpShow }: commonModalPopup.IDetailModalPopup) => {
  const dispatch = useDispatch();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();
  const showDefaultNotificationSettings = useShowDefaultNotificationSettings();

  const isUserDataUpdate = useSelector((state: IStore) => state.login.isUserDataUpdate);
  const activeUser = useSelector((state: IStore) => state.login.activeUser);
  const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const revvActiveUser = useSelector((state: IStore) => state.revv.login.activeUser);
  const profileConfigResponse = useSelector((state: IStore) => state.revv.user.profileSettingsResponse);
  const isProfileSettingReqFetching = useSelector((state: IStore) => state.revv.user.isProfileSettingReqFetching);
  const isRevvAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const showRevv = useSelector((state: IStore) => state.accountSwitcher.showRevv);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter?.isRevvConnectedToCurrentFranchisor || false);

  const [notifyDataObj, setNotifyDataObj] = useState<null | loginReducerType.ILoginResponseUser>(null);
  const [saveEnable, setSaveEnable] = useState(false);
  const [smsSave, setSMSSave] = useState(false);
  const [reviewFacebookSave, setReviewFacebookSave] = useState(false);
  const [reportsSave, setReportsSave] = useState(false);
  const [openAccordians, setOpenAccordians] = useState<string[]>([
    ACCORDIAN_EVENTS.EVENT_ZERO,
    ACCORDIAN_EVENTS.EVENT_ONE,
    ACCORDIAN_EVENTS.EVENT_TWO,
    ACCORDIAN_EVENTS.EVENT_THREE,
    ACCORDIAN_EVENTS.EVENT_FOUR,
    ACCORDIAN_EVENTS.EVENT_FIVE
  ]);
  const [phoneNumTouched, setPhoneNumTouched] = useState(false);
  const [revvSurveySettingsState, setRevvSettingSettingVal] = useState<{ [key: string]: { [key: string]: string } | null }>({ surveyResultEmail: null, surveyResultSummery: null });

  const notificationsRestrictions = useMemo(() => {
    const restrictions = {
      isReviewsSettingsDisabled: false,
      isEmailSettingsDisabled: false,
      isSMSSettingsDisabled: false,
      isReportsSettingsDisabled: false
    };

    const siteRestrictions = menuAccess?.site_restrictions;
    if (showDefaultNotificationSettings) {
      restrictions.isReviewsSettingsDisabled = true;
      restrictions.isEmailSettingsDisabled = true;
      restrictions.isReportsSettingsDisabled = true;
    } else if (id && userOwnership && isValidId) {
      if (userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        restrictions.isReviewsSettingsDisabled = Boolean(siteRestrictions?.hub_user?.includes('notifications_reviews_settings'));
        restrictions.isEmailSettingsDisabled = Boolean(siteRestrictions?.hub_user?.includes('notifications_email_settings'));
        restrictions.isSMSSettingsDisabled = Boolean(siteRestrictions?.hub_user?.includes('notifications_sms_settings'));
        restrictions.isReportsSettingsDisabled = Boolean(siteRestrictions?.hub_user?.includes('notifications_reports_settings'));
      } else if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
        restrictions.isReviewsSettingsDisabled = Boolean(siteRestrictions?.location_user?.includes('notifications_reviews_settings'));
        restrictions.isEmailSettingsDisabled = Boolean(siteRestrictions?.location_user?.includes('notifications_email_settings'));
        restrictions.isSMSSettingsDisabled = Boolean(siteRestrictions?.location_user?.includes('notifications_sms_settings'));
        restrictions.isReportsSettingsDisabled = Boolean(siteRestrictions?.location_user?.includes('notifications_reports_settings'));
      }
    }
    return restrictions;
  }, [showDefaultNotificationSettings, id, userOwnership, menuAccess, isValidId]);

  useEffect(() => {
    if (activeUser) {
      setNotifyDataObj({
        ...activeUser,
        ...(showDefaultNotificationSettings && { rallio_local_assets_notification_enrolled_at: NOTIFICATION_DEFAULT_DATE })
      });
    }
  }, [showDefaultNotificationSettings, activeUser]);

  const surveyResult = useMemo(() => {
    if (profileConfigResponse) {
      return Object.keys(profileConfigResponse.survey).filter((a: string) => profileConfigResponse?.survey[a]);
    } else {
      return '';
    }
  }, [profileConfigResponse]);

  const revvSurveySettingValue = useMemo(() => {
    const surveyEmail = surveyResult.length > 1 ? 'allResults' : surveyResultEmailOptions.find((it) => it.value === surveyResult[0])?.value || 'doNotDisturb';
    return {
      surveyResultEmail: surveyEmail,
      surveyResultSummery: profileConfigResponse?.dailySurveySummaries ? surveyResultDailySummary[0] : surveyResultDailySummary[1]
    };
  }, [profileConfigResponse, surveyResult]);

  const isRevvProfileSettingEnabled = useMemo(() => {
    return (
      !(revvActiveUser?.role === 'superadmin') && (revvActiveUser?.role === 'admin' || revvActiveUser?.role === 'employee') && isRevvAuthenticated && (isRevvConnectedToCurrentFranchisor || showRevv)
    );
  }, [revvActiveUser?.role, isRevvAuthenticated, isRevvConnectedToCurrentFranchisor, showRevv]);

  useEffect(() => {
    if (revvActiveUser && isRevvProfileSettingEnabled) {
      dispatch(userProfileSettingsRequest({ id: String(revvActiveUser.id) }));
    }
  }, [revvActiveUser, isRevvProfileSettingEnabled, dispatch]);

  const isRevvSettingsUpdated = useMemo(() => {
    return (
      (revvSurveySettingsState.surveyResultEmail && revvSurveySettingValue.surveyResultEmail !== revvSurveySettingsState.surveyResultEmail.value) ||
      (revvSurveySettingsState.surveyResultSummery && revvSurveySettingValue.surveyResultSummery.value !== revvSurveySettingsState.surveyResultSummery.value)
    );
  }, [revvSurveySettingsState, revvSurveySettingValue]);

  useEffect(() => {
    if (notifyDataObj && activeUser) {
      setSMSSave(
        showDefaultNotificationSettings
          ? showDefaultNotificationSettings
          : activeUser.mobile_phone !== notifyDataObj.mobile_phone || notifyDataObj.rallio_local_assets_notification_enrolled_at !== activeUser.rallio_local_assets_notification_enrolled_at
      );
      setReviewFacebookSave(notifyDataObj.review_alert_ratings?.length !== activeUser.review_alert_ratings?.length);
      setReportsSave(notifyDataObj.receive_monthly_enduser_report !== activeUser.receive_monthly_enduser_report);
      const isChanged = !compareTwoObjects(activeUser, notifyDataObj);
      setSaveEnable(isChanged);
    }
  }, [showDefaultNotificationSettings, activeUser, notifyDataObj]); // eslint-disable-line

  const removeReviewItem = (reviewAlertArr: number[], item: number) => {
    const tempReviewArr = [...reviewAlertArr];
    if (reviewAlertArr.includes(item)) {
      const index = reviewAlertArr.indexOf(item);
      if (index !== -1) tempReviewArr.splice(index, 1);
    } else tempReviewArr.push(item);
    return tempReviewArr;
  };

  const handleToggleChange = (fieldType: keyof loginReducerType.ILoginResponseUser, fieldVal: boolean | number | string) => {
    if (notifyDataObj) {
      let modifiedFieldVal: boolean | number | number[] | string = fieldVal;
      if (fieldType === USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION && notifyDataObj.review_alert_ratings && typeof fieldVal === 'number') {
        modifiedFieldVal = removeReviewItem(notifyDataObj.review_alert_ratings, fieldVal);
      }
      setNotifyDataObj({ ...notifyDataObj, [fieldType]: modifiedFieldVal });
    }
  };

  const handleSavePreferences = () => {
    if (
      notifyDataObj &&
      notifyDataObj.rallio_local_assets_notification_enrolled_at &&
      notifyDataObj.rallio_local_assets_notification_enrolled_at !== NOTIFICATION_DEFAULT_DATE &&
      !notifyDataObj.mobile_phone
    ) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, NS_ENTER_PHONE_NUMBER_WARN_MSG);
    } else if (
      notifyDataObj &&
      notifyDataObj.rallio_local_assets_notification_enrolled_at &&
      notifyDataObj.rallio_local_assets_notification_enrolled_at !== NOTIFICATION_DEFAULT_DATE &&
      parsePhoneNumbers(notifyDataObj?.mobile_phone)?.length < 10
    ) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, NS_INVALID_PHONE_NUMBER_WARN_MSG);
    } else if (notifyDataObj && !isUserDataUpdate) {
      dispatch(updateUserDetailRequest({ userId: notifyDataObj.id, reqPayload: notifyDataObj, isNotificationSettings: true }));
    }
  };

  const handleRevvFormSubmit = (value: { [key: string]: { [key: string]: string } | null }) => {
    if (isRevvProfileSettingEnabled && activeUser) {
      const surveyEmail = value?.surveyResultEmail?.value;
      const surveySummery = value.surveyResultSummery?.value === surveyResultDailySummary[0].value;
      const data = surveyResultEmailOptions.slice(1, 5).map((it) => {
        if (surveyEmail === surveyResultEmailOptions[5].value) {
          return { [it.value]: false };
        } else if (surveyEmail === surveyResultEmailOptions[0].value) {
          if (it.value === surveyResultEmailOptions[1].value || it.value === surveyResultEmailOptions[2].value) {
            return { [it.value]: true };
          } else {
            return { [it.value]: false };
          }
        } else {
          return { [it.value]: it.value === surveyEmail };
        }
      });
      const dataObj = Object.assign({}, ...data);
      dispatch(
        userProfileSettingsRequest({
          id: String(revvActiveUser?.id),
          attributes: {
            ...dataObj,
            dailySurveySummaries: surveySummery
          },
          callBack: (res) => {
            if (res.isSuccess) {
              alertBoxCall(ALERT_BOX_TYPES.SUCCESS, res?.msg || '');
            } else {
              alertBoxCall(ALERT_BOX_TYPES.ERROR, res?.msg || '');
            }
          }
        })
      );
    }
  };

  const handleChangePhoneNum = (val: string) => {
    if (notifyDataObj) {
      setNotifyDataObj({ ...notifyDataObj, mobile_phone: val.replace(ALLOW_ONLY_NUMBERS_REGEX, '') || null });
    }
    if (!phoneNumTouched) setPhoneNumTouched(true);
  };

  const handleSetAccordians = (currentAccord: string) => {
    let newAcc = [...openAccordians];
    if (newAcc.includes(currentAccord)) {
      newAcc = newAcc.filter((it) => it !== currentAccord);
    } else {
      newAcc.push(currentAccord);
    }
    setOpenAccordians(newAcc);
  };

  const getSmsNotificationValue = () => {
    return notifyDataObj && (!notifyDataObj.rallio_local_assets_notification_enrolled_at! || notifyDataObj.rallio_local_assets_notification_enrolled_at === NOTIFICATION_DEFAULT_DATE)
      ? `${new Date()}`
      : NOTIFICATION_DEFAULT_DATE;
  };

  return (
    <Modal className="notify-settings-section-wrp" aria-labelledby="contained-modal-title-vcenter" centered size="lg" show={isModalShow} animation={false} onHide={handleModalClose}>
      {!showDefaultNotificationSettings && (
        <div className="mod__close--icon">
          <ImageValidation defaultImg="modal-close2" customClassname="mod__close--img" customName="cancel" onClick={handleModalClose} />
        </div>
      )}
      <Modal.Body>
        <div className={`notification-settings-content-section${isUserDataUpdate || isProfileSettingReqFetching ? ` pointer-events-none` : ''}`}>
          {isUserDataUpdate || isProfileSettingReqFetching ? <Loading /> : null}
          <div className={`not__settings--top ${isNeedHelpShow ? '' : 'p-head-only'}`}>
            <h3 className="not__top--title">Notification Settings</h3>
            {isNeedHelpShow && !VALID_APP_URL_TITLES.includes(APP_TITLE) ? (
              <aside className={`not__top--right`}>
                <div className="not__top--icon">
                  <ImageValidation isImgValid defaultImg="need-help" customName="need-help" />
                </div>
                <div
                  className="not__right--desc"
                  onClick={() =>
                    userOwnership === USER_OWNERSHIP.FRANCHISOR
                      ? handleLinkNavigation(`${HELP_URL}/articles/5992739-notifications-settings-for-hub-admins`)
                      : userOwnership === USER_OWNERSHIP.ACCOUNT
                      ? handleLinkNavigation(`${HELP_URL}/articles/6029808-notifications-settings-for-location-admins`)
                      : handleLinkNavigation(`${HELP_URL}`)
                  }
                >
                  <a href="/#" className="need__help--text">
                    Need Help?
                  </a>
                  <span className="not__right--text">Check out our Knowledge Base!</span>
                </div>
              </aside>
            ) : null}
          </div>

          <Accordion
            defaultActiveKey={[ACCORDIAN_EVENTS.EVENT_ZERO, ACCORDIAN_EVENTS.EVENT_ONE, ACCORDIAN_EVENTS.EVENT_TWO, ACCORDIAN_EVENTS.EVENT_THREE, ACCORDIAN_EVENTS.EVENT_FOUR]}
            activeKey={openAccordians}
            alwaysOpen
          >
            {/* 1st accordion */}
            {!notificationsRestrictions.isSMSSettingsDisabled && notifyDataObj && notifyDataObj?.rlc_account ? (
              <Card className={`acc-card  ${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_ZERO) ? 'active' : ''}`}>
                <Card.Header onClick={() => handleSetAccordians(ACCORDIAN_EVENTS.EVENT_ZERO)}>
                  <Accordion.Item className="card__header--wrp" eventKey={ACCORDIAN_EVENTS.EVENT_ZERO}>
                    <div className="acc__arrow--icon">
                      <ImageValidation
                        defaultImg="arrow-down-grey"
                        customName={'down arrow'}
                        customClassname={`${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_ZERO) ? 'arrow-right' : 'arrow-point'}`}
                      />
                    </div>
                    Receive SMS Notifications
                  </Accordion.Item>
                </Card.Header>
                <Accordion.Collapse eventKey={ACCORDIAN_EVENTS.EVENT_ZERO} className="not__acc--body">
                  <Card.Body>
                    <p className="para__text">
                      Turning on this feature will send the user SMS notifications when the assets for your location falls below expected counts, so that you can upload assets for the strategist to
                      use when they create local content for social media.
                    </p>
                    <p className="para__text">Turning off this feature will turn off the SMS notifications for this.</p>
                    {notifyDataObj && notifyDataObj.rallio_local_assets_notification_enrolled_at && notifyDataObj.rallio_local_assets_notification_enrolled_at !== NOTIFICATION_DEFAULT_DATE && (
                      <div
                        className={`form-group form-field-item ${
                          (!phoneNumTouched ||
                            (notifyDataObj.rallio_local_assets_notification_enrolled_at && notifyDataObj.rallio_local_assets_notification_enrolled_at !== NOTIFICATION_DEFAULT_DATE)) &&
                          notifyDataObj &&
                          parsePhoneNumbers(notifyDataObj?.mobile_phone)?.length === 10
                            ? ''
                            : 'erroritem'
                        }`}
                      >
                        <input
                          type="tel"
                          maxLength={notifyDataObj && parsePhoneNumbers(notifyDataObj?.mobile_phone)?.length === 10 ? 10 : undefined}
                          name="PhoneNumber"
                          className={`form-control ${notifyDataObj && notifyDataObj.mobile_phone ? 'used' : ''}`}
                          autoComplete="off"
                          value={notifyDataObj && notifyDataObj.mobile_phone ? formatPhoneNumber(notifyDataObj.mobile_phone) : ''}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangePhoneNum(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            const regexData = new RegExp(VALID_PHONE_NUMBER_REGEX);
                            const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                            if (!regexData.test(enteredKey)) {
                              event.preventDefault();
                              return false;
                            }
                          }}
                        />
                        <span className="fltlabels">
                          Phone Number<span className="mandatory">*</span>
                        </span>
                      </div>
                    )}
                    <div className="sms-notify-togle-section">
                      <h2>SMS Notifications</h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-1'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.SMS_NOTIFICATION, getSmsNotificationValue())}
                            checkedStatus={
                              notifyDataObj && notifyDataObj.rallio_local_assets_notification_enrolled_at && notifyDataObj.rallio_local_assets_notification_enrolled_at !== NOTIFICATION_DEFAULT_DATE
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="save-btn ap-actions">
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!smsSave || isUserDataUpdate ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary size-xs ${!smsSave || isUserDataUpdate ? 'disabled' : ''}`} onClick={() => handleSavePreferences()}>
                          Save
                        </button>
                      </CustomRippleButton>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ) : null}

            {/* 2nd accordion */}
            {!VALID_APP_URL_TITLES.includes(APP_TITLE) && !notificationsRestrictions.isEmailSettingsDisabled && (
              <Card className={`acc-card  ${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_ONE) ? 'active' : ''}`}>
                <Card.Header onClick={() => handleSetAccordians(ACCORDIAN_EVENTS.EVENT_ONE)}>
                  <Accordion.Item className="card__header--wrp" eventKey={ACCORDIAN_EVENTS.EVENT_ONE}>
                    <div className="acc__arrow--icon">
                      <ImageValidation
                        defaultImg="arrow-down-grey"
                        customName={'down arrow'}
                        customClassname={`${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_ONE) ? 'arrow-right' : 'arrow-point'}`}
                      />
                    </div>
                    Receive Email Notifications
                  </Accordion.Item>
                </Card.Header>
                <Accordion.Collapse eventKey={ACCORDIAN_EVENTS.EVENT_ONE} className="not__acc--body">
                  <Card.Body>
                    <p className="para__text">
                      Turning on this feature will send you all notifications for the locations you have access to, such as content approval emails, disconnection notifications, and review
                      notifications.
                    </p>
                    <p className="para__text">
                      Turning off this feature will turn off all notifications to your account. (If you have access to a hub, you will receive notifications for all locations you can access.)
                    </p>
                    <div className="sms-notify-togle-section">
                      <h2>Email Notifications</h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-2'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.EMAIL_NOTIFICATION, !notifyDataObj?.receive_notification_emails)}
                            checkedStatus={notifyDataObj && notifyDataObj.receive_notification_emails ? notifyDataObj.receive_notification_emails : false}
                          />
                        </div>
                      </div>
                    </div>
                    {activeUser?.account_owner && (
                      <div className="sms-notify-togle-section">
                        <h2>Brand Communication Emails</h2>
                        <div className="r-sec switch-wrp">
                          <div>
                            <ToggleSwitch
                              id={'material-switch-2'}
                              width={30}
                              height={17.5}
                              handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.BRAND_COMMUNICATIONS, !notifyDataObj?.receive_communication_emails)}
                              checkedStatus={notifyDataObj && notifyDataObj.receive_communication_emails ? notifyDataObj.receive_communication_emails : false}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="save-btn ap-actions">
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box  ${!saveEnable || isUserDataUpdate ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary size-xs ${!saveEnable || isUserDataUpdate ? 'disabled' : ''}`} onClick={() => handleSavePreferences()}>
                          Save
                        </button>
                      </CustomRippleButton>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}

            {/* 3rd accordion */}
            {!notificationsRestrictions.isReviewsSettingsDisabled && (
              <Card className={`acc-card  ${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_TWO) ? 'active' : ''}`}>
                <Card.Header onClick={() => handleSetAccordians(ACCORDIAN_EVENTS.EVENT_TWO)}>
                  <Accordion.Item className="card__header--wrp" eventKey={ACCORDIAN_EVENTS.EVENT_TWO}>
                    <div className="acc__arrow--icon">
                      <ImageValidation
                        defaultImg="arrow-down-grey"
                        customName={'down arrow'}
                        customClassname={`${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_TWO) ? 'arrow-right' : 'arrow-point'}`}
                      />
                    </div>
                    Review Notifications
                  </Accordion.Item>
                </Card.Header>
                <Accordion.Collapse eventKey={ACCORDIAN_EVENTS.EVENT_TWO} className="not__acc--body">
                  <Card.Body>
                    <p className="para__text">By checking the options below, you will receive reviews with those ratings. To receive all reviews, turn on all the ratings.</p>
                    <p className="para__text">To only receive certain rating levels, turn off the ratings you don't wish to receive.</p>
                    <p className="para__text">Facebook no longer uses star rating. They now use “Recommend” or “Does Not Recommend”. Select notifications for Facebook Recommendations below.</p>
                    <div className="sms-notify-togle-section">
                      <h2>
                        1 Star
                        <span className="star-filled" />
                      </h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-3'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION, 1)}
                            checkedStatus={notifyDataObj && notifyDataObj.review_alert_ratings && notifyDataObj.review_alert_ratings.includes(1) ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sms-notify-togle-section">
                      <h2>
                        2 Stars
                        <span className="star-filled" />
                        <span className="star-filled" />
                      </h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-4'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION, 2)}
                            checkedStatus={notifyDataObj && notifyDataObj.review_alert_ratings && notifyDataObj.review_alert_ratings.includes(2) ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sms-notify-togle-section">
                      <h2>
                        3 Stars
                        <span className="star-filled" />
                        <span className="star-filled" />
                        <span className="star-filled" />
                      </h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-5'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION, 3)}
                            checkedStatus={notifyDataObj && notifyDataObj.review_alert_ratings && notifyDataObj.review_alert_ratings.includes(3) ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sms-notify-togle-section">
                      <h2>
                        4 Stars
                        <span className="star-filled" />
                        <span className="star-filled" />
                        <span className="star-filled" />
                        <span className="star-filled" />
                      </h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-6'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION, 4)}
                            checkedStatus={notifyDataObj && notifyDataObj.review_alert_ratings && notifyDataObj.review_alert_ratings.includes(4) ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sms-notify-togle-section">
                      <h2>
                        5 Stars
                        <span className="star-filled" />
                        <span className="star-filled" />
                        <span className="star-filled" />
                        <span className="star-filled" />
                        <span className="star-filled" />
                      </h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-7'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION, 5)}
                            checkedStatus={notifyDataObj && notifyDataObj.review_alert_ratings && notifyDataObj.review_alert_ratings.includes(5) ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="save-btn ap-actions">
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!reviewFacebookSave || isUserDataUpdate ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary size-xs ${!reviewFacebookSave || isUserDataUpdate ? 'disabled' : ''}`} onClick={() => handleSavePreferences()}>
                          Save
                        </button>
                      </CustomRippleButton>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}

            {/* 4th accordion */}
            {!notificationsRestrictions.isReviewsSettingsDisabled && (
              <Card className={`acc-card  ${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_THREE) ? 'active' : ''}`}>
                <Card.Header onClick={() => handleSetAccordians(ACCORDIAN_EVENTS.EVENT_THREE)}>
                  <Accordion.Item className="card__header--wrp" eventKey={ACCORDIAN_EVENTS.EVENT_THREE}>
                    <div className="acc__arrow--icon">
                      <ImageValidation
                        defaultImg="arrow-down-grey"
                        customName={'down arrow'}
                        customClassname={`${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_THREE) ? 'arrow-right' : 'arrow-point'}`}
                      />
                    </div>
                    Facebook Notifications
                  </Accordion.Item>
                </Card.Header>
                <Accordion.Collapse eventKey={ACCORDIAN_EVENTS.EVENT_THREE} className="not__acc--body">
                  <Card.Body>
                    <div className="sms-notify-togle-section">
                      <h2>Recommend Reviews</h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-8'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION, 1000)}
                            checkedStatus={notifyDataObj && notifyDataObj.review_alert_ratings && notifyDataObj.review_alert_ratings.includes(1000) ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sms-notify-togle-section">
                      <h2>Not Recommend Reviews</h2>
                      <div className="r-sec switch-wrp">
                        <div>
                          <ToggleSwitch
                            id={'material-switch-9'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REVIEWS_NOTIFICATION, -1000)}
                            checkedStatus={notifyDataObj && notifyDataObj.review_alert_ratings && notifyDataObj.review_alert_ratings.includes(-1000) ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    {!(activeUser?.admin || activeUser?.content_writer || activeUser?.franchisor) && (
                      <div className="sms-notify-togle-section">
                        <h2>Direct Messages</h2>
                        <div className="r-sec switch-wrp">
                          <div>
                            <ToggleSwitch
                              id={'material-switch-10'}
                              width={30}
                              height={17.5}
                              handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.DIRECT_MESSAGE, !notifyDataObj?.direct_message_notification)}
                              checkedStatus={notifyDataObj && notifyDataObj.direct_message_notification ? notifyDataObj.direct_message_notification : false}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="save-btn ap-actions">
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!reviewFacebookSave || isUserDataUpdate ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary size-xs ${!reviewFacebookSave || isUserDataUpdate ? 'disabled' : ''}`} onClick={() => handleSavePreferences()}>
                          Save
                        </button>
                      </CustomRippleButton>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}

            {/* 5th accordion */}
            {!notificationsRestrictions.isReportsSettingsDisabled && (
              <Card className={`acc-card  ${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_FOUR) ? 'active' : ''}`}>
                <Card.Header onClick={() => handleSetAccordians(ACCORDIAN_EVENTS.EVENT_FOUR)}>
                  <Accordion.Item className="card__header--wrp" eventKey={ACCORDIAN_EVENTS.EVENT_FOUR}>
                    <div className="acc__arrow--icon">
                      <ImageValidation
                        defaultImg="arrow-down-grey"
                        customName={'down arrow'}
                        customClassname={`${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_FOUR) ? 'arrow-right' : 'arrow-point'}`}
                      />
                    </div>
                    Reports
                  </Accordion.Item>
                </Card.Header>
                <Accordion.Collapse eventKey={ACCORDIAN_EVENTS.EVENT_FOUR} className="not__acc--body">
                  <Card.Body>
                    <div className="sms-notify-togle-section">
                      <h2>Monthly Reports</h2>
                      <div className="r-sec switch-wrp">
                        <label>
                          <ToggleSwitch
                            id={'material-switch-11'}
                            width={30}
                            height={17.5}
                            handleOnChange={() => handleToggleChange(USER_NOTIFICATION_SETTINGS_FIELD_TYPE.REPORT_NOTIFICATION, !notifyDataObj?.receive_monthly_enduser_report)}
                            checkedStatus={notifyDataObj && notifyDataObj.receive_monthly_enduser_report ? notifyDataObj.receive_monthly_enduser_report : false}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="save-btn ap-actions">
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!reportsSave || isUserDataUpdate ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary size-xs ${!reportsSave || isUserDataUpdate ? 'disabled' : ''}`} onClick={() => handleSavePreferences()}>
                          Save{' '}
                        </button>
                      </CustomRippleButton>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}

            {isRevvProfileSettingEnabled ? (
              <Card className={`acc-card  ${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_FIVE) ? 'active' : ''}`}>
                <Card.Header onClick={() => handleSetAccordians(ACCORDIAN_EVENTS.EVENT_FIVE)}>
                  <Accordion.Item className="card__header--wrp" eventKey={ACCORDIAN_EVENTS.EVENT_FIVE}>
                    <div className="acc__arrow--icon">
                      <ImageValidation
                        defaultImg="arrow-down-grey"
                        customName={'down arrow'}
                        customClassname={`${openAccordians.includes(ACCORDIAN_EVENTS.EVENT_FIVE) ? 'arrow-right' : 'arrow-point'}`}
                      />
                    </div>
                    Revv Settings
                  </Accordion.Item>
                </Card.Header>
                <Accordion.Collapse eventKey={ACCORDIAN_EVENTS.EVENT_FIVE} className="not__acc--body">
                  <Card.Body>
                    <Form className="revv__notify--wrp">
                      <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
                        <CustomDropdown
                          classNamePrefix="cs-select"
                          id="dl-store-details-country-dropdown"
                          placeholder={''}
                          isSearchable
                          value={revvSurveySettingsState.surveyResultEmail || surveyResultEmailOptions.find((it) => it.value === revvSurveySettingValue.surveyResultEmail)}
                          options={surveyResultEmailOptions ? surveyResultEmailOptions.map((it) => ({ value: it.value, label: it.label })) : []}
                          onChange={(val: any) => {
                            setRevvSettingSettingVal({ ...revvSurveySettingsState, surveyResultEmail: val });
                          }}
                        />
                        <span className={`fltlabels`}>Survey Results - Email</span>
                      </div>
                      <div className={`form-group form-field-item fg-dropdown m-align-items cs-item cs-clean`}>
                        <CustomDropdown
                          classNamePrefix="cs-select"
                          id="dl-store-details-country-dropdown"
                          placeholder={''}
                          options={surveyResultDailySummary ? surveyResultDailySummary.map((it) => ({ value: it.value, label: it.label })) : []}
                          value={revvSurveySettingsState.surveyResultSummery || revvSurveySettingValue.surveyResultSummery}
                          onChange={(val: any) => {
                            setRevvSettingSettingVal({ ...revvSurveySettingsState, surveyResultSummery: val });
                          }}
                        />
                        <span className={`fltlabels`}>Survey Results - Daily Summary</span>
                      </div>
                    </Form>
                    <div className="save-btn ap-actions">
                      <CustomRippleButton rippleClass={`ripple-unset ac-primary-box ${!isRevvSettingsUpdated ? 'pointer-events-none' : ''}`} custColor={RIPPLE_COLOR.primary}>
                        <button className={`ac-btn ac-primary size-xs ${!isRevvSettingsUpdated ? 'disabled' : ''}`} onClick={() => handleRevvFormSubmit(revvSurveySettingsState)}>
                          Save
                        </button>
                      </CustomRippleButton>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ) : null}
          </Accordion>
          {!showDefaultNotificationSettings && (
            <div className="saveall save-btn ap-actions pb-40 pe-40 mt-0">
              <CustomRippleButton
                rippleClass={`ripple-unset ac-primary-box ${(!saveEnable || isUserDataUpdate) && !isRevvSettingsUpdated ? 'pointer-events-none' : ''}`}
                custColor={RIPPLE_COLOR.primary}
              >
                <Button
                  className={`ac-btn ac-primary size-xs ${(!saveEnable || isUserDataUpdate) && !isRevvSettingsUpdated ? 'disabled' : ''}`}
                  onClick={() => {
                    if (isRevvSettingsUpdated) {
                      handleRevvFormSubmit(revvSurveySettingsState);
                    }
                    if (saveEnable && !isUserDataUpdate) {
                      handleSavePreferences();
                    }
                  }}
                >
                  Save All
                </Button>
              </CustomRippleButton>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
