import { put, takeLatest } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { logoutRequest, exceptionHandlerRequest } from 'actions';
import { apiCall, defaultHeader, API } from 'utils/helpers';
import { ALERT_BOX_TYPES, DONE_SUCCESS_MSG, PAGINATION_PER_PAGE, PARTNER_BANNER_EXCEPTION_ERROR, SETTINGS_FAILURE_MSG } from 'utils/constants';
import {
  settingsGetPartnerBannerFranchisorsFailure,
  settingsGetPartnerBannerFranchisorsResponse,
  settingsGetPartnerBannersCSListFailure,
  settingsGetPartnerBannersCSListResponse,
  settingsEnrollPartnerBannersFailure,
  settingsEnrollPartnerBannersResponse,
  createOrUpdatePartnerBannerFailure,
  createOrUpdatePartnerBannerResponse
} from 'actions/modules/settings-partner-banners';
import { ISagaPayload } from 'types/common/api';
import { alertBoxCall } from 'components';

function* sendGetSettingsPartnerBannersListRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = { ...API.getPartnersBannerList };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    const params = {
      page: payload.page,
      per: PAGINATION_PER_PAGE
    };
    const response = yield apiCall({ headers, params, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(settingsGetPartnerBannersCSListResponse({ page: payload.page, response: response.data.franchisors }));
    } else {
      yield put(settingsGetPartnerBannersCSListFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(PARTNER_BANNER_EXCEPTION_ERROR));
  }
}

function* sendGetSettingsPartnersBannerFranchisorsRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = { ...API.getPartnerBannerFranchisors };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    const params = {
      page: payload.page,
      per: PAGINATION_PER_PAGE,
      search: payload.search
    };
    const response = yield apiCall({ headers, params, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(settingsGetPartnerBannerFranchisorsResponse({ page: payload.page, response: response.data.franchisors }));
    } else {
      yield put(settingsGetPartnerBannerFranchisorsFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(PARTNER_BANNER_EXCEPTION_ERROR));
  }
}

function* sendUpdateSettingsPartnersEnrollRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const updatedPayload = { ...payload };
    delete updatedPayload?.id;
    const apiObj = { ...API.enrollPartnerBanner };
    apiObj.apiPath = apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data: updatedPayload, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(settingsEnrollPartnerBannersResponse(updatedPayload));
    } else {
      yield put(settingsEnrollPartnerBannersFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(PARTNER_BANNER_EXCEPTION_ERROR));
  }
}

function* sendCreateOrUpdateSettingsPartnerBannerRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = payload.partnerBannerId ? { ...API.updatePartnerBanner } : { ...API.createPartnerBanner };
    apiObj.apiPath = payload.partnerBannerId ? apiObj.apiPath.replace(':id', payload.id).replace(':partner_banner_id', payload.partnerBannerId) : apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, data: payload.reqPayload, ...apiObj });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, DONE_SUCCESS_MSG);
      yield put(createOrUpdatePartnerBannerResponse(response.data.partner_banner));
    } else {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, SETTINGS_FAILURE_MSG);
      yield put(createOrUpdatePartnerBannerFailure(response.data.message));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(PARTNER_BANNER_EXCEPTION_ERROR));
  }
}

export function* takeSettingsPartnerBannersRequest() {
  yield takeLatest(types.GET_PARTNER_BANNERS_CS_LIST_REQUEST, sendGetSettingsPartnerBannersListRequest);
  yield takeLatest(types.GET_PARTNER_BANNER_FRANCHISORS_REQUEST, sendGetSettingsPartnersBannerFranchisorsRequest);
  yield takeLatest(types.ENROLL_PARTNER_BANNERS_REQUEST, sendUpdateSettingsPartnersEnrollRequest);
  yield takeLatest(types.CREATE_OR_UPDATE_PARTNER_BANNER_REQUEST, sendCreateOrUpdateSettingsPartnerBannerRequest);
}
