import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { SettingsHashTagsAction } from 'actions';
import { settingsHashTagsReducerTypes } from 'types';
import { SettingsHashtagsActionsVal } from 'utils';

const initialState: settingsHashTagsReducerTypes.ISettingsHashTagsReducer = {
  isFetching: false,
  isUpdating: false,
  hashTagsState: { action_status: SettingsHashtagsActionsVal.OFF, saved_hashtags: [] },
  error: null,
  message: ''
};

export const settingsHashTagsReducer: Reducer<settingsHashTagsReducerTypes.ISettingsHashTagsReducer, SettingsHashTagsAction> = (
  state: settingsHashTagsReducerTypes.ISettingsHashTagsReducer = initialState,
  action: SettingsHashTagsAction
) => {
  switch (action.type) {
    case types.GET_HASHTAGS_LIST_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true
      };
    case types.GET_HASHTAGS_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        hashTagsState: {
          action_status: action.payload.action_status || SettingsHashtagsActionsVal.OFF,
          saved_hashtags: action.payload.saved_hashtags?.length ? action.payload.saved_hashtags.split(',').sort() : []
        }
      };
    case types.GET_HASHTAGS_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        hashTagsState: initialState.hashTagsState,
        error: action.payload
      };
    case types.CREATE_OR_UPDATE_HASHTAGS_REQUEST:
      return {
        ...state,
        error: null,
        isUpdating: true
      };
    case types.CREATE_OR_UPDATE_HASHTAGS_RESPONSE:
      return {
        ...state,
        isUpdating: false,
        hashTagsState: {
          action_status: action.payload.action_status || SettingsHashtagsActionsVal.OFF,
          saved_hashtags: action.payload.saved_hashtags?.length ? action.payload.saved_hashtags.split(',').sort() : []
        }
      };
    case types.CREATE_OR_UPDATE_HASHTAGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };
    case types.HASHTAGS_RESET_ALL:
      return {
        ...state,
        ...initialState,
        hashTagsState: { ...initialState.hashTagsState, action_status: state.hashTagsState.action_status }
      };
    default:
      return state;
  }
};
