import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { IStore, postsContainerTypes } from 'types';
import {
  getUpdatedTagsList,
  getEditOrSchedulePost,
  getCreatorLinkData,
  getAddMediaListForCreator,
  getAccountUpdatedPostDetails,
  getInitialSelectedContentData,
  atLeastApproved,
  canBeScheduled,
  canScheduleAccountPost,
  isSocialMediaActive,
  getPostContentTextField,
  getPostPersonalizedTextField,
  getValidContentData
} from 'utils/helpers';
import { alertBoxCall, BrandSchedulePostModal, ModalPopup } from 'components';
import {
  ROUTES,
  USER_OWNERSHIP,
  AP_ACTION_TYPES,
  ALERT_BOX_TYPES,
  AUTO_SCHEDULE_DISABLE_PACKAGE,
  MANUALLY_SCHEDULE_DISABLE_PACKAGE,
  COMMON_SOCIAL_PROVIDER_TYPE,
  POSTS_PACKAGE_EXPIRED_MSG,
  POSTS_SYN_CONTENT_OBJ,
  MODAL_BOX_TYPES,
  POSTS_SYNDICATE_CONTENT_MSG,
  RIPPLE_COLOR,
  CreatorInstaPhotoType,
  POST_DETAIL_TYPE,
  IAIResultsTab,
  SIDE_NAV_NAME,
  IAISugesstionTabs,
  SettingsHashtagsActionsVal
} from 'utils/constants';
import {
  addPostSetTags,
  addPostGetActionTypeForPost,
  addPostSetFranPageType,
  addPostUpdateSavedPostDetails,
  addPostSetLinkData,
  addPostGetEditSavedPostObj,
  addPostUpdateContentObj,
  addPostSetSelectedMediaList,
  postDetailDataReset,
  addPostGetSelectedFBTagList,
  addPostResetAll,
  addPostFranResetAll,
  addPostActionsResetAll,
  addPostGetSelectedContentObj,
  addPostSetCustomTextEditorData,
  addPostSetInstaPhotoPreviewObj,
  addPostSetCampaignData,
  addPostSetPersonalizedTextEditorData,
  setCreatorAITabName,
  aiShowImageTab,
  addPostSaveMessageReset,
  addPostSaveAsScheduledPostRequest,
  setCoachMark
} from 'actions';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { useAccountSwitcherData, useActivePlatform, useNavBarData } from 'utils/hooks';
import { SchedulePostModal } from 'containers/Content/Creator/AccountSubPages';
// import { PostCard } from './PostCard';
import { PostCardOverlay } from './PostCardOverlay';
import { PostCardContentSupplier } from './PostCardContentSupplier';

export const PostsList = ({ type, isMultiSelect }: postsContainerTypes.IPostsListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, userOwnership, optionalParams } = useAccountSwitcherData();

  const activePlatforms = useActivePlatform();
  const { subNavPageName } = useNavBarData();

  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);

  const postsList = useSelector((state: IStore) => (isMultiSelect ? state.posts?.multiSelectPostsList : state.posts.postsList));
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const postsMediaList = useSelector((state: IStore) => state.posts.postsMediaList);
  const postsLinks = useSelector((state: IStore) => state.posts.postsLinks);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor || false);
  const overriddenCustomPostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_custom_posts_locked || false);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const isCoachMarkActive = useSelector((state: IStore) => state.aiContentCreator.isCoachMark);
  const selectedPosts = useSelector((state: IStore) => state.campaigns.selectedPosts);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const apActionType = useSelector((state: IStore) => state.addPostAccount.apActionType);
  const savedPostId = useSelector((state: IStore) => state.addPostAccount.savedPostId);
  const addPostMessage = useSelector((state: IStore) => state.addPostAccount.addPostMessage);
  const { ai_playbook, verticals } = useSelector((state: IStore) => state.aiPlayBook);
  // const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  // const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const [editOrScheduleObj, setEditOrScheduleObj] = useState({ isModalOpen: false, isEdit: false, isAccountScheduleModalOpen: false });
  const [syndicateContent, setSyndicateContent] = useState(POSTS_SYN_CONTENT_OBJ);
  const [hoverSelectedPostId, sethoverSelectedPostId] = useState(0);
  const [brandCategory, setBrandCategory] = useState('');

  useEffect(() => {
    if (editOrScheduleObj?.isEdit) {
      if (postsDetails && !optionalParams.length && userOwnership === USER_OWNERSHIP.ACCOUNT) {
        handleEditAccountPost();
      } else if (postsDetails && !optionalParams.length && userOwnership === USER_OWNERSHIP.FRANCHISOR) {
        if (postsDetails?.syndicate_to_account_databases) {
          setSyndicateContent({ ...syndicateContent, isShowModal: true });
        } else {
          setSyndicateContent({ isShowModal: false, isSyndicateConfirm: true });
        }
      }
    }
  }, [postsDetails?.id, optionalParams.length, userOwnership, editOrScheduleObj?.isEdit]); // eslint-disable-line

  useEffect(() => {
    if (syndicateContent.isSyndicateConfirm) {
      handleEditContentPost();
    }
  }, [syndicateContent.isSyndicateConfirm]); // eslint-disable-line

  useEffect(() => {
    if (addPostMessage) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, addPostMessage, { success: false });
      dispatch(addPostSaveMessageReset());
      handleCreatorResetData();
    }
  }, [addPostMessage, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!isAISubscribed) dispatch(setCoachMark(false));
  }, [isAISubscribed, dispatch]);

  useEffect(() => {
    if (apActionType && savedPostId) {
      dispatch(
        addPostSaveAsScheduledPostRequest({
          savedPostId,
          scheduledPost: {
            scheduled_for: savedPostDetails?.schedule_date_time.utcFormat
          }
        })
      );
    }
  }, [apActionType, savedPostId]); // eslint-disable-line

  useEffect(() => {
    if (verticals && verticals.length) {
      const filteredBrandCategory = verticals.find((it) => it.id === ai_playbook.vertical_id);
      if (filteredBrandCategory?.value) setBrandCategory(filteredBrandCategory?.value);
    }
  }, [verticals, ai_playbook.vertical_id]);

  const handleNavigateToCreatorPage = () => {
    navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('/*', ''));
  };

  const handleCreatorResetData = () => {
    dispatch(postDetailDataReset());
    dispatch(addPostResetAll());
    dispatch(addPostFranResetAll());
    dispatch(addPostActionsResetAll());
  };

  const handleEditContentPost = () => {
    if (postsDetails) {
      if (atLeastApproved(postsDetails?.status_id) && !postsDetails?.auto_schedule) {
        if (canBeScheduled(postsDetails, userOwnership)) {
          handleGetContentPostData(
            {
              currentPostDetail: postsDetails,
              postLinks: postsLinks,
              postMedia: postsMediaList
            },
            false
          );
          setEditOrScheduleObj({ isModalOpen: true, isEdit: true, isAccountScheduleModalOpen: false });
        } else {
          alertBoxCall(ALERT_BOX_TYPES.ERROR, POSTS_PACKAGE_EXPIRED_MSG);
          handleCreatorResetData();
        }
      } else if (postsDetails?.auto_schedule) {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, AUTO_SCHEDULE_DISABLE_PACKAGE);
        handleCreatorResetData();
      } else {
        alertBoxCall(ALERT_BOX_TYPES.ERROR, MANUALLY_SCHEDULE_DISABLE_PACKAGE);
        handleCreatorResetData();
      }
      setSyndicateContent(POSTS_SYN_CONTENT_OBJ);
    }
  };

  const handleEditAccountPost = () => {
    if (postsDetails) {
      if (canScheduleAccountPost(postsDetails, isAdmin, isFranchisor, isFranchisee, overriddenCustomPostsLocked)) {
        if (canBeScheduled(postsDetails, userOwnership)) {
          handleGetSavedPostData(
            {
              currentPostDetail: postsDetails
            },
            false
          );
        } else {
          alertBoxCall(ALERT_BOX_TYPES.ERROR, POSTS_PACKAGE_EXPIRED_MSG);
          handleCreatorResetData();
        }
      }
      if (postsDetails?.status_id === 1) {
        handleGetSavedPostData(
          {
            currentPostDetail: postsDetails
          },
          true
        );
      }
    }
  };

  const handleOpenAIGenerator = (postData: postsContainerTypes.ICurrentPostDetailData) => {
    dispatch(aiShowImageTab(true));
    dispatch(
      setCreatorAITabName(
        postData.postMedia?.length ? { parent: IAISugesstionTabs.IMAGES, child: IAIResultsTab.MY_IMAGES } : { parent: IAISugesstionTabs.CAPTIONS_AND_HASHTAGS, child: IAIResultsTab.CONTENTS }
      )
    );
  };

  const handleGetContentPostData = (postData: postsContainerTypes.ICurrentPostDetailData, isNavigate: boolean, type?: string) => {
    if (postData.currentPostDetail?.id) {
      const linkData = postData.postLinks?.length ? postData.postLinks[0] : null;
      dispatch(addPostSetFranPageType(AP_ACTION_TYPES.EDIT));
      dispatch(addPostUpdateContentObj(getValidContentData(activePlatforms, postData.currentPostDetail)));
      const selectedContentObj = getInitialSelectedContentData(activePlatforms, postData.currentPostDetail, contentObj, AP_ACTION_TYPES.EDIT, linkData);
      dispatch(addPostGetSelectedContentObj(selectedContentObj));
      dispatch(addPostSetCustomTextEditorData(getPostContentTextField(activePlatforms, postData.currentPostDetail)));
      dispatch(addPostSetPersonalizedTextEditorData(getPostPersonalizedTextField(activePlatforms, postData.currentPostDetail)));
      dispatch(addPostSetSelectedMediaList(postData.postMedia || []));
      dispatch(addPostGetSelectedFBTagList(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? postData.currentPostDetail?.page_tag_details.facebook || [] : []));
      dispatch(addPostSetTags(getUpdatedTagsList(postData.currentPostDetail?.tags_list || '')));
      dispatch(addPostSetLinkData(linkData));
      if (postData.currentPostDetail?.use_instagram && postData.currentPostDetail?.photo_urls?.length) {
        dispatch(
          addPostSetInstaPhotoPreviewObj({
            actionType: postData.currentPostDetail?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
            photoUrl: { originalUrls: [], previewUrls: [] }
          })
        );
      }
      if (postData.currentPostDetail?.campaigns?.length) {
        dispatch(addPostSetCampaignData(postData.currentPostDetail?.campaigns));
      }
      if (type === POST_DETAIL_TYPE.AI_POST) handleOpenAIGenerator(postData);
      if (isNavigate) handleNavigateToCreatorPage();
    }
  };

  const handleGetSavedPostData = (postData: postsContainerTypes.ICurrentPostDetailData, isNavigate: boolean, type?: string) => {
    if (postData.currentPostDetail?.id) {
      const mediaList = getAddMediaListForCreator(postData.currentPostDetail, accountDetails?.account?.id || 0, type === POST_DETAIL_TYPE.AI_POST);
      dispatch(addPostGetActionTypeForPost(AP_ACTION_TYPES.EDIT));
      dispatch(
        addPostGetEditSavedPostObj(
          getAccountUpdatedPostDetails(activePlatforms, postData.currentPostDetail, hashTagsState?.action_status === SettingsHashtagsActionsVal.IN_POST ? hashTagsState?.saved_hashtags : [])
        )
      );
      dispatch(
        addPostUpdateSavedPostDetails(
          getEditOrSchedulePost(
            activePlatforms,
            postData.currentPostDetail,
            accountDetails?.account?.tzinfo_name || '',
            null,
            false,
            hashTagsState?.action_status === SettingsHashtagsActionsVal.IN_POST ? hashTagsState?.saved_hashtags : [],
            mediaList
          )
        )
      );
      dispatch(
        addPostSetCustomTextEditorData(
          getPostContentTextField(activePlatforms, postData.currentPostDetail, hashTagsState?.action_status === SettingsHashtagsActionsVal.IN_POST ? hashTagsState?.saved_hashtags : [])
        )
      );
      dispatch(addPostSetSelectedMediaList(mediaList));
      dispatch(addPostGetSelectedFBTagList(isSocialMediaActive(activePlatforms, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK) ? postData.currentPostDetail?.page_tag_details?.facebook || [] : []));
      dispatch(addPostSetTags(getUpdatedTagsList(postData.currentPostDetail?.tags_list || '')));
      dispatch(addPostSetLinkData(postData.currentPostDetail?.url ? getCreatorLinkData(postData.currentPostDetail) : null));
      if (postData.currentPostDetail?.use_instagram && postData.currentPostDetail?.photo_urls?.length) {
        dispatch(
          addPostSetInstaPhotoPreviewObj({
            actionType: postData.currentPostDetail?.pad_instagram_photos ? CreatorInstaPhotoType.PAD : CreatorInstaPhotoType.CROP,
            photoUrl: { originalUrls: [], previewUrls: [] }
          })
        );
      }
      if (postData.currentPostDetail?.campaigns?.length) {
        dispatch(addPostSetCampaignData(postData.currentPostDetail?.campaigns));
      }
      if (type === POST_DETAIL_TYPE.AI_POST) handleOpenAIGenerator({ ...postData, postMedia: mediaList });
      if (isNavigate) handleNavigateToCreatorPage();
    }
  };

  const firstAIActiveId = postsList.find((it) => it.status_id === 3)?.id;

  return (
    <>
      <div className="content-post__list pls post-list__main lpx vpy-20">
        {postsList.map((listItem, index) => {
          const postId = listItem.saved_post_id || listItem.content_id || 0;
          const isCoachMark = firstAIActiveId === listItem.id;
          const isCampaignPostSelected = selectedPosts.includes(postId);
          return (
            <div
              className={`m-item mi-compact${listItem.video_url || listItem.photo_preview_url || listItem.link_image_url ? '' : ` only-text`}${isCoachMark && isCoachMarkActive ? ` ai-cmp-show` : ''}${
                isCampaignPostSelected ? ` cp-selected` : ''
              }`}
              key={`posts-list-${index}`}
              onMouseOver={() => subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS && sethoverSelectedPostId(postId)}
              onMouseOut={() => subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS && sethoverSelectedPostId(0)}
            >
              {listItem.campaign ? (
                <div className="campaign-tag">
                  <span>CAMPAIGN</span>
                </div>
              ) : null}
              <PostCardOverlay
                listItem={listItem}
                type={type}
                brandCategory={brandCategory}
                handleGetContentPostData={handleGetContentPostData}
                handleGetSavedPostData={handleGetSavedPostData}
                postId={postId}
                isCoachMark={isCoachMark}
                isAISubscribed={isAISubscribed}
                isCampaignPostSelected={isCampaignPostSelected}
                hoverSelectedPostId={hoverSelectedPostId}
                setEditOrScheduleObj={setEditOrScheduleObj}
                dispatch={dispatch}
              />
              <PostCardContentSupplier listItem={listItem} />
            </div>
          );
        })}
      </div>
      <BrandSchedulePostModal
        isModalShow={editOrScheduleObj?.isModalOpen}
        handleModalClose={() => {
          setSyndicateContent(POSTS_SYN_CONTENT_OBJ);
          setEditOrScheduleObj({ isModalOpen: false, isEdit: false, isAccountScheduleModalOpen: false });
        }}
        className="post-schedule--modal"
      />
      <SchedulePostModal
        isModalShow={editOrScheduleObj?.isAccountScheduleModalOpen}
        handleModalClose={() => {
          setEditOrScheduleObj({ isModalOpen: false, isEdit: false, isAccountScheduleModalOpen: false });
        }}
        containerClassName={'aps-modal'}
      />
      <ModalPopup
        isModalShow={syndicateContent.isShowModal}
        modalHeaderType={MODAL_BOX_TYPES.INFO}
        containerClassName={'modal-confirmation confirm-delete'}
        modalBody={() => (
          <>
            <div className="edit-approval-post-wrp-modal">
              <div className="modal-message-wraps-text">{POSTS_SYNDICATE_CONTENT_MSG}</div>
            </div>
          </>
        )}
        modalCloseButton
        handleModalClose={() => {
          handleCreatorResetData();
          setSyndicateContent(POSTS_SYN_CONTENT_OBJ);
        }}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button
                className="ac-btn ac-secondary-white ac-outline ac-block border-0"
                onClick={() => {
                  handleCreatorResetData();
                  setSyndicateContent(POSTS_SYN_CONTENT_OBJ);
                }}
              >
                Cancel
              </button>
            </CustomRippleButton>

            <CustomRippleButton rippleClass={`ac-primary-box `} custColor={RIPPLE_COLOR.primary}>
              <button
                className={`ac-btn ac-primary ac-block`}
                onClick={() => {
                  setSyndicateContent({ isShowModal: false, isSyndicateConfirm: true });
                }}
              >
                OK
              </button>
            </CustomRippleButton>
          </div>
        )}
      />
    </>
  );
};
