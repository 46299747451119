import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import {
  addPostSavedPostReset,
  addPostActionsResetAll,
  addPostScheduledContentReset,
  addPostSetSelectedMediaList,
  addPostResetAll,
  addPostSetFranPageType,
  addPostContentPostDataRequest,
  setCorporatePostBoostData,
  addPostSetContentSavedDate,
  commonSetContentModifiedStatus,
  setAIPostContentOpen,
  resetAIContentCreator,
  resetAIImageGenerationImages,
  setCreatorTextAlertMsg,
  addPostFranResetAll,
  addPostGetSelectedContentObj
} from 'actions';
import { IStore } from 'types';
import { getContentPostReqPayload, getModifiedStatusForContentPost, calendarUTCtoLocalHandler, convertStringToEditorState } from 'utils/helpers';
import { useNavBarData, useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import {
  ADD_POST_STATUS_NAME,
  AP_ACTION_TYPES,
  CALENDAR_DATE_FORMAT,
  ACCOUNT_API_TYPES,
  AP_FRAN_INIT_CONTENT_SAVED_DATE,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  AP_SOCIAL_MEDIA_INIT_STATUS,
  AP_SOCIAL_MEDIA_INIT_TEXT,
  SettingsHashtagsActionsVal,
  HASHTAG_BLOCK_DEFAULT_SPACING
} from 'utils/constants';
import { EditorStateContext } from '..';

export const CreatePostStats = () => {
  const dispatch = useDispatch();
  const { subNavPageName } = useNavBarData();
  const { id, userOwnership } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const pageType = useSelector((state: IStore) => state.addPostFranchisor.pageType);
  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const editData = useSelector((state: IStore) => state.calendar.editableData.editData);
  const campaignData = useSelector((state: IStore) => state.addPostFranchisorAction.campaignData);
  const selectedLocData = useSelector((state: IStore) => state.addPostFranchisorAction.selectedLocData);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const creatorTextAlertMsg = useSelector((state: IStore) => state.common.creatorTextAlertMsg);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const editorStateArray = useContext(EditorStateContext);
  const setEditorState = editorStateArray[1];

  const addSavedHashtags = Boolean(hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length);

  const isDataEdited = useMemo(() => {
    if (contentData) {
      return getModifiedStatusForContentPost(contentData, contentObj, selectedMediaList, linkData, selectedTags, selectedLocData, campaignData);
    }
    return false;
  }, [contentData, contentObj, selectedMediaList.length, linkData, selectedTags.length, selectedLocData?.id, campaignData]); // eslint-disable-line

  useEffect(() => {
    dispatch(commonSetContentModifiedStatus(isDataEdited));
  }, [isDataEdited, dispatch]);

  const handleCreatorStartOver = () => {
    setEditorState(
      addSavedHashtags
        ? { ...AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT, [activePlatforms[0]]: convertStringToEditorState(`${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState?.saved_hashtags?.join(' ')}`) }
        : AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT
    );
    dispatch(resetAIContentCreator());
    dispatch(resetAIImageGenerationImages());
    if (isAiContentOpen) dispatch(setAIPostContentOpen(false));
    if (creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
    if (pageType) {
      if (pageType === AP_ACTION_TYPES.EDIT) dispatch(addPostSetFranPageType(AP_ACTION_TYPES.ADD));
      else {
        const reqPayload = getContentPostReqPayload(id, userOwnership);
        dispatch(addPostContentPostDataRequest(reqPayload));
      }
      dispatch(addPostSavedPostReset());
      dispatch(setCorporatePostBoostData({ isBoostEnabled: false, lifetimeSpend: 0, duration: 0, boostType: '', facebookDarkPost: false }));
      dispatch(addPostScheduledContentReset());
      dispatch(addPostSetSelectedMediaList([]));
      dispatch(addPostResetAll());
      dispatch(addPostActionsResetAll());
      dispatch(addPostSetContentSavedDate(AP_FRAN_INIT_CONTENT_SAVED_DATE));
      dispatch(addPostFranResetAll());
      if (addSavedHashtags) {
        dispatch(
          addPostGetSelectedContentObj({
            ...contentObj,
            currentTab: activePlatforms[0],
            postStatus: {
              ...AP_SOCIAL_MEDIA_INIT_STATUS,
              [activePlatforms[0]]: true
            },
            customText: {
              ...AP_SOCIAL_MEDIA_INIT_TEXT,
              [activePlatforms[0]]: `${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState?.saved_hashtags?.join(' ')}`
            }
          })
        );
      }
    }
  };

  return (
    <div className="package-id-holder mb-20">
      <div className="package-id-wrp-sec creator__header--title">
        <div className="package__header--icon">
          <ImageValidation isImgValid defaultImg={'package-id'} customName={'package id'} />
        </div>
        <span className="pk-title">Package ID: {!contentData ? `---` : `C${contentData.id.toString().padStart(6, '0')}`}</span>
      </div>

      <div className="package-id-wrp-sec creator__header--right">
        <div className="title-text-section">
          <span>Status:</span>{' '}
          <span className="b-text">
            {contentData?.status_id === 3
              ? ADD_POST_STATUS_NAME.APPROVED
              : contentData?.status_id === 2
              ? ADD_POST_STATUS_NAME.PENDING_APPROVAL
              : contentData?.status_id === 1
              ? ADD_POST_STATUS_NAME.DRAFT
              : contentData?.status_id === 5 && 'AI Generated'}
            {isDataEdited && `, Modified`}
          </span>
        </div>
        {subNavPageName === ACCOUNT_API_TYPES.CALENDAR ? (
          <div className="title-text-section">
            <span>Scheduled:</span>
            <span className="b-text">{postsDetails ? `@ ${calendarUTCtoLocalHandler(editData?.scheduledTime || '', CALENDAR_DATE_FORMAT)}` : null}</span>
          </div>
        ) : (
          <>
            <span className="start-over cur-pointer" onClick={() => handleCreatorStartOver()}>
              Start Over
            </span>
            <div className="start-over-icon cur-pointer" onClick={() => handleCreatorStartOver()}>
              <ImageValidation isImgValid defaultImg={'start-over'} customName={'start over'} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
