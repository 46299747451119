import { useMemo, useRef, useState } from 'react';
import { Card, Form, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectCountriesToSyndicate } from 'actions';
import { apFranContainerTypes, IStore } from 'types';
import { CustomDropdownWithSearch } from 'widgets/CustomDropdown';
import { ImageValidation } from 'widgets/Media';
import { FranSyndCountryType } from 'utils/constants';

export const FranSyndicateWhere = ({ dispatch }: apFranContainerTypes.IFranSyndicateScheduleLocProps) => {
  const dropdownRef = useRef(null);
  const selectedSyndicateCountries = useSelector((state: IStore) => state.addPostFranchisor.selectedSyndicateCountries);
  const countriesList = useSelector((state: IStore) => state.franchisors.countries || []);
  const isCountryFetching = useSelector((state: IStore) => state.franchisors.isCountryFetching);

  const [countryType, setCountryType] = useState(FranSyndCountryType.ALL_REGIONS);
  const dropdownOptions = useMemo(() => {
    return [
      {
        label: '',
        options: countriesList?.length ? countriesList.map((country, index) => ({ id: index + 1, value: country.code, label: country.name })) : [{ id: 0, value: '', label: '' }]
      }
    ];
  }, [countriesList]);

  const handleRemoveCountry = (val: string) => {
    dispatch(selectCountriesToSyndicate(selectedSyndicateCountries.filter((country) => country !== val)));
  };

  return (
    <Card className="glbl__modal--card">
      <Card.Header>Where</Card.Header>
      <Card.Body>
        {isCountryFetching ? (
          <></>
        ) : (
          <>
            <Tabs
              activeKey={countryType}
              className="globalTabs"
              onSelect={(selectedKey) => {
                setCountryType(selectedKey as FranSyndCountryType);
                dispatch(selectCountriesToSyndicate([]));
              }}
            >
              <Tab
                eventKey={FranSyndCountryType.ALL_REGIONS}
                title={
                  <div>
                    <Form.Group className="form-group" controlId="formBasicCheckbox1">
                      <Form.Check type="radio" label={'All Regions'} checked={countryType === FranSyndCountryType.ALL_REGIONS} readOnly />
                    </Form.Group>
                  </div>
                }
              />
              <Tab
                eventKey={FranSyndCountryType.SPECIFIC_REGION}
                title={
                  <div>
                    <Form.Group className="form-group" controlId="formBasicCheckbox2">
                      <Form.Check type="radio" label={'Specific Regions'} checked={countryType === FranSyndCountryType.SPECIFIC_REGION} readOnly />
                    </Form.Group>
                  </div>
                }
              >
                <div className="form-group form-field-item">
                  <CustomDropdownWithSearch
                    dropdownRef={dropdownRef}
                    classNamePrefix="rs-drp"
                    id="syndicate-dropdown"
                    placeholder={'Select Regions'}
                    onChange={(event: any) => {
                      const isCountryExist = selectedSyndicateCountries?.find((country) => country === event?.value) || null;
                      if (!isCountryExist) dispatch(selectCountriesToSyndicate([...selectedSyndicateCountries, event?.value]));
                    }}
                    value={
                      dropdownOptions?.[0]?.options?.length && selectedSyndicateCountries?.length
                        ? dropdownOptions?.[0]?.options.find((option) => option.value === selectedSyndicateCountries[selectedSyndicateCountries?.length - 1])
                        : null
                    }
                    options={dropdownOptions}
                    isSearchable={countriesList?.length <= 1 ? false : true}
                    menuIsOpen
                  />
                </div>
              </Tab>
            </Tabs>
            {selectedSyndicateCountries.length && countryType === FranSyndCountryType.SPECIFIC_REGION ? (
              <div className="select-location-section-wrp">
                <h3 className="glbl__title--txt">
                  Selected <span>({selectedSyndicateCountries.length} Regions)</span>
                </h3>
                <Card>
                  <Card.Body>
                    {selectedSyndicateCountries?.length
                      ? selectedSyndicateCountries.map((country, index) => {
                          const countryData = countriesList.find((c) => c.code === country) || null;
                          return (
                            <div className="grid-section mb-3" key={`selected-countries-${index}`}>
                              <div className="g-left">
                                <span className="labelText">{countryData?.name}</span>
                              </div>
                              <div className="g-right">
                                <div className="glbl__round--delete">
                                  <ImageValidation isImgValid defaultImg={'minus-red'} customName={'minus-red'} onClick={() => handleRemoveCountry(country)} />
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </Card.Body>
                </Card>
              </div>
            ) : null}
          </>
        )}
      </Card.Body>
    </Card>
  );
};
