import { EditorState } from 'draft-js';

import { reviewsContainerTypes } from 'types';

export const REVIEWS_LOCATIONS_DROPDOWN_OBJ = { id: 0, value: 'All Locations', label: 'All Locations', type: 'All' };
export const DROPDOWN_DEFAULT_FILTER_OBJ = { id: 0, value: 'All', label: 'All', type: 'All' };

export const REVIEWS_TOOLTIP_ICONS_NAME = {
  INTERNAL_NOTE: 'Leave an internal note',
  CLOSED: 'Mark as "Closed"',
  REVIEW_REPLY: 'Reply to Review',
  REVIEW_LINK: 'See this Review on the Network',
  REVIEW_CLOSED: 'Review closed',
  REVIEW_NOT_REPLIED: 'Mark as "Replied to"',
  REVIEW_REPLIED: 'This Review has been replied to'
};

export enum ReviewsInteraction {
  ALL_INTERACTION = 'all_interaction',
  REPLIED_TO = 'replied_to',
  NOT_REPLIED_TO = 'not_replied_to'
}

export const REVIEWS_INTERACTION_STATUS = [
  { value: ReviewsInteraction.ALL_INTERACTION, label: 'Show All' },
  { value: ReviewsInteraction.REPLIED_TO, label: 'Only Replied to' },
  { value: ReviewsInteraction.NOT_REPLIED_TO, label: 'Only Not Replied to' }
];

export enum ReviewsStatus {
  ALL_STATUS = 'show_all_status',
  CLOSE = 'close',
  OPEN = 'open'
}

export const REVIEWS_STATUS_DATA = [
  { value: ReviewsStatus.ALL_STATUS, label: 'Show All' },
  { value: ReviewsStatus.CLOSE, label: 'Closed' },
  { value: ReviewsStatus.OPEN, label: 'Open' }
];

export const REVIEWS_FACEBOOK_STATUS = {
  RECOMMENDED: 'recommends',
  NOT_RECOMMENDED: 'does not recommend'
};

export enum ReviewsActiveTab {
  INTERNAL_NOTE = 'internal_notes',
  REPLY_TO_REVIEW = 'reply_to_review'
}

export const REVIEWS_BRAND_PLATFORMS = ['facebook', 'yelp', 'google'];

export const REVIEWS_REPLY_APPROVE_TOOLTIP = 'Approve and automatically post this reply';
export const REVIEWS_REPLY_REJECT_TOOLTIP = 'A comment must be left to reject';

export const REVIEWS_REPLY_DELETE_CONFIRM_MSG = 'Are you sure you want to delete your reply?';

export const REVIEWS_ACTION_INIT_OBJ: reviewsContainerTypes.IReviewsActionObj = {
  id: 0,
  isEditReply: false,
  noteText: '',
  replyText: EditorState.createEmpty(),
  feedbackText: EditorState.createEmpty()
};

export const KEYWORDS_CATEGORY_TAB_FIELDS: reviewsContainerTypes.IKeywordsCategoryTabFields = {
  NAME: 'name',
  DESCRIPTION: 'description',
  KEYWORDS: 'keywords'
};

export const KEYWORDS_CATEGORY_CHECKFILEDS = {
  [KEYWORDS_CATEGORY_TAB_FIELDS.DESCRIPTION]: false,
  [KEYWORDS_CATEGORY_TAB_FIELDS.NAME]: false,
  [KEYWORDS_CATEGORY_TAB_FIELDS.KEYWORDS]: false
};
