import { useEffect, useState, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AccountCreatePost } from 'containers/Content/Creator/AccountSubPages';
import { IStore } from 'types';
import {
  CREATOR_USER_OWNERSHIP,
  USER_OWNERSHIP,
  MG_MEDIA_TYPE,
  POST_IMAGES_LIMITATION,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  AP_ACTION_TYPES,
  ACCOUNT_API_TYPES,
  AP_SELECTED_LOC_INIT_DATA,
  CALENDAR_VIEW_DATE_RANGE,
  ICommonDateTypes,
  AP_INTERNAL_NOTES_TYPES,
  SIDE_NAV_NAME,
  MG_SOURCE_TYPE,
  MG_CLOUD_MEDIA_TYPE,
  MgMediaType,
  MEDIA_PAGINATION_PER_PAGE,
  UTC_TIMESTAMP_FORMAT,
  IAISugesstionTabs,
  SCHEDULE_PARAM_TYPE,
  CALENDAR_EXCEPTION_ERROR,
  COMMON_SOCIAL_PROVIDER_TYPE,
  SettingsHashtagsActionsVal,
  SETTINGS_HASHTAG_ACTIONS,
  SettingsHashtagsActionsIdentifier
} from 'utils/constants';
import { BrandOrHubCreatePost } from 'containers/Content/Creator/BrandOrHubSubPages';
import {
  addPostFranResetAll,
  addPostResetAll,
  addPostActionsResetAll,
  creatorSetComponentName,
  calendarResetAll,
  accountProfileReset,
  getFBAdsTargetingChoicesRequest,
  addPostGetSocialMediaPhotoPreviewRequest,
  campaignsResetAll,
  fbAdsCreatorBoostDataReset,
  franchisorScheduleTreeReset,
  setAIPostContentOpen,
  getAIToneRequest,
  resetAIContentCreator,
  addPostContentPostDataRequest,
  addPostGetInternalNotesListRequest,
  addPostGetSelectedContentObj,
  addPostSetSelectedLocData,
  calendarViewDateRange,
  getPostsInternalNotesListRequest,
  getMGListRequest,
  mgResetAll,
  commonSetContentModifiedStatus,
  getScheduledPostResponse,
  getScheduledPostRequest,
  resetAIImageGenerationImages,
  aiPlaybookGetVerticalRequest,
  getFranchisorDetailsRequest,
  setCreatorTextAlertMsg,
  settingsGetHashTagsListRequest,
  hashTagsResetAll
} from 'actions';
import { Loading } from 'components';
import {
  getCurrentDate,
  getContentPostReqPayload,
  getInitialSelectedContentData,
  utcTimeStampConverter,
  getStartAndEndOfUpcomingWeek,
  getBrandMediaTypeForAI,
  getPreviousMonthFormattedDate,
  apiEventSourcePolyfillHandler,
  API,
  scrollToTop
} from 'utils/helpers';
import { useNavBarData, useAccountSwitcherData, useActivePlatform } from 'utils/hooks';

export const EditorStateContext = createContext<any>([{}]);

export const ContentCreator = () => {
  const dispatch = useDispatch();
  const { subNavPageName } = useNavBarData();

  const { id, userOwnership, isValidId } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const isAccountFetching = useSelector((state: IStore) => state.accountSwitcher.isAccountFetching);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const actionType = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.actionType);
  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const isToneFetching = useSelector((state: IStore) => state.aiContentCreator.aiTone.isToneFetching);
  const pageType = useSelector((state: IStore) => state.addPostFranchisor.pageType);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const editSavedPostId = useSelector((state: IStore) => state.addPostAccount.editSavedPost?.id);
  const isShowAiImageTab = useSelector((state: IStore) => state.addPostFranchisorAction.isShowAiImageTab);
  const aiTagsList = useSelector((state: IStore) => state.aiContentCreator.aiTags.aiTagsList);
  const selectedTab = useSelector((state: IStore) => state.addPostFranchisorAction.aiTabName);
  const useMediaContentData = useSelector((state: IStore) => state.addPostFranchisor.useMediaContentData);
  const creatorModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorModalName);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const [textEditorState, setEditorState] = useState(reducerTextEditorState);

  const addSavedHashtags = Boolean(hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length);

  // For Franchisor Syndication
  useEffect(() => {
    if (id && userOwnership === USER_OWNERSHIP.FRANCHISOR) {
      dispatch(getFranchisorDetailsRequest({ id, userOwnership }));
    }
  }, [id, userOwnership, dispatch]);

  // Get context in text editor for all social media
  useEffect(() => {
    setEditorState(reducerTextEditorState);
  }, [reducerTextEditorState]);

  // Get new content data
  useEffect(() => {
    if (creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR && pageType === AP_ACTION_TYPES.ADD && isValidId) {
      const reqPayload = getContentPostReqPayload(id, userOwnership, useMediaContentData);
      dispatch(addPostContentPostDataRequest(reqPayload));
    }
  }, [creatorCompName, pageType, isValidId]); // eslint-disable-line

  useEffect(() => {
    if ([SettingsHashtagsActionsVal.IN_POST, SettingsHashtagsActionsVal.IN_COMMENT].includes(hashTagsState.action_status) && isValidId && userOwnership === USER_OWNERSHIP.ACCOUNT) {
      dispatch(
        settingsGetHashTagsListRequest({
          id,
          userOwnership,
          params: { action_status: SETTINGS_HASHTAG_ACTIONS.find((action) => action.value === hashTagsState.action_status)?.identifier || SettingsHashtagsActionsIdentifier.IN_POST },
          action_status: hashTagsState.action_status
        })
      );
    }
  }, [id, userOwnership, hashTagsState.action_status, isValidId, dispatch]);

  useEffect(() => {
    if (creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR) {
      // Get content comments
      if (contentData?.id && isValidId) {
        dispatch(addPostGetInternalNotesListRequest(contentData.id));
        if (subNavPageName !== ACCOUNT_API_TYPES.CALENDAR) {
          const selectedContentObj = getInitialSelectedContentData(
            activePlatforms,
            contentData,
            contentObj,
            pageType,
            linkData,
            isContentSupplierFranchisor,
            addSavedHashtags ? hashTagsState.saved_hashtags : []
          );
          dispatch(addPostGetSelectedContentObj(selectedContentObj));
          dispatch(addPostSetSelectedLocData(contentData?.account_list ? { id: contentData?.account_list?.id, name: contentData?.account_list?.name } : AP_SELECTED_LOC_INIT_DATA));
        }
      }
    } else if (creatorCompName === CREATOR_USER_OWNERSHIP.ACCOUNT) {
      // Get scheduled posts list
      const initialStartDate = getStartAndEndOfUpcomingWeek(getCurrentDate(), ICommonDateTypes.START_TIME);
      const initialEndDate = getStartAndEndOfUpcomingWeek(getCurrentDate(), ICommonDateTypes.END_TIME);
      if (id && creatorCompName && subNavPageName === 'creator' && initialStartDate && initialEndDate) {
        dispatch(
          calendarViewDateRange({
            ...CALENDAR_VIEW_DATE_RANGE,
            startDate: initialStartDate,
            endDate: initialEndDate
          })
        );
        apiEventSourcePolyfillHandler(
          API.getScheduledPost.apiPath,
          {
            hidden: 0,
            order: SCHEDULE_PARAM_TYPE.SCHEDULE_FOR,
            account_id: id,
            date_range: [utcTimeStampConverter(initialStartDate), utcTimeStampConverter(initialEndDate)]
          },
          'scheduled_posts',
          () => dispatch(getScheduledPostRequest()),
          (data) => {
            dispatch(getScheduledPostResponse(data));
          },
          CALENDAR_EXCEPTION_ERROR
        );
      }
    }
  }, [creatorCompName, id, contentData?.id, isValidId]); // eslint-disable-line

  // Get saved post comments
  useEffect(() => {
    if (creatorCompName && [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName) && editSavedPostId) {
      dispatch(
        getPostsInternalNotesListRequest({
          postsDataId: editSavedPostId,
          userOwnership: USER_OWNERSHIP.ACCOUNT,
          type: AP_INTERNAL_NOTES_TYPES.SAVED_POST
        })
      );
    }
  }, [creatorCompName, editSavedPostId]); // eslint-disable-line

  // Get initial similar post images list
  useEffect(() => {
    if ((id || accountId) && creatorCompName && selectedTab.parent === IAISugesstionTabs.IMAGES && isShowAiImageTab && aiTagsList?.length) {
      dispatch(
        getMGListRequest({
          page: 1,
          per: MEDIA_PAGINATION_PER_PAGE,
          media_type: getBrandMediaTypeForAI(selectedMediaList) === MG_CLOUD_MEDIA_TYPE.IMAGE ? MgMediaType.PHOTOS : MgMediaType.VIDEOS,
          source: MG_SOURCE_TYPE.ALL,
          ...(creatorCompName === CREATOR_USER_OWNERSHIP.FRANCHISOR ? { franchisor_id: id } : { account_id: accountId || id }),
          ai_tags: aiTagsList,
          date_range: [getPreviousMonthFormattedDate(getCurrentDate(), UTC_TIMESTAMP_FORMAT, 2, 'months'), utcTimeStampConverter(getCurrentDate())]
        })
      );
    }
  }, [id, accountId, creatorCompName, selectedTab.parent, isShowAiImageTab, aiTagsList, dispatch, creatorModalName]); // eslint-disable-line

  // Reset data when the edited post is location content
  useEffect(() => {
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_CREATOR) {
      if (id && creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT && userOwnership === USER_OWNERSHIP.ACCOUNT) {
        dispatch(creatorSetComponentName(userOwnership));
        dispatch(addPostResetAll());
        dispatch(addPostFranResetAll());
        dispatch(addPostActionsResetAll());
        dispatch(campaignsResetAll());
      }
    }
  }, [id, creatorCompName, userOwnership]); // eslint-disable-line

  // Crop and padding functionality for instagram (Maximum 10 images)
  useEffect(() => {
    const currentTab = userOwnership === USER_OWNERSHIP.ACCOUNT ? savedPostDetails?.current_tab || activePlatforms?.[0] : contentObj.currentTab;
    if (
      actionType &&
      selectedMediaList.length &&
      selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO &&
      [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].includes(currentTab)
    ) {
      const updatedMediaList = currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? selectedMediaList.slice(0, POST_IMAGES_LIMITATION.INSTAGRAM) : selectedMediaList;
      [COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM].forEach((tab) => {
        dispatch(
          addPostGetSocialMediaPhotoPreviewRequest({
            socialMedia: tab,
            payload: {
              mode: actionType,
              urls: updatedMediaList.map((it) => it.url)
            }
          })
        );
      });
    }
  }, [actionType, selectedMediaList]); // eslint-disable-line

  useEffect(() => {
    scrollToTop();
    dispatch(getAIToneRequest());
    return () => {
      dispatch(hashTagsResetAll());
      setEditorState(AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT);
      if (isAiContentOpen) dispatch(setAIPostContentOpen(false));
      dispatch(setCreatorTextAlertMsg(null));
      dispatch(resetAIContentCreator());
      dispatch(resetAIImageGenerationImages());
      if (subNavPageName === SIDE_NAV_NAME.CONTENT_CREATOR || subNavPageName === SIDE_NAV_NAME.CONTENT_CAMPAIGNS) {
        dispatch(addPostResetAll());
        dispatch(addPostFranResetAll());
        dispatch(addPostActionsResetAll());
        dispatch(mgResetAll());
        dispatch(calendarResetAll());
        if (subNavPageName !== SIDE_NAV_NAME.CONTENT_CAMPAIGNS) dispatch(campaignsResetAll());
        else dispatch(commonSetContentModifiedStatus(false));
        dispatch(fbAdsCreatorBoostDataReset());
        dispatch(franchisorScheduleTreeReset());
      }
      if (creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT && userOwnership) {
        dispatch(creatorSetComponentName(userOwnership));
        dispatch(accountProfileReset());
        dispatch(getFBAdsTargetingChoicesRequest({ id, userOwnership, initialLoad: true }));
      }
    };
  }, []); // eslint-disable-line

  // to get ai playbook setting details
  useEffect(() => {
    dispatch(aiPlaybookGetVerticalRequest());
  }, [dispatch]);

  // to get ai playbook setting document list
  // useEffect(() => {
  //   dispatch(aiPlaybookGetDocumentsListRequest({ page: 1, ai_playbook: true, [`${userOwnership}_id`]: id }));
  //   dispatch(aiPlaybookGetDocumentsListRequest({ page: 1, ai_compliance: true, franchisor_id: topLevelFranchisorId }));
  // }, [userOwnership, id, topLevelFranchisorId, dispatch]);

  return (
    <EditorStateContext.Provider value={[textEditorState, setEditorState]}>
      {isAccountFetching || isToneFetching ? (
        <Loading />
      ) : creatorCompName && [CREATOR_USER_OWNERSHIP.ACCOUNT, CREATOR_USER_OWNERSHIP.LOCATION_CONTENT].includes(creatorCompName) ? (
        <AccountCreatePost />
      ) : (
        <BrandOrHubCreatePost />
      )}
      {/* <AICoachMark /> */}
    </EditorStateContext.Provider>
  );
};
