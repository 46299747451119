import { commonProps } from 'types';
import { COMMON_PAGE_TYPE, RALLIO_PROFILE_TEXT } from './common';

export const ROUTE_TABS = {
  LOGIN: 'Login',
  SINGLE_SIGN_ON: 'Single Sign On',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  USER_CONFIRMATION: 'User Confirmation',
  DOWNLOAD_RALLIO_APP: 'Download Rallio App',
  ANALYTICS: 'Analytics',
  ANALYTICS_SOCIAL: 'Analytics Social',
  ANALYTICS_CONTENT: 'Analytics Content',
  ANALYTICS_PAGE: 'Analytics Page',
  ANALYTICS_REVIEW: 'Analytics Review',
  ANALYTICS_LEADERBOARD: 'Analytics Leaderboard',
  CONTENT: 'Content',
  CONTENT_CREATOR: 'Creator',
  CONTENT_POSTS: 'Posts',
  CONTENT_MEDIA: 'Media',
  CONTENT_FEED: 'Feed',
  CONTENT_SCHEDULER: 'Scheduler',
  CONTENT_CAMPAIGNS: 'Campaigns',
  CONTENT_COUPONS: 'Coupons',
  CONTENT_CALENDAR: 'Calendar',
  CONTENT_RSS_FEED: 'Rss Feeds',
  CONTENT_PROFILE_IMAGERY: 'Profile Imagery',
  COMMUNITY: 'Community',
  COMMUNITY_INBOX: 'Inbox',
  COMMUNITY_SANDBOX: 'Sandbox',
  MARKETING_PROSPECTS_CLIENTS: 'Prospects Clients',
  MARKETING_FB_ADS: 'FB Ads',
  MARKETING_TARGETING: 'Targeting',
  REPUTATION: 'Reputation',
  REPUTATION_OUTBOX: 'Outbox',
  REPUTATION_REVIEWS: 'Reviews',
  REPUTATION_AI_REVIEW_RESPONDER: 'AI Review Settings',
  ADVOCACY: 'Employee Advocacy',
  ADVOCACY_OVERVIEW: 'Overview',
  ADVOCACY_LEADER_BOARD: 'Advocacy Leaderboard',
  ADVOCACY_REWARD_PROGRAM: 'Reward Programs',
  DIRECTORY_LISTINGS: 'Directory Listings',
  DIRECTORY_LISTINGS_PROFILE: 'Profile',
  DIRECTORY_LISTINGS_LISTINGS: 'Listings',
  DIRECTORY_LISTINGS_RANKINGS: 'Rankings',
  TEAM_MANAGEMENT: 'Team Management',
  TEAM_MANAGEMENT_CONNECTIONS: 'Connections',
  TEAM_MANAGEMENT_LISTS: 'Lists',
  TEAM_MANAGEMENT_LOGINS: 'Logins',
  TEAM_MANAGEMENT_PERMISSIONS: 'Permissions',
  SETTINGS: 'Settings',
  SETTINGS_REVIEW_TEMPLATE: 'Review Templates',
  SETTINGS_RELEASE_FORM: 'Model Release',
  SETTINGS_RALLIO: RALLIO_PROFILE_TEXT,
  SETTINGS_BRAND_PROFILE: 'Brand Profile',
  SETTINGS_PARTNER_BANNERS: 'Partner Banners',
  SETTINGS_AI_PLAYBOOK: 'AI Playbook',
  SETTINGS_SOCIAL: 'Social Profile',
  SETTINGS_FB_ADS: 'FB Ads',
  SETTINGS_HASHTAGS: 'Hashtags',
  DEFAULT: 'Analytics Leaderboard',
  ADMIN_ACCOUNTS: 'Accounts',
  ADMIN_FRANCHISORS: 'Franchisors',
  ADMIN_USERS: 'Users',
  FAQs: 'Faqs',
  VIDEO_TUTORIALS: 'Video Tutorials',
  FEATURE_UPDATES: 'Feature Updates',
  NEED_HELP: 'Need Help?',
  // REVV
  REVV: 'Revv',
  REVV_DASHBOARD: 'Dashboard',
  REVV_SURVEYS: 'Surveys',
  REVV_REPORTS: 'Reports',
  REVV_TEAMS: 'Revv Teams',
  REVV_TEAMS_DETAIL: 'Revv Teams Detail',
  REVV_COMMUNITY: 'Revv Community',
  REVV_LOCATION: 'Revv Locations',
  REVV_EMPLOYEES: 'Revv Employees',
  REVV_CUSTOMERS: 'Revv Customers',
  REVV_CUSTOMERS_DETAIL: 'Revv Customers Detail',
  REVV_SETTINGS: 'Revv Settings',
  REVV_ANALYTICS: 'Analytics Revv',
  REVV_ANALYTICS_SURVEY_STATUS: 'Survey Status',
  REVV_ANALYTICS_SENTIMENT: 'Sentiment Over Time',
  REVV_ANALYTICS_REVIEW: 'Review Calculator',
  REVV_ANALYTICS_AVERAGE_RATING: 'Average Rating',
  AI_ASSISTANT: 'AI Assistant'
};

export const ROUTES = {
  LOGIN: '/',
  SINGLE_SIGN_ON_ERROR: '/sign_on_tokens',
  SINGLE_SIGN_ON: '/sign_on_tokens/*',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password/*',
  USER_CONFIRMATION: '/first_time_setup/*',
  DOWNLOAD_RALLIO_APP: '/download_rallio_app',
  ANALYTICS: '/:type/:id/analytics',
  ANALYTICS_SOCIAL: '/:type/:id/analytics/analytics_social/*',
  ANALYTICS_CONTENT: '/:type/:id/analytics/analytics_content/*',
  ANALYTICS_PAGE: '/:type/:id/analytics/analytics_page/*',
  ANALYTICS_REVIEW: '/:type/:id/analytics/analytics_reviews/*',
  ANALYTICS_LEADERBOARD: '/:type/:id/analytics/analytics_leaderboard/*',
  CONTENT: '/:type/:id/content',
  CONTENT_CREATOR: '/:type/:id/content/creator/*',
  CONTENT_POSTS: '/:type/:id/content/posts/*',
  CONTENT_MEDIA: '/:type/:id/content/media/*',
  CONTENT_FEED: '/:type/:id/content/feed/*',
  CONTENT_SCHEDULER: '/:type/:id/content/scheduler/*',
  CONTENT_CAMPAIGNS: '/:type/:id/content/campaigns/*',
  CONTENT_COUPONS: '/:type/:id/content/coupons/*',
  CONTENT_CALENDAR: '/:type/:id/content/calendar/*',
  CONTENT_RSS_FEED: '/:type/:id/content/rss_feeds/*',
  CONTENT_PROFILE_IMAGERY: '/:type/:id/content/profile_imagery/*',
  COMMUNITY: '/:type/:id/community',
  COMMUNITY_INBOX: '/:type/:id/community/inbox/*',
  COMMUNITY_SANDBOX: '/:type/:id/community/sandbox/*',
  MARKETING: '/:type/:id/marketing',
  MARKETING_PROSPECTS_CLIENTS: '/:type/:id/marketing/prospects_clients',
  MARKETING_FB_ADS: '/:type/:id/marketing/fb_ads_marketing/*',
  MARKETING_TARGETING: '/:type/:id/marketing/targeting/*',
  REPUTATION: '/:type/:id/reputation',
  REPUTATION_OUTBOX: '/:type/:id/reputation/outbox/*',
  REPUTATION_REVIEWS: '/:type/:id/reputation/reviews/*',
  REPUTATION_AI_REVIEW_RESPONDER: '/:type/:id/reputation/ai_review_responder/*',
  ADVOCACY: '/:type/:id/employee_advocacy',
  ADVOCACY_OVERVIEW: '/:type/:id/employee_advocacy/overview/*',
  ADVOCACY_LEADER_BOARD: '/:type/:id/employee_advocacy/advocacy_leaderboard/*',
  ADVOCACY_REWARD_PROGRAM: '/:type/:id/employee_advocacy/reward_programs/*',
  DIRECTORY_LISTINGS: '/:type/:id/directory_listings',
  DIRECTORY_LISTINGS_PROFILE: '/:type/:id/directory_listings/profile/*',
  DIRECTORY_LISTINGS_LISTINGS: '/:type/:id/directory_listings/listings/*',
  DIRECTORY_LISTINGS_RANKINGS: '/:type/:id/directory_listings/rankings/*',
  TEAM_MANAGEMENT: '/:type/:id/team_management',
  TEAM_MANAGEMENT_CONNECTIONS: '/:type/:id/team_management/connections/*',
  TEAM_MANAGEMENT_LISTS: '/:type/:id/team_management/lists/*',
  TEAM_MANAGEMENT_LOGINS: '/:type/:id/team_management/logins/*',
  TEAM_MANAGEMENT_PERMISSIONS: '/:type/:id/team_management/permissions/*',
  SETTINGS: '/:type/:id/settings',
  SETTINGS_REVIEW_TEMPLATE: '/:type/:id/settings/reviews_settings/*',
  SETTINGS_RELEASE_FORM: '/:type/:id/settings/release_form/*',
  SETTINGS_RALLIO: '/:type/:id/settings/rallio_profile/*',
  SETTINGS_BRAND_PROFILE: '/:type/:id/settings/brand_profile/*',
  SETTINGS_PARTNER_BANNERS: '/:type/:id/settings/partner_banners/*',
  SETTINGS_SOCIAL: '/:type/:id/settings/social_profile/*',
  SETTINGS_AI_PLAYBOOK: '/:type/:id/settings/ai_playbook/*',
  SETTINGS_HASHTAGS: '/:type/:id/settings/hashtags',
  SETTINGS_FB_ADS: '/:type/:id/settings/fb_ads_settings/*',
  ADMIN_ACCOUNTS: '/admin_area/accounts',
  ADMIN_FRANCHISORS: '/admin_area/franchisors',
  ADMIN_USERS: '/admin_area/users',
  VIDEO_TUTORIALS: '/:type/:id/video_tutorials',
  AI_ASSISTANT: '/:type/:id/ai_assistant',
  DEFAULT: '/content/posts',

  // REVV
  REVV: '/:type/:id/revv/login',
  REVV_DASHBOARD: '/:type/:id/revv/dashboard',
  REVV_ANALYTICS: '/:type/:id/analytics/analytics_revv',
  REVV_ANALYTICS_SURVEY_STATUS: '/:type/:id/analytics/analytics_revv/survey_status',
  REVV_ANALYTICS_SENTIMENT: '/:type/:id/analytics/analytics_revv/sentiment',
  REVV_ANALYTICS_REVIEW: '/:type/:id/analytics/analytics_revv/review_change',
  REVV_ANALYTICS_AVERAGE_RATING: '/:type/:id/analytics/analytics_revv/average_rating',
  REVV_SURVEYS: '/:type/:id/revv/surveys',
  REVV_REPORTS: '/:type/:id/revv/reports',
  REVV_TEAMS: '/:type/:id/team_management/revv_teams',
  REVV_TEAMS_DETAIL: '/:type/:id/team_management/revv_teams/:id',
  REVV_CUSTOMERS: '/:type/:id/revv/customers',
  REVV_CUSTOMERS_DETAIL: '/:type/:id/revv/customers/:id',
  // REVV_COMMUNITY: '/:type/:id/community/revv/*',
  REVV_SETTINGS: '/:type/:id/revv/revv_settings',
  REVV_LOCATION: '/:type/:id/revv/locations',
  REVV_EMPLOYEES: '/:type/:id/revv/employees'
};

export const CHILD_ROUTES = {
  ANALYTICS_LEADERBOARD: {
    OPERATIONS: 'operations',
    LOCATIONS: 'revv/locations',
    TEAMS: 'revv/teams'
  },
  ADMIN_ACCOUNTS: {
    RALLIO_PROFILE: 'account_wizard/:account_wizard_id/rallio_profile',
    UPGRADE_PACKAGE: 'account_wizard/:account_wizard_id/upgrade_package',
    SOCIAL_PROFILE: 'account_wizard/:account_wizard_id/social_profiles',
    FEED: 'account_wizard/:account_wizard_id/feed',
    SCHEDULER: 'account_wizard/:account_wizard_id/scheduler'
  },
  ADMIN_FRANCHISORS: {
    FRANCHISEE_STATS: ':franchisee_id/franchisee_stats',
    NEW_RALLIO_PROFILE: 'new_account_wizard/:account_wizard_id/rallio_profile',
    RALLIO_PROFILE: 'account_wizard/:account_wizard_id/rallio_profile',
    UPGRADE_PACKAGE: 'account_wizard/:account_wizard_id/upgrade_package',
    SOCIAL_PROFILE: 'account_wizard/:account_wizard_id/social_profiles',
    FEED: 'account_wizard/:account_wizard_id/feed',
    SCHEDULER: 'account_wizard/:account_wizard_id/scheduler'
  },
  ADMIN_USERS: {
    ADMIN_USERS_ID: ':user_id'
  },
  ANALYTICS_CONTENT: {
    CONTENT_ID: ':content_id',
    MULTIPLE_LOCATION_FACEBOOK: 'facebook_post_stats/:multiple_location_id',
    MULTIPLE_LOCATION_LINKEDIN: 'linkedin_post_stats/:multiple_location_id'
  },
  // REVV_COMMUNITY: {
  //   REVV_LOCATIONS: 'revv_locations',
  //   REVV_CUSTOMERS: 'revv_customers',
  //   CUSTOMERS_DETAILS: 'customer/:id'
  // },
  REVV_SETTINGS: {
    REVV_GLOBAL: 'global',
    REVV_OPTIN: 'optIn',
    REVV_SURVEY: 'survey',
    REVV_COUPON: 'coupon',
    REVV_CONNECTION: 'connection'
  },
  CONTENT_POSTS: {
    FAVORITE_TAGS: 'favorite_tags'
  },
  ADVOCACY_REWARD_PROGRAM: {
    NEW: COMMON_PAGE_TYPE.NEW,
    DETAIL_VIEW: ':program_id/detail_view',
    ACHEIVERS: ':program_id/acheivers',
    EDIT: ':program_id/edit'
  },
  TEAM_MANAGEMENT_CONNECTIONS: {
    USERS: ':user_id/users',
    EMPLOYEES: ':user_id/employees',
    NEW_RALLIO_PROFILE: 'new_account_wizard/:account_wizard_id/rallio_profile',
    RALLIO_PROFILE: 'account_wizard/:account_wizard_id/rallio_profile',
    UPGRADE_PACKAGE: 'account_wizard/:account_wizard_id/upgrade_package',
    SOCIAL_PROFILE: 'account_wizard/:account_wizard_id/social_profiles',
    FEED: 'account_wizard/:account_wizard_id/feed',
    SCHEDULER: 'account_wizard/:account_wizard_id/scheduler'
  },
  SETTINGS_SOCIAL: {
    CONNECT_INSTAGRAM: 'connect/instagram'
  },
  DIRECTORY_LISTINGS_PROFILE: {
    BUSINESS_HOURS: 'business_hours',
    ADD_PHOTOS: 'add_photos',
    BUSINESS_DETAILS: 'store_details'
  }
};

export const SIDE_NAV_NAME = {
  ANALYTICS_SOCIAL: 'analytics_social',
  ANALYTICS_CONTENT: 'analytics_content',
  ANALYTICS_PAGE: 'analytics_page',
  ANALYTICS_REVIEWS: 'analytics_reviews',
  ANALYTICS_LEADERBOARD: 'analytics_leaderboard',
  ANALYTICS_REVV: 'analytics_revv',
  CONTENT_CREATOR: 'creator',
  CONTENT_POSTS: 'posts',
  CONTENT_MEDIA: 'media',
  CONTENT_FEED: 'feed',
  CONTENT_SCHEDULER: 'scheduler',
  CONTENT_CAMPAIGNS: 'campaigns',
  CONTENT_COUPONS: 'coupons',
  CONTENT_RSS_FEEDS: 'rss_feeds',
  CONTENT_CALENDAR: 'calendar',
  CONTENT_PROFILE_IMAGERY: 'profile_imagery',
  COMMUNITY_INBOX: 'inbox',
  COMMUNITY_SANDBOX: 'sandbox',
  COMMUNITY_REVV: 'revv',
  MARKETING_PROSPECTS_CLIENTS: 'prospects_clients',
  MARKETING_FB_ADS_MARKETING: 'fb_ads_marketing',
  MARKETING_TARGETING: 'targeting',
  REPUTATION_REVIEWS: 'reviews',
  REPUTATION_OUTBOX: 'outbox',
  REPUTATION_AI_REVIEW_RESPONDER: 'ai_review_responder',
  REVV_LOGIN: 'login',
  REVV_DASHBOARD: 'dashboard',
  REVV_SURVEYS: 'surveys',
  REVV_REPORTS: 'reports',
  REVV_SETTINGS: 'revv_settings',
  REVV_LOCATION: 'locations',
  REVV_EMPLOYEES: 'employees',
  EMP_ADV_OVERVIEW: 'overview',
  EMP_ADV_REWARD_PROGRAMS: 'reward_programs',
  EMP_ADV_LEADERBOARD: 'advocacy_leaderboard',
  DL_PROFILE: 'profile',
  DL_LISTINGS: 'listings',
  TM_LOGINS: 'logins',
  TM_CONNECTIONS: 'connections',
  TM_LISTS: 'lists',
  TM_PERMISSIONS: 'permissions',
  TM_REVV_TEAMS: 'revv_teams',
  SETTINGS_BRAND_PROFILE: 'brand_profile',
  SETTINGS_PARTNER_BANNERS: 'partner_banners',
  REVIEWS_SETTINGS: 'reviews_settings',
  RELEASE_FORM: 'release_form',
  RALLIO_PROFILE: 'rallio_profile',
  SOCIAL_PROFILE: 'social_profile',
  FB_ADS_SETTINGS: 'fb_ads_settings',
  ADMIN_AREA_ACCOUNTS: 'accounts',
  ADMIN_AREA_FRANCHISORS: 'franchisors',
  ADMIN_AREA_USERS: 'users',
  AI_PLAYBOOK: 'ai_playbook',
  HASHTAGS: 'hashtags'
};

export const SIDE_NAV_DATA: any = {
  analytics: [
    // 0
    { name: 'analytics_social', label: 'Social', isShow: false },
    { name: 'analytics_content', label: 'Content', isShow: false },
    { name: 'analytics_page', label: 'Page', isShow: false },
    { name: 'analytics_reviews', label: 'Reviews', isShow: false },
    { name: 'analytics_leaderboard', label: 'Leaderboard', isShow: false },
    { name: 'analytics_revv', label: 'Revv', isShow: false }
  ],
  content: [
    // 1
    { name: 'creator', label: 'Creator', isShow: false },
    { name: 'posts', label: 'Posts', isShow: false },
    { name: 'media', label: 'Media', isShow: false },
    { name: 'feed', label: 'Feed', isShow: false },
    { name: 'scheduler', label: 'Scheduler', isShow: false },
    { name: 'campaigns', label: 'Campaigns', isShow: false },
    { name: 'coupons', label: 'Coupons', isShow: false },
    { name: 'rss_feeds', label: 'RSS Feeds', isShow: false },
    { name: 'calendar', label: 'Calendar', isShow: false },
    { name: 'profile_imagery', label: 'Profile Imagery', isShow: false }
  ],
  community: [
    // 2
    { name: 'inbox', label: 'Inbox', isShow: false },
    { name: 'sandbox', label: 'Sandbox', isShow: false }
  ],
  marketing: [
    // 3
    { name: 'prospects_clients', label: 'Prospects / Clients', isShow: false },
    { name: 'fb_ads_marketing', label: 'FB Ads', isShow: false },
    { name: 'targeting', label: 'Targeting', isShow: false }
  ],
  reputation: [
    // 4
    { name: 'reviews', label: 'Reviews', isShow: false },
    { name: 'outbox', label: 'Outbox', isShow: false },
    { name: 'ai_review_responder', label: 'AI Review Settings', isShow: false }
  ],
  revv: [
    // 5
    { name: 'login', label: 'Revv', isShow: false },
    { name: 'dashboard', label: 'Dashboard', isShow: true },
    { name: 'surveys', label: 'Surveys', isShow: true },
    { name: 'customers', label: 'Customers', isShow: true },
    { name: 'reports', label: 'Reports', isShow: true },
    { name: 'locations', label: 'Locations', isShow: false },
    { name: 'employees', label: 'Employees', isShow: false },
    { name: 'revv_settings', label: 'Settings', isShow: false }
  ],
  employee_advocacy: [
    // 6
    { name: 'overview', label: 'Overview', isShow: false },
    { name: 'reward_programs', label: 'Reward Programs', isShow: false },
    { name: 'advocacy_leaderboard', label: 'Leaderboard', isShow: false }
  ],
  directory_listings: [
    // 7
    { name: 'profile', label: 'Profile', isShow: false }
    /* { name: 'listings', label: 'Listings', isShow: false }
     { name: 'rankings', label: 'Rankings', isShow: false } */
  ],
  team_management: [
    // 8
    { name: 'logins', label: 'Logins', isShow: false },
    { name: 'connections', label: 'Connections', isShow: false },
    { name: 'lists', label: 'Lists & Feeds', isShow: false },
    { name: 'permissions', label: 'Permissions', isShow: false },
    { name: 'revv_teams', label: 'Revv', isShow: false }
  ],
  settings: [
    // 9
    { name: 'brand_profile', label: 'Profile', isShow: false },
    { name: 'reviews_settings', label: 'Reviews', isShow: false },
    { name: 'release_form', label: 'Release Form', isShow: false },
    { name: 'rallio_profile', label: RALLIO_PROFILE_TEXT, isShow: false },
    { name: 'ai_playbook', label: 'AI Playbook', isShow: false },
    { name: 'social_profile', label: 'Social Profiles', isShow: false },
    { name: 'fb_ads_settings', label: 'FB Ads', isShow: false },
    { name: 'partner_banners', label: 'Partners', isShow: false },
    { name: 'hashtags', label: 'Hashtags', isShow: false }
  ],
  admin_area: [
    // 11
    { name: 'accounts', label: 'Accounts', isShow: true },
    { name: 'franchisors', label: 'Franchisors', isShow: true },
    { name: 'users', label: 'Users', isShow: true }
  ]
};

export const MAIN_NAV_BAR_NAME = {
  AI_ASSISTANT: 'ai_assistant',
  ANALYTICS: 'analytics',
  CONTENT: 'content',
  COMMUNINTY: 'community',
  MARKETING: 'marketing',
  REPUTATION: 'reputation',
  REVV: 'revv',
  EMPLOYEE_ADVOCACY: 'employee_advocacy',
  DIRECTORY_LISTINGS: 'directory_listings',
  TEAM_MANAGEMENT: 'team_management',
  SETTINGS: 'settings',
  ADMIN_AREA: 'admin_area',
  VIDEO_TUTORIALS: 'video_tutorials'
};

export const SUPER_ADMIN_NAV_BAR: commonProps.IMainPageNavBarData[] = [
  {
    menu: 'Admin Area',
    name: 'admin_area',
    icon: 'm10',
    isShow: true
  }
];

export const NAV_BAR: commonProps.IMainPageNavBarData[] = [
  {
    menu: 'AI Assistant',
    name: 'ai_assistant',
    icon: 'mai.gif',
    isShow: true
  },
  {
    menu: 'Analytics',
    name: 'analytics',
    icon: 'm1',
    isShow: true
  },
  {
    menu: 'Content',
    name: 'content',
    icon: 'm2',
    isShow: true
  },
  {
    menu: 'Community',
    name: 'community',
    icon: 'm3',
    isShow: true
  },
  {
    menu: 'Marketing',
    name: 'marketing',
    icon: 'm7',
    isShow: true
  },
  {
    menu: 'Reputation',
    name: 'reputation',
    icon: 'm4',
    isShow: true
  },
  {
    menu: 'Revv',
    name: 'revv',
    icon: 'm5',
    isShow: true
  },
  {
    menu: 'Employee Advocacy',
    name: 'employee_advocacy',
    icon: 'm6',
    isShow: true
  },
  {
    menu: 'Directory Listings',
    name: 'directory_listings',
    icon: 'm7',
    isShow: true
  },
  {
    menu: 'Team Management',
    name: 'team_management',
    icon: 'm8',
    isShow: true
  },
  {
    menu: 'Settings',
    name: 'settings',
    icon: 'm9',
    isShow: true
  },
  ...SUPER_ADMIN_NAV_BAR
];

export const SUPER_ADMIN_MENU = {
  admin_area: true
};

export const SUPER_ADMIN_MENU_AVAILABLITY: commonProps.ICommonObj<boolean> = {
  accounts: true,
  franchisors: true,
  users: true
};

export const MAIN_PAGES_MENU_AVAILABILITY: commonProps.ICommonObj<boolean> = {
  ai_assistant: false,
  analytics: false,
  content: false,
  community: false,
  marketing: false,
  reputation: false,
  revv: false,
  employee_advocacy: false,
  directory_listings: false,
  team_management: false,
  settings: false,
  admin_area: false
};
