// SETTINGS HASHTAGS - GET HASHTAGS LIST
export const GET_HASHTAGS_LIST_REQUEST = 'hashTags/GET_HASHTAGS_LIST_REQUEST';
export const GET_HASHTAGS_LIST_RESPONSE = 'hashTags/GET_HASHTAGS_LIST_RESPONSE';
export const GET_HASHTAGS_LIST_FAILURE = 'hashTags/GET_HASHTAGS_LIST_FAILURE';

// SETTINGS HASHTAGS - CREATE OR UPDATE HASHTAGS
export const CREATE_OR_UPDATE_HASHTAGS_REQUEST = 'hashTags/CREATE_OR_UPDATE_HASHTAGS_REQUEST';
export const CREATE_OR_UPDATE_HASHTAGS_RESPONSE = 'hashTags/CREATE_OR_UPDATE_HASHTAGS_RESPONSE';
export const CREATE_OR_UPDATE_HASHTAGS_FAILURE = 'hashTags/CREATE_OR_UPDATE_HASHTAGS_FAILURE';

// RESET HASHTAGS
export const HASHTAGS_RESET_ALL = 'hashTags/HASHTAGS_RESET_ALL';
