import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import {
  AP_ADD_TO_POST_TAB_NAMES,
  COMMON_SOCIAL_PROVIDER_TYPE,
  TOOLTIP_PLACEMENT,
  AP_ADD_TO_POST_TAB_TOOLTIP,
  CREATOR_PAGE_TAG_INFO_TOOLTIP,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  FB_CALL_TO_ACTION_SELECT_OPTIONS,
  SIDE_NAV_NAME,
  AI_ICON_BG_IMG,
  AI_COACHMARK,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM,
  CREATOR_TEXT_EDITOR_ALERT_MSG,
  SOCIAL_MEDIA_POST_TYPE_VALUES,
  AP_ACTION_TYPES
  // AI_SUBSCRIPTION_PLAN_TYPES
} from 'utils/constants';
import { apAccountContainerTypes, IStore } from 'types';
import { AddMediaModal, AddCouponModal, AddLink, AddTagPagesModal, AddSocialMediaCallToAction } from '../CommonModal';
import { CustomTooltip } from 'widgets/Tooltip';
import {
  addPostIsEmojiOpen,
  addPostSetEditConfirmForApprovedPost,
  setCreatorTextAlertMsg,
  setAIPostContentOpen,
  setAISubscriptionDetailsModal,
  setCreatorModalName,
  setAITextContent,
  setCustomizeLinkEnable,
  mgResetAll,
  mgResetCustomVideoThumbnailResponse,
  resetApMediaExpiration,
  setCsLocationSubscriptionModalType
} from 'actions';
import { creatorPhotosCount, creatorHasVideo /* convertEditorStateToString */ } from 'utils/helpers';
import { useAIFeatureAccess, useAISubscriptionPlan, useAccountSwitcherData, useActivePlatform, useNavBarData, useSSOIdentifiersForAccount, useSiteRestriction } from 'utils/hooks';
import { BoostMainModal } from 'components/Common';
import { AICoachMark } from 'components/Common/Modal/AICoachMark';
import { AddHashtag } from '../CommonModal/AddHashtag';

export const AddToYourPostSection = () => {
  const dispatch = useDispatch();
  const { userOwnership, id } = useAccountSwitcherData();
  const { isCSLocation } = useSSOIdentifiersForAccount();
  const { subNavPageName } = useNavBarData();
  const { aiRestriction } = useSiteRestriction();
  const { aiCaptionAccess, aiImageAccess } = useAIFeatureAccess();
  const { subscriptionPlan } = useAISubscriptionPlan();

  const fbTargetingChoice = useSelector((state: IStore) => state.fbAdsTargeting.fbTargetingChoice);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);
  const isPostEditable = useSelector((state: IStore) => state.addPostAccount.editConfirmObj.isPostEditable);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const isFBPageTaggingEnabled = useSelector((state: IStore) => state.accounts.accountDetails?.account?.facebook_page_tagging_feature || false);
  const couponFranchisorId = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.coupon_franchisor_id || null);
  const creatorModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorModalName);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  // const textEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const isEmojiOpen = useSelector((state: IStore) => state.addPostFranchisorAction.isEmojiOpen);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const aiTextContent = useSelector((state: IStore) => state.aiContentCreator.aiTextContent);
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);
  const aiLimitation = useSelector((state: IStore) => state.aiContentCreator.aiLimitation);
  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);

  const isCoachMark = [SIDE_NAV_NAME.CONTENT_CAMPAIGNS, SIDE_NAV_NAME.CONTENT_CALENDAR].includes(subNavPageName)
    ? false
    : savedPostDetails?.current_tab
    ? addPostAction !== AP_ACTION_TYPES.ADD // old - convertEditorStateToString(textEditorState[savedPostDetails?.current_tab])
      ? false
      : true
    : false;

  const isDisableEmoji = Boolean(
    (savedPostDetails?.current_tab &&
      IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(savedPostDetails?.current_tab) &&
      savedPostDetails?.[`${savedPostDetails?.current_tab}_text`]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[savedPostDetails?.current_tab.toUpperCase()] - 3) ||
      savedPostDetails?.locked
  )
    ? true
    : false;

  const activePlatforms = useActivePlatform();

  const connectedToFacebook = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);

  useEffect(() => {
    dispatch(setCustomizeLinkEnable(savedPostDetails?.link_preview_customized ? true : false));
  }, [savedPostDetails?.link_preview_customized, dispatch]);

  const handleDisableImageTab = () => {
    if (savedPostDetails?.locked) return true;
    else if (creatorHasVideo(selectedMediaList)) return true;
    else if (creatorPhotosCount(selectedMediaList) >= 500) return true;
    // else if (editData?.isCorporatePost) return true;  // ras-4406 enable edit option for scheduled post
    else return false;
  };

  const handleDisableVideoTab = () => {
    if (savedPostDetails?.locked) return true;
    else if (creatorPhotosCount(selectedMediaList)) return true;
    else if (creatorHasVideo(selectedMediaList)) return true;
    // else if (editData?.isCorporatePost) return true; // ras-4406 enable edit option for scheduled post
    else return false;
  };

  const handleDisableCouponOrLinkTab = () => {
    if (savedPostDetails?.locked) return true;
    else if (creatorPhotosCount(selectedMediaList)) return true;
    // else if (editData?.isCorporatePost) return true; // ras-4406 enable edit option for scheduled post
    else return false;
  };

  const handleDisableCommonTab = () => {
    if (savedPostDetails?.locked) return true;
    // else if (editData?.isCorporatePost) return true; // ras-4406 enable edit option for scheduled post
    else return false;
  };

  const handleEditConfirmation = () => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isShowModal: true, isEditConfirmed: false }));
  };

  const handleTabAction = (tabName: string) => {
    dispatch(setCreatorTextAlertMsg(null));
    if (isPostEditable) {
      handleEditConfirmation();
    } else {
      setModalName(tabName);
    }
  };

  const handleCSSubscription = () => {
    dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
    dispatch(setCsLocationSubscriptionModalType('ai_caption'));
  };

  const handleAiClick = () => {
    if (!isAISubscribed) {
      if (!isCSLocation) {
        dispatch(setAISubscriptionDetailsModal({ type: subscriptionPlan, isOpen: true }));
      } else {
        handleCSSubscription();
      }
    } else if (Boolean(aiLimitation && +aiLimitation?.ai_caption_limit <= 0)) {
      handleCSSubscription();
    } else {
      dispatch(setAIPostContentOpen(!isAiContentOpen));
      dispatch(setAITextContent({ ...aiTextContent, content: `${savedPostDetails?.[`${savedPostDetails?.current_tab}_text`]}` || '' }));
    }
    // navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('*', COMMON_PAGE_TYPE.NEW));
    // if (!isAiContentOpen)
  };

  const handleAddLinkModalClose = () => {
    setModalName(null);
    if (response.length) dispatch(mgResetAll());
  };

  const setModalName = (data: string | null) => dispatch(setCreatorModalName(data));

  /*  const siteRestrictions = useMemo(() => {
    const restrictions = {
      showBoost: false,
    };
    if (menuAccess) {
      const siteRestrictions = menuAccess?.site_restrictions;
      if (id && userOwnership) {
        if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
          restrictions.showBoost = Boolean(siteRestrictions?.location_user?.includes('boost'));
        }
      }
    }
    return restrictions;
  }, [menuAccess, id, userOwnership]); */

  const showLinkWarningForStoryOrReel = () => {
    dispatch(
      setCreatorTextAlertMsg(
        CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG_WITH_STORY_OR_REEL.replaceAll(
          `/post-type/`,
          savedPostDetails?.facebook_story || savedPostDetails?.instagram_story ? SOCIAL_MEDIA_POST_TYPE_VALUES.STORY : SOCIAL_MEDIA_POST_TYPE_VALUES.REELS
        )
      )
    );
  };

  const creatorMediaButtons: apAccountContainerTypes.IAPAddToPostMediaButton[] = useMemo(
    () => [
      {
        isShow: true,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.EMOJI,
        classname: 'add__post--icon emoji-20 add-emojis',
        defaultImg: 'add-emoji-dark-Blue',
        isDisabled: isDisableEmoji,
        imgName: 'Emoji',
        onClick: () => {
          if (
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && savedPostDetails?.facebook_story) ||
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
          ) {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAG_ALERT_STORY_MSG));
          } else if (isEmojiOpen) {
            dispatch(addPostIsEmojiOpen(false));
          } else {
            dispatch(addPostIsEmojiOpen(true));
          }
        }
      },
      {
        isShow: [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER].includes(savedPostDetails?.current_tab || ''),
        tooltipText: CREATOR_PAGE_TAG_INFO_TOOLTIP,
        classname: 'mic info-icon info-ins-twt',
        defaultImg: 'info-tooltip',
        imgName: 'Info'
      },
      {
        isShow: connectedToFacebook && isFBPageTaggingEnabled && savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.TAG_PAGES,
        classname: 'add__post--icon tags add-tags',
        isDisabled: handleDisableCommonTab(),
        defaultImg: 'add-tags',
        imgName: 'Tags',
        onClick: () => {
          if (
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && savedPostDetails?.facebook_story) ||
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
          ) {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAG_ALERT_STORY_MSG));
          } else if (savedPostDetails?.use_facebook) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.TAG_PAGES);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAGS_ALERT_MSG));
          }
        }
      },
      {
        isShow: savedPostDetails?.current_tab !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.IMAGES,
        classname: 'add__post--icon add-img',
        isDisabled: handleDisableImageTab(),
        defaultImg: 'add-image-color',
        imgName: 'Images',
        onClick: () => {
          if (!linkData && !savedPostDetails?.google_call_to_action_type) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.PHOTOS);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: savedPostDetails?.current_tab !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.VIDEOS,
        classname: 'add__post--icon add-vid',
        isDisabled: handleDisableVideoTab(),
        defaultImg: 'add-video-color',
        imgName: 'Video',
        onClick: () => {
          if (!linkData && !savedPostDetails?.google_call_to_action_type) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.VIDEOS);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: Boolean(couponFranchisorId) && savedPostDetails?.current_tab !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.COUPONS,
        classname: 'add__post--icon coupons',
        isDisabled: handleDisableCouponOrLinkTab(),
        defaultImg: 'coup_icon',
        imgName: 'Coupon',
        onClick: () => {
          if (
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel)) ||
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
          ) {
            showLinkWarningForStoryOrReel();
          } else if (!creatorHasVideo(selectedMediaList)) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.COUPON);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: savedPostDetails?.current_tab !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.LINKS,
        classname: 'add__post--icon add-links add-hls',
        isDisabled: handleDisableCouponOrLinkTab(),
        defaultImg: 'add-link-color',
        imgName: 'Link',
        onClick: () => {
          if (
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel)) ||
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
          ) {
            showLinkWarningForStoryOrReel();
          } else if (!creatorHasVideo(selectedMediaList)) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.LINK);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: Boolean(connectedToFacebook && fbTargetingChoice?.facebook_ad_account?.id && savedPostDetails?.use_facebook) /* siteRestrictions.showBoost && */,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.BOOST,
        classname: 'add__post--icon add-boost',
        isDisabled: handleDisableCommonTab(),
        defaultImg: 'add-misc-color',
        imgName: 'Boost',
        onClick: () => handleTabAction(AP_ADD_TO_POST_TAB_NAMES.BOOST)
      },
      {
        isShow: [COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK].includes(savedPostDetails?.current_tab || ''),
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.CALL_TO_ACTION,
        classname: 'add__post--icon add-gpost',
        isDisabled: handleDisableCouponOrLinkTab(),
        defaultImg: 'google-post',
        imgName: 'GooglePost',
        onClick: () => {
          if (
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel)) ||
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
          ) {
            showLinkWarningForStoryOrReel();
          } else if (!creatorHasVideo(selectedMediaList)) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.CALL_TO_ACTION);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: true,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.HASHTAGS,
        classname: 'add__post--icon add-links add-hts',
        isDisabled: handleDisableCommonTab(),
        defaultImg: 'hash-tags',
        imgName: 'Hashtags',
        onClick: () => {
          if (
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (savedPostDetails?.facebook_story || savedPostDetails?.facebook_reel)) ||
            (savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
          ) {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAG_ALERT_STORY_MSG));
          } else {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.HASHTAGS);
          }
        }
      },
      {
        isShow: true,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.AI_ASSISTANT,
        classname: `add__post--icon add-ai-feature ${isAiContentOpen ? 'ai-events-none' : ''} ${isCoachMark ? 'cm-enable' : ''}`,
        isDisabled: handleDisableCommonTab(),
        defaultImg: AI_ICON_BG_IMG.LIGHT_BLUISHGREY,
        imgName: 'AI',
        isAICoachMark: true
      }
    ],
    // eslint-disable-next-line
    [
      savedPostDetails?.current_tab,
      connectedToFacebook,
      isFBPageTaggingEnabled,
      savedPostDetails?.use_facebook,
      couponFranchisorId,
      fbTargetingChoice?.facebook_ad_account?.id,
      selectedMediaList,
      // editData?.isCorporatePost, // ras-4406 enable edit option for scheduled post
      isPostEditable,
      savedPostDetails?.locked,
      isEmojiOpen,
      isDisableEmoji,
      savedPostDetails?.google_call_to_action_type,
      linkData,
      savedPostDetails?.facebook_reel,
      savedPostDetails?.facebook_story,
      savedPostDetails?.instagram_story,
      showLinkWarningForStoryOrReel
    ]
  );

  const handleAddMediaModalClose = () => {
    setModalName(null);
    dispatch(mgResetCustomVideoThumbnailResponse());
    dispatch(resetApMediaExpiration());
  };

  return (
    <>
      {creatorMediaButtons.map((it, index) => {
        return it.isShow ? (
          <CustomTooltip
            key={`acc-media-${index}`}
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={it.tooltipText}
            customClassname={'custom-tooltip-long-text'}
            customInput={() => (
              <div className={`${it.classname}${it?.isDisabled ? ` disabled` : ``}`} onClick={it.onClick}>
                {isRallioai && (aiCaptionAccess || aiImageAccess) && it.isAICoachMark && !aiRestriction.button ? (
                  <AICoachMark coachMarkPosition={'right'} coachMarkDetails={AI_COACHMARK.find((it) => it.value === 'caption')} isCoachMark={isCoachMark}>
                    <div className={`ai-btn ai-creator-btn ai-anim-btn ${isAiContentOpen ? 'ai-events-none' : ''} ${isCoachMark ? 'cm-enable' : ''}`} onClick={handleAiClick}>
                      <ImageValidation isImgValid defaultImg={it.defaultImg} isNotSvgFormat customName={it.imgName} customClassname={'add__post--icon add-boost'} />
                    </div>
                  </AICoachMark>
                ) : (
                  <ImageValidation isImgValid defaultImg={it.defaultImg} customName={it.imgName} customClassname={'add__post--icon add-boost'} />
                )}
              </div>
            )}
          />
        ) : null;
      })}
      {creatorModalName === AP_ADD_TO_POST_TAB_NAMES.TAG_PAGES ? (
        <AddTagPagesModal isModalShow={creatorModalName === AP_ADD_TO_POST_TAB_NAMES.TAG_PAGES ? true : false} handleModalClose={() => setModalName(null)} />
      ) : creatorModalName && [AP_ADD_TO_POST_TAB_NAMES.PHOTOS, AP_ADD_TO_POST_TAB_NAMES.VIDEOS].includes(creatorModalName) ? (
        <AddMediaModal mediaName={creatorModalName || ''} handleClose={handleAddMediaModalClose} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.COUPON ? (
        <AddCouponModal isModalShow={creatorModalName === AP_ADD_TO_POST_TAB_NAMES.COUPON ? true : false} handleModalClose={() => setModalName(null)} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.LINK ? (
        <AddLink isModalShow={creatorModalName === AP_ADD_TO_POST_TAB_NAMES.LINK ? true : false} handleModalClose={handleAddLinkModalClose} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.HASHTAGS ? (
        <AddHashtag isModalShow={creatorModalName === AP_ADD_TO_POST_TAB_NAMES.HASHTAGS} handleModalClose={() => setModalName(null)} id={id} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.BOOST ? (
        // This boost modal for calendar edit in add to your post button
        <BoostMainModal isModalShow={creatorModalName === AP_ADD_TO_POST_TAB_NAMES.BOOST ? true : false} handleModalClose={() => setModalName(null)} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.CALL_TO_ACTION ? (
        <AddSocialMediaCallToAction
          isShowModal
          onModalClose={handleAddLinkModalClose}
          actionTypeOptions={savedPostDetails?.current_tab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS : FB_CALL_TO_ACTION_SELECT_OPTIONS}
          currentTab={savedPostDetails?.current_tab || COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK}
          userOwnership={userOwnership}
        />
      ) : null}
    </>
  );
};
