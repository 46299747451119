import { useState } from 'react';
import { Tabs, Tab, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { CardCVCElement, CardExpiryElement, CardNumberElement, Elements, injectStripe, ReactStripeElements, StripeProvider } from 'react-stripe-elements';
import SwiperCore from 'swiper';
import 'swiper/swiper.min.css';

import { IStore, rpContainerTypes, stripeReducerTypes } from 'types';
import {
  RP_PAYMENT_VALIDATION,
  RpPaymentField,
  RP_PAYMENT_TABS,
  RP_PAYMENT_CARD_DATA,
  RP_PAYMENT_DEFAULT_CVC_PAYMENT,
  ALERT_BOX_TYPES,
  AI_SUBSCRIPTION_UPGRADE_SUCCESS,
  AI_SUBSCRIPTION_INFO_MESSAGE,
  USER_OWNERSHIP,
  IReactResponsiveTypes,
  ICSSubscriptionPlans,
  AI_SUBSCRIPTION_PLAN_TYPES
} from 'utils/constants';
import { config } from 'config';
import {
  accountGetProfileRequest,
  aiCreateSubscriptionPlanRequest,
  aiUpdateSubscriptionPlanRequest,
  setAISubscriptionDetailsModal,
  setSubscriptionMainPageType,
  stripeCardsListRequest
} from 'actions';
import { useReactResponsive, useAccountSwitcherData, useAISubscriptionPlan } from 'utils/hooks';
import { getRemainingDaysFromToday, getDiscountedDaysFromToday } from 'utils/helpers';
import { alertBoxCall } from 'components/Common/AlertBox';

export const StripePayment = (props: ReactStripeElements.StripeProps | any) => {
  SwiperCore.use([]);
  const dispatch = useDispatch();
  const responsiveType = useReactResponsive();
  const { id, userOwnership } = useAccountSwitcherData();
  const { subscriptionPlan } = useAISubscriptionPlan();

  const cardsListArray = useSelector((state: IStore) => state.stripe.cardsListArray);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id || 0);
  const subscriptionType = useSelector((state: IStore) => state.aiContentCreator.subscriptionType);
  const isFetching = useSelector((state: IStore) => state.stripe.isFetching);
  const isAiSubscriptionFetching = useSelector((state: IStore) => state.aiContentCreator.isAiSubscriptionFetching);
  const selectedSubscription = useSelector((state: IStore) => state.aiContentCreator.selectedSubscription);
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  const topLevelFranchisorId = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.accountSwitcher?.franchisors?.map((it) => it.id).join(',') || '' : state.accountSwitcher?.accounts?.map((it) => it.id).join(',') || ''
  );
  const customerObj = useSelector((state: IStore) => state.stripe.customerObj);
  const contentSupplierSelectedPlan = useSelector((state: IStore) => state.aiContentCreator.contentSupplierSelectedPlan);
  const accSubscriptionDetails = useSelector(
    (state: IStore) => state.accounts.accountDetails?.account?.active_subscription_details?.[0] || state.accounts.accountDetails?.account?.subscription_details?.[0]
  );

  const [fieldValid, setFieldValidation] = useState<rpContainerTypes.IRPPaymentValidation>({ ...RP_PAYMENT_VALIDATION });
  const [paymentTab, setPaymentTab] = useState(RP_PAYMENT_TABS.SAVED.value);
  const [selectedCard, setSelectedCard] = useState('');
  const [ishandleSubmit, sethandleSubmit] = useState(false);

  const franchisorSubscriptionTypeName = accSubscriptionDetails?.franchisor_subscription_type?.plan_name;
  const isTrailExpired = getRemainingDaysFromToday(accSubscriptionDetails?.end_date || '') <= 0;
  const isCSLocationSubscription = ['RC_Free', 'RC_Premium'].includes(franchisorSubscriptionTypeName) && aiSubscriptionDetailsModal.type === 'CSLocationAISubscription';
  const discountDays = getDiscountedDaysFromToday(accSubscriptionDetails?.created_at || '');
  const isDiscountPriceAvailable = discountDays >= 1 && discountDays <= 7;

  const handleValidation = (paymentEvt: React.ChangeEvent<HTMLInputElement> | any, fieldName: string) => {
    setFieldValidation((prevState: rpContainerTypes.IRPPaymentValidation) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        isTouched: true
      }
    }));
    if (fieldName === RpPaymentField.USER_NAME) {
      if (paymentEvt.target) {
        const userVal = paymentEvt.target.value;
        setFieldValidation((prevState) => ({
          ...prevState,
          userName: {
            ...prevState.userName,
            isValid: userVal !== '' ? true : false
          }
        }));
      }
    } else {
      setFieldValidation((prevState: rpContainerTypes.IRPPaymentValidation) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          isValid: paymentEvt.complete
        }
      }));
    }
  };
  const handleCancel = () => {
    if (!(isFetching || isAiSubscriptionFetching)) {
      dispatch(setSubscriptionMainPageType('selection'));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();
    if (!selectedCard && paymentTab === RP_PAYMENT_TABS.SAVED.value) {
      alertBoxCall(ALERT_BOX_TYPES.INFO, AI_SUBSCRIPTION_INFO_MESSAGE);
    }
    if (!(isFetching || isAiSubscriptionFetching)) {
      Object.values(RpPaymentField).forEach((fieldName: string) => {
        setFieldValidation((prevState: rpContainerTypes.IRPPaymentValidation) => ({
          ...prevState,
          [fieldName]: {
            ...prevState[fieldName],
            isTouched: true
          }
        }));
      });
      const subscriptionObj: any = accSubscriptionDetails;

      const paymentObject: any =
        aiSubscriptionDetailsModal.type === 'contentSupplierSubscription'
          ? {
              account_id: subscriptionObj?.account_id,
              email: activeUserEmail || '',
              id: subscriptionObj?.id,
              isUpgradeSusbcription: true,
              subscription_id: subscriptionObj?.subscription_id,
              subscription_plan: contentSupplierSelectedPlan === ICSSubscriptionPlans.TIER2 ? ICSSubscriptionPlans.TIER2 : ICSSubscriptionPlans.TIER2POINT5,
              subscription_type_id: subscriptionObj?.subscription_type_id,
              subscriptionType: 'WTH',
              user_id: subscriptionObj?.user_id,
              franchisor_subscription_type_id: contentSupplierSelectedPlan === ICSSubscriptionPlans.TIER2 ? 2 : 3
            }
          : isCSLocationSubscription
          ? {
              account_id: subscriptionObj?.account_id,
              user_id: subscriptionObj?.user_id,
              email: activeUserEmail || '',
              isAiSubscription: true,
              subscriptionType: 'CS_FREE',
              subscriptionPlan: 'CS_PREMIUM',
              programDuration: 'month',
              autoRenewal: true,
              subscription_type_id: subscriptionObj?.subscription_type_id,
              franchisor_subscription_type_id: 8,
              id: subscriptionObj?.id,
              isCSDiscountedPrice: isDiscountPriceAvailable
            }
          : {
              email: activeUserEmail || '',
              isAiSubscription: true,
              programDuration: subscriptionType?.programDuration,
              autoRenewal: true,
              ...(subscriptionType?.type !== 'FBBC' && { amount: subscriptionType?.price }),
              ...(subscriptionPlan !== AI_SUBSCRIPTION_PLAN_TYPES.TRIAL_PERIOD && { subscriptionType: subscriptionPlan }),
              ...(subscriptionType?.franchisor_subscription_type_id && { franchisor_subscription_type_id: subscriptionType?.franchisor_subscription_type_id }),
              ...(subscriptionType?.subscription_plan && { subscriptionPlan: subscriptionType?.subscription_plan })
            };
      let payload: stripeReducerTypes.IStripeToken;
      sethandleSubmit(true);
      if (props.stripe) {
        if (paymentTab === RP_PAYMENT_TABS.NEW.value) {
          payload = await props.stripe.createToken();
          paymentObject.token = payload.token.id || '';
        } else if (paymentTab === RP_PAYMENT_TABS.SAVED.value) {
          paymentObject.cardId = selectedCard || '';
        }
      }

      if (isCSLocationSubscription && (paymentObject.token || paymentObject.cardId)) {
        dispatch(
          aiCreateSubscriptionPlanRequest({
            createParams: {
              data: [{ ...paymentObject }]
            },
            getParams: {
              id,
              topLevelFranchisorId,
              userOwnership,
              userId: activeUserId
            },
            closeModal: () => {
              if (paymentObject.token && customerObj?.id) {
                dispatch(stripeCardsListRequest(customerObj.id));
              }
              dispatch(setSubscriptionMainPageType('selection'));
              if (userOwnership === USER_OWNERSHIP.ACCOUNT) dispatch(accountGetProfileRequest({ id, isDataRetain: true, isPayment: true }));
              dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: false }));
              sethandleSubmit(false);
            }
          })
        );
      } else if (aiSubscriptionDetailsModal.type !== 'contentSupplierSubscription' && (paymentObject.token || paymentObject.cardId) && activeUserId) {
        dispatch(
          aiCreateSubscriptionPlanRequest({
            createParams: {
              data: selectedSubscription.map((it) => ({
                franchisor_id: it.franchisorId || null,
                account_id: it.accountId || null,
                user_id: activeUserId,
                autoRenewal: true,
                ...paymentObject
              }))
            },
            getParams: {
              id,
              topLevelFranchisorId,
              userOwnership,
              userId: activeUserId
            },
            closeModal: () => {
              if (paymentObject.token && customerObj?.id) {
                dispatch(stripeCardsListRequest(customerObj.id));
              }
              if (aiSubscriptionDetailsModal.type === 'modifySubscription') {
                alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UPGRADE_SUCCESS);
              }
              if (userOwnership === USER_OWNERSHIP.ACCOUNT) dispatch(accountGetProfileRequest({ id, isDataRetain: true, isPayment: true }));
              dispatch(setSubscriptionMainPageType('selection'));
              const type = aiSubscriptionDetailsModal?.isCoachMark ? 'AICoach' : 'susbcriptionTable';
              dispatch(setAISubscriptionDetailsModal({ type, isOpen: true }));
              sethandleSubmit(false);
            }
          })
        );
      } else if (aiSubscriptionDetailsModal.type === 'contentSupplierSubscription' && (paymentObject.token || paymentObject.cardId)) {
        if (!['Tier 1 - Free'].includes(franchisorSubscriptionTypeName)) delete paymentObject.cardId;
        if (isTrailExpired) {
          delete paymentObject.subscription_id;
          delete paymentObject.id;
          delete paymentObject.isUpgradeSusbcription;

          paymentObject.isTrialEnable = false;
          paymentObject.isAiSubscription = true;
          dispatch(
            aiCreateSubscriptionPlanRequest({
              createParams: {
                data: [{ ...paymentObject, autoRenewal: true, subscriptionPlan: paymentObject.subscription_plan }]
              },
              getParams: {
                id,
                topLevelFranchisorId,
                userOwnership,
                userId: activeUserId
              },
              closeModal: () => {
                if (paymentObject.token && customerObj?.id) {
                  dispatch(stripeCardsListRequest(customerObj.id));
                }
                if (aiSubscriptionDetailsModal.type === 'modifySubscription') {
                  alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UPGRADE_SUCCESS);
                }
                dispatch(setSubscriptionMainPageType('selection'));
                if (userOwnership === USER_OWNERSHIP.ACCOUNT) dispatch(accountGetProfileRequest({ id, isDataRetain: true, isPayment: true }));
                const type = aiSubscriptionDetailsModal?.isCoachMark ? 'AICoach' : 'susbcriptionTable';
                dispatch(setAISubscriptionDetailsModal({ type, isOpen: true }));
                sethandleSubmit(false);
              }
            })
          );
        } else {
          dispatch(
            aiUpdateSubscriptionPlanRequest({
              createParams: {
                data: [{ ...paymentObject, auto_renewal: true }]
              },
              getParams: {
                id,
                topLevelFranchisorId,
                userOwnership,
                userId: activeUserId
              },
              closeModal: () => {
                dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
                if (userOwnership === USER_OWNERSHIP.ACCOUNT) dispatch(accountGetProfileRequest({ id, isDataRetain: true, isPayment: true }));
                alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UPGRADE_SUCCESS);
              }
            })
          );
        }
      }
    }
  };

  const renderSavedCardList = (cards: stripeReducerTypes.IStripeCardItem[]) => {
    return (
      <>
        {cards.length ? (
          cards.map((cardItem) => {
            return (
              <div key={cardItem.id} className="mcvf-main">
                <div
                  className={`moneycard ${RP_PAYMENT_CARD_DATA.find((datum) => datum.name === cardItem.brand)?.classname}${selectedCard === cardItem.id ? ` active` : ``}`}
                  onClick={() => {
                    setSelectedCard(cardItem.id);
                    sethandleSubmit(false);
                  }}
                >
                  <Card.Body>
                    <div className="card-number-wrp">
                      <span>{RP_PAYMENT_CARD_DATA.find((datum) => datum.name === cardItem.brand)?.first4}</span>
                      <span>****</span>
                      <span>****</span>
                      <span>{cardItem.last4}</span>
                    </div>
                    <div className="grid-sec">
                      <div className="expiry-date-wrp">
                        <span>
                          {String(cardItem.exp_month).length === 2 ? cardItem.exp_month : `0${cardItem.exp_month}`} / {String(cardItem.exp_year).substring(2, 4)}
                        </span>
                      </div>
                      <div className="cvv-wrp">
                        <span>{cardItem.cvc_check === RP_PAYMENT_DEFAULT_CVC_PAYMENT ? '***' : ''}</span>
                      </div>
                    </div>
                  </Card.Body>
                </div>
                <div className="vf-wrap d-none">
                  <div className="rewards-programdetails-modal-wrp">
                    <div className="payment-section-wrp">
                      <div className="payment-content-section-main">
                        <div className="pay-newcard-wrp">
                          <div className="left-section">
                            <div className={`form-group${fieldValid.userName.isTouched && !fieldValid.userName.isValid ? ` error-disp` : ``}`}>
                              <input
                                type="text"
                                name="cardname"
                                className={`form-control${fieldValid.userName ? ` used` : ``}`}
                                autoComplete="off"
                                // defaultValue={cardItem.name}
                                onChange={(cardEvt: React.ChangeEvent<HTMLInputElement>) => handleValidation(cardEvt, 'userName')}
                              />
                              <span className="fltlabels">Name on card</span>
                            </div>
                            <div className={`strip-section-align${fieldValid.cardNumber.isTouched && !fieldValid.cardNumber.isValid ? ` error-disp` : ``}`}>
                              <div className="StripeElement">
                                <input type="text" name="cardnumber" className={`form-control`} autoComplete="off" />
                              </div>
                              <span className="fltlabels-static">Card Number</span>
                            </div>
                            <div className="stripe-exp-cvc-grid">
                              <div className={`strip-section-align${fieldValid.cardValid.isTouched && !fieldValid.cardValid.isValid ? ` error-disp` : ``}`}>
                                <div className="StripeElement">
                                  <input type="text" name="exp-date" className={`form-control`} autoComplete="off" />
                                </div>
                                <span className="fltlabels-static">Expiry</span>
                              </div>
                              <div className={`strip-section-align${fieldValid.cardCvc.isTouched && !fieldValid.cardCvc.isValid ? ` error-disp` : ``}`}>
                                <div className="StripeElement">
                                  <input type="text" name="cvc" className={`form-control`} autoComplete="off" />
                                </div>
                                <span className="fltlabels-static">CVV</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="info-state">No Saved Cards Available</div>
        )}
      </>
    );
  };
  return (
    <>
      {selectedCard ? (
        <>
          <div className="saved-card-new-section ai-card-selcted">{renderSavedCardList(cardsListArray.filter((it) => it.id === selectedCard))}</div>
          <div className="payment__btn--wrp r-flx r-flx-ac g-20">
            <button className="ac-btn ac-outline ac-secondary-white" onClick={handleCancel}>
              <span>Cancel</span>
            </button>
            <button className={`ac-btn ac-primary ${ishandleSubmit ? 'events-none pointer-events-none' : ''}`} onClick={handleSubmit}>
              <span>Proceed</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="ai__payment--bg">
            <Tabs
              activeKey={paymentTab}
              onSelect={(selectedTab: null | string) => {
                if (selectedTab) {
                  setPaymentTab(selectedTab);
                  sethandleSubmit(selectedTab === RP_PAYMENT_TABS.NEW.value && false);
                }
              }}
            >
              <Tab eventKey={RP_PAYMENT_TABS.SAVED.value} title={RP_PAYMENT_TABS.SAVED.title}>
                <div className="saved-card-new-section">{renderSavedCardList(cardsListArray)}</div>
              </Tab>
              <Tab eventKey={RP_PAYMENT_TABS.NEW.value} title={RP_PAYMENT_TABS.NEW.title}>
                <div className="rewards-programdetails-modal-wrp">
                  <div className="payment-section-wrp">
                    <div className="payment-content-section-main">
                      <div className="pay-newcard-wrp">
                        <div className="left-section">
                          <div className={`form-group${fieldValid.userName.isTouched && !fieldValid.userName.isValid ? ` error-disp` : ``}`}>
                            <input
                              type="text"
                              name="cardname"
                              className={`form-control${fieldValid.userName ? ` used` : ``}`}
                              autoComplete="off"
                              placeholder="Name on card"
                              onChange={(cardEvt: React.ChangeEvent<HTMLInputElement>) => handleValidation(cardEvt, 'userName')}
                            />
                          </div>
                          <div className={`strip-section-align${fieldValid.cardNumber.isTouched && !fieldValid.cardNumber.isValid ? ` error-disp` : ``}`}>
                            <CardNumberElement
                              onChange={(cardEvt: ReactStripeElements.ElementChangeResponse): void => handleValidation(cardEvt, 'cardNumber')}
                              style={{
                                base: {
                                  fontSize: responsiveType === IReactResponsiveTypes.DESKTOP_OR_LAPTOP ? '4vw' : '16px'
                                }
                              }}
                              placeholder="Card Number"
                            />
                          </div>
                          <div className="stripe-exp-cvc-grid">
                            <div className={`strip-section-align${fieldValid.cardValid.isTouched && !fieldValid.cardValid.isValid ? ` error-disp` : ``}`}>
                              <CardExpiryElement
                                onChange={(cardEvt: ReactStripeElements.ElementChangeResponse) => handleValidation(cardEvt, 'cardValid')}
                                style={{
                                  base: {
                                    fontSize: responsiveType === IReactResponsiveTypes.DESKTOP_OR_LAPTOP ? '9.5vw' : '16px'
                                  }
                                }}
                                placeholder="Expiry"
                              />
                            </div>
                            <div className={`strip-section-align${fieldValid.cardCvc.isTouched && !fieldValid.cardCvc.isValid ? ` error-disp` : ``}`}>
                              <CardCVCElement
                                onChange={(cardEvt: ReactStripeElements.ElementChangeResponse) => handleValidation(cardEvt, 'cardCvc')}
                                style={{
                                  base: {
                                    fontSize: responsiveType === IReactResponsiveTypes.DESKTOP_OR_LAPTOP ? '9.5vw' : '16px'
                                  }
                                }}
                                placeholder="CVV"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="payment__btn--wrp r-flx r-flx-ac g-20">
            <button className="ac-btn ac-outline ac-secondary-white" onClick={handleCancel}>
              <span>Cancel</span>
            </button>
            <button className={`ac-btn ac-primary ${ishandleSubmit ? 'events-none pointer-events-none' : ''}`} onClick={handleSubmit}>
              <span>Proceed</span>
            </button>
          </div>
        </>
      )}
    </>
  );
};

const CardForm = injectStripe(StripePayment);

export const AISubscriptionPayment = () => {
  return (
    <StripeProvider apiKey={config.stripe.publishKey}>
      <Elements>
        <CardForm />
      </Elements>
    </StripeProvider>
  );
};
