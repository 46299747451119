import { takeEvery, put } from 'redux-saga/effects';

import * as types from 'actions/action-types';
import { commonApiTypes } from 'types';
import { API, apiCall, defaultHeader } from 'utils/helpers';
import { exceptionHandlerRequest, logoutRequest, ssoAccountSwitchBrandRequestFail, ssoAccountSwitchBrandResponse } from 'actions';
import { SSO_ACCOUNT_SWITCH_BRAND_ERROR } from 'utils/constants';

type ISagaPayload = commonApiTypes.ISagaPayload;

function* sendSSOAccountSwitchBrandRequest({ payload }: ISagaPayload): any {
  try {
    const headers = defaultHeader();
    const apiObj = API.ssoUpdateAccountSwitchToBrand;
    const path = apiObj.apiPath.replace(':id', payload.id);
    const response = yield apiCall({ headers, apiPath: path, action: apiObj.action });
    if (response.status === 401) {
      yield put(logoutRequest());
    } else if (response.status === 200) {
      yield put(ssoAccountSwitchBrandResponse(response.data));
    } else {
      yield put(ssoAccountSwitchBrandRequestFail(SSO_ACCOUNT_SWITCH_BRAND_ERROR));
    }
  } catch (error) {
    yield put(exceptionHandlerRequest(SSO_ACCOUNT_SWITCH_BRAND_ERROR));
  }
}

export function* takeSSOAccountSwitchBrandRequest() {
  yield takeEvery(types.SSO_ACCOUNT_SWITCH_BRAND_REQUEST, sendSSOAccountSwitchBrandRequest);
}
