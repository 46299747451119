import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageValidation } from 'widgets/Media';
import {
  addPostSavedPostReset,
  addPostActionsResetAll,
  mgResetAll,
  addPostUpdateSavedPostDetails,
  addPostGetActionTypeForPost,
  addPostResetAll,
  accountProfileReset,
  creatorSetComponentName,
  updateFBBoostConfigData,
  commonSetContentModifiedStatus,
  addPostGetEditSavedPostObj,
  addPostSetEditConfirmForApprovedPost,
  addPostFranResetAll,
  getFBAdsTargetingChoicesRequest,
  setAIPostContentOpen,
  resetAIContentCreator,
  resetAIImageGenerationImages,
  setCreatorTextAlertMsg
} from 'actions';
import { IStore } from 'types/reducers';
import {
  AP_SAVED_POST_INIT_DETAILS,
  AP_ACTION_TYPES,
  COMMON_SOCIAL_PROVIDER_TYPE,
  AP_EDIT_CONFIRM_INIT_OBJ,
  CREATOR_USER_OWNERSHIP,
  ACCOUNT_API_TYPES,
  LOCATION_TIME_ZONE_DATE_TIME_FORMAT,
  CREATOR_EDIT_INIT_OBJ,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  SettingsHashtagsActionsVal,
  HASHTAG_BLOCK_DEFAULT_SPACING
} from 'utils/constants';
import { calendarConvertDateBasedOnTimeZone, dateObjBasedOnTimeZone, getModifiedStatusForSavedPost, getFacebookBoostConfigData, convertStringToEditorState } from 'utils/helpers';
import { useNavBarData, useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import { EditorStateContext } from '..';

export const CreatePostStats = () => {
  const dispatch = useDispatch();

  const { subNavPageName } = useNavBarData();
  const { id, userOwnership } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const fbTargetingChoice = useSelector((state: IStore) => state.fbAdsTargeting.fbTargetingChoice);
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const selectedTags = useSelector((state: IStore) => state.addPostAccount.selectedTags);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const mgTagsList = useSelector((state: IStore) => state.mediaGallery.mgTagsList);
  const timeZone = useSelector((state: IStore) => state.accounts.accountDetails?.account?.tzinfo_name || '');
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const campaignData = useSelector((state: IStore) => state.addPostFranchisorAction.campaignData);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const creatorTextAlertMsg = useSelector((state: IStore) => state.common.creatorTextAlertMsg);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const editorStateArray = useContext(EditorStateContext);
  const setEditorState = editorStateArray[1];

  const addSavedHashtags = Boolean(hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length);

  const isDataEdited = useMemo(() => {
    if (savedPostDetails) {
      return getModifiedStatusForSavedPost(savedPostDetails, editSavedPost, selectedMediaList, linkData, selectedTags, campaignData);
    }
    return false;
  }, [savedPostDetails, editSavedPost, selectedMediaList.length, linkData, selectedTags.length, campaignData]); // eslint-disable-line

  useEffect(() => {
    dispatch(commonSetContentModifiedStatus(isDataEdited));
  }, [isDataEdited]); // eslint-disable-line

  const handleCreatorStartOver = () => {
    const currentTab = activePlatforms.length ? activePlatforms[0] : COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK;
    if (isAiContentOpen) dispatch(setAIPostContentOpen(false));
    if (creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
    dispatch(resetAIContentCreator());
    dispatch(resetAIImageGenerationImages());
    if (addPostAction === AP_ACTION_TYPES.EDIT) dispatch(addPostGetActionTypeForPost(AP_ACTION_TYPES.ADD));
    dispatch(
      addPostUpdateSavedPostDetails({
        ...AP_SAVED_POST_INIT_DETAILS,
        current_tab: currentTab,
        schedule_date_time: dateObjBasedOnTimeZone(timeZone),
        account_schedule_date_time: dateObjBasedOnTimeZone(timeZone),
        ...(addSavedHashtags && { [`use_${currentTab}`]: true, [`${currentTab}_text`]: `${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState.saved_hashtags?.join(' ')}` })
      })
    );
    setEditorState(
      addSavedHashtags
        ? { ...AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT, [currentTab]: convertStringToEditorState(`${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState.saved_hashtags?.join(' ')}`) }
        : AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT
    );
    dispatch(addPostSavedPostReset());
    dispatch(addPostFranResetAll());
    dispatch(addPostActionsResetAll());
    dispatch(addPostGetEditSavedPostObj(CREATOR_EDIT_INIT_OBJ));
    dispatch(addPostSetEditConfirmForApprovedPost(AP_EDIT_CONFIRM_INIT_OBJ));
    dispatch(updateFBBoostConfigData(getFacebookBoostConfigData(fbTargetingChoice)));
    if (mgTagsList.length) dispatch(mgResetAll());
    if (userOwnership && creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT) {
      dispatch(addPostResetAll());
      dispatch(creatorSetComponentName(userOwnership));
      dispatch(accountProfileReset());
      dispatch(getFBAdsTargetingChoicesRequest({ id, userOwnership, initialLoad: true }));
    }
  };

  return (
    <div className="package-id-holder mb-20">
      <div className="package-id-wrp-sec creator__header--title">
        <div>
          <ImageValidation customClassname="package__header--icon" isImgValid defaultImg="package-id" customName="Package ID" />
        </div>
        {addPostAction === AP_ACTION_TYPES.ADD ? <span className="pk-title">Create Post</span> : <span className="pk-title">{`Package ID: ${editSavedPost?.id}`}</span>}
      </div>
      {subNavPageName === ACCOUNT_API_TYPES.CALENDAR ? (
        <div className="title-text-section">
          <span>Scheduled: </span>
          <span className="b-text">{`@${calendarConvertDateBasedOnTimeZone(timeZone, savedPostDetails?.schedule_date_time.utcFormat || '', LOCATION_TIME_ZONE_DATE_TIME_FORMAT, true)}`}</span>
        </div>
      ) : null}
      {subNavPageName !== ACCOUNT_API_TYPES.CALENDAR ? (
        <div className="package-id-wrp-sec creator__header--right" onClick={() => handleCreatorStartOver()}>
          <span className="start-over">Start Over</span>
          <div className="start-over-icon">
            <ImageValidation isImgValid defaultImg="start-over" customName="Start over" />
          </div>
        </div>
      ) : null}
    </div>
  );
};
