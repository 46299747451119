import { useState, useEffect, useRef } from 'react';
import { Modal, ToggleButton, ToggleButtonGroup, Form } from 'react-bootstrap';
import Slider from 'rc-slider';
import { Tag } from 'react-tag-autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'config';
import { ImageValidation } from 'widgets/Media';
import { commonBoost, fbAdsReducerTypes, fbAdsApiTypes, IStore } from 'types';
import {
  FB_ADS_TARGET_GENDER_LIST,
  VALID_ONLY_NUMBERS_REGEX,
  RIPPLE_COLOR,
  ALERT_BOX_TYPES,
  FbAdsGenderType,
  // INVALID_STORE_LOCATION_CONTEXT,
  USER_OWNERSHIP
  // MAX_FB_ADS_POTENTIAL_REACH
} from 'utils/constants';
import { handleAddInterestTagSymbol, isUSAZipCode, getFormattedNumber, /* getPercentage, */ compareTwoObjects } from 'utils/helpers';
import { CustomDropdownMenu } from 'widgets/CustomDropdownMenu';
import { getAccTargetingInterestsListRequest, getAccountConfigDataRequest } from 'actions';
import { alertBoxCall, LeafletMap, Loading } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
// import { GaugeSpeedometerChart } from 'widgets/Highcharts';
import { useAccountSwitcherData } from 'utils/hooks';

export const EditAudienceModal = ({ isModalShow, handleModalClose, boostConfig, handleUpdateAllFBBoostConfig, reCalculateReachEstimation }: commonBoost.IEditBoostAudienceProps) => {
  const { id, userOwnership, optionalParams, isValidId } = useAccountSwitcherData();

  const dispatch = useDispatch();

  const targetRef = useRef(null);

  const isInterestsFetching = useSelector((state: IStore) => state.fbAdsTargeting.isInterestsFetching);
  const accTargetingInterests = useSelector((state: IStore) => state.fbAdsTargeting.accTargetingInterests);
  const facebookBoostConfig = useSelector((state: IStore) => state.fbAdsTargeting.facebookBoostConfig);
  // const isAccountConfigFetching = useSelector((state: IStore) => state.targeting.isAccountConfigFetching);
  const isEstimatedReachFetching = useSelector((state: IStore) => state.targeting.isEstimatedReachFetching);

  // const [isOpen, setOpen] = useState(false);
  const [boostDefaultConfig, setBoostDefaultConfig] = useState<null | fbAdsReducerTypes.IUpdateFacebookBoostConfig>(null);
  const [interestTags, setInterestsTags] = useState<fbAdsApiTypes.IInterestsTags>({ suggestions: [], tags: [] });
  const [active, setActive] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
    address: string;
    radiusInMiles: number;
  } | null>(null);
  const [zipCode, setZipCode] = useState<null | string>(null);

  let interestApiTimeout: any = null;
  const accountId = optionalParams[1];

  const isConfigUpdated = () => {
    const initialValues = {
      coordinates: boostConfig?.coordinates,
      locations: boostConfig?.locations,
      gender: boostConfig?.gender,
      minAge: boostConfig?.minAge,
      maxAge: boostConfig?.maxAge,
      interests: boostConfig?.interests
    };
    const updatedValues = {
      coordinates: boostDefaultConfig?.coordinates,
      locations: boostDefaultConfig?.locations,
      gender: boostDefaultConfig?.gender,
      minAge: boostDefaultConfig?.minAge,
      maxAge: boostDefaultConfig?.maxAge,
      interests: boostDefaultConfig?.interests
    };

    return !compareTwoObjects(initialValues, updatedValues);
  };

  useEffect(() => {
    if (isModalShow && boostConfig) {
      const configuration = { ...boostConfig };
      /* if (configuration?.coordinates?.length === 0) {
        configuration.coordinates = configuration.homeCoordinate ? [{ ...configuration.homeCoordinate }, ...configuration.coordinates] : configuration.coordinates;
      } */
      setBoostDefaultConfig(configuration);
      setInterestsTags((prevState) => ({
        ...prevState,
        tags: handleAddInterestTagSymbol(boostConfig?.interests || [])
      }));
    }
  }, [isModalShow, boostConfig]); // eslint-disable-line

  useEffect(() => {
    if (accTargetingInterests.length) {
      const uniqueSuggestions = accTargetingInterests.filter((val) => !interestTags.tags.find((it: any) => it.id === val.id));
      setInterestsTags((prevState) => ({
        ...prevState,
        suggestions: handleAddInterestTagSymbol(uniqueSuggestions)
      }));
    }
  }, [accTargetingInterests, interestTags.tags]); // eslint-disable-line

  useEffect(() => {
    if (isValidId && id && accountId) dispatch(getAccountConfigDataRequest({ franchisorId: id, accountId }));
  }, [isValidId, id, accountId, dispatch]);

  const handleAddInterestTag = (activeTags: Tag | any) => {
    if (!interestTags.tags.find((it) => it.id === activeTags.id)) {
      const addedInterestTags = [...interestTags.tags, activeTags];
      handleInterestTags(addedInterestTags);
    }
  };

  const handleDeleteInterestTag = (id: any) => {
    const retainedInterestTags = interestTags.tags.filter((it) => it.id !== id);
    handleInterestTags(retainedInterestTags);
  };

  const handleInterestTags = (interestTags: any) => {
    setInterestsTags((prevState) => ({
      ...prevState,
      tags: interestTags
    }));
    const interestsTagList = interestTags.reduce((acc: fbAdsApiTypes.IAccDefaultConfigInterestObj[], curr: fbAdsApiTypes.IAccTargetingInterestsObj) => {
      const interestObj = {
        audience_size: curr.audience_size,
        id: curr.id,
        name: curr.path[curr.path.length - 1],
        path: curr.path
      };
      acc = [...acc, interestObj];
      return acc;
    }, []);
    setBoostDefaultConfig(
      boostDefaultConfig
        ? {
            ...boostDefaultConfig,
            interests: interestsTagList
          }
        : null
    );
  };

  const handleUpdateFBAdsDefaultConfig = () => {
    handleUpdateAllFBBoostConfig(boostDefaultConfig ? { ...boostDefaultConfig, isAddBoost: true } : null);
    handleModalClose();
  };

  const ageMarkStyle = {
    [boostDefaultConfig?.minAge ? +boostDefaultConfig?.minAge : 18]: {
      label: <span className={'rsmark-txt'}>{`${boostDefaultConfig?.minAge || `18`}`}</span>
    },
    [boostDefaultConfig?.maxAge ? +boostDefaultConfig?.maxAge : 66]: {
      label: <span className={'rsmark-txt'}>{`${boostDefaultConfig?.maxAge || `65+`}`}</span>
    }
  };

  const handleDeleteZipcodeByValue = (value: string) => {
    setBoostDefaultConfig(
      boostDefaultConfig
        ? {
            ...boostDefaultConfig,
            locations: boostDefaultConfig?.locations?.length ? boostDefaultConfig?.locations.filter((it) => it !== value) : []
          }
        : null
    );
    setZipCode(null);
  };

  const handleDisableSaveChanges = () => {
    if (boostDefaultConfig && !boostDefaultConfig?.locations.length && !boostDefaultConfig?.coordinates.length) return true;
    else return false;
  };

  return (
    <Modal show={isModalShow} centered size="lg" className="edit-audience-modal gbModal" onHide={handleModalClose}>
      <div className="mod__close--icon">
        <img
          className="mod__close--img"
          src={`${config.cdnImgUrl}modal-close2.svg`}
          alt="cancel"
          onClick={() => {
            handleModalClose();
          }}
        />
      </div>
      <Modal.Body>
        <div>
          <div>
            <span className="boost__box--title">Edit Audience</span>
            <div className="boost__desc--txt r-mt1">Select the location, age, gender and interest of people you want to reach with your ad.</div>
          </div>
          <div className="mca-wrap">
            <div className="wb-fields gender-sec">
              <div className="wbf-item">
                <div className="wbf-inputs">
                  <div className="wbf-label">
                    <span className="wf-sub-heads">Gender</span>
                  </div>
                  <ToggleButtonGroup className="btn-group-toggle" type="checkbox" value={boostDefaultConfig?.gender ? [boostDefaultConfig?.gender] : [FbAdsGenderType.ALL]}>
                    {FB_ADS_TARGET_GENDER_LIST.map((genderDatum, index) => {
                      return (
                        <ToggleButton
                          key={`gender-${index}`}
                          id={`gender-${index}`}
                          type={'radio'}
                          className={(!boostDefaultConfig?.gender && FbAdsGenderType.ALL === genderDatum.name) || boostDefaultConfig?.gender === genderDatum.name ? 'active' : ''}
                          value={genderDatum.name}
                          onChange={(event) =>
                            setBoostDefaultConfig(
                              boostDefaultConfig
                                ? {
                                    ...boostDefaultConfig,
                                    gender: FbAdsGenderType.ALL === event.target.value ? null : event.target.value
                                  }
                                : null
                            )
                          }
                        >
                          <div className="rel-icons">
                            <ImageValidation
                              isImgValid
                              defaultImg={
                                (!boostDefaultConfig?.gender && FbAdsGenderType.ALL === genderDatum.name) || boostDefaultConfig?.gender === genderDatum.name
                                  ? `gender-${genderDatum.name}-a`
                                  : `gender-${genderDatum.name}`
                              }
                              customName={genderDatum.label}
                            />
                          </div>
                          <span className="g-txt">{genderDatum.label}</span>
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>
              </div>
            </div>
            <div className="wb-fields age-sec">
              <div className="wbf-item">
                <div className="wbf-label-main">
                  <div className="wbf-label">
                    <span>Ages</span>
                    <p className="wbf-sublabel">(between 18 and 65)</p>
                  </div>
                </div>
                <div className="wbf-inputs r-mt3">
                  <Slider
                    range
                    min={18}
                    max={66}
                    value={[boostDefaultConfig?.minAge || 18, boostDefaultConfig?.maxAge || 66]}
                    count={1}
                    allowCross={false}
                    marks={ageMarkStyle}
                    onChange={(value) => {
                      if (typeof value === 'object') {
                        setBoostDefaultConfig(
                          boostDefaultConfig
                            ? {
                                ...boostDefaultConfig,
                                minAge: value[0],
                                maxAge: value[1] <= 65 ? value[1] : null
                              }
                            : null
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="wb-fields ea-tops">
              <div className="wbf-item">
                <div className="wbf-label-main tags">
                  <span className="wbf-label wf-sub-heads">Interests</span>
                </div>
                <div className="wbf-inputs">
                  <div className="il-options-main">
                    <div className="ilo-dropdown">
                      {/* <div className="wbf-inputs"> */}
                      {/* <div className="il-options-main"> */}
                      <div className="ilo-group">
                        <div className="react-tags" ref={targetRef}>
                          {interestTags.tags.map((it: any) => (
                            <div className="react-tags__selected" key={it.name}>
                              <button type="button" className="react-tags__selected-tag fst " onClick={() => handleDeleteInterestTag(it.id)}>
                                <span className="react-tags__selected-tag-name">{it.name}</span>
                              </button>
                            </div>
                          ))}
                          <div className="react-tags__search">
                            <div className="react-tags__search-input">
                              <input
                                placeholder="Add a new interest"
                                type="text"
                                onChange={(e) => {
                                  const search = e.target.value;
                                  if (interestApiTimeout) {
                                    clearTimeout(interestApiTimeout);
                                  }
                                  if (id && search) {
                                    interestApiTimeout = setTimeout(() => dispatch(getAccTargetingInterestsListRequest({ userOwnership, id, search })), 500);
                                  }
                                }}
                                onFocus={() => setActive(true)}
                              />
                              <CustomDropdownMenu
                                targetRef={targetRef}
                                showMenu={active}
                                setActive={setActive}
                                options={interestTags.suggestions.map((it) => ({ value: it.id, name: it.name }))}
                                isLoading={isInterestsFetching}
                                onSelect={(value: any) => {
                                  const obj = interestTags.suggestions.find((it: any) => it.id === value);
                                  handleAddInterestTag(obj);
                                }}
                                showTooltip
                                renderTooltipJSX={(value: any) => {
                                  const dataObj = interestTags.suggestions.find((it) => it.id === value);
                                  return (
                                    <>
                                      <span className="man-inactive">{dataObj?.topic || '---'}</span>
                                      <br />
                                      {/* <span>{getFormattedNumber(dataObj?.audience_size || 0)} People</span> */}
                                      {/* <br /> */}
                                      <span>{dataObj?.name}</span>
                                    </>
                                  );
                                }}
                                placeholder="Add a new interest"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wb-fields cea">
              <div className="r-flx r-mt2 norTop r-flx-ac g-10">
                <span className="wf-sub-heads">Area</span>
                <div className="info__tool--tip">
                  <ImageValidation isImgValid defaultImg="info-blue" customName="hint" />
                </div>
                {/* <span className="errorText nur">Non-U.S. Residents use the map instead of the zip code</span> */}
                <span className="pin__err--txt">Non-U.S. Residents use the map instead of the zip code</span>
              </div>
              <div className="addzip r-mt1">
                <div className="zipcodes-main">
                  <div className="selected-zip">
                    {boostDefaultConfig?.locations?.length
                      ? boostDefaultConfig.locations.map((zipCode, index) => {
                          return (
                            <span className="ifa" key={`us-zip-code-${index}`}>
                              {zipCode}
                              <span
                                className="remove-zip-code"
                                onClick={() => {
                                  handleDeleteZipcodeByValue(zipCode);
                                }}
                              />
                            </span>
                          );
                        })
                      : null}
                  </div>
                  <div className="zip-search">
                    <div className="zip-search-wrapper">
                      <Form.Control
                        type="text"
                        placeholder="Add US Zip Codes"
                        onKeyPress={(event: React.KeyboardEventHandler<HTMLInputElement> | any) => {
                          const regexData = new RegExp(VALID_ONLY_NUMBERS_REGEX);
                          const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                          if (!regexData.test(enteredKey)) {
                            event.preventDefault();
                            return false;
                          }
                        }}
                        aria-describedby="inputGroupPrepend"
                        name={'locations'}
                        className={'plain-input'}
                        value={zipCode || ''}
                        onChange={(event) => setZipCode(event.target.value)}
                        onKeyUp={(event: React.KeyboardEventHandler<HTMLInputElement> | any) => {
                          if (event.keyCode === 13 && zipCode) {
                            const validZipcode = isUSAZipCode(zipCode) ? zipCode : zipCode.substr(0, 5);
                            setBoostDefaultConfig(
                              boostDefaultConfig
                                ? {
                                    ...boostDefaultConfig,
                                    [event.target.name]: [...boostDefaultConfig.locations, validZipcode]
                                  }
                                : null
                            );
                            setZipCode(null);
                          } else if (event.keyCode === 8 && boostDefaultConfig?.locations.length) {
                            handleDeleteZipcodeByValue(boostDefaultConfig?.locations[boostDefaultConfig?.locations.length - 1]);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* {Boolean(boostDefaultConfig?.homeCoordinate?.lat && boostDefaultConfig?.homeCoordinate?.lng) ? ( */}
              <div className="audience-edit--option">
                <LeafletMap
                  enableSearch
                  selectedLocation={selectedLocation}
                  placeholder={'Add Address'}
                  selectedLocationList={
                    boostDefaultConfig?.coordinates
                      ? boostDefaultConfig?.coordinates?.map((it) => ({
                          address: it.human_readable_address,
                          lat: it.lat,
                          lng: it.lng,
                          radiusInMiles: Math.round(it.radius_in_meters * 0.00062)
                        }))
                      : []
                  }
                  onLocationSelection={(selectedLocation) => {
                    if (boostDefaultConfig) {
                      if (!boostDefaultConfig.coordinates) {
                        boostDefaultConfig.coordinates = [];
                      }
                      const alreadyPresent = boostDefaultConfig.coordinates.find((it) => it.lat === selectedLocation.y && it.lng === selectedLocation.x);
                      if (alreadyPresent) {
                        alertBoxCall(ALERT_BOX_TYPES.WARN, 'Location already added with the same address.');
                      } else {
                        setSelectedLocation({
                          address: selectedLocation.label,
                          lat: selectedLocation.y,
                          lng: selectedLocation.x,
                          radiusInMiles: 25
                        });
                      }
                      setBoostDefaultConfig({
                        ...boostDefaultConfig,
                        coordinates: [
                          ...boostDefaultConfig?.coordinates,
                          {
                            human_readable_address: selectedLocation.label,
                            lat: selectedLocation.y,
                            lng: selectedLocation.x,
                            radius_in_meters: 25 / 0.00062
                          }
                        ]
                      });
                    }
                  }}
                  onRemoveLocation={(locationObj) => {
                    if (boostDefaultConfig) {
                      const alreadyPresent = boostDefaultConfig.coordinates.find((it) => it.lat === locationObj.lat && it.lng === locationObj.lng);
                      if (alreadyPresent) {
                        boostDefaultConfig.coordinates = boostDefaultConfig.coordinates.filter((it) => it.lat !== locationObj.lat && it.lng !== locationObj.lng);
                        boostDefaultConfig.homeCoordinate = null;
                        setBoostDefaultConfig({ ...boostDefaultConfig });
                      }
                    }
                  }}
                  onLocationClick={(locationObj) => {
                    setSelectedLocation(locationObj);
                  }}
                  onChangeRadius={(radiusInMiles: number) => {
                    if (boostDefaultConfig) {
                      const coordinates = [...boostDefaultConfig?.coordinates];
                      const coordinateIndex = coordinates?.length ? coordinates?.findIndex((it) => it.lat === selectedLocation?.lat && it.lng === selectedLocation?.lng) : -1;
                      if (coordinateIndex >= 0) {
                        coordinates[coordinateIndex] = { ...coordinates[coordinateIndex], radius_in_meters: radiusInMiles / 0.00062 };
                      }
                      setBoostDefaultConfig({ ...boostDefaultConfig, coordinates });
                    }
                  }}
                />
              </div>
              {/* ) : (
                <div className="qn">{INVALID_STORE_LOCATION_CONTEXT}</div>
              )} */}
            </div>

            {userOwnership === USER_OWNERSHIP.FRANCHISOR ? (
              <div className="wb-fields cea mark-ea-item detlTargtng">
                <div className="r-flx r-mt2 norTop prrWrap">
                  <span className="wf-sub-heads">Potential Reach</span>
                  <ImageValidation isImgValid defaultImg="information-grey" customName="hint" customClassname="d-none" />
                  {/* {(boostDefaultConfig?.locations?.length || boostDefaultConfig?.coordinates?.length) && isConfigUpdated() ? (
                    <CustomRippleButton rippleClass={`ac-primary-box prr-btn-wrap`} custColor={RIPPLE_COLOR.primary}>
                      <button
                        className={`ac-btn ac-primary ac-block prr-btn`}
                        onClick={() => {
                          if (boostDefaultConfig && reCalculateReachEstimation) {
                            reCalculateReachEstimation(boostDefaultConfig);
                          }
                        }}
                      >
                        Recalculate
                      </button>
                    </CustomRippleButton>
                  ) : null} */}
                </div>
                {/* <div className="dt-area">
                  <GaugeSpeedometerChart
                    isDataLoading={isAccountConfigFetching}
                    startAngle={-90}
                    endAngle={90}
                    center={['48%', '75px']} // semi circle gauge
                    min={0}
                    max={100}
                    plotBands={[
                      {
                        from: 0,
                        to: 20,
                        label: {
                          text: 'Specific',
                          verticalAlign: 'bottom',
                          x: 10,
                          y: 55
                        },
                        color: '#C52E15' // red
                      },
                      {
                        from: 20,
                        to: 80,
                        color: '#17962F' // green
                      },
                      {
                        from: 80,
                        to: 100,
                        label: {
                          text: 'Broad',
                          verticalAlign: 'bottom',
                          x: -15,
                          y: 55
                        },
                        color: '#FCC9A4' // peach
                      }
                    ]}
                    series={[
                      {
                        name: 'Potential Reach',
                        data: [
                          {
                            color: '#000000',
                            y: getPercentage(MAX_FB_ADS_POTENTIAL_REACH, boostConfig?.potentialReach || 0)
                          }
                        ],
                        dataLabels: {
                          formatter: () => null
                        }
                      }
                    ]}
                    dial={{
                      backgroundColor: '#131313',
                      borderWidth: 1,
                      baseWidth: 14,
                      topWidth: 1,
                      baseLength: '1%',
                      rearLength: '1%',
                      radius: '75%'
                    }}
                    pivot={{
                      radius: 7 // dial circle at center
                    }}
                  />
                </div> */}
                <div className="mea-cnt">
                  <div className="meac-top">
                    <div className="meac-right">
                      <div className="divise">
                        {(boostDefaultConfig?.locations?.length || boostDefaultConfig?.coordinates?.length) && isConfigUpdated() ? (
                          <CustomRippleButton rippleClass={`ac-primary-box prr-btn-wrap`} custColor={RIPPLE_COLOR.primary}>
                            <button
                              className={`ac-btn ac-primary ac-block prr-btn`}
                              onClick={() => {
                                if (boostDefaultConfig && reCalculateReachEstimation) {
                                  reCalculateReachEstimation(boostDefaultConfig);
                                }
                              }}
                            >
                              Recalculate
                            </button>
                          </CustomRippleButton>
                        ) : !isEstimatedReachFetching ? (
                          <>
                            <span className="med-txt"> {`${getFormattedNumber(boostConfig?.potentialReach || 0)} people`}</span>
                            {boostConfig?.potentialReach && (
                              <>
                                <hr />
                                <span className="med-txt">Your audience is defined.</span>
                              </>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="r-flx r-mt4 g-20 edit__aud--btn">
            <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button
                className="ac-btn ac-secondary-white ac-outline ac-block border-0"
                onClick={() => {
                  if (facebookBoostConfig) {
                    handleUpdateAllFBBoostConfig(
                      boostConfig && facebookBoostConfig
                        ? {
                            ...boostConfig,
                            coordinates: facebookBoostConfig?.coordinates || [],
                            gender: facebookBoostConfig?.gender || null,
                            interests: facebookBoostConfig?.interests || [],
                            locations: facebookBoostConfig?.locations || [],
                            maxAge: facebookBoostConfig?.maxAge || null,
                            minAge: facebookBoostConfig?.minAge
                          }
                        : null
                    );
                  }
                  handleModalClose();
                }}
              >
                Cancel
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ac-primary-box ${handleDisableSaveChanges() ? `events-none pointer-events-none` : ``}`} custColor={RIPPLE_COLOR.primary}>
              <button className={`ac-btn ac-primary ac-block ${handleDisableSaveChanges() ? `events-none pointer-events-none` : ``}`} onClick={() => handleUpdateFBAdsDefaultConfig()}>
                Save Audience
              </button>
            </CustomRippleButton>
          </div>
        </div>
      </Modal.Body>
      {isEstimatedReachFetching ? <Loading /> : null}
    </Modal>
  );
};
