import { useRef } from 'react';
import { Card, Form, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { CustomDropdownWithSearch } from 'widgets/CustomDropdown';
import { FranScheduledLocName, AP_SELECTED_LOC_INIT_DATA, ALL_LOCATION_LISTS, FranScheduledLocLabel, FranchisorSyndicationMediaType } from 'utils/constants';
import { apFranContainerTypes, IStore } from 'types';
import { addPostSetSelectedLocData } from 'actions';
import { ImageValidation } from 'widgets/Media';

export const FranSyndicateWho = ({ mediaType, locationType, dropdownOptions, handleLocationTabSelection, handleSelectSyndicateFranchisors }: apFranContainerTypes.IFranSyndicateWhoProps) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const selectedLocData = useSelector((state: IStore) => state.addPostFranchisorAction.selectedLocData);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const locationLists = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList?.locationList || []);
  const franchisorSyndication = useSelector((state: IStore) => state.franchisors.currentFranDetails?.franchisors_for_syndication);
  const selectedSyndicateFranchisors = useSelector((state: IStore) => state.addPostFranchisor.selectedSyndicateFranchisors);

  return (
    <Card className="glbl__modal--card">
      <Card.Body>
        <Tabs className="card-header-tabs" activeKey={locationType} onSelect={(selectedKey) => handleLocationTabSelection(selectedKey as FranScheduledLocName)}>
          {mediaType === FranchisorSyndicationMediaType.CONTENT && !isContentSupplier && (
            <Tab
              eventKey={FranScheduledLocName.ALL_LOCATIONS}
              title={
                <div>
                  <Form.Group className="form-group" controlId="formBasicCheckbox1">
                    <Form.Check type="radio" label={FranScheduledLocLabel.ALL_LOCATIONS} checked={locationType === FranScheduledLocName.ALL_LOCATIONS} readOnly />
                  </Form.Group>
                </div>
              }
            />
          )}

          {mediaType === FranchisorSyndicationMediaType.CONTENT && !isContentSupplier && (
            <Tab
              eventKey={FranScheduledLocName.SPECIFIC_LOCATION_LIST}
              title={
                <div>
                  <Form.Group className="form-group" controlId="formBasicCheckbox2">
                    <Form.Check type="radio" label={FranScheduledLocLabel.SPECIFIC_LOCATION_LIST} checked={locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST} readOnly />
                  </Form.Group>
                </div>
              }
            >
              <div className="form-group form-field-item">
                <CustomDropdownWithSearch
                  dropdownRef={dropdownRef}
                  classNamePrefix="rs-drp"
                  id="syndicate-dropdown"
                  placeholder={'Select List'}
                  onChange={(event: any) => {
                    if (selectedLocData.id !== +event.id) dispatch(addPostSetSelectedLocData({ id: +event.id, name: event.label }));
                    else dispatch(addPostSetSelectedLocData(AP_SELECTED_LOC_INIT_DATA));
                  }}
                  value={
                    selectedLocData.id
                      ? { id: +selectedLocData.id, value: selectedLocData.name, label: selectedLocData.name }
                      : { id: AP_SELECTED_LOC_INIT_DATA.id, value: ALL_LOCATION_LISTS, label: ALL_LOCATION_LISTS }
                  }
                  options={dropdownOptions}
                  isSearchable={locationLists.length === 1 ? false : true}
                  menuIsOpen
                />
              </div>
            </Tab>
          )}

          {isContentSupplier || franchisorSyndication?.length ? (
            <Tab
              eventKey={FranScheduledLocName.ALL_FRANCHISE_CONSULTANT}
              title={
                <div>
                  <Form.Group className="form-group" controlId="formBasicCheckbox3">
                    <Form.Check
                      type="radio"
                      label={isContentSupplier ? FranScheduledLocLabel.ALL_BRANDS : FranScheduledLocLabel.ALL_FRANCHISE_CONSULTANT}
                      checked={locationType === FranScheduledLocName.ALL_FRANCHISE_CONSULTANT}
                      readOnly
                    />
                  </Form.Group>
                </div>
              }
            />
          ) : null}

          {isContentSupplier || franchisorSyndication?.length ? (
            <Tab
              eventKey={FranScheduledLocName.FRANCHISE_CONSULTANT}
              title={
                <div>
                  <Form.Group className="form-group" controlId="formBasicCheckbox4">
                    <Form.Check
                      type="radio"
                      label={isContentSupplier ? FranScheduledLocLabel.SPECIFIC_BRANDS : FranScheduledLocLabel.FRANCHISE_CONSULTANT}
                      checked={locationType === FranScheduledLocName.FRANCHISE_CONSULTANT}
                      readOnly
                    />
                  </Form.Group>
                </div>
              }
            >
              <div className="form-group form-field-item">
                <CustomDropdownWithSearch
                  dropdownRef={dropdownRef}
                  classNamePrefix="rs-drp"
                  id="syndicate-dropdown-content-writer"
                  placeholder={isContentSupplier ? 'Select Brands' : 'Select Franchise Consultants'}
                  onChange={(event: any) => {
                    handleSelectSyndicateFranchisors(event.id);
                  }}
                  value={franchisorSyndication?.find((syndicate) => selectedSyndicateFranchisors.includes(syndicate.id)) || ''}
                  options={isContentSupplier ? dropdownOptions : franchisorSyndication}
                  isSearchable={isContentSupplier || (franchisorSyndication && franchisorSyndication?.length > 1) ? true : false}
                  menuIsOpen
                />
              </div>
            </Tab>
          ) : null}
        </Tabs>

        {(locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST && selectedLocData.id) || (locationType === FranScheduledLocName.FRANCHISE_CONSULTANT && selectedSyndicateFranchisors.length) ? (
          <div className="select-location-section-wrp">
            <h3 className="glbl__title--txt">
              Selected{' '}
              <span>
                {locationType === FranScheduledLocName.SPECIFIC_LOCATION_LIST
                  ? selectedLocData.id
                    ? `(1 Location)`
                    : null
                  : selectedSyndicateFranchisors.length
                  ? `(${selectedSyndicateFranchisors.length} Franchise)`
                  : null}
              </span>
            </h3>
            <Card>
              <Card.Body>
                {locationType === FranScheduledLocName.FRANCHISE_CONSULTANT && franchisorSyndication ? (
                  selectedSyndicateFranchisors.map((franchisorId) => (
                    <div className="grid-section mb-3" key={`syndicateFranchior-${franchisorId}`}>
                      <div className="g-left">
                        <span className="labelText"> {franchisorSyndication.find((syndicate) => franchisorId === syndicate.id)?.value} </span>
                      </div>
                      <div className="g-right">
                        <div className="glbl__round--delete">
                          <ImageValidation isImgValid defaultImg={'minus-red'} customName={'minus-red'} onClick={() => handleSelectSyndicateFranchisors(franchisorId)} />
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="grid-section">
                    <div className="g-left">
                      <div className="icons-loc">
                        <ImageValidation isImgValid defaultImg={'location-grey'} customName={'hub-item-img'} />
                      </div>
                      <span className="labelText"> {selectedLocData.name} </span>
                    </div>
                    <div className="g-right">
                      <div className="glbl__round--delete">
                        <ImageValidation isImgValid defaultImg={'minus-red'} customName={'minus-red'} onClick={() => dispatch(addPostSetSelectedLocData(AP_SELECTED_LOC_INIT_DATA))} />
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        ) : null}
      </Card.Body>
    </Card>
  );
};
