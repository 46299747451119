import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import moment from 'moment-timezone';

import { subscriptionTableEndDateChecker, dateFormatByRequestedFormat, isDateInBetweenTwoDates, getDiscountedDaysFromToday } from 'utils/helpers';
import {
  MONTH_DATE_FORMAT,
  SCROLL_THRESHOLD,
  AI_SUBSCRIPTION_TAB,
  AI_SUBSCRIPTION_TABLE_HEADERS,
  CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB,
  ICSSubscriptionPlans,
  FBBC_SUBSCRIPTION_PLANS,
  AI_SUBSCRIPTION_PLAN_DURATION_LABELS,
  IAISubscriptionPlansDurationVal,
  FBBC_BRAND_SUBSCRIPTION_TAB,
  JBF_SUBSCRIPTION_PLANS,
  JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN,
  JBF_BRAND_SIX_MONTH_REGULAR_PLAN,
  ALERT_BOX_TYPES,
  AI_SUBSCRIPTION_CANCEL_SUCCESS,
  USER_OWNERSHIP,
  AI_SUBSCRIPTION_RENEW_SUCCESS
} from 'utils/constants';
import { ICustomHeaderDropDownOption, IStore, ITableHeader } from 'types';
import { aiUpdateSubscriptionPlanRequest, setAISubscriptionDetailsModal, setAISubscriptionType, setCsLocationSubscriptionModalType, setSelectedSubscriptionTableData } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { VirtualTable } from 'components/Common/CarouselTable';
import { useAccountSwitcherData } from 'utils/hooks';
import { AICancelSusbcription } from './AICancelSusbcription';
import { alertBoxCall } from 'components/Common/AlertBox';

type ISortingOptions = 'ascending' | 'descending';

export const AISubscriptionTable = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const subscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.subscriptionTableData);
  const isFetching = useSelector((state: IStore) => state.stripe.isFetching);
  const isAiSubscriptionFetching = useSelector((state: IStore) => state.aiContentCreator.isAiSubscriptionFetching);
  const subscriptionType = useSelector((state: IStore) => state.aiContentCreator.subscriptionType);
  const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);
  const isContentSupplierFranchisor = useSelector((state: IStore) => state.accountSwitcher?.content_supplier_franchisor || false);
  const isAIContractSubscription = useSelector((state: IStore) => state.accountSwitcher.ai_subscription_contract);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const selectedSubscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.data);
  const topLevelFranchisorId = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.accountSwitcher?.franchisors?.map((it) => it.id).join(',') || '' : state.accountSwitcher?.accounts?.map((it) => it.id).join(',') || ''
  );
  // const isPeersSubscription = useSelector((state: IStore) => state.accountSwitcher.peers_subscription);

  const subscriptionObj: any = currentSubscription.find((datum) => datum.franchisor_id === id || datum.account_id === id);

  const [isAscending, setAscending] = useState<boolean>(false);
  const [isCancelSubscription, setCancelSusbcription] = useState<boolean>(false);

  const renderHeader = useCallback(
    (headerObj: ITableHeader<any, {}>, isResponsiveMode?: boolean, setShowDropdown?: React.Dispatch<React.SetStateAction<string>>, pinned?: boolean) => {
      return (
        <div
          onClick={() => {
            if (isResponsiveMode && !pinned && setShowDropdown) {
              setShowDropdown((prevState) => (headerObj.identifier === prevState ? '' : headerObj.identifier));
            }
          }}
        >
          <span>{headerObj.label}</span>
          {headerObj.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[3].value ? (
            <ImageValidation isImgValid defaultImg="sort" customName="sort" customClassname={`sort-cus ${isAscending ? 'sort-icon__rotate' : ''}`} />
          ) : null}
        </div>
      );
    },
    [isAscending]
  );

  const renderColumn = useCallback((rowObj: any, header: ITableHeader<any, {}>, rowIndex: number, colState?: {}) => {
    return (
      <>
        {!header.identifier ? (
          <span className={`adsi-sign ${rowObj[header.identifier] ? 'active' : ''}`} />
        ) : header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[1].value ? (
          <div className="ad-label">
            <span className="adl-top ">Rallio AI</span>
          </div>
        ) : header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[6].value ? (
          <span className="ad-cnt adc-bold">{rowObj[header.identifier]}</span>
        ) : (
          <span className="ad-cnt">{rowObj[header.identifier]}</span>
        )}
      </>
    );
  }, []);

  const tableComponentHeader = AI_SUBSCRIPTION_TABLE_HEADERS.map((it): ITableHeader<string> => {
    const responsiveSortingOptions: ICustomHeaderDropDownOption[] = [
      {
        identifier: 'ascending' as ISortingOptions,
        text: 'Low to High',
        type: 'sort'
      },
      {
        identifier: 'descending' as ISortingOptions,
        text: 'High to Low',
        type: 'sort'
      }
    ];
    return {
      identifier: it.value,
      headerClassName: it.className,
      colClassName: !it.value ? 'ads-item' : it.value === AI_SUBSCRIPTION_TABLE_HEADERS[3].value ? 'sort-active' : '',
      label: it.title,
      labelString: it.title,
      headerDropdownOptions: it.value === AI_SUBSCRIPTION_TABLE_HEADERS[3].value ? responsiveSortingOptions : [],
      renderColumn,
      renderHeader
    };
  });

  const handleClick = (it: any) => {
    if (it?.data[0]?.franchisor_subscription_type_id) {
      if (isContentSupplierFranchisor && [7, 8].includes(+it?.data[0]?.franchisor_subscription_type_id)) {
        dispatch(setAISubscriptionDetailsModal({ type: 'CSLocationAISubscription', isOpen: true }));
        dispatch(setCsLocationSubscriptionModalType('ai_community'));
        dispatch(setSelectedSubscriptionTableData(it));
      } else if ([FBBC_SUBSCRIPTION_PLANS.MONTH, FBBC_SUBSCRIPTION_PLANS.SIX_MONTH, FBBC_SUBSCRIPTION_PLANS.YEAR].includes(it?.data[0]?.subscription_plan)) {
        const activePlanIndex = FBBC_BRAND_SUBSCRIPTION_TAB.findIndex((subs) => subs.subscription_plan === it.data[0]?.subscription_plan);
        dispatch(setSelectedSubscriptionTableData(it));
        dispatch(setAISubscriptionType(FBBC_BRAND_SUBSCRIPTION_TAB?.[activePlanIndex === 2 ? activePlanIndex : activePlanIndex + 1 || 0]));
        dispatch(setAISubscriptionDetailsModal({ type: 'modifySubscription', isOpen: true }));
      } else if ([JBF_SUBSCRIPTION_PLANS.SIX_MONTH_REGULAR, JBF_SUBSCRIPTION_PLANS.SIX_MONTH_DISCOUNT].includes(it?.data[0]?.subscription_plan)) {
        dispatch(setSelectedSubscriptionTableData(it));
        /* dispatch(setAISubscriptionType(isPeersSubscription ? JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN : JBF_BRAND_SIX_MONTH_REGULAR_PLAN));
        dispatch(setAISubscriptionDetailsModal({ type: 'modifySubscription', isOpen: true })); */
      } else if (isContentSupplierFranchisor && ![7, 8].includes(+it?.data[0]?.franchisor_subscription_type_id)) {
        dispatch(setSelectedSubscriptionTableData(it));
        dispatch(setAISubscriptionType(CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB[it.data[0]?.subscription_plan === ICSSubscriptionPlans.TIER2 ? 0 : 1]));
        dispatch(setAISubscriptionDetailsModal({ type: 'contentSupplierSubscription', isOpen: true }));
      }
    } else {
      dispatch(setSelectedSubscriptionTableData(it));
      dispatch(setAISubscriptionType(AI_SUBSCRIPTION_TAB[it.data[0]?.subscription_period === 'month' ? 0 : 1]));
      dispatch(setAISubscriptionDetailsModal({ type: 'modifySubscription', isOpen: true }));
    }
  };

  const handleCancelSusbcription = () => {
    const validSusbcription = selectedSubscriptionTableData.filter((it) => {
      return it.user_id === activeUserId && !it?.subscription_device_type;
    });
    if (validSusbcription?.length && activeUserId) {
      const data = validSusbcription.map((it) => ({ ...it, auto_renewal: false }));
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_CANCEL_SUCCESS);
          }
        })
      );
    }
  };

  const handleRenewSubscription = () => {
    const validSusbcription = selectedSubscriptionTableData.filter((it) => {
      return it.user_id === activeUserId;
    });
    const isUpdateAutoRenewal = isDateInBetweenTwoDates(moment(validSusbcription[0]?.start_date), moment(validSusbcription[0]?.end_date), moment());
    if (validSusbcription?.length && isUpdateAutoRenewal && activeUserId) {
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data: validSusbcription.map((it) => ({ ...it, auto_renewal: true }))
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_RENEW_SUCCESS);
          }
        })
      );
    }
  };

  const subscriptionKeys = Object.keys(subscriptionTableData);
  const tableValue = useMemo(() => {
    return subscriptionKeys
      .reduce((acc: any[], curr: any) => {
        const currentDataArr = subscriptionTableData[curr];
        const defaultSubscriptionData = currentDataArr?.filter(
          (it) => ![ICSSubscriptionPlans.FREE, ICSSubscriptionPlans.TIER2, ICSSubscriptionPlans.TIER2POINT5, ICSSubscriptionPlans.CS_PREMIUM].includes(it.subscription_plan)
        );
        const contentSupplierData = currentDataArr?.filter((it) =>
          [ICSSubscriptionPlans.FREE, ICSSubscriptionPlans.TIER2, ICSSubscriptionPlans.TIER2POINT5, ICSSubscriptionPlans.CS_PREMIUM].includes(it.subscription_plan)
        );

        if (defaultSubscriptionData?.length) {
          let dataObj = {};
          const endDate = dateFormatByRequestedFormat(defaultSubscriptionData[0].end_date, MONTH_DATE_FORMAT);
          const currentValue: any = {
            subscritpionPeriod: endDate,
            plan: defaultSubscriptionData[0]?.trial_period
              ? AI_SUBSCRIPTION_PLAN_DURATION_LABELS.FREE_TRIAL
              : defaultSubscriptionData[0].subscription_period === IAISubscriptionPlansDurationVal.MONTH
              ? AI_SUBSCRIPTION_PLAN_DURATION_LABELS.MONTH
              : defaultSubscriptionData[0].subscription_period === IAISubscriptionPlansDurationVal.SIX_MONTH
              ? AI_SUBSCRIPTION_PLAN_DURATION_LABELS.SIX_MONTH
              : defaultSubscriptionData[0].subscription_period === IAISubscriptionPlansDurationVal.SIX_MONTH_DISCOUNT
              ? AI_SUBSCRIPTION_PLAN_DURATION_LABELS.SIX_MONTH
              : AI_SUBSCRIPTION_PLAN_DURATION_LABELS.YEAR,
            purchaseDate: dateFormatByRequestedFormat(curr, MONTH_DATE_FORMAT),
            dueDate: endDate,
            locations: defaultSubscriptionData.length,
            auto_renewal: defaultSubscriptionData[0]?.auto_renewal,
            amount: `$${
              defaultSubscriptionData[0]?.trial_period
                ? 0
                : defaultSubscriptionData?.length *
                  (defaultSubscriptionData[0].subscription_plan === FBBC_SUBSCRIPTION_PLANS.MONTH
                    ? 25
                    : defaultSubscriptionData[0].subscription_plan === FBBC_SUBSCRIPTION_PLANS.SIX_MONTH
                    ? 119
                    : defaultSubscriptionData[0].subscription_plan === FBBC_SUBSCRIPTION_PLANS.YEAR
                    ? 225
                    : defaultSubscriptionData[0].subscription_plan === JBF_SUBSCRIPTION_PLANS.SIX_MONTH_REGULAR
                    ? JBF_BRAND_SIX_MONTH_REGULAR_PLAN.price
                    : defaultSubscriptionData[0].subscription_plan === JBF_SUBSCRIPTION_PLANS.SIX_MONTH_DISCOUNT
                    ? JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN.price
                    : defaultSubscriptionData[0].subscription_period === IAISubscriptionPlansDurationVal.MONTH
                    ? 29
                    : 288)
            }`,
            ...([JBF_SUBSCRIPTION_PLANS.SIX_MONTH_REGULAR, JBF_SUBSCRIPTION_PLANS.SIX_MONTH_DISCOUNT].includes(defaultSubscriptionData[0]?.subscription_plan) && {
              cancel: (
                <button
                  className={`ai-unsribe`}
                  onClick={() => {
                    defaultSubscriptionData[0]?.auto_renewal ? setCancelSusbcription(true) : handleRenewSubscription();
                  }}
                >
                  <span>{defaultSubscriptionData[0]?.auto_renewal ? 'Cancel' : 'Renew'}</span>
                </button>
              )
            })
          };
          const isActive = isDateInBetweenTwoDates(moment(defaultSubscriptionData[0]?.start_date), moment(defaultSubscriptionData[0]?.end_date), moment());
          AI_SUBSCRIPTION_TABLE_HEADERS.forEach((it) => {
            const value = it?.value
              ? it.value === AI_SUBSCRIPTION_TABLE_HEADERS[4].value
                ? currentValue.auto_renewal
                  ? `Renews On ${currentValue[it?.value]}`
                  : `${defaultSubscriptionData[0]?.end_date && subscriptionTableEndDateChecker(defaultSubscriptionData[0]?.end_date) ? 'Ends' : 'Ended'} On ${currentValue[it?.value]}`
                : currentValue[it?.value]
              : isActive;
            dataObj = {
              ...dataObj,
              [it.value]: value,
              identifier: { isActive, data: defaultSubscriptionData }
            };
          });
          acc.push(dataObj);
        }
        if (contentSupplierData?.length) {
          let dataObj = {};
          const endDate = dateFormatByRequestedFormat(contentSupplierData[0]?.end_date, MONTH_DATE_FORMAT);
          const discountDays = getDiscountedDaysFromToday(contentSupplierData[0]?.created_at || '');
          const isDiscountPriceAvailable = discountDays >= 1 && discountDays <= 7;
          const currentValue: any = {
            subscritpionPeriod: endDate,
            plan:
              contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.FREE
                ? contentSupplierData[0]?.franchisor_subscription_type_id === 1
                  ? 'Tier 1'
                  : 'Free'
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2
                ? 'Tier 2'
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2POINT5
                ? 'Tier 2.5'
                : 'Premium',
            purchaseDate: dateFormatByRequestedFormat(curr, MONTH_DATE_FORMAT),
            dueDate: endDate,
            locations: contentSupplierData.length,
            auto_renewal: contentSupplierData[0]?.auto_renewal,
            amount: `$${
              contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.FREE
                ? 0
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2
                ? 10
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.TIER2POINT5
                ? 25
                : contentSupplierData[0]?.subscription_plan === ICSSubscriptionPlans.CS_PREMIUM
                ? isDiscountPriceAvailable
                  ? 7.95
                  : 9.95
                : 0
            }`
          };
          const isActive = isDateInBetweenTwoDates(moment(contentSupplierData[0]?.start_date), moment(contentSupplierData[0]?.end_date), moment());
          AI_SUBSCRIPTION_TABLE_HEADERS.forEach((it) => {
            const value = it?.value
              ? it.value === AI_SUBSCRIPTION_TABLE_HEADERS[4].value
                ? currentValue.auto_renewal
                  ? `Renews On ${currentValue[it?.value]}`
                  : contentSupplierData[0]?.subscription_plan !== ICSSubscriptionPlans.FREE
                  ? `${contentSupplierData[0]?.end_date && subscriptionTableEndDateChecker(contentSupplierData[0]?.end_date) ? 'Ends' : 'Ended'} On ${currentValue[it?.value]}`
                  : ''
                : currentValue[it?.value]
              : isActive;
            dataObj = {
              ...dataObj,
              [it.value]: value,
              identifier: { isActive, data: contentSupplierData }
            };
          });
          acc.push(dataObj);
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => {
        return isAscending
          ? new Date(a.identifier?.data[0]?.created_at).valueOf() - new Date(b.identifier?.data[0].created_at).valueOf()
          : new Date(b.identifier?.data[0]?.created_at).valueOf() - new Date(a.identifier?.data[0].created_at).valueOf();
      });
  }, [isAscending, subscriptionKeys, subscriptionTableData]); // eslint-disable-line

  return (
    <>
      <Modal.Header>
        <div className="rai-sub-top">
          <h2>Rallio Subscriptions</h2>
          {isAIContractSubscription && !isAISubscribed && !subscriptionObj?.franchisor_subscription_type_id && !isAiSubscriptionFetching ? (
            <div className="ai-stripe-btns csuy-btns ans-btn">
              <button
                className="ac-btn ai__sub--btn"
                onClick={() => {
                  if (!subscriptionType) {
                    dispatch(setAISubscriptionType(FBBC_BRAND_SUBSCRIPTION_TAB[0]));
                  }
                  dispatch(setAISubscriptionDetailsModal({ type: 'newSubscription', isOpen: true }));
                }}
              >
                <span>Add New Subscriptions</span>
              </button>
            </div>
          ) : null}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="resuseTable adptTable rswc">
          <div className={`adpt-height ${tableValue.length === 0 ? 'adpt__nodata--height' : ''}`} id="subscriptionTable">
            <VirtualTable<any>
              data={[tableValue]}
              headers={tableComponentHeader}
              ipadBoundary={768}
              ipadColCount={2}
              mobileBoundary={575}
              mobileColCount={2}
              mdDeviceBoundary={992}
              mdDeviceColCount={3}
              pinnedColumns={[0, 1]}
              isFetching={false}
              noDataMesg={isFetching || isAiSubscriptionFetching ? 'Loading...' : undefined}
              scrollableTargetString="subscriptionTable"
              scrollThreshold={SCROLL_THRESHOLD}
              tableClassName="responsiveTable"
              onRowClick={(row) => {
                handleClick(row.identifier);
              }}
              onHeaderClick={(header) => {
                if (header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[3].value) {
                  setAscending(!isAscending);
                }
              }}
              onCustomHeaderDropDownClick={(header, dropdownItem) => {
                if (header.identifier === AI_SUBSCRIPTION_TABLE_HEADERS[3].value) {
                  if (dropdownItem.identifier === ('ascending' as ISortingOptions)) {
                    setAscending(true);
                  } else {
                    setAscending(false);
                  }
                }
              }}
            />
          </div>
          <p className="adp-base">Your payments will be charged against the credit card on file. You will be charged for the upcoming period unless you manually cancel the subscription.</p>
        </div>
      </Modal.Body>
      {isCancelSubscription && <AICancelSusbcription showDeleteModal={Boolean(isCancelSubscription)} handleModalClose={() => setCancelSusbcription(false)} handleCancel={handleCancelSusbcription} />}
    </>
  );
};
