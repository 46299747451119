import { useSelector } from 'react-redux';

import { aiContentCreatorReducerTypes, franchisorReducerTypes, IStore } from 'types';
import {
  AI_SUBSCRIPTION_PLAN_TYPES,
  AI_SUBSCRIPTION_TAB,
  FBBC_BRAND_SUBSCRIPTION_TAB,
  FBBC_SUBSCRIPTION_PLANS,
  JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN,
  JBF_BRAND_SIX_MONTH_REGULAR_PLAN,
  JBF_SUBSCRIPTION_PLANS
} from 'utils/constants';

export const useAISubscriptionPlan = () => {
  const franchisorSubscriptionPlans = useSelector((state: IStore) => state.franchisors.franchisorSubscriptions);
  const isPeersSubscription = useSelector((state: IStore) => state.accountSwitcher.peers_subscription);

  const subscriptionPlan = franchisorSubscriptionPlans?.length
    ? franchisorSubscriptionPlans.every((subscription: franchisorReducerTypes.IFranSubscriptionTypes) =>
        [FBBC_SUBSCRIPTION_PLANS.MONTH, FBBC_SUBSCRIPTION_PLANS.SIX_MONTH, FBBC_SUBSCRIPTION_PLANS.YEAR].includes(subscription?.plan_name)
      )
      ? AI_SUBSCRIPTION_PLAN_TYPES.FBBC_BRAND
      : franchisorSubscriptionPlans.every((subscription: franchisorReducerTypes.IFranSubscriptionTypes) =>
          [JBF_SUBSCRIPTION_PLANS.SIX_MONTH_REGULAR, JBF_SUBSCRIPTION_PLANS.SIX_MONTH_DISCOUNT].includes(subscription?.plan_name)
        )
      ? AI_SUBSCRIPTION_PLAN_TYPES.JBF_BRAND
      : AI_SUBSCRIPTION_PLAN_TYPES.TRIAL_PERIOD
    : AI_SUBSCRIPTION_PLAN_TYPES.TRIAL_PERIOD;

  const getPlanOptions = () => {
    switch (subscriptionPlan) {
      case AI_SUBSCRIPTION_PLAN_TYPES.FBBC_BRAND: {
        return FBBC_BRAND_SUBSCRIPTION_TAB;
      }
      case AI_SUBSCRIPTION_PLAN_TYPES.JBF_BRAND: {
        return isPeersSubscription ? [{ ...JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN }] : [{ ...JBF_BRAND_SIX_MONTH_REGULAR_PLAN }];
      }
      default: {
        return AI_SUBSCRIPTION_TAB;
      }
    }
  };

  return { subscriptionPlan: subscriptionPlan as aiContentCreatorReducerTypes.IAISubscriptionPlans, planOptions: getPlanOptions() };
};
