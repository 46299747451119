import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { directoryListingsContainerTypes, IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { NoDataFoundProps, RIPPLE_COLOR } from 'utils/constants';
import { NoDataFound } from 'components';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { getCountriesCodeForDropdown } from 'utils/helpers';

export const CountrySelectFilterModal = ({
  initSelectedCountries,
  onSubmit,
  setIsModalOpen
}: {
  initSelectedCountries: string;
  onSubmit: (countries: string[]) => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const countriesCode = useSelector((state: IStore) => state.franchisors.countries);
  const [selectedCountries, setSelectedCountries] = useState<directoryListingsContainerTypes.ICountriesCodeObj[]>([]);
  const countriesCodeList = getCountriesCodeForDropdown(countriesCode);

  useEffect(() => {
    const initCountryArr = initSelectedCountries ? initSelectedCountries.split(',') : [];
    if (initCountryArr?.length) setSelectedCountries(countriesCodeList.filter((item) => initSelectedCountries.includes(item.id)));
  }, [countriesCodeList?.length]); // eslint-disable-line

  const [searchString, setSearchString] = useState('');

  return (
    <Modal show className="lb-filter__modal accountSwitchModal global-modal-secondary cssWrap" centered size="lg" onHide={() => setIsModalOpen(false)}>
      <>
        <div className="mod__close--icon">
          <ImageValidation isImgValid defaultImg="mpcIcon" customClassname="close-icon close mod__close--img wh-30" customName={'close'} onClick={() => setIsModalOpen(false)} />
        </div>
        <Modal.Body>
          <div className="asm-top-head">
            <h3 className="title">Country Selector</h3>
            <div className="asm-lf">
              <input type="text" name="ContentSuppliers" onChange={(event) => setSearchString(event.target.value.trim())} placeholder="Search" />
            </div>
          </div>
          <div className="asm-accord cssheadLabels">
            <div className="all-locs cssTopLabel">
              <label onClick={() => setSelectedCountries([])} className={`cur-pointer lblc r-flx r-flx-ac ${Boolean(!selectedCountries?.length) ? 'active' : ''}`}>
                {/* <input type="radio" defaultChecked={Boolean(!selectedCountries?.length)} className="option-input radio" name="selectedLocation" /> */}
                <span>All Countries</span>
              </label>
            </div>
            <div className="asm-accord cssSearchList">
              <div className="all-locs">
                <ul className="hub-list">
                  {countriesCodeList?.length ? (
                    countriesCodeList
                      .filter((iterated) => iterated.label.toLowerCase().indexOf(searchString?.toLowerCase()) > -1)
                      .map((country, index: number) => {
                        const isSelected = Boolean(selectedCountries.find((ele) => ele.id === country?.id));
                        return (
                          <li key={`country-list-${index}`}>
                            <label
                              className={`r-flx r-flx-ac lblc lc-acc-cnt${isSelected ? ` active` : ''}`}
                              onClick={() => setSelectedCountries(isSelected ? selectedCountries.filter((obj) => obj.id !== country.id) : [...selectedCountries, country])}
                            >
                              {/* <input
                                type="radio"
                                defaultChecked={isSelected}
                                className="option-input radio"
                                name="selectedLocation"
                                onChange={() => setCountriesData(isSelected ? countriesData.filter((obj) => obj.value !== country.value) : [...countriesData, country])}
                              /> */}
                              <div className={`list-lbl cur-pointer`}>
                                <span className="lcs-name" title={country.label}>
                                  {country.label}
                                </span>
                              </div>
                            </label>
                          </li>
                        );
                      })
                  ) : (
                    <NoDataFound size={NoDataFoundProps.SMALL} />
                  )}
                </ul>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <div className="asm-btn">
              <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
                <button className="ac-btn ac-secondary-white ac-outline ac-block border-0" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ac-primary-box r-ml2`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary ac-block`}
                  onClick={() => {
                    onSubmit(selectedCountries.map((country) => country.id));
                    setIsModalOpen(false);
                  }}
                >
                  Ok
                </button>
              </CustomRippleButton>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </>
    </Modal>
  );
};
