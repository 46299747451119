import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Tag } from 'react-tag-autocomplete';

import { ImageValidation } from 'widgets/Media';
import {
  disconnectFBAdsTargetingChoicesRequest,
  fbAdsBoostDataResetAll,
  getAccTargetingInterestsListRequest,
  saveFbUserCredentials,
  updateAccFacebookAdAccountIdRequest,
  updateAccFBDefaultConfigRequest
} from 'actions';
import { fbAdsApiTypes, IStore, postsContainerTypes, settingsSocialProfileReducerTypes } from 'types';
import { alertBoxCall, LeafletMap, Loading, ModalPopup, SettingsSocialModalPopup } from 'components';
import {
  MODAL_BOX_TYPES,
  FB_ADS_DISCONNECT_DELETE_CONFIRM_MSG,
  ALERT_BOX_TYPES,
  VALID_ONLY_NUMBERS_REGEX,
  FbAdsConnectionsType,
  FB_ADS_TARGET_GENDER_LIST,
  RIPPLE_COLOR /* US_ZIP_CODE_VALIDATION_ERROR */,
  FbAdsGenderType
} from 'utils/constants';
import { handleAddInterestTagSymbol, isUSAZipCode } from 'utils/helpers';
import { useAccountSwitcherData } from 'utils/hooks';
import { FBAdsFacebookViewPagesModalBody, FacebookButton } from 'containers/Settings/FBAds/SubPages';
import { CustomDropdownMenu } from 'widgets/CustomDropdownMenu';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const LocationFBAdsTargetingList = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();

  const targetRef = useRef(null);
  const [active, setActive] = useState(false);

  const fbApplicationId = useSelector((state: IStore) => state.common.decryptedEnvData?.fb_application_id);
  // const { id, userOwnership } = useSelector((state: IStore) => ({ id: state.accountSwitcher.id, userOwnership: state.accountSwitcher.userOwnership }));
  const { fbTargetingChoice, accTargetingInterests, targetSuccessMsg, isSaveConfigChanges, accountUserCredential, adAccountFetching, isInterestsFetching } = useSelector(
    (state: IStore) => state.fbAdsTargeting
  );
  const { facebookAccountDetail, isUserDataFetching, isAdAccountFetching } = accountUserCredential;
  const userId = useSelector((state: IStore) => state.login.activeUser?.id);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState<postsContainerTypes.IPostDeleteItem>({ deleteId: null, deleteMsg: null });
  const [boostSettingDefaults, setBoostSettingDefaults] = useState<null | fbAdsApiTypes.IFBAdsAccDefaultConfiguration>(null);
  const [zipCode, setZipCode] = useState<null | string>(null);
  const [interestTags, setInterestsTags] = useState<fbAdsApiTypes.IInterestsTags>({ suggestions: [], tags: [] });
  const [showFBPageModal, setShowFBPageModal] = useState(false);
  const [stopSpinnerOnCancel, setstopSpinnerOnCancel] = useState(false);
  const [selectedFBPageId, setSelectedFBPageId] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
    address: string;
    radiusInMiles: number;
  } | null>(null);

  let interestApiTimeout: any = null;

  useEffect(() => {
    if (targetSuccessMsg) alertBoxCall(ALERT_BOX_TYPES.SUCCESS, targetSuccessMsg);
  }, [targetSuccessMsg]); // eslint-disable-line

  useEffect(() => {
    if (!fbTargetingChoice) {
      setBoostSettingDefaults(null);
    } else if (fbTargetingChoice && `default_configuration` in fbTargetingChoice) {
      const configuration = {
        ...fbTargetingChoice.default_configuration,
        lifetime_spend: fbTargetingChoice.default_configuration.lifetime_spend / 100,
        connections: FbAdsConnectionsType.ALL_CONNECTIONS
      };
      if (configuration?.coordinates?.length === 0) {
        configuration.coordinates = configuration.home_coordinate ? [{ ...configuration.home_coordinate }, ...configuration.coordinates] : configuration.coordinates;
      }
      setBoostSettingDefaults(configuration);
      setInterestsTags((prevState) => ({
        ...prevState,
        tags: handleAddInterestTagSymbol(fbTargetingChoice?.default_configuration?.interests)
      }));
    }
  }, [fbTargetingChoice]); // eslint-disable-line

  useEffect(() => {
    if (accTargetingInterests.length) {
      const uniqueSuggestions = accTargetingInterests.filter((val) => !interestTags.tags.find((it: any) => it.id === val.id));
      setInterestsTags((prevState) => ({
        ...prevState,
        suggestions: handleAddInterestTagSymbol(uniqueSuggestions)
      }));
    }
  }, [accTargetingInterests, interestTags.tags]); // eslint-disable-line

  useEffect(() => {
    dispatch(fbAdsBoostDataResetAll());
  }, [dispatch]);

  const handleDisconnectFBAdsTarget = () => {
    setShowDeleteModal(true);
    if (id) {
      setDeleteItem({ deleteId: id, deleteMsg: FB_ADS_DISCONNECT_DELETE_CONFIRM_MSG });
    }
  };

  const handleDisconnectFBAdsTargetConfirm = () => {
    if (deleteItem.deleteId && userOwnership) {
      dispatch(disconnectFBAdsTargetingChoicesRequest({ id: deleteItem.deleteId, userOwnership }));
      setShowDeleteModal(false);
    }
  };

  const handleDisableSaveChanges = () => {
    if (boostSettingDefaults && boostSettingDefaults?.lifetime_spend < 1.5) return true;
    else if (boostSettingDefaults && !boostSettingDefaults?.locations.length && !boostSettingDefaults?.coordinates.length) return true;
    else return false;
  };

  const handleSaveBoostSettingChanges = () => {
    if (id && boostSettingDefaults) {
      dispatch(
        updateAccFBDefaultConfigRequest({
          accountId: id,
          reqPayload: {
            connections: boostSettingDefaults.connections,
            coordinates: boostSettingDefaults.coordinates,
            duration: boostSettingDefaults.duration,
            gender: boostSettingDefaults.gender,
            ...(boostSettingDefaults.home_coordinate
              ? {
                  home_coordinate: {
                    lat: boostSettingDefaults.home_coordinate.lat,
                    lng: boostSettingDefaults.home_coordinate.lng,
                    radius_in_meters: boostSettingDefaults.home_coordinate.radius_in_meters,
                    human_readable_address: boostSettingDefaults.home_coordinate.human_readable_address
                  }
                }
              : { home_coordinate: null }),
            interests: boostSettingDefaults.interests,
            lifetime_spend: boostSettingDefaults.lifetime_spend * 100,
            locations: boostSettingDefaults.locations,
            max_age: boostSettingDefaults.max_age,
            min_age: boostSettingDefaults.min_age
          }
        })
      );
    }
  };

  const handleAddInterestTag = (activeTags: Tag | any) => {
    if (!interestTags.tags.find((it) => it.id === activeTags.id)) {
      const addedInterestTags = [...interestTags.tags, activeTags];
      handleInterestTags(addedInterestTags);
    }
  };

  const handleDeleteInterestTag = (id: any) => {
    const retainedInterestTags = interestTags.tags.filter((it) => it.id !== id);
    handleInterestTags(retainedInterestTags);
  };

  const handleInterestTags = (interestTags: any) => {
    setInterestsTags((prevState) => ({
      ...prevState,
      tags: interestTags
    }));
    const interestsTagList = interestTags.reduce((acc: fbAdsApiTypes.IAccDefaultConfigInterestObj[], curr: fbAdsApiTypes.IAccTargetingInterestsObj) => {
      const interestObj = {
        audience_size: curr.audience_size,
        id: curr.id,
        name: curr.path[curr.path.length - 1],
        path: curr.path
      };
      acc = [...acc, interestObj];
      return acc;
    }, []);
    setBoostSettingDefaults(
      boostSettingDefaults
        ? {
            ...boostSettingDefaults,
            interests: interestsTagList
          }
        : null
    );
  };

  /* const getFacebookDetails = (fbResponse: fbAdsApiTypes.IFBAdsResponseObj) => {
    if (fbResponse && fbResponse.id) {
      dispatch(getAccFacebookAdAccountUuidRequest(fbResponse.id));
      setShowFBPageModal(true);
    }
  }; */
  const getFacebookDetails = (fbResponse: any | null) => {
    if (fbResponse && fbResponse.accessToken) {
      const payload: settingsSocialProfileReducerTypes.IFacebookCredential = {
        ...fbResponse,
        rallioUserId: userId || 0,
        app_id: fbApplicationId,
        callback: () => {
          setShowFBPageModal(true);
        }
      };
      dispatch(saveFbUserCredentials(payload));
    }
  };

  const handleDeleteZipcode = (type: string) => {
    boostSettingDefaults?.locations.pop();
    setBoostSettingDefaults(
      boostSettingDefaults
        ? {
            ...boostSettingDefaults,
            [type]: boostSettingDefaults?.locations
          }
        : null
    );
    setZipCode(null);
  };

  const ageMarkStyle = {
    [boostSettingDefaults?.min_age ? +boostSettingDefaults?.min_age : 18]: {
      label: <span className={'rsmark-txt'}>{`${boostSettingDefaults?.min_age}`}</span>
    },
    [boostSettingDefaults?.max_age ? +boostSettingDefaults?.max_age : 66]: {
      label: <span className={'rsmark-txt'}>{`${boostSettingDefaults?.max_age || `65+`}`}</span>
    }
  };

  return (
    <>
      <div className="mainContent animate__animated animate__fadeIn wts fb-ads--wrp fb-ads__main lpx vpy-20 mx-0">
        <div className="transparent-box mb-20">
          <div className="rel-head-label">
            <div className="rel-icons">
              <ImageValidation defaultImg="fb-lv" customName="Facebook Ad Account" />
            </div>
            <span className="rel-txt">Facebook Ad Account</span>
          </div>
          {fbTargetingChoice?.facebook_ad_account?.id && (
            <div className="scl-details">
              <div className="scld-item">
                <span className="scld-label">Account Name</span>
                <span className="scld-value">{fbTargetingChoice.facebook_ad_account.name}</span>
              </div>
              <div className="scld-item">
                <span className="scld-label">Account ID</span>
                <span className="scld-value">{fbTargetingChoice.facebook_ad_account.id}</span>
              </div>
            </div>
          )}
          <div className="scl-controls g-20 r-flx r-flx-ac">
            {!fbTargetingChoice ? (
              <FacebookButton label="Connect Now" stop={stopSpinnerOnCancel} isFetching={isAdAccountFetching && showFBPageModal} handler={getFacebookDetails} />
            ) : (
              <>
                <CustomRippleButton rippleClass="ripple-unset ac-secondary-box" custColor={RIPPLE_COLOR.whiteGrey}>
                  <button className="ac-btn ac-secondary-grey" onClick={() => handleDisconnectFBAdsTarget()}>
                    Disconnect
                  </button>
                </CustomRippleButton>
                <FacebookButton label="Change Account" stop={stopSpinnerOnCancel} isFetching={isAdAccountFetching && showFBPageModal} handler={getFacebookDetails} />
              </>
            )}
          </div>
        </div>
        {fbTargetingChoice && Object.keys(fbTargetingChoice).length && (
          <>
            <div className="blue-trans-box mb-20">
              <div className="rel-head-label r-flx r-flx-ac g-15">
                <div className="rel-icons">
                  <ImageValidation defaultImg="boost" customName="Boost Settings Defaults" />
                </div>
                <span className="glbl__title--txt">Boost Settings Defaults</span>
              </div>
            </div>
            <div className="fb__white--box wb-first">
              {/* <div className="fb__white--box white-box wb-first"> */}

              <div className="wb-top r-flx r-flx-ac">
                <div className="wb-label">
                  <div className="wbl-head">Budget</div>
                </div>
                <div className="wb-f-main">
                  <div className="wb-fields">
                    <div className="wbf-item r-flx r-flx-ac">
                      <div className="wbf-label-main">
                        <span className="wbf-label">Total Budget</span>
                      </div>
                      <div className="wbf-inputs">
                        <div className="input-fields">
                          <div className="if-main if-h">
                            <span className="ifa">$</span>
                            <Form.Control
                              type="number"
                              placeholder=""
                              className={'plain-input'}
                              aria-describedby="inputGroupPrepend"
                              value={boostSettingDefaults?.lifetime_spend}
                              name={'lifetime_spend'}
                              onChange={(event) =>
                                setBoostSettingDefaults(
                                  boostSettingDefaults
                                    ? {
                                        ...boostSettingDefaults,
                                        [event.target.name]: event.target.value
                                      }
                                    : null
                                )
                              }
                              step="0.5"
                              autoComplete="off"
                            />
                          </div>
                          <span className="country-initials">U.S.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="fb__white--box white-box wb-second"> */}
            <div className="fb__white--box wb-second">
              <div className="wb-top r-flx">
                <div className="wb-label">
                  <div className="wbl-head">Target Audience</div>
                </div>
                <div className="wb-f-main">
                  <div className="wb-fields gender-sec">
                    <div className="wbf-item r-flx r-flx-ac">
                      <div className="wbf-label-main">
                        <span className="wbf-label">Gender</span>
                      </div>
                      <div className="wbf-inputs">
                        <ToggleButtonGroup className={'btn-group-toggle'} type="checkbox" value={boostSettingDefaults?.gender ? [boostSettingDefaults?.gender] : [FbAdsGenderType.ALL]}>
                          {FB_ADS_TARGET_GENDER_LIST.map((genderDatum, index) => {
                            return (
                              <ToggleButton
                                key={`gender-${index}`}
                                id={`gender-${index}`}
                                type={'radio'}
                                className={(!boostSettingDefaults?.gender && FbAdsGenderType.ALL === genderDatum.name) || boostSettingDefaults?.gender === genderDatum.name ? 'active' : ''}
                                value={genderDatum.name}
                                onChange={(event) =>
                                  setBoostSettingDefaults(
                                    boostSettingDefaults
                                      ? {
                                          ...boostSettingDefaults,
                                          gender: FbAdsGenderType.ALL === event.target.value ? null : event.target.value
                                        }
                                      : null
                                  )
                                }
                              >
                                <div className="rel-icons">
                                  <ImageValidation
                                    defaultImg={
                                      (!boostSettingDefaults?.gender && FbAdsGenderType.ALL === genderDatum.name) || boostSettingDefaults?.gender === genderDatum.name
                                        ? `gender-${genderDatum.name}-a`
                                        : `gender-${genderDatum.name}`
                                    }
                                    customName={genderDatum.label}
                                  />
                                </div>
                                <span className="g-txt">{genderDatum.label}</span>
                              </ToggleButton>
                            );
                          })}
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </div>

                  <div className="wb-fields">
                    <div className="wbf-item age-item r-flx r-flx-ac">
                      <div className="wbf-label-main age-lbl r-flx g-10">
                        <span className="wbf-label">Ages</span>
                        <span className="sub-lbl">(between 18 and 65)</span>
                      </div>
                      <div className="wbf-inputs">
                        <Slider
                          range
                          min={18}
                          max={66}
                          value={[boostSettingDefaults?.min_age || 18, boostSettingDefaults?.max_age || 66]}
                          count={1}
                          allowCross={false}
                          marks={ageMarkStyle}
                          onChange={(value: any) => {
                            if (typeof value === 'object') {
                              setBoostSettingDefaults(
                                boostSettingDefaults
                                  ? {
                                      ...boostSettingDefaults,
                                      min_age: value[0],
                                      max_age: value[1] <= 65 ? value[1] : null
                                    }
                                  : null
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="wb-fields">
                    <div className="wbf-item r-flx wbf__interest--item">
                      <div className="wbf-label-main tags wbf__interest--lbl">
                        <span className="wbf-label">Interests</span>
                      </div>
                      <div className="wbf-inputs">
                        <div className="il-options-main">
                          <div className="ilo-dropdown">
                            {/* <div className="wbf-inputs"> */}
                            {/* <div className="il-options-main"> */}
                            <div className="ilo-group">
                              <div className="react-tags" ref={targetRef}>
                                {interestTags.tags.map((it) => (
                                  <div className="react-tags__selected" key={it.name}>
                                    <button type="button" className="react-tags__selected-tag fst " onClick={() => handleDeleteInterestTag(it.id)}>
                                      <span className="react-tags__selected-tag-name">{it.name}</span>
                                    </button>
                                  </div>
                                ))}
                                <div className="react-tags__search">
                                  <div className="react-tags__search-input">
                                    <input
                                      placeholder="Add a new interest"
                                      type="text"
                                      onChange={(e) => {
                                        const search = e.target.value;
                                        if (interestApiTimeout) {
                                          clearTimeout(interestApiTimeout);
                                        }
                                        if (id && search) {
                                          interestApiTimeout = setTimeout(() => dispatch(getAccTargetingInterestsListRequest({ userOwnership, id, search })), 500);
                                        }
                                      }}
                                      onFocus={() => setActive(true)}
                                    />
                                    <CustomDropdownMenu
                                      targetRef={targetRef}
                                      showMenu={active}
                                      setActive={setActive}
                                      options={interestTags.suggestions.map((it) => ({ value: it.id, name: it.name }))}
                                      isLoading={isInterestsFetching}
                                      onSelect={(value: any) => {
                                        const obj = interestTags.suggestions.find((it: any) => it.id === value);
                                        handleAddInterestTag(obj);
                                      }}
                                      showTooltip
                                      renderTooltipJSX={(value: any) => {
                                        const dataObj = interestTags.suggestions.find((it) => it.id === value);
                                        return (
                                          <>
                                            <span className="man-inactive">{dataObj?.topic || '---'}</span>
                                            <br />
                                            {/* <span>{getFormattedNumber(dataObj?.audience_size || 0)} People</span> */}
                                            {/* <br /> */}
                                            <span>{dataObj?.name}</span>
                                          </>
                                        );
                                      }}
                                      placeholder="Add a new interest"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wb-fields lzm">
                    <div className="wbf-item r-flx r-flx-ac">
                      <div className="wbf-label-main nr-wrap">
                        <span className="wbf-label">Area</span>
                        <span className="errorText nur">Non-U.S. Residents use the map instead of the zip code</span>
                      </div>
                      <div className="addzip">
                        <div className="zipcodes-main">
                          <div className="selected-zip">
                            {boostSettingDefaults?.locations?.length
                              ? boostSettingDefaults.locations.map((zipCode, index) => {
                                  return (
                                    <span className="ifa" key={`us-zip-code-${index}`}>
                                      {zipCode}
                                      <span
                                        className="remove-zip-code"
                                        onClick={() => {
                                          handleDeleteZipcode('locations');
                                        }}
                                      />
                                    </span>
                                  );
                                })
                              : null}
                          </div>
                          <div className="zip-search">
                            <div className="zip-search-wrapper">
                              <Form.Control
                                type="text"
                                placeholder="Add US Zip Codes"
                                onKeyPress={(event: React.KeyboardEventHandler<HTMLInputElement> | any) => {
                                  const regexData = new RegExp(VALID_ONLY_NUMBERS_REGEX);
                                  const enteredKey = String.fromCharCode(event.charCode ? event.which : event.charCode);
                                  if (!regexData.test(enteredKey)) {
                                    event.preventDefault();
                                    return false;
                                  }
                                }}
                                aria-describedby="inputGroupPrepend"
                                name={'locations'}
                                className={'plain-input'}
                                value={zipCode || ''}
                                onChange={(event) => setZipCode(event.target.value)}
                                onKeyUp={(event: React.KeyboardEventHandler<HTMLInputElement> | any) => {
                                  if (event.keyCode === 13 && zipCode) {
                                    const validZipcode = isUSAZipCode(zipCode) ? zipCode : zipCode.substr(0, 5);
                                    setBoostSettingDefaults(
                                      boostSettingDefaults
                                        ? {
                                            ...boostSettingDefaults,
                                            [event.target.name]: [...boostSettingDefaults.locations, validZipcode]
                                          }
                                        : null
                                    );
                                    setZipCode(null);
                                  } else if (event.keyCode === 8 && boostSettingDefaults?.locations.length) {
                                    handleDeleteZipcode(event.target.name);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="edit-audience-modal">
                      <div className="wbf-inputs audience-edit--option">
                        <LeafletMap
                          enableSearch
                          selectedLocation={selectedLocation}
                          placeholder={'Add Address'}
                          selectedLocationList={
                            boostSettingDefaults?.coordinates
                              ? boostSettingDefaults?.coordinates?.map((it) => ({
                                  address: it.human_readable_address,
                                  lat: it.lat,
                                  lng: it.lng,
                                  radiusInMiles: Math.round(it.radius_in_meters * 0.00062)
                                }))
                              : []
                          }
                          onLocationSelection={(selectedLocation) => {
                            if (boostSettingDefaults) {
                              if (!boostSettingDefaults.coordinates) {
                                boostSettingDefaults.coordinates = [];
                              }
                              const alreadyPresent = boostSettingDefaults.coordinates.find((it) => it.lat === selectedLocation.y && it.lng === selectedLocation.x);
                              if (alreadyPresent) {
                                alertBoxCall(ALERT_BOX_TYPES.WARN, 'Location already added with the same address.');
                              } else {
                                boostSettingDefaults.coordinates.push({
                                  human_readable_address: selectedLocation.label,
                                  lat: selectedLocation.y,
                                  lng: selectedLocation.x,
                                  radius_in_meters: 25 / 0.00062
                                });
                                setSelectedLocation({
                                  address: selectedLocation.label,
                                  lat: selectedLocation.y,
                                  lng: selectedLocation.x,
                                  radiusInMiles: 25
                                });
                              }
                              setBoostSettingDefaults({ ...boostSettingDefaults });
                            }
                          }}
                          onRemoveLocation={(locationObj) => {
                            if (boostSettingDefaults) {
                              const alreadyPresent = boostSettingDefaults.coordinates.find((it) => it.lat === locationObj.lat && it.lng === locationObj.lng);
                              if (alreadyPresent) {
                                boostSettingDefaults.coordinates = boostSettingDefaults.coordinates.filter((it) => it.lat !== locationObj.lat && it.lng !== locationObj.lng);
                                setBoostSettingDefaults({ ...boostSettingDefaults });
                              }
                            }
                          }}
                          onLocationClick={(locationObj) => {
                            setSelectedLocation(locationObj);
                          }}
                          onChangeRadius={(radiusInMiles: number) => {
                            if (boostSettingDefaults) {
                              const coordinates = boostSettingDefaults?.coordinates;
                              const currentObj = coordinates?.find((it) => it.lat === selectedLocation?.lat && it.lng === selectedLocation?.lng);
                              if (currentObj) {
                                currentObj.radius_in_meters = radiusInMiles / 0.00062;
                              }
                              setBoostSettingDefaults({ ...boostSettingDefaults });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className='wb-fields lzmConnections'>
                    <div className='wbf-item'>
                      <div className='wbf-label-main'>
                        <span className='wbf-label'>Connections</span>
                      </div>
                      <div className='wbf-inputs'>
                        <div className={`form-group`}>
                          {
                            FB_ADS_TARGET_CONNECTIONS_LIST.map((connectDatum, index) => {
                              return (
                                <label key={`fb-ads-connections-${index}`} className={`${connectDatum.name === boostSettingDefaults?.connections ? ` active` : ``}`}>
                                  <input type='radio' className='option-input radio' name={'connections'}
                                    value={connectDatum.name}
                                    checked={connectDatum.name === boostSettingDefaults?.connections ? true : false}
                                    onChange={(event) => setBoostSettingDefaults(boostSettingDefaults ?
                                      { ...boostSettingDefaults, [event.target.name]: event.target.value } : null)} />
                                  <span className='labelText'>{connectDatum.label}</span>
                                </label>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className="wb-base scl-controls wb-controls boxshadow-none"> */}
              <div className="settings__fb--btn r-flx r-flx-je">
                <CustomRippleButton
                  rippleClass={`ripple-unset ac-primary-box  ${handleDisableSaveChanges() || isSaveConfigChanges ? `events-none pointer-events-none` : ``}`}
                  custColor={RIPPLE_COLOR.primary}
                >
                  <button
                    className={`ac-btn ac-primary size-sm ${handleDisableSaveChanges() || isSaveConfigChanges ? `events-none pointer-events-none` : ``}`}
                    disabled={handleDisableSaveChanges() || isSaveConfigChanges}
                    onClick={() => handleSaveBoostSettingChanges()}
                  >
                    {handleDisableSaveChanges() ? `Cannot Save` : `Save Changes`}
                  </button>
                </CustomRippleButton>
              </div>
            </div>
          </>
        )}
      </div>
      <ModalPopup
        isModalShow={showDeleteModal}
        modalHeaderType={MODAL_BOX_TYPES.DANGER}
        containerClassName={'modal-confirmation confirm-delete'}
        modalBody={() => (
          <div>
            <div className="modal-message-wraps">{deleteItem.deleteMsg}</div>
          </div>
        )}
        modalCloseButton
        handleModalClose={() => {
          setShowDeleteModal(false);
          setDeleteItem({ deleteId: null, deleteMsg: null });
        }}
        isModalFooterShow
        modalFooter={() => (
          <div className="modal-btn-grp-wraps">
            <button
              className="modal-btn-action-itm modal-cancel-btn"
              onClick={() => {
                setShowDeleteModal(false);
                setDeleteItem({ deleteId: null, deleteMsg: null });
              }}
            >
              Cancel
            </button>
            <button className="modal-btn-action-itm modal-delete-btn" onClick={() => handleDisconnectFBAdsTargetConfirm()}>
              Ok
            </button>
          </div>
        )}
      />
      {showFBPageModal ? (
        <div className="rlc-pt-modal-main-wraps">
          <SettingsSocialModalPopup
            isModalShow={showFBPageModal}
            modalCloseButton
            handleModalClose={() => setShowFBPageModal(false)}
            modalHeader={() => (
              <>
                <h3 className={`aps-head`}>Please select an Ad Account</h3>
              </>
            )}
            modalHeaderType=""
            modalBody={() =>
              !facebookAccountDetail && isUserDataFetching && adAccountFetching ? (
                <Loading />
              ) : (
                (setstopSpinnerOnCancel(false), (<FBAdsFacebookViewPagesModalBody selectedFBPageId={selectedFBPageId} handleFbPageSelect={(id: string) => setSelectedFBPageId(id)} />))
              )
            }
            modalFooter={() => (
              <div className="apmodal modal-btn-grp-wraps ap-actions">
                <Button
                  // className="btnOutline modal-cancel-btn cancel cancel-shadow"
                  className="ac-btn ac-outline ac-secondary-white"
                  variant="outline-secondary"
                  onClick={() => {
                    setstopSpinnerOnCancel(true);
                    setShowFBPageModal(false);
                  }}
                >
                  Cancel
                </Button>
                {/* <CustomRippleButton rippleClass="ripple-cmn primary-boxshadow conn-mod-ripple" custColor={RIPPLE_COLOR.primary}> */}
                <CustomRippleButton rippleClass="ripple-unset ac-primary-box" custColor={RIPPLE_COLOR.primary}>
                  <Button
                    // className={`modal-conn primeblueaction events-none-opacity-${selectedFBPageId ? '' : 'pointfive'}`}
                    className={`ac-btn ac-primary events-none-opacity-${selectedFBPageId ? '' : 'pointfive'}`}
                    variant="primary"
                    onClick={() => {
                      dispatch(
                        updateAccFacebookAdAccountIdRequest({
                          accountId: id || 0,
                          userOwnership: userOwnership || '',
                          credentialId: facebookAccountDetail?.id || '',
                          adAccountId: selectedFBPageId || '',
                          callback: () => setShowFBPageModal(false)
                        })
                      );
                      setSelectedFBPageId(null);
                    }}
                  >
                    Connect Now
                  </Button>
                </CustomRippleButton>
              </div>
            )}
          />
        </div>
      ) : null}
    </>
  );
};
