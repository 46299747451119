import { aiReviewResponderReducerTypes, directoryListingsContainerTypes, reviewsContainerTypes } from 'types';
import { getFormattedNumber } from 'utils/helpers';
import { AI_REVIEW_STATS_SUBTITLES, AI_REVIEW_STATS_TITLE } from 'utils/constants';

export const getLocationListForDropdown = (locationListData: reviewsContainerTypes.IReviewsLocationObj[]): directoryListingsContainerTypes.ISubcategoriesObj[] => {
  return locationListData.reduce((acc: directoryListingsContainerTypes.ISubcategoriesObj[], curr) => {
    const locationListObj = {
      id: curr.id,
      value: curr.value,
      label: curr.label
    };
    acc = [...acc, locationListObj];
    return acc;
  }, []);
};

export const generateAIReviewStats = (aiReviewSettingsStats: aiReviewResponderReducerTypes.IAIReviewSettingsStats) => {
  return [
    {
      wrapClassName: 'fc-ai-locations',
      fcImg: 'location-green-stats-w',
      title: AI_REVIEW_STATS_TITLE.LOCATIONS,
      value: aiReviewSettingsStats.accounts_count,
      subTitle: `${AI_REVIEW_STATS_SUBTITLES.LOCATIONS}: ${getFormattedNumber(aiReviewSettingsStats.accounts_count)}`
    },
    {
      wrapClassName: 'fc-ai-responses',
      fcImg: 'stars-gold-stats-w',
      title: AI_REVIEW_STATS_TITLE.RESPONSES,
      value: aiReviewSettingsStats.replies_count,
      subTitle: `${AI_REVIEW_STATS_SUBTITLES.RESPONSES}: ${getFormattedNumber(aiReviewSettingsStats.replies_count)}`
    },
    { wrapClassName: 'fc-ai-hours', fcImg: 'time-orange-stats-w', title: AI_REVIEW_STATS_TITLE.HOURS_SAVED, value: aiReviewSettingsStats.hours_saved, subTitle: AI_REVIEW_STATS_SUBTITLES.HOURS_SAVED }
  ];
};
