import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';

// ACCOUNT SWITCHER
export const ssoAccountSwitchBrandRequest = (payload: { id: number }) => createAction(actionTypes.SSO_ACCOUNT_SWITCH_BRAND_REQUEST, payload);
export const ssoAccountSwitchBrandResponse = (payload: any) => createAction(actionTypes.SSO_ACCOUNT_SWITCH_BRAND_RESPONSE, payload);
export const ssoAccountSwitchBrandRequestFail = (error: string | null) => createAction(actionTypes.SSO_ACCOUNT_SWITCH_BRAND_REQUEST_FAIL, error);

export const setSwitchBrandStatus = (payload: boolean) => createAction(actionTypes.SET_SWITCH_BRAND_STATUS, payload);

const SSO_ACCOUNT_SWITCH_BRAND_ACTIONS = {
  ssoAccountSwitchBrandRequest,
  ssoAccountSwitchBrandResponse,
  ssoAccountSwitchBrandRequestFail,
  setSwitchBrandStatus
};

export type SSOAccountSwitchBrandActions = ActionsUnion<typeof SSO_ACCOUNT_SWITCH_BRAND_ACTIONS>;
