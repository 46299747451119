import { ActionsUnion, createAction } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { settingsHashTagsApiTypes } from 'types';

// SETTINGS HASHTAGS - GET HASHTAGS LIST
export const settingsGetHashTagsListRequest = (payload: settingsHashTagsApiTypes.IGetHashtags) => createAction(actionTypes.GET_HASHTAGS_LIST_REQUEST, payload);
export const settingsGetHashTagsListResponse = (payload: settingsHashTagsApiTypes.IUpdateHashtagsRes) => createAction(actionTypes.GET_HASHTAGS_LIST_RESPONSE, payload);
export const settingsGetHashTagsListFailure = (payload: string | null) => createAction(actionTypes.GET_HASHTAGS_LIST_FAILURE, payload);

// SETTINGS PARTNER BANNER - UPDATE PURCHASED BRANDS
export const createOrUpdateHashTagsListRequest = (payload: settingsHashTagsApiTypes.ICreateUpdateHashtagReq) => createAction(actionTypes.CREATE_OR_UPDATE_HASHTAGS_REQUEST, payload);
export const createOrUpdateHashTagsListResponse = (payload: settingsHashTagsApiTypes.IUpdateHashtagsRes) => createAction(actionTypes.CREATE_OR_UPDATE_HASHTAGS_RESPONSE, payload);
export const createOrUpdateHashTagsListFailure = (payload: string | null) => createAction(actionTypes.CREATE_OR_UPDATE_HASHTAGS_FAILURE, payload);

// SETTINGS HASHTAGS - RESET ALL
export const hashTagsResetAll = () => createAction(actionTypes.HASHTAGS_RESET_ALL);

const SETTINGS_HASHTAGS = {
  settingsGetHashTagsListRequest,
  settingsGetHashTagsListResponse,
  settingsGetHashTagsListFailure,
  createOrUpdateHashTagsListRequest,
  createOrUpdateHashTagsListResponse,
  createOrUpdateHashTagsListFailure,
  hashTagsResetAll
};

export type SettingsHashTagsAction = ActionsUnion<typeof SETTINGS_HASHTAGS>;
