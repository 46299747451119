import { Reducer } from 'redux';

import * as types from 'actions/action-types';
import { SSOAccountSwitchBrandActions } from 'actions';
import { ssoAccountSwitchBrandReducerTypes } from 'types';

const initialState: ssoAccountSwitchBrandReducerTypes.ISSOAccountSwitchBrandReducer = {
  isFetching: false,
  isSwitchBrand: false,
  ssoAccountSwitchBrand: null
};

const ssoAccountSwitchBrandReducer: Reducer<ssoAccountSwitchBrandReducerTypes.ISSOAccountSwitchBrandReducer, SSOAccountSwitchBrandActions> = (
  state: ssoAccountSwitchBrandReducerTypes.ISSOAccountSwitchBrandReducer = initialState,
  action: SSOAccountSwitchBrandActions
) => {
  switch (action.type) {
    case types.SET_SWITCH_BRAND_STATUS:
      return {
        ...state,
        isSwitchBrand: action.payload
      };
    case types.SSO_ACCOUNT_SWITCH_BRAND_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.SSO_ACCOUNT_SWITCH_BRAND_RESPONSE:
      return {
        ...state,
        isFetching: false,
        ssoAccountSwitchBrand: action.payload
      };
    case types.SSO_ACCOUNT_SWITCH_BRAND_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export { ssoAccountSwitchBrandReducer };
