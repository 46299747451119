import { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  AI_IOS_SUBSCRIPTION,
  AI_SUBSCRIPTION_PLAN_TYPES,
  AI_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS,
  AI_SUBSCRIPTION_UPGRADE_YEARLY_SUCCESS,
  ALERT_BOX_TYPES,
  FBBC_BRAND_SUBSCRIPTION_TAB,
  PostSourceType,
  USER_OWNERSHIP
} from 'utils/constants';
import { IStore } from 'types';
import { aiUpdateSubscriptionPlanRequest, setAISubscriptionDetailsModal, setSelectedSubscriptionTableData, setSubscription, setSubscriptionMainPageType } from 'actions';
import { ImageValidation } from 'widgets/Media';
import { alertBoxCall } from 'components/Common/AlertBox';
import { AICancelSusbcription } from './AICancelSusbcription';
import { PageTagInfoTooltip } from 'containers/Content/Creator/CommonModal';
import { useAccountSwitcherData, useAISubscriptionPlan } from 'utils/hooks';

export const AISubscriptionSelection = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();
  const { subscriptionPlan } = useAISubscriptionPlan();

  // const locationAvailableTree = useSelector((state: IStore) => state.operationsList.treeData);

  const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);
  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const selectedSusbcripitonActive = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.isActive);
  /* const availableTree = useSelector((state: IStore) =>
    subscriptionPlan === AI_SUBSCRIPTION_PLAN_TYPES.FBBC_BRAND
      ? userOwnership === USER_OWNERSHIP.ACCOUNT
        ? locationAvailableTree?.[0]?.children?.length
          ? locationAvailableTree?.[0]?.children?.map((obj) => ({ id: obj.id, name: obj.title, type: 'account' }))
          : []
        : [
            ...locationAvailableTree.map((obj) => ({ id: obj.id, name: obj.title, type: PostSourceType.HUB })),
            ...locationAvailableTree?.[0]?.children?.map((obj) => ({ id: obj.id, name: obj.title, type: 'account' }))
          ]
      : userOwnership === USER_OWNERSHIP.ACCOUNT && state.accountSwitcher.currentAccountOrFranchisor
      ? [{ id: state.accountSwitcher.currentAccountOrFranchisor.id, name: state.accountSwitcher.currentAccountOrFranchisor.name, type: 'account' }]
      : [
          ...state.accountSwitcher.franchisors.map((it) => ({ ...it, type: PostSourceType.HUB })),
          ...(state.accountSwitcher.accounts?.length
            ? state.accountSwitcher.accounts.map((it) => ({ ...it, type: 'account' }))
            : state.accountSwitcher.userOwnership === USER_OWNERSHIP.ACCOUNT && state.accountSwitcher.currentAccountOrFranchisor?.id
            ? [{ id: state.accountSwitcher.currentAccountOrFranchisor.id, name: state.accountSwitcher.currentAccountOrFranchisor.name, type: 'account' }]
            : [])
        ]
  ); */
  const availableTree = useSelector((state: IStore) =>
    subscriptionPlan === AI_SUBSCRIPTION_PLAN_TYPES.JBF_BRAND && userOwnership === USER_OWNERSHIP.ACCOUNT && state.accountSwitcher.currentAccountOrFranchisor
      ? [{ id: state.accountSwitcher.currentAccountOrFranchisor.id, name: state.accountSwitcher.currentAccountOrFranchisor.name, type: 'account' }]
      : [
          ...state.accountSwitcher.franchisors.map((it) => ({ ...it, type: PostSourceType.HUB })),
          ...(state.accountSwitcher.accounts?.length
            ? state.accountSwitcher.accounts.map((it) => ({ ...it, type: 'account' }))
            : state.accountSwitcher.userOwnership === USER_OWNERSHIP.ACCOUNT && state.accountSwitcher.currentAccountOrFranchisor?.id
            ? [{ id: state.accountSwitcher.currentAccountOrFranchisor.id, name: state.accountSwitcher.currentAccountOrFranchisor.name, type: 'account' }]
            : [])
        ]
  );

  const [searchText, setSearch] = useState<string>('');
  const [cancelSubsription, setCancelSubsription] = useState<any>(null);

  const selectedSubscription = useSelector((state: IStore) => state.aiContentCreator.selectedSubscription);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id || 0);
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  const selectedSubscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.data);
  const isActive = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.isActive);
  // const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const subscriptionType = useSelector((state: IStore) => state.aiContentCreator.subscriptionType);
  const topLevelFranchisorId = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.accountSwitcher?.franchisors?.map((it) => it.id).join(',') || '' : state.accountSwitcher?.accounts?.map((it) => it.id).join(',') || ''
  );
  const validYearlySubscription = useSelector((state: IStore) =>
    state.aiContentCreator.selectedSubscriptionTableData?.data?.filter((it) => {
      return it.user_id === activeUserId;
    })
  );
  const currentSubscriptionPlan = currentSubscription.find((datum) => datum.subscription_plan === selectedSubscriptionTableData?.[0]?.subscription_plan);

  const setSelected = (payload: any) => {
    dispatch(setSubscription(payload));
  };

  const unSelectedTree = useMemo(() => {
    return availableTree.filter((it) => !currentSubscription.some((datum) => datum.franchisor_id === it.id || datum.account_id === it.id));
  }, [availableTree, currentSubscription]);

  const handleCancelSusbcription = () => {
    if (cancelSubsription && activeUserId) {
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data: [cancelSubsription]
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            const updatedSusbcription = selectedSubscriptionTableData.filter((it) => {
              return cancelSubsription.id !== it.id;
            });
            dispatch(setSelectedSubscriptionTableData({ data: [...updatedSusbcription, cancelSubsription], isActive }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS);
          }
        })
      );
    }
  };

  return (
    <Card>
      {aiSubscriptionDetailsModal.type === 'newSubscription' ? (
        <div className="ai-pay-right-head">
          <span className="lca-head">Selected</span>
          {selectedSubscription.length > 0 && <span className="lca-sel-label">({selectedSubscription.length})</span>}
        </div>
      ) : (
        <div className="ai-pay-right-head">
          <span className="lca-head">Active Locations</span>
          {selectedSubscriptionTableData.length > 0 && <span className="lca-sel-label">({selectedSubscriptionTableData.length})</span>}
        </div>
      )}
      {availableTree?.length > 1 ? (
        <div className="aips-search-input">
          <input
            type="text"
            className="npsp-search-box"
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      ) : null}
      <div className="aips-hl-wraps">
        <ul className="hub-list">
          {availableTree?.length > 1 && !currentSubscription?.length ? (
            <li className="aips-select-all">
              <label className={`lc-acc-cnt ${selectedSubscription?.length === unSelectedTree?.length ? 'active' : ''}`}>
                <span className="checkbox-hover">
                  <input
                    value={'all'}
                    onChange={() => {
                      const subscriptions =
                        selectedSubscription?.length === unSelectedTree?.length
                          ? []
                          : [...unSelectedTree]?.map((it) => ({
                              franchisorId: it.type === PostSourceType.HUB ? it.id : null,
                              accountId: it.type !== PostSourceType.HUB ? it.id : null,
                              userId: activeUserId,
                              isChecked: true
                            }));
                      setSelected(subscriptions);
                    }}
                    type="checkbox"
                    className="option-input checkbox"
                    name="All"
                    checked={selectedSubscription?.length === unSelectedTree?.length}
                  />
                </span>
                <span className="labelText">Select All</span>
              </label>
            </li>
          ) : null}
          {availableTree?.length
            ? availableTree
                .filter((it) => (searchText ? it.name.toLowerCase().trim().includes(searchText.toLowerCase().trim()) : it))
                .filter((it) =>
                  aiSubscriptionDetailsModal.type === 'modifySubscription'
                    ? selectedSubscriptionTableData.some((datum) => datum[it.type === PostSourceType.HUB ? 'franchisor_id' : 'account_id'] === it.id)
                    : true
                )
                .map((it, index) => {
                  const currentHub = selectedSubscription.find((datum) => datum[it.type === PostSourceType.HUB ? 'franchisorId' : 'accountId'] === it.id);
                  const alreadySubscribed = currentSubscription.find((datum) => datum[it.type === PostSourceType.HUB ? 'franchisor_id' : 'account_id'] === it.id);
                  const isAutoRenewal = selectedSubscriptionTableData.find((datum) => datum[it.type === PostSourceType.HUB ? 'franchisor_id' : 'account_id'] === it.id)?.auto_renewal;
                  // const trialEnable = aiSubscription.find((item) => {
                  //   return item.account_id ? item.account_id === it.id : item.franchisor_id === it.id;
                  // });
                  return (
                    <li key={`${it.id}${index}`} className={`aips-options`}>
                      <label className={`lc-acc-cnt ${aiSubscriptionDetailsModal.type === 'modifySubscription' || alreadySubscribed ? 'ai-active' : currentHub?.isChecked ? 'active' : ''}`}>
                        <span className={`checkbox-hover`}>
                          <input
                            type="checkbox"
                            className="option-input checkbox"
                            name="selectedLocation"
                            onChange={() => {
                              if (!alreadySubscribed) {
                                const selectedHub = selectedSubscription.find((datum) => datum[it.type === PostSourceType.HUB ? 'franchisorId' : 'accountId'] === it.id);
                                if (selectedHub) {
                                  const remainingHubLocations = selectedSubscription.filter((datum) => datum[it.type === PostSourceType.HUB ? 'franchisorId' : 'accountId'] !== it.id);
                                  setSelected([...remainingHubLocations]);
                                } else {
                                  setSelected([
                                    ...selectedSubscription,
                                    {
                                      franchisorId: it.type === PostSourceType.HUB ? it.id : null,
                                      accountId: it.type !== PostSourceType.HUB ? it.id : null,
                                      userId: activeUserId,
                                      isChecked: true
                                    }
                                  ]);
                                }
                              }
                            }}
                            checked={currentHub?.isChecked || false}
                          />
                        </span>
                        <ImageValidation isImgValid defaultImg={it.type === PostSourceType.HUB ? 'hubs-active-new' : 'loc-icon-blue'} customName="location selector option" customClassname="hub" />
                        <div className={`list-lbl cur-pointer`}>
                          <div className="lsl-counts-wrap">
                            <span className="lcs-name" title={it.name}>
                              {it.name}
                            </span>
                          </div>
                        </div>
                      </label>
                      {alreadySubscribed?.subscription_device_type === 'IOS' && aiSubscriptionDetailsModal.type !== 'newSubscription' ? (
                        <PageTagInfoTooltip tooltipText={AI_IOS_SUBSCRIPTION} icon="infor-orange" />
                      ) : (
                        aiSubscriptionDetailsModal.type !== 'newSubscription' &&
                        selectedSusbcripitonActive &&
                        isAutoRenewal && (
                          <button
                            className={`ai-unsribe`}
                            onClick={() => {
                              setCancelSubsription({ ...alreadySubscribed, auto_renewal: false });
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        )
                      )}
                    </li>
                  );
                })
            : null}
        </ul>
        {aiSubscriptionDetailsModal.type === 'modifySubscription' && aiSubscription.length > 0 && (
          <div className="rai-btns rai-right-btns">
            <button
              className="ac-btn ac-secondary-white ac-outline upg__back--btn"
              onClick={() => {
                dispatch(setSubscriptionMainPageType('selection'));
                dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
              }}
            >
              <span>Back</span>
            </button>
            {currentSubscriptionPlan?.subscription_plan !== FBBC_BRAND_SUBSCRIPTION_TAB[2].subscription_plan && subscriptionPlan !== AI_SUBSCRIPTION_PLAN_TYPES.JBF_BRAND && (
              <button
                className="ac-btn ac-primary upg__back--btn"
                onClick={() =>
                  dispatch(
                    aiUpdateSubscriptionPlanRequest({
                      createParams: {
                        data: validYearlySubscription.map((it) => ({
                          ...it,
                          isUpgradeSusbcription: true,
                          subscription_period: subscriptionType?.value || 'month',
                          ...(subscriptionType?.type && { subscriptionType: subscriptionType?.type }),
                          ...(subscriptionPlan === AI_SUBSCRIPTION_PLAN_TYPES.FBBC_BRAND && { subscription_plan: subscriptionType?.subscription_plan }),
                          ...(subscriptionType?.franchisor_subscription_type_id && { franchisor_subscription_type_id: subscriptionType?.franchisor_subscription_type_id })
                        }))
                      },
                      getParams: {
                        id,
                        topLevelFranchisorId,
                        userOwnership,
                        userId: activeUserId
                      },
                      closeModal: () => {
                        dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
                        alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_UPGRADE_YEARLY_SUCCESS);
                      }
                    })
                  )
                }
              >
                <span>Upgrade</span>
              </button>
            )}
          </div>
        )}
      </div>
      {cancelSubsription && <AICancelSusbcription showDeleteModal={Boolean(cancelSubsription)} handleModalClose={() => setCancelSubsription(null)} handleCancel={handleCancelSusbcription} />}
    </Card>
  );
};
