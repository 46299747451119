import { MentionData } from '@draft-js-plugins/mention';

import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { apAccountApiTypes, apFranApiTypes, apFranContainerTypes, apFranReducerTypes, mgApiTypes, postsApiTypes, postsReducerTypes } from 'types';

// ADD POST - GET CONTENT POST DATA
export const addPostContentPostDataRequest = (payload: apAccountApiTypes.IAPGetContentPostDataReqPayload) => createAction(actionTypes.AP_GET_CONTENT_POST_DATA_REQUEST, payload);
export const addPostContentPostDataResponse = (data: null | postsApiTypes.IPostDetailContentPost) => createAction(actionTypes.AP_GET_CONTENT_POST_DATA_RESPONSE, data);
export const addPostContentPostDataFailure = (error: null | string) => createAction(actionTypes.AP_GET_CONTENT_POST_DATA_FAILURE, error);

// ADD POST - UPDATE CONTENT
export const addPostUpdateContentRequest = (payload: apFranApiTypes.IAPSaveDaftRequestPayload) => createAction(actionTypes.AP_UPDATE_CONTENT_REQUEST, payload);
export const addPostUpdateContentResponse = (data: postsApiTypes.IPostDetailContentPost) => createAction(actionTypes.AP_UPDATE_CONTENT_RESPONSE, data);
export const addPostUpdateContentFailure = (error: null | string) => createAction(actionTypes.AP_UPDATE_CONTENT_FAILURE, error);

// ADD POST - GET LOCATION IDS BY SELECTING HUB
export const addPostGetLocIdsBasedOnHubRequest = (payload: number) => createAction(actionTypes.AP_GET_LOC_IDS_BASED_ON_HUB_REQUEST, payload);
export const addPostGetLocIdsBasedOnHubResponse = (data: number[]) => createAction(actionTypes.AP_GET_LOC_IDS_BASED_ON_HUB_RESPONSE, data);
export const addPostGetLocIdsBasedOnHubFailure = (error: null | string) => createAction(actionTypes.AP_GET_LOC_IDS_BASED_ON_HUB_FAILURE, error);

// ADD POST - GET LOCATION DETAILS BY SELECTING LOC
export const addPostGetLocDetailBasedOnLocRequest = (payload: number) => createAction(actionTypes.AP_GET_LOC_DETAIL_BASED_ON_LOC_REQUEST, payload);
export const addPostGetLocDetailBasedOnLocResponse = (data: apFranApiTypes.IAPFranAvailSelectLoc) => createAction(actionTypes.AP_GET_LOC_DETAIL_BASED_ON_LOC_RESPONSE, data);
export const addPostGetLocDetailBasedOnLocFailure = (error: null | string) => createAction(actionTypes.AP_GET_LOC_DETAIL_BASED_ON_LOC_FAILURE, error);

// ADD POST - ADD SCHEDULED MULTIPOSTS
export const addPostAddScheduledMultipostsRequest = (payload: apFranApiTypes.IAPScheduledMultipostsReq) => createAction(actionTypes.AP_ADD_SCHEDULED_MULTIPOSTS_REQUEST, payload);
export const addPostAddScheduledMultipostsResponse = (data: number) => createAction(actionTypes.AP_ADD_SCHEDULED_MULTIPOSTS_RESPONSE, data);
export const addPostAddScheduledMultipostsFailure = (error: null | string) => createAction(actionTypes.AP_ADD_SCHEDULED_MULTIPOSTS_FAILURE, error);

// ADD POST - MASS UPDATE FOR SCHEDULED POST
export const addPostMassUpdateForScheduledPostRequest = (payload: number) => createAction(actionTypes.AP_MASS_UPDATE_FOR_SCHEDULED_POST_REQUEST, payload);
export const addPostMassUpdateForScheduledPostResponse = (data: null) => createAction(actionTypes.AP_MASS_UPDATE_FOR_SCHEDULED_POST_RESPONSE, data);
export const addPostMassUpdateForScheduledPostFailure = (error: null | string) => createAction(actionTypes.AP_MASS_UPDATE_FOR_SCHEDULED_POST_FAILURE, error);

// ADD POST - GET SELECTED CONTENT OBJ
export const addPostGetSelectedContentObj = (payload: apFranReducerTypes.IAddPostFranContentObj) => createAction(actionTypes.AP_GET_SELECTED_CONTENT_OBJ, payload);

// ADD POST - GET SELECTED FB TAG LIST
export const addPostGetSelectedFBTagList = (payload: MentionData[]) => createAction(actionTypes.AP_GET_SELECTED_FB_TAG_LIST, payload);

// ADD POST - UPDATE CONTENT OBJ
export const addPostUpdateContentObj = (data: postsReducerTypes.IPostDetailsData) => createAction(actionTypes.AP_UPDATE_CONTENT_OBJ, data);

// ADD POST - SET SELECTED MEDIA LIST
export const addPostSetSelectedMediaList = (data: mgApiTypes.IMGListResponseObj[]) => createAction(actionTypes.AP_SET_SELECTED_MEDIA_LIST, data);

// ADD POST - SET SELECTED LOC LIST DATA
export const addPostSetSelectedLocListData = (data: postsApiTypes.IPostListDetailData[]) => createAction(actionTypes.AP_SET_SELECTED_LOC_LIST_DATA, data);

// ADD POST - SET  FRANCHISOR PAGE TYPE
export const addPostSetFranPageType = (data: string) => createAction(actionTypes.AP_FRAN_SET_PAGE_TYPE, data);

// ADD POST - SET CONTENT SAVED DATE
export const addPostSetContentSavedDate = (data: apFranReducerTypes.IAPContentSavedDate) => createAction(actionTypes.AP_FRAN_SET_SAVED_DATE, data);

export const addPostUseMediaContentData = (data: null | apFranContainerTypes.IUseMediaContentData) => createAction(actionTypes.USE_MEDIA_CONTENT_DATA, data);

// ADD POST RESET ALL
export const addPostPreviousPackageDataReset = () => createAction(actionTypes.AP_PREVIOUS_PACKAGE_DATA_RESET);
export const addPostScheduledContentReset = () => createAction(actionTypes.AP_SCHEDULED_CONTENT_RESET);
export const addPostFranUpdatedMsgReset = () => createAction(actionTypes.AP_FRAN_UPDATED_MSG_RESET);
export const addPostFranSelectedMediaReset = () => createAction(actionTypes.AP_SET_FRAN_SELECTED_MEDIA_LIST_RESET);
export const addPostFranResetAll = () => createAction(actionTypes.AP_FRAN_RESET_ALL);

// GET FRANCHISOR SYNDICATION
export const getFranchisorSyndicationRequest = (data: Omit<apFranReducerTypes.IFranchisorSyndRequest, 'franchisor_ids'>) => createAction(actionTypes.GET_FRANCHISOR_SYNDICATION_REQUEST, data);
export const getFranchisorSyndicationResponse = (data: apFranReducerTypes.IPostSyndicationDetails[]) => createAction(actionTypes.GET_FRANCHISOR_SYNDICATION_RESPONSE, data);
export const getFranchisorSyndicationFailure = () => createAction(actionTypes.GET_FRANCHISOR_SYNDICATION_FAILURE);

// ADD FRANCHISOR SYNDICATION
export const addFranchisorSyndicationRequest = (data: apFranReducerTypes.IFranchisorSyndRequest) => createAction(actionTypes.ADD_FRANCHISOR_SYNDICATION_REQUEST, data);
export const addFranchisorSyndicationResponse = (data: null | string) => createAction(actionTypes.ADD_FRANCHISOR_SYNDICATION_RESPONSE, data);
export const addFranchisorSyndicationFailure = () => createAction(actionTypes.ADD_FRANCHISOR_SYNDICATION_FAILURE);

// SELECT SYNDICATE TO FRANCHISOR BRAND
export const selectFranchisorToSyndicate = (data: number[]) => createAction(actionTypes.SELECT_SYNDICATE_TO_FRANCHISOR, data);

// RESET FRANCHISOR SYNDICATION
export const franchisorSyndicationResetAll = () => createAction(actionTypes.FRANCHISOR_SYNDICATION_RESET_ALL);

// GET SM VIDEO COMPATIBILITY
export const getVideoCompatibilityRequest = (payload: string | null) => createAction(actionTypes.AP_GET_VIDEO_COMPATIBILITY_REQUEST, payload);
export const getVideoCompatibilityResponse = (data: apFranReducerTypes.IVideoCompatibilityResponse) => createAction(actionTypes.AP_GET_VIDEO_COMPATIBILITY_RESPONSE, data);
export const getVideoCompatibilityFailure = () => createAction(actionTypes.AP_GET_VIDEO_COMPATIBILITY_FAILURE);

// RESET SM COMPATIBILITY
export const videoCompatibilityReset = () => createAction(actionTypes.AP_VIDEO_COMPATIBILITY_RESET);

// SELECTED_MEDIA_LIST_SORT
export const selectedMediaListSort = (payload: any) => createAction(actionTypes.SELECTED_MEDIA_LIST_SORT, payload);

// ADD CUSTOM VIDEO THUMBNAIL
export const addCustomizeVideoThumbnailRequest = (payload: { id: number; cover_cloudinary_id: string; thumbnail_url: string }) =>
  createAction(actionTypes.AP_ADD_CUSTOM_VIDEO_THUMBNAIL_REQUEST, payload);

// ADD FRANCHISOR MULTI SYNDICATION
export const addFranchisorMultiSyndicationRequest = (payload: apFranReducerTypes.IFranMultiSyndicationReq) => createAction(actionTypes.ADD_FRANCHISOR_MULTI_SYNDICATION_REQUEST, payload);

// ADD FRANCHISOR MULTI SYNDICATION
export const addFranchisorUpdateMultiTagsRequest = (payload: apFranReducerTypes.IUpdateMultiSelectTagsReq) => createAction(actionTypes.UPDATE_MULTI_SELECT_TAGS_REQUEST, payload);
export const addFranchisorUpdateMultiTagsResponse = (message: string) => createAction(actionTypes.UPDATE_MULTI_SELECT_TAGS_RESPONSE, message);
export const addFranchisorUpdateMultiTagsFailure = (err: string | null) => createAction(actionTypes.UPDATE_MULTI_SELECT_TAGS_FAILURE, err);

// SELECT SYNDICATE TO FRANCHISOR BRAND
export const selectCountriesToSyndicate = (data: string[]) => createAction(actionTypes.SELECT_SYNDICATE_TO_COUNTRIES, data);

// RESET - MEDIA EXPIRATION
export const resetApMediaExpiration = () => createAction(actionTypes.AP_RESET_MEDIA_EXPIRATION);

const ADD_POST_FRANCHISOR = {
  addPostContentPostDataRequest,
  addPostContentPostDataResponse,
  addPostContentPostDataFailure,
  addPostUpdateContentRequest,
  addPostUpdateContentResponse,
  addPostUpdateContentFailure,
  addPostGetLocIdsBasedOnHubRequest,
  addPostGetLocIdsBasedOnHubResponse,
  addPostGetLocIdsBasedOnHubFailure,
  addPostGetLocDetailBasedOnLocRequest,
  addPostGetLocDetailBasedOnLocResponse,
  addPostGetLocDetailBasedOnLocFailure,
  addPostAddScheduledMultipostsRequest,
  addPostAddScheduledMultipostsResponse,
  addPostAddScheduledMultipostsFailure,
  addPostMassUpdateForScheduledPostRequest,
  addPostMassUpdateForScheduledPostResponse,
  addPostMassUpdateForScheduledPostFailure,
  addPostGetSelectedContentObj,
  addPostGetSelectedFBTagList,
  addPostUpdateContentObj,
  addPostSetSelectedMediaList,
  addPostSetSelectedLocListData,
  addPostSetFranPageType,
  addPostSetContentSavedDate,
  addPostUseMediaContentData,
  addPostPreviousPackageDataReset,
  addPostScheduledContentReset,
  addPostFranUpdatedMsgReset,
  addPostFranSelectedMediaReset,
  addPostFranResetAll,
  addFranchisorSyndicationRequest,
  addFranchisorSyndicationResponse,
  addFranchisorSyndicationFailure,
  getFranchisorSyndicationRequest,
  getFranchisorSyndicationResponse,
  getFranchisorSyndicationFailure,
  selectFranchisorToSyndicate,
  franchisorSyndicationResetAll,
  getVideoCompatibilityRequest,
  getVideoCompatibilityResponse,
  getVideoCompatibilityFailure,
  selectedMediaListSort,
  videoCompatibilityReset,
  addCustomizeVideoThumbnailRequest,
  addFranchisorMultiSyndicationRequest,
  addFranchisorUpdateMultiTagsRequest,
  addFranchisorUpdateMultiTagsResponse,
  addFranchisorUpdateMultiTagsFailure,
  selectCountriesToSyndicate,
  resetApMediaExpiration
};

export type AddPostFranchisorActions = ActionsUnion<typeof ADD_POST_FRANCHISOR>;
