export const AI_REVIEW_RESPONDER_TABS = {
  REVIEW_RESPONSE: 'review_response',
  REVIEW_AUTOMATION: 'review_automation'
};

export const AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES = {
  STATUS: 'status',
  NAME: 'name',
  SERIAL_NO: 's.no'
};

export const AI_AUTO_RESPONDER_LOCATION_HEADER = [
  { label: 'S.No', value: AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES.SERIAL_NO },
  { label: 'Name', value: AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES.NAME },
  { label: 'Status', value: AI_AUTO_RESPONDER_LOCATION_HEADER_VALUES.STATUS }
];

export const AI_REVIEW_STATS_TITLE = {
  LOCATIONS: 'Locations',
  RESPONSES: 'Responses',
  HOURS_SAVED: 'Hours Saved'
};

export const AI_REVIEW_STATS_SUBTITLES = {
  LOCATIONS: 'Number of locations with auto responses enabled',
  RESPONSES: 'Total number of automated responses generated',
  HOURS_SAVED: 'Estimated time saved in person-hours, assuming each response would take 1 minute to create by hand.'
};

export const AI_REVIEW_STATS_VALUES = {
  LOCATIONS: 'accounts_count',
  RESPONSES: 'replies_count',
  HOURS_SAVED: 'hours_saved'
};
