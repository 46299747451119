import { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CreatePostStats } from './CreatePostStats';
import { LeftCreatorPostSection } from './LeftCreatorPostSection';
import { RightCreatorPreviewSection } from './RightCreatorPreviewSection';
import { IStore } from 'types';
import { alertBoxCall, BrandSchedulePostModal, Loading } from 'components/Common';
import { convertStringToEditorState, getContentPostReqPayload, getFranApAddContentList, getPreviewBrandHubCreatorActions } from 'utils/helpers';
import { useAccountSwitcherData, useActivePlatform, useNavBarData } from 'utils/hooks';
import {
  addPostSetFranPageType,
  addPostContentPostDataRequest,
  addPostPreviousPackageDataReset,
  addPostActionsResetAll,
  addPostResetAll,
  addPostFranUpdatedMsgReset,
  addPostScheduledContentReset,
  addPostSetContentSavedDate,
  toggleFilter,
  franchisorScheduleTreeReset,
  setCreatorModalName,
  setAIPostContentOpen,
  resetAIContentCreator,
  resetAIImageGenerationImages,
  videoCompatibilityReset,
  setCreatorTextAlertMsg,
  contentSupplierGetCorpFranchisorsListRequest,
  addPostUpdateCustomTextEditor,
  addPostGetSelectedContentObj
} from 'actions';
import {
  ADD_POST_APPOVED_BUT_NOT_SCHEDULE_SUCCESS_MSG,
  ALERT_BOX_TYPES,
  AP_ACTION_TYPES,
  ADD_POST_APPROVE_AND_SYNDICATE_MSG,
  AP_FRAN_INIT_CONTENT_SAVED_DATE,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  AP_FRAN_MODAL_NAMES,
  SIDE_NAV_NAME,
  FranchisorSyndicationMediaType,
  SettingsHashtagsActionsVal,
  HASHTAG_BLOCK_DEFAULT_SPACING,
  AP_CONTENT_INIT_OBJ
} from 'utils/constants';
import { EditorStateContext } from '..';
import { FranSyndicatePostModal } from './Modals';
import { AIContents } from '../AIContent';

export const BrandOrHubCreatePost = () => {
  const dispatch = useDispatch();

  const { id, userOwnership } = useAccountSwitcherData();
  const { subNavPageName } = useNavBarData();
  const activePlatforms = useActivePlatform();

  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const pageType = useSelector((state: IStore) => state.addPostFranchisor.pageType);
  const mobileFilter = useSelector((state: IStore) => state.common.mobileFilter);
  const contentSuccessMsg = useSelector((state: IStore) => state.addPostFranchisor.updatedMessage.contentSuccessMsg);
  const error = useSelector((state: IStore) => state.addPostFranchisor.error);
  const isSaveDraftFetching = useSelector((state: IStore) => state.addPostFranchisor.isSaveDraftFetching);
  const isApproveAndSaveToDBFetching = useSelector((state: IStore) => state.addPostFranchisor.isApproveAndSaveToDBFetching);
  const isApproveAndSyndicateFetching = useSelector((state: IStore) => state.addPostFranchisor.isApproveAndSyndicateFetching);
  const isCampaignFetching = useSelector((state: IStore) => state.addPostFranchisor.isCampaignFetching);
  const isPostFetching = useSelector((state: IStore) => state.addPostFranchisor.isPostFetching);
  const creatorModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorModalName);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const creatorTextAlertMsg = useSelector((state: IStore) => state.common.creatorTextAlertMsg);
  const isContentSupplier = useSelector((state: IStore) => state.franchisors.franchisorDetails?.content_supplier);
  const isCaptionValidating = useSelector((state: IStore) => state.aiContentCreator.isCaptionValidating);
  const isAICaptionTranslating = useSelector((state: IStore) => state.addPostFranchisorAction.isAICaptionTranslating);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const isContentUpdate = isSaveDraftFetching || isApproveAndSaveToDBFetching || isApproveAndSyndicateFetching || isCampaignFetching || isPostFetching || isCaptionValidating || isAICaptionTranslating;

  const editorStateArray = useContext(EditorStateContext);
  const setEditorState = editorStateArray[1];
  const addSavedHashtags = Boolean(hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length);

  const addContentList = useMemo(() => {
    return getFranApAddContentList(contentObj.customText) || [];
  }, [contentObj.customText]);

  const previewList = useMemo(() => {
    return getPreviewBrandHubCreatorActions(contentObj.postStatus) || [];
  }, [contentObj.postStatus]);

  useEffect(() => {
    if (contentSuccessMsg && ![ADD_POST_APPOVED_BUT_NOT_SCHEDULE_SUCCESS_MSG, ADD_POST_APPROVE_AND_SYNDICATE_MSG].includes(contentSuccessMsg)) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, contentSuccessMsg);
      handleCreatorResetAll();
    }
  }, [contentSuccessMsg]); // eslint-disable-line

  useEffect(() => {
    if (contentSuccessMsg && mobileFilter) {
      dispatch(toggleFilter());
    }
  }, [contentSuccessMsg, mobileFilter]); // eslint-disable-line

  useEffect(() => {
    if (error) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, error);
      dispatch(addPostFranUpdatedMsgReset());
    }
  }, [error]); // eslint-disable-line

  useEffect(() => {
    if (isContentSupplier) {
      dispatch(contentSupplierGetCorpFranchisorsListRequest({ id }));
    }
  }, [isContentSupplier, id, dispatch]);

  const handleCreatorResetAll = () => {
    const defaultHashtagTextBlock = `${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState.saved_hashtags.join(' ')}`;
    if (isAiContentOpen) dispatch(setAIPostContentOpen(false));
    if (creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
    dispatch(resetAIContentCreator());
    dispatch(resetAIImageGenerationImages());
    if (pageType === AP_ACTION_TYPES.EDIT) dispatch(addPostSetFranPageType(AP_ACTION_TYPES.ADD));
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_CREATOR && pageType === AP_ACTION_TYPES.ADD) {
      const reqPayload = getContentPostReqPayload(id, userOwnership);
      dispatch(addPostContentPostDataRequest(reqPayload));
    }
    dispatch(addPostPreviousPackageDataReset());
    dispatch(addPostScheduledContentReset());
    dispatch(franchisorScheduleTreeReset());
    dispatch(addPostResetAll());
    dispatch(addPostActionsResetAll());
    dispatch(addPostSetContentSavedDate(AP_FRAN_INIT_CONTENT_SAVED_DATE));
    dispatch(videoCompatibilityReset());
    setEditorState(addSavedHashtags ? { ...AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT, [activePlatforms[0]]: convertStringToEditorState(defaultHashtagTextBlock) } : AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT);
    dispatch(
      addPostGetSelectedContentObj({
        ...AP_CONTENT_INIT_OBJ,
        ...(addSavedHashtags && {
          customText: { ...AP_CONTENT_INIT_OBJ.customText, [activePlatforms[0]]: defaultHashtagTextBlock },
          postStatus: { ...AP_CONTENT_INIT_OBJ.postStatus, [activePlatforms[0]]: true }
        })
      })
    );
    if (addSavedHashtags) {
      dispatch(addPostUpdateCustomTextEditor({ key: activePlatforms[0], value: convertStringToEditorState(defaultHashtagTextBlock) }));
    }
  };

  const setModalName = (data: string | null) => dispatch(setCreatorModalName(data));

  return (
    <>
      <div
        // className={`mainContent content__creator--main createpost-wrp-new-section rcpm lpx vpy-20 hubuser-createpost-new-section ref-cpm create-post__main${isContentUpdate ? ` pointer-events-none` : ''
        //   }`}
        className={`mainContent content__creator--main  rcpm lpx vpy-20  ref-cpm mbl-head--margin ${isContentUpdate ? ` pointer-events-none` : ''}`}
      >
        {isContentUpdate ? <Loading /> : null}
        <CreatePostStats />
        <div className="addpost-section-main-wrp creator__main--content">
          <LeftCreatorPostSection activeContentList={addContentList} />
          {isAiContentOpen ? <AIContents editedContent={contentObj.customText[contentObj.currentTab] || ''} /> : <RightCreatorPreviewSection accessibleSocialMedia={previewList} />}
        </div>
      </div>
      {creatorModalName === AP_FRAN_MODAL_NAMES.SCHEDULE ? (
        <BrandSchedulePostModal isModalShow handleModalClose={() => setModalName(null)} />
      ) : creatorModalName === AP_FRAN_MODAL_NAMES.SYNDICATE ? (
        <FranSyndicatePostModal isModalShow handleModalClose={() => setModalName(null)} mediaType={FranchisorSyndicationMediaType.CONTENT} />
      ) : null}
    </>
  );
};
