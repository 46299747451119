import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { config } from 'config';
import { ReactVideoPlayer } from 'widgets/Media';
import { IStore } from 'types';
import {
  CONNECTION_TABS,
  RIPPLE_COLOR,
  VIDEO_INTRO_TDC_AGENCIES_ENGLISH,
  VIDEO_INTRO_TDC_AGENCIES_FRENCH,
  VIDEO_INTRO_TDC_AGENTS_ENGLISH,
  VIDEO_INTRO_TDC_AGENTS_FRENCH,
  VIDEO_INTRO_DEFAULT_LANG,
  BROWSER_LANGUAGE_FRENCH
} from 'utils/constants';
import { useAccountSwitcherData, useParamsDeconstructor } from 'utils/hooks';
import { CustomRippleButton } from 'widgets/CustomRippleButton';

export const VideoIntroduction = () => {
  const navigate = useNavigate();

  const { userOwnership, id, currentRoute } = useAccountSwitcherData();
  const { queryParams } = useParamsDeconstructor();

  const accountId = useSelector((state: IStore) => state.accounts.accountDetails?.account?.id || 0);
  const topLevelFranchisorId = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisorId);

  // Detect browser language
  let userLang = navigator.language;
  userLang = userLang.split('-')[0]; // Get the main language code

  let videoURL = VIDEO_INTRO_DEFAULT_LANG;
  if (topLevelFranchisorId === Number(config.TdcAgenciesId) && BROWSER_LANGUAGE_FRENCH.includes(userLang)) {
    videoURL = VIDEO_INTRO_TDC_AGENCIES_FRENCH;
  } else if (topLevelFranchisorId === Number(config.TdcAgentsId) && BROWSER_LANGUAGE_FRENCH.includes(userLang)) {
    videoURL = VIDEO_INTRO_TDC_AGENTS_FRENCH;
  } else if (topLevelFranchisorId === Number(config.TdcAgenciesId) && !BROWSER_LANGUAGE_FRENCH.includes(userLang)) {
    videoURL = VIDEO_INTRO_TDC_AGENCIES_ENGLISH;
  } else if (topLevelFranchisorId === Number(config.TdcAgentsId) && !BROWSER_LANGUAGE_FRENCH.includes(userLang)) {
    videoURL = VIDEO_INTRO_TDC_AGENTS_ENGLISH;
  }

  const handleNext = () => {
    navigate({
      pathname: `/${userOwnership}/${id.toString()}/${currentRoute}/account_wizard/${accountId.toString()}/${CONNECTION_TABS.RALLIO_PROFILE}`,
      search: `?${new URLSearchParams(queryParams).toString()}`
    });
  };

  return (
    <div className="mainContent animate__animated animate__fadeIn social-profile-details-wrp settings__main lpx vpy-20 vicWrap vIntro">
      <div className="settings-main-wrp">
        <div className="frameModule settings">
          <div className="csp">
            <div className="video-outer-element">
              <ReactVideoPlayer isPlayControl light={`${config.cdnImgUrl}welcomeRallio.png`} muted videoUrl={videoURL} customClassname="m-ast-itm m-ast-video" />
            </div>
          </div>
        </div>
      </div>

      <div className="stg-item form-configure">
        <div className="pAgreement">
          <p>
            By clicking Next, you agree to our{' '}
            <a href="https://www.rallio.com/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://www.rallio.com/terms-of-use/" target="_blank" rel="noreferrer">
              Terms of Use.
            </a>
          </p>
        </div>
        <div className="modal-btn-grp-wraps csp-action ap-actions">
          <CustomRippleButton rippleClass={`ripple-unset ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
            <button className={`ac-btn ac-primary size-xs`} onClick={() => handleNext()}>
              Next
            </button>
          </CustomRippleButton>
        </div>
      </div>
    </div>
  );
};
