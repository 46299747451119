import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { AISubscriptionPayment } from './AISubscriptionPayment';
import {
  aiStripeCustomerRequest,
  aiUpdateSubscriptionPlanRequest,
  setAISubscriptionDetailsModal,
  setAISubscriptionType,
  setSubscription,
  setSubscriptionMainPageType,
  stripeCardsListRequest
} from 'actions';
import { IStore, aiContentCreatorReducerTypes } from 'types';
import {
  DATE_FORMAT,
  ALERT_BOX_TYPES,
  AI_SUBSCRIPTION_CANCEL_SUCCESS,
  AI_SUBSCRIPTION_RENEW_FAIL,
  MONTH_DATE_YEAR_FORMAT,
  AI_SUBSCRIPTION_RENEW_SUCCESS,
  USER_OWNERSHIP,
  AI_SUBSCRIPTION_PLAN_TYPES,
  JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN,
  JBF_BRAND_SIX_MONTH_REGULAR_PLAN
} from 'utils/constants';
import { getDateTimeAfterAWeek, getFormattedNumber, isDateInBetweenTwoDates } from 'utils/helpers';
import { useAccountSwitcherData, useAISubscriptionPlan } from 'utils/hooks';
import { AISubscriptionSelection } from './AISubscritpionSelection';
import { alertBoxCall } from 'components/Common/AlertBox';
import { utcToLocalHandler } from 'analytics/utils';
import { AICancelSusbcription } from './AICancelSusbcription';

export const AISubscriptionMainPage = () => {
  const dispatch = useDispatch();
  const { id, userOwnership } = useAccountSwitcherData();
  const { subscriptionPlan, planOptions } = useAISubscriptionPlan();

  const aiSubscription = useSelector((state: IStore) => state.aiContentCreator.aiSubscription);
  const subscriptionType = useSelector((state: IStore) => state.aiContentCreator.subscriptionType);
  const currentSubscription = useSelector((state: IStore) => state.aiContentCreator.currentSubscription);
  const selectedSubscription = useSelector((state: IStore) => state.aiContentCreator.selectedSubscription);
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  const activeUserEmail = useSelector((state: IStore) => state.login.activeUser?.email);
  const selectedSubscriptionTableData = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.data);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const aiSubscriptionMainPageType = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionMainPageType);
  const topLevelFranchisorId = useSelector((state: IStore) =>
    userOwnership === USER_OWNERSHIP.FRANCHISOR ? state.accountSwitcher?.franchisors?.map((it) => it.id).join(',') || '' : state.accountSwitcher?.accounts?.map((it) => it.id).join(',') || ''
  );
  const selectedSusbcripitonActive = useSelector((state: IStore) => state.aiContentCreator.selectedSubscriptionTableData.isActive);
  // const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const { customerObj, cardsListArray } = useSelector((state: IStore) => state.stripe);
  const isRallioContractSubscription = useSelector((state: IStore) => state.accountSwitcher.rallio_subscription_contract);
  const isPeersSubscription = useSelector((state: IStore) => state.accountSwitcher.peers_subscription);

  const upgradablePlans =
    aiSubscriptionDetailsModal.type === 'modifySubscription' && selectedSubscriptionTableData?.length // old (isAISubscribed && currentSubscription?.length) || aiSubscriptionDetailsModal.type === 'modifySubscription'
      ? planOptions.filter((_, index) => index > planOptions.findIndex((plan) => plan?.subscription_plan === selectedSubscriptionTableData?.[0]?.subscription_plan))
      : planOptions;

  const [isCancelSubscription, setCancelSusbcription] = useState<boolean>(false);

  const isRenewSubscription = !selectedSusbcripitonActive
    ? selectedSubscriptionTableData
        ?.filter((it) => !currentSubscription.some((datum) => datum.franchisor_id === it.franchisor_id && datum.account_id === it.account_id))
        .filter((it) => !it.auto_renewal)?.length > 0
    : selectedSubscriptionTableData.filter((it) => it.auto_renewal)?.length === 0;

  const setView = (type: 'selection' | 'payment') => {
    dispatch(setSubscriptionMainPageType(type));
  };

  useEffect(() => {
    if (activeUserEmail && aiSubscriptionDetailsModal.type !== 'modifySubscription') {
      dispatch(aiStripeCustomerRequest(activeUserEmail || null));
    }
  }, [activeUserEmail, aiSubscriptionDetailsModal, dispatch]);

  useEffect(() => {
    if (customerObj?.id && !cardsListArray.length) {
      dispatch(stripeCardsListRequest(customerObj.id));
    }
  }, [customerObj?.id && cardsListArray.length]); // eslint-disable-line

  useEffect(() => {
    if (isRallioContractSubscription && subscriptionPlan === AI_SUBSCRIPTION_PLAN_TYPES.JBF_BRAND) {
      dispatch(setAISubscriptionType(isPeersSubscription ? JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN : JBF_BRAND_SIX_MONTH_REGULAR_PLAN));
    }
  }, [selectedSubscription, isPeersSubscription, isRallioContractSubscription, currentSubscription, subscriptionPlan, dispatch]);

  const handleCancelSusbcription = () => {
    const validSusbcription = selectedSubscriptionTableData.filter((it) => {
      return it.user_id === activeUserId && !it?.subscription_device_type;
    });
    if (validSusbcription?.length && activeUserId) {
      const data = validSusbcription.map((it) => ({ ...it, auto_renewal: false }));
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_CANCEL_SUCCESS);
          }
        })
      );
    }
  };

  const handleRenewSubscription = () => {
    const validSusbcription = selectedSubscriptionTableData.filter((it) => {
      return it.user_id === activeUserId;
    });
    const isUpdateAutoRenewal = isDateInBetweenTwoDates(moment(validSusbcription[0]?.start_date), moment(validSusbcription[0]?.end_date), moment());
    if (validSusbcription?.length && isUpdateAutoRenewal && activeUserId) {
      dispatch(
        aiUpdateSubscriptionPlanRequest({
          createParams: {
            data: validSusbcription.map((it) => ({ ...it, auto_renewal: true }))
          },
          getParams: {
            id,
            topLevelFranchisorId,
            userOwnership,
            userId: activeUserId
          },
          closeModal: () => {
            dispatch(setAISubscriptionDetailsModal({ type: 'susbcriptionTable', isOpen: true }));
            alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_RENEW_SUCCESS);
          }
        })
      );
    } else {
      handleExpiredRenewSusbcription();
    }
  };

  const handleExpiredRenewSusbcription = () => {
    const validSubscription = selectedSubscriptionTableData.filter(
      (it) => it.user_id === activeUserId && !currentSubscription.some((datum) => datum.franchisor_id === it.franchisor_id && datum.account_id === it.account_id)
    );
    if (validSubscription?.length && activeUserId) {
      dispatch(
        setSubscription(
          validSubscription.map(
            (it) =>
              ({
                accountId: it.account_id,
                franchisorId: it.franchisor_id,
                isChecked: true,
                userId: activeUserId
              } as aiContentCreatorReducerTypes.IAISetSusbscripiton)
          )
        )
      );
      setView('payment');
    } else {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, AI_SUBSCRIPTION_RENEW_FAIL);
    }
  };

  return (
    <>
      <Modal.Header>
        <div className="rai-sub-top rai-head">
          <h2>{isRallioContractSubscription ? 'Rallio Subscription' : 'Rallio AI Subscription'}</h2>
          {(aiSubscriptionDetailsModal.type !== 'modifySubscription' || aiSubscriptionMainPageType === 'payment') && (
            <p className="ai__sub--txt">
              {isRallioContractSubscription
                ? `Jump in and experience how simple, easy, and fun your social media suddenly becomes with Rallio!.`
                : `Jump in and experience how simple, easy, and fun your social media suddenly becomes with Rallio AI! Try it for free for a week and get ready to fall in love! Want to save 25%? Click the
              yearly option.`}
            </p>
          )}

          {aiSubscriptionDetailsModal.type === 'modifySubscription' && aiSubscriptionMainPageType !== 'payment' && isRenewSubscription && (
            <div className="ai-stripe-btns csuy-btns ans-btn ansb-rs">
              {selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length ? null : (
                <button className="ac-btn ai__sub--btn renew__sub--btn" onClick={handleRenewSubscription}>
                  <span>Renew Subscription</span>
                </button>
              )}
            </div>
          )}

          {upgradablePlans.length ? (
            <div className="rai-switch">
              {upgradablePlans.map((it) => (
                <div
                  key={it.value}
                  className={`raist-item ${it.value === subscriptionType?.value ? 'active' : ''}`}
                  onClick={() => {
                    dispatch(setAISubscriptionType(it));
                  }}
                >
                  <span className="raist-txt">{it.label}</span>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="rai-sub-base rai-base-cnt">
          <div className="raisb-item raisb-left ai-lt">
            <div className="raisb-cnt">
              <div className="fabs">
                <div className="raisbc-field-group">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big">{selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length ? 'Free Trial' : subscriptionType?.label}</span>
                      <span className="rais-lbl-small">(Per location per {subscriptionType?.billing_period || 'month'})</span>
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value rais__value--right">{`$ ${selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length ? 0 : subscriptionType?.price}`}</span>
                    </div>
                  </div>
                </div>

                <div className="raisbc-field-group raisbc__field--selected">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big">Locations/Hubs Selected</span>
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value rais__value--right">
                        {aiSubscriptionDetailsModal.type === 'newSubscription' ? selectedSubscription.length : selectedSubscriptionTableData?.length}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="raisbc-field-group rais-total rais__tot--val">
                  <div className="raisbc-field">
                    <div className="raisbcf-item raisbcf-left">
                      <span className="rais-lbl-big rais__lbl-tot">Total</span>
                      {aiSubscriptionDetailsModal.type === 'modifySubscription' && (
                        <div className="raisb_cnt--text">
                          Your{' '}
                          {selectedSubscriptionTableData?.filter((it) => it.auto_renewal)?.length
                            ? 'plan renews'
                            : selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length
                            ? 'free trial ends'
                            : 'plan ends'}{' '}
                          on {utcToLocalHandler(selectedSubscriptionTableData[0]?.end_date || '', MONTH_DATE_YEAR_FORMAT)}
                        </div>
                      )}
                    </div>
                    <div className="raisbcf-item raisbcf-right">
                      <span className="rais-value ravs-xbold">{`$ ${
                        selectedSubscriptionTableData?.filter((it) => it.trial_period)?.length
                          ? 0
                          : subscriptionType &&
                            getFormattedNumber(
                              (subscriptionType.price || 0) * (aiSubscriptionDetailsModal.type === 'newSubscription' ? selectedSubscription.length : selectedSubscriptionTableData?.length)
                            )
                      }`}</span>
                    </div>
                  </div>
                  {selectedSubscription?.length && subscriptionPlan === AI_SUBSCRIPTION_PLAN_TYPES.TRIAL_PERIOD ? (
                    <span className="rais-lbl-small">{`Your card will be charged post free trail on ${getDateTimeAfterAWeek(DATE_FORMAT)}`}</span>
                  ) : null}
                </div>
              </div>
              {aiSubscriptionDetailsModal.type === 'modifySubscription' &&
                selectedSusbcripitonActive &&
                aiSubscriptionMainPageType !== 'payment' &&
                selectedSubscriptionTableData?.filter((it) => it.auto_renewal)?.length > 0 && (
                  <button onClick={() => setCancelSusbcription(true)} className="raisb_cnt--cancel">
                    Cancel Subscription
                  </button>
                )}
            </div>
            <div className="ai-stripe-btns r-flx r-flx-ac g-20">
              {aiSubscriptionDetailsModal.type === 'newSubscription' && aiSubscription.length > 0 && (
                <button
                  className="ac-btn ac-secondary-white ac-outline"
                  onClick={() => {
                    setView('selection');
                    const type = aiSubscriptionDetailsModal?.isTrialPreiod ? 'trialPeriod' : 'susbcriptionTable';
                    dispatch(setAISubscriptionDetailsModal({ type, isOpen: isRallioContractSubscription && aiSubscriptionDetailsModal?.isTrialPreiod ? false : true }));
                  }}
                >
                  <span>Back</span>
                </button>
              )}
              {aiSubscriptionDetailsModal.type !== 'modifySubscription' && aiSubscriptionMainPageType === 'selection' && (
                <button
                  className={`ac-btn ac-primary ${!selectedSubscription?.length ? 'pointer-events-none' : ''}`}
                  disabled={!selectedSubscription?.length}
                  onClick={() => {
                    setView('payment');
                  }}
                >
                  <span>Make Payment</span>
                </button>
              )}
            </div>
          </div>
          <div className={`raisb-item raisb-right ai-payment aips`}>
            <div className="raisb-cnt">
              {aiSubscriptionMainPageType === 'selection' ? (
                <AISubscriptionSelection />
              ) : (
                <div className="ai__payment--card">
                  <AISubscriptionPayment />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      {isCancelSubscription && <AICancelSusbcription showDeleteModal={isCancelSubscription} handleModalClose={() => setCancelSusbcription(false)} handleCancel={handleCancelSusbcription} />}
    </>
  );
};
