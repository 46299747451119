import { apFranContainerTypes } from 'types';
import { APP_TITLE, COMMON_SOCIAL_PROVIDER_TYPE } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';

export const SocialMediaPreviewCommentSec = ({ hashtagState, accSocialMedia, socialMedia }: apFranContainerTypes.ISocialMediaCommentsPreview) => {
  const selectedSocialMedia = accSocialMedia.find((medium) => medium.provider_name === socialMedia);
  return !selectedSocialMedia ? (
    <></>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
    <div className="cmnts-sec vmcWrap">
      <h5>View More Comments</h5>
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer">
              <span>24m</span>
              <span>
                <b>Like</b>
              </span>
              <span>
                <b>Reply</b>
              </span>
              <span>
                <b>See translation</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(socialMedia) ? (
    <div className="cmnts-sec vmcWrap">
      <h5>View More Comments</h5>
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer">
              <span>24m</span>
              <span>
                <b>Like</b>
              </span>
              <span>
                <b>Reply</b>
              </span>
              <span>
                <b>See translation</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? (
    <div className="cmnts-sec vmcWrap">
      <h5>View More Comments</h5>
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer">
              <span>24m</span>
              <span>
                <b>Like</b>
              </span>
              <span>
                <b>Reply</b>
              </span>
              <span>
                <b>See translation</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? (
    <div className="cmnts-sec vmcWrap">
      <h5>View More Comments</h5>
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer">
              <span>24m</span>
              <span>
                <b>Like</b>
              </span>
              <span>
                <b>Reply</b>
              </span>
              <span>
                <b>See translation</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? (
    <div className="cmnts-sec vmcWrap">
      <h5>View More Comments</h5>
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer">
              <span>24m</span>
              <span>
                <b>Like</b>
              </span>
              <span>
                <b>Reply</b>
              </span>
              <span>
                <b>See translation</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
