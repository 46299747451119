import { useSelector } from 'react-redux';

import { addPostGetSelectedContentObj, addPostUpdatePersonalizedTextEditor, resetAIContentCreator } from 'actions';
import { IStore, apFranContainerTypes } from 'types';
import {
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  COMMON_SOCIAL_MEDIA_NAME,
  COMMON_SOCIAL_PROVIDER_TYPE,
  OVERVIEW_MON_CHRT_LABLES,
  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM,
  STRING_WITH_PHONE_NUMBER,
  SettingsHashtagsActionsVal,
  HASHTAG_BLOCK_DEFAULT_SPACING
} from 'utils/constants';
import { capitalizeName, convertStringToEditorState, getContentListForDropdown } from 'utils/helpers';
import { CustomDropdown } from 'widgets/CustomDropdown';

export const RenderAddContentList = ({
  socialMedia,
  contentObj,
  dispatch,
  addContentList,
  setEditorState,
  textEditorState,
  editableSocialMedia,
  setEditableSocialMedia,
  selectedMediaList,
  linkData
}: apFranContainerTypes.IRenderAddContentProps) => {
  const createPostSuggestions = useSelector((state: IStore) => state.aiContentCreator.createPostSuggestions);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const { customText, postStatus, personalizedText } = contentObj;

  const addSavedHashtags = Boolean(hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length);

  const handleUpdateSocialMediaText = (textKey: string, socialMediaKey: string, socialMediaText: string) => {
    const limitedTextValue = socialMediaText;
    // const limitedTextValue = customText[socialMediaKey] || ''; //old
    // old cond -const limitedTextValue = getContentTextWithMaximumLimit(customText[socialMediaKey] || '', textKey);
    setEditorState({ ...textEditorState, [textKey]: convertStringToEditorState(limitedTextValue || '') });
    const regexData = new RegExp(STRING_WITH_PHONE_NUMBER);
    const editorGoogleText = regexData.test(customText[socialMediaKey]);
    // dispatch(addPostUpdateCustomTextEditor({ key: textKey, value: convertStringToEditorState(limitedTextValue || '') }));
    const postStatusValue =
      contentObj && !limitedTextValue && !selectedMediaList.length && !linkData
        ? false
        : textKey === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && !selectedMediaList.length
        ? false
        : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(textKey) && limitedTextValue.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH[textKey.toUpperCase()]
        ? false
        : socialMediaKey === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE &&
          (editorGoogleText || contentObj.personalizedText[socialMediaKey].includes('[phone_number]') || contentObj.personalizedText[socialMediaKey].includes('[phone'))
        ? false
        : limitedTextValue
        ? true
        : false;
    const addPersonalizedText = personalizedText[socialMediaKey] || '';
    // old-cond const addPersonalizedText = getContentTextWithMaximumLimit(personalizedText[socialMediaKey] || '', textKey);
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        customText: {
          ...customText,
          [textKey]: limitedTextValue
        },
        personalizedText: {
          ...personalizedText,
          [textKey]: addPersonalizedText
        },
        postStatus: {
          ...postStatus,
          [textKey]: postStatusValue
        }
      })
    );
    dispatch(addPostUpdatePersonalizedTextEditor({ key: textKey, value: convertStringToEditorState(addPersonalizedText) }));
    setEditableSocialMedia({ name: '', isEdit: false });
  };

  const handleAddNewTextArea = () => {
    setEditableSocialMedia({ name: '', isEdit: true });
    if (createPostSuggestions?.length) {
      const data = { createPostSuggestions: [], createPostHashtagSuggestions: [] };
      dispatch(resetAIContentCreator(data));
    }
    if (addSavedHashtags) {
      handleUpdateSocialMediaText(editableSocialMedia.name, contentObj.currentTab, `${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState?.saved_hashtags.join(' ')}`);
    }
  };

  return (
    <>
      {addContentList.length && !addContentList.includes(socialMedia) && editableSocialMedia.name === socialMedia && !editableSocialMedia.isEdit ? (
        <div className="social-section-copy-wrp">
          <div className={`at-textarea-overlay-updated${contentObj?.isPostLock ? ` pointer-events-none` : ``}`}>
            {addContentList.length === 1 ? (
              <div className="h-btn-o-updated copy-fb-btn-updated" onClick={() => handleUpdateSocialMediaText(editableSocialMedia.name, addContentList[0], customText[addContentList[0]])}>
                <span>{`Add Content From ${
                  addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN
                    ? OVERVIEW_MON_CHRT_LABLES.LINKEDIN
                    : addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE
                    ? COMMON_SOCIAL_MEDIA_NAME.GOOGLE_MY_BUSINESS
                    : addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                    ? COMMON_SOCIAL_MEDIA_NAME.INSTAGRAM_BUSINESS
                    : addContentList[0] === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER
                    ? COMMON_SOCIAL_MEDIA_NAME.X
                    : capitalizeName(addContentList[0])
                }`}</span>
              </div>
            ) : (
              <div className="desc-wrp flex-item-xl">
                <div className="flex-item-sm">
                  <div className="form-group form-field-item fg-dropdown m-align-items cs-item">
                    <CustomDropdown
                      classNamePrefix="cs-select"
                      id="dl-store-details-category-select"
                      placeholder={'Add Content From'}
                      options={getContentListForDropdown(addContentList)}
                      isSearchable={false}
                      onChange={(event: any) => handleUpdateSocialMediaText(editableSocialMedia.name, event.value, customText[event.value])}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="h-btn-o-updated" onClick={() => handleAddNewTextArea()}>
              <span>Add New Content</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
