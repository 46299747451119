import { useDispatch, useSelector } from 'react-redux';

import { IStore } from 'types';
import { setAISubscriptionDetailsModal, setAISubscriptionType } from 'actions';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { useAISubscriptionPlan } from 'utils/hooks';

export const AISubscriptionTrialPage = ({ isAlreadySubscribed }: { isAlreadySubscribed: boolean }) => {
  const dispatch = useDispatch();

  const { planOptions } = useAISubscriptionPlan();
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);

  return (
    <div className="ai-sub-home">
      <div className="ai-promo-video">
        <div className="video-section">
          <ReactVideoPlayer videoUrl={'https://res.cloudinary.com/ralliohq/video/upload/q_auto,c_limit,h_1024,w_1024/uflyanvylewjw41fr3sy.mp4'} isPlayControl customClassname={'video'} />
          <div className="ai-vs-top">
            <ImageValidation isImgValid defaultImg="ai-play" customName={'play'} />
          </div>
        </div>
      </div>
      <div className="ral-aip-main">
        <h2>Rallio AI Pricing &amp; Plans</h2>
        <p className="ai__pricing--txt">
          Jump in and experience how simple, easy, and fun your social media suddenly becomes with Rallio AI! Try it for free for a week and get ready to fall in love! Want to save 25%? Click the
          yearly option.
        </p>

        <div className={`raip-item-group cc-rai-plans ${isAlreadySubscribed ? 'ai-as-options as-active' : 'ai-as-options as-inactive'}`}>
          {planOptions.map((it) => (
            <div className={it.class} key={it.value}>
              <div className="raip-top">
                <h4>{it.label}</h4>
                <p>Per location</p>
              </div>
              <div className="raip-mid">
                <div className="raipm-price-main">
                  <span className="raipm-dollar">$</span>
                  <span className="raipm-price">{`${it.price}`}</span>
                  <span className="raipm-monthly">/{it.billing_period}</span>
                </div>
              </div>
              <button
                className="ac-btn ac-primary"
                onClick={() => {
                  dispatch(setAISubscriptionDetailsModal({ type: 'newSubscription', isOpen: true, isTrialPreiod: true, isCoachMark: aiSubscriptionDetailsModal?.isCoachMark || false }));
                  dispatch(setAISubscriptionType(it));
                }}
              >
                <span>Subscribe Now</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
