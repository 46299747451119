import { createAction, ActionsUnion } from 'utils/helpers';
import * as actionTypes from 'actions/action-types';
import { franchisorApiTypes, franchisorReducerTypes, menuAccessApiTypes } from 'types';

// FRANCHISORS - GET/UPDATE FRANCHISORS PERMISSION LIST REQUEST
export const franchisorsPermissionListRequest = (payload: franchisorReducerTypes.IFranchisorsPermissionListRequest) => createAction(actionTypes.FRANCHISOR_PERMISSION_LIST_REQUEST, payload);

export const franchisorsPermissionListResponse = (response: franchisorReducerTypes.IFranchisorsPermissionList) => createAction(actionTypes.FRANCHISOR_PERMISSION_LIST_RESPONSE, response);

export const franchisorsPermissionListFailure = (err: null | string) => createAction(actionTypes.FRANCHISOR_PERMISSION_LIST_FAILURE, err);

// FRANCHISORS - GET OR UPDATE FRANCHISOR
export const franchisorGetOrUpdateProfileRequest = (payload: franchisorApiTypes.IGetOrUpdateFranchisorReqPayload) => createAction(actionTypes.FRANCHISOR_GET_OR_UPDATE_PROFILE_REQUEST, payload);
export const franchisorGetOrUpdateProfileResponse = (response: franchisorApiTypes.IFranchisorProfileResponse) => createAction(actionTypes.FRANCHISOR_GET_OR_UPDATE_PROFILE_RESPONSE, response);
export const franchisorGetOrUpdateProfileFailure = (error: null | string) => createAction(actionTypes.FRANCHISOR_GET_OR_UPDATE_PROFILE_FAILURE, error);

export const franchisorError = (error: null | string) => createAction(actionTypes.FRANCHISOR_ERROR, error);

// FRANCHISORS - SET FRANCHISOR PROFILE RESET
export const franchisorProfileReset = () => createAction(actionTypes.FRANCHISOR_PROFILE_RESET);

export const franchisorPermissionListReset = () => createAction(actionTypes.FRANCHISOR_PERMISSION_LIST_RESET);

// GET FRANCHISOR DETAILS - (based on response, show calendar brand scheduled post)
export const getFranchisorDetailsRequest = (payload: menuAccessApiTypes.IGetFranDetailsRequest) => createAction(actionTypes.GET_FRANCHISOR_DETAILS_REQUEST, payload);
export const getFranchisorDetailsResponse = (response: null | menuAccessApiTypes.IGetUserDetailsResponse) => createAction(actionTypes.GET_FRANCHISOR_DETAILS_RESPONSE, response);
export const getFranchisorDetailsFailure = (error: null | string) => createAction(actionTypes.GET_FRANCHISOR_DETAILS_FAILURE, error);

// GET DIRECT LOCATIONS FOR HUB OR LOC LIST
export const getDirectLocsForHubOrLocListRequest = (payload: franchisorApiTypes.IDirectLocsFromHubOrListReqPayload) => createAction(actionTypes.GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_REQUEST, payload);
export const getDirectLocsForHubOrLocListResponse = (data: franchisorApiTypes.IDirectLocsFromHubOrListDataResp) => createAction(actionTypes.GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_RESPONSE, data);
export const getDirectLocsForHubOrLocListFailure = (error: string | null) => createAction(actionTypes.GET_DIRECT_LOCS_FOR_HUB_OR_LOC_LIST_FAILURE, error);

// FRANCHISORS - GET OR UPDATE FRANCHISOR
export const contentSupplierGetCorpFranchisorsListRequest = (payload: franchisorApiTypes.ICSCorpFranchisorReq) => createAction(actionTypes.CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_REQUEST, payload);
export const contentSupplierGetCorpFranchisorsListResponse = (response: menuAccessApiTypes.IFranchisorSyndication[]) =>
  createAction(actionTypes.CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_RESPONSE, response);
export const contentSupplierGetCorpFranchisorsListFailure = (error: null | string) => createAction(actionTypes.CONTENT_SUPPLIER_GET_CORP_FRANCHISORS_LIST_FAILURE, error);

// GET - RANDOM ADS BANNER
export const getPartnerBannersRequest = (payload: franchisorApiTypes.IPartnerBannersReq) => createAction(actionTypes.GET_PARTNER_BANNERS_REQUEST, payload);
export const getPartnerBannersResponse = (response: franchisorApiTypes.IPartnerBannersRes) => createAction(actionTypes.GET_PARTNER_BANNERS_RESPONSE, response);
export const getPartnerBannersFailure = (error: null | string) => createAction(actionTypes.GET_PARTNER_BANNERS_FAILURE, error);

export const setFranchisorScheduleLocType = (data: null | string) => createAction(actionTypes.SET_FRANCHISOR_SCHEDULE_LOC_TYPE, data);

export const deleteItemInLocationTree = (data: franchisorApiTypes.IDeleteItemInLocationTree) => createAction(actionTypes.DELETE_ITEM_IN_LOCATION_TREE, data);

export const getFranScheduleTreeStats = (data: franchisorReducerTypes.IFranScheduleTreeStats) => createAction(actionTypes.SET_FRAN_SCHEDULE_TREE_STATS, data);

export const franchisorScheduleTreeReset = () => createAction(actionTypes.FRANCHISOR_SCHEDULE_TREE_RESET);

// GET - FRANCHISOR SUBSCRIPTION TYPE
export const getFranSubscriptionRequest = (payload: { params: { franchisor_id: number } }) => createAction(actionTypes.GET_FRAN_SUBSCRIPTION_TYPES_REQUEST, payload);
export const getFranSubscriptionResponse = (response: franchisorReducerTypes.IFranSubscriptionTypes[]) => createAction(actionTypes.GET_FRAN_SUBSCRIPTION_TYPES_RESPONSE, response);
export const getFranSubscriptionFailure = (error: null | string) => createAction(actionTypes.GET_FRAN_SUBSCRIPTION_TYPES_FAILURE, error);

// GET - SYNDICATION COUNTRIES LIST
export const getFranCountriesListRequest = (payload: franchisorApiTypes.IGetCountriesListReqPayload) => createAction(actionTypes.GET_SYNDICATION_COUNTRIES_LIST_REQUEST, payload);
export const getFranCountriesListResponse = (response: franchisorReducerTypes.IGetCountriesListResponse[]) => createAction(actionTypes.GET_SYNDICATION_COUNTRIES_LIST_RESPONSE, response);
export const getFranCountriesListFailure = (error: null | string) => createAction(actionTypes.GET_SYNDICATION_COUNTRIES_LIST_FAILURE, error);

// RESET - PARTNER BANNER
export const resetPartnerBanner = () => createAction(actionTypes.RESET_PARTNER_BANNER);

const FRANCHISORS_PROFILE = {
  franchisorsPermissionListRequest,
  franchisorsPermissionListResponse,
  franchisorsPermissionListFailure,
  franchisorGetOrUpdateProfileRequest,
  franchisorGetOrUpdateProfileResponse,
  franchisorGetOrUpdateProfileFailure,
  franchisorError,
  franchisorProfileReset,
  franchisorPermissionListReset,
  getFranchisorDetailsRequest,
  getFranchisorDetailsResponse,
  getFranchisorDetailsFailure,
  getDirectLocsForHubOrLocListRequest,
  getDirectLocsForHubOrLocListResponse,
  getDirectLocsForHubOrLocListFailure,
  setFranchisorScheduleLocType,
  deleteItemInLocationTree,
  getFranScheduleTreeStats,
  franchisorScheduleTreeReset,
  contentSupplierGetCorpFranchisorsListRequest,
  contentSupplierGetCorpFranchisorsListResponse,
  contentSupplierGetCorpFranchisorsListFailure,
  getPartnerBannersRequest,
  getPartnerBannersResponse,
  getPartnerBannersFailure,
  getFranSubscriptionRequest,
  getFranSubscriptionResponse,
  getFranSubscriptionFailure,
  getFranCountriesListRequest,
  getFranCountriesListResponse,
  getFranCountriesListFailure,
  resetPartnerBanner
};

export type FranchisorsProfileActions = ActionsUnion<typeof FRANCHISORS_PROFILE>;
