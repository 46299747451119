import { useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { commonModalPopup, IStore } from 'types';
import { ImageValidation } from 'widgets/Media';
import { CustomTooltip } from 'widgets/Tooltip';
import { CustomDatePicker } from 'widgets/DatePicker';
import {
  ICommonDateTypes,
  PAGINATION_PER_PAGE_SMALL_GRID,
  DATEPICKER_DATE_ONLY_FORMAT,
  DATEPICKER_TIME_ONLY_FORMAT,
  YEAR_MONTH_DAY_WITH_TIME_FORMAT,
  TOOLTIP_PLACEMENT,
  OVERRIDE_POSTING_PERMISSIONS,
  FranCreatorScheduleType,
  COMMON_SOCIAL_PROVIDER_TYPE,
  AP_CONTENT_ACTION_TYPE,
  FRAN_SCHEDULE_POST_TYPE,
  FRANCHISORS_SELECTIONS,
  LOCATION_LISTS,
  SIDE_NAV_NAME,
  ALERT_BOX_TYPES,
  ADD_BOOST_FOR_SCH_POST_NO_DATA_MSG
} from 'utils/constants';
import {
  getUTCDateAndTimeForSelectedData,
  getParsedDate,
  calendarUTCtoLocalHandler,
  convertMomentObjToDate,
  getDefaultDateObjForNextDay,
  getDefaultFromAndToDateForOneYear,
  getScheduledPostReqPayload,
  getFormatBasedOnExactDate,
  utcTimeStampConverter,
  getUTCDateForPosts,
  convertStringToDate,
  getIncludedAccounts
} from 'utils/helpers';
import { useNavBarData, useAccountSwitcherData } from 'utils/hooks';
import {
  addPostGetSelectedContentObj,
  addPostResetAll,
  addPostFranResetAll,
  addPostActionsResetAll,
  postDetailDataReset,
  getAverageLocationsSpendRequest,
  getAverageLocationsSpendResponse,
  setCorporatePostBoostData,
  addPostAddScheduledMultipostsRequest,
  calendarEditView,
  updateScheduledMultiPostsRequest,
  franchisorScheduleTreeReset,
  addPostMassUpdateForScheduledPostRequest,
  setFranchisorScheduleLocType,
  getDirectLocsForHubOrLocListRequest,
  getFranScheduleTreeStats,
  getDirectLocsForHubOrLocListResponse
} from 'actions';
import { alertBoxCall, BrandBoostSection, CorporatePostFooter, ScheduleLocationSection } from 'components';
import { FranPostNowModal } from 'containers/Content/Creator/BrandOrHubSubPages';

export const BrandSchedulePostModal = ({
  isModalShow,
  handleModalClose,
  className,
  scheduleType,
  isEditEnabled = true,
  selectedObj = null,
  handleUpdateContentData
}: commonModalPopup.IBrandSchedulePostModalPopup) => {
  const dispatch = useDispatch();

  const { subNavPageName } = useNavBarData();
  const { id, optionalParams } = useAccountSwitcherData();

  const postsDetails = useSelector((state: IStore) => state.posts.postsDetails);
  const facebookAdAccountId = useSelector((state: IStore) => state.fbAdsTargeting.fbTargetingChoice?.facebook_ad_account?.id) || 0;
  const dropdownList = useSelector((state: IStore) => state.accountSwitcher.accountSelectionList);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const schLocationTree = useSelector((state: IStore) => state.franchisors.franScheduleTree.schLocationTree);
  const locationType = useSelector((state: IStore) => state.franchisors.franScheduleTree.locationType);
  const { defaultDate, dateAndTime, isPermissionOverride, postStatus, isNotShowPost } = contentObj;
  const corporatePostBoostObj = useSelector((state: IStore) => state.fbAdsTargeting.corporatePostBoostObj);
  const { isBoostEnabled, lifetimeSpend, duration, boostType, facebookDarkPost } = corporatePostBoostObj;
  const averageLocationsSpend = useSelector((state: IStore) => state.fbAdsTargeting.averageLocationsSpend);
  const { data: boostedLocData, isFetching, actionType } = averageLocationsSpend;
  const corporateAccountId = useSelector((state: IStore) => state.franchisors.franchisorDetails?.corporate_account_id || 0);
  const editData = useSelector((state: IStore) => state.calendar.editableData.editData);
  const calendarDateRange = useSelector((state: IStore) => state.calendar.calendarDateRange);
  const isPostNowEnabled = useSelector((state: IStore) => state.addPostAccount.isPostNowEnabled);

  const [openPostNowModal, setOpenPostNowModal] = useState(false);

  // const isFacebookActive = useMemo(() => {
  //   if (facebookAdAccountId) {
  //     if (postStatus?.facebook) return true;
  //     else if (selectedObj && selectedObj?.dataObj.find((it) => it.label === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)?.isEnabled) return true;
  //     else return false;
  //   }
  //   return false;
  // }, [facebookAdAccountId, postStatus?.facebook, selectedObj]);

  const isFacebookActive =
    (facebookAdAccountId && postStatus?.facebook) || (facebookAdAccountId && selectedObj && selectedObj?.dataObj.find((it) => it.label === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)?.isEnabled);

  const selectedLocationsCount = () => {
    return [
      ...new Set(
        schLocationTree?.reduce((acc: number[], curr) => {
          if (curr.type) acc = [...acc, ...(curr.locations || []).filter((it) => it.active).map((it) => it.id)];
          else acc = [...acc, curr.id];
          return acc;
        }, [])
      )
    ].length;
  };

  const disableButton = locationType === FRAN_SCHEDULE_POST_TYPE.SPECIFIC_LOCS_OR_LISTS && selectedLocationsCount() === 0;

  useEffect(() => {
    if (isModalShow && !isFacebookActive) handleBoostDataReset();
  }, [isModalShow, isFacebookActive]); // eslint-disable-line

  useEffect(() => {
    if (boostedLocData.length && actionType) {
      if (subNavPageName === SIDE_NAV_NAME.CONTENT_CALENDAR) handleUpdateScheduleMultipost(actionType);
      else handleAddScheduleMultipost(actionType);
    }
  }, [boostedLocData.length, actionType, subNavPageName]); // eslint-disable-line

  useEffect(() => {
    if (isBoostEnabled && !boostedLocData.length && actionType && [AP_CONTENT_ACTION_TYPE.SCHEDULE, AP_CONTENT_ACTION_TYPE.POST_NOW].includes(actionType)) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, ADD_BOOST_FOR_SCH_POST_NO_DATA_MSG);
      dispatch(getAverageLocationsSpendResponse({ data: [], actionType: null }));
    }
  }, [boostedLocData.length, actionType, isBoostEnabled]); // eslint-disable-line

  useEffect(() => {
    if (isModalShow) {
      if (selectedObj?.id) {
        handleGetSelectedLocation();
        const dateTime = getParsedDate(calendarUTCtoLocalHandler(selectedObj?.scheduledTime || '', YEAR_MONTH_DAY_WITH_TIME_FORMAT));
        dispatch(
          addPostGetSelectedContentObj({
            ...contentObj,
            isPermissionOverride: subNavPageName === SIDE_NAV_NAME.CONTENT_CALENDAR ? false : selectedObj?.permission || false,
            scheduledBy: FranCreatorScheduleType.MANUAL_SCHEDULE,
            dateAndTime: {
              selected: dateTime,
              utcFormat: dateTime ? getUTCDateAndTimeForSelectedData(dateTime) : null
            },
            ...(selectedObj.start_date &&
              selectedObj.end_date && {
                defaultDate: {
                  fromDate: {
                    selected: getUTCDateForPosts(convertStringToDate(selectedObj.start_date), 0, 0, 0).selectedDate,
                    utcFormat: getUTCDateAndTimeForSelectedData(selectedObj.start_date)
                  },
                  toDate: {
                    selected: getUTCDateForPosts(convertStringToDate(selectedObj.end_date), 23, 59, 59).selectedDate,
                    utcFormat: getUTCDateAndTimeForSelectedData(selectedObj.start_date)
                  }
                }
              })
          })
        );
      } else if (!postsDetails) {
        dispatch(franchisorScheduleTreeReset());
        const stdDateAndTime = contentData?.start_date ? getUTCDateForPosts(convertStringToDate(contentData?.start_date), 0, 0, 0).selectedDate : convertMomentObjToDate(getDefaultDateObjForNextDay());
        dispatch(
          addPostGetSelectedContentObj({
            ...contentObj,
            scheduledBy: FranCreatorScheduleType.MANUAL_SCHEDULE,
            defaultDate:
              contentData?.start_date && contentData?.end_date
                ? {
                    fromDate: {
                      selected: getUTCDateForPosts(convertStringToDate(contentData.start_date), 0, 0, 0).selectedDate,
                      utcFormat: getUTCDateAndTimeForSelectedData(getUTCDateForPosts(convertStringToDate(contentData?.start_date), 0, 0, 0).selectedDate)
                    },
                    toDate: {
                      selected: getUTCDateForPosts(convertStringToDate(contentData.end_date), 23, 59, 59).selectedDate,
                      utcFormat: getUTCDateAndTimeForSelectedData(getUTCDateForPosts(convertStringToDate(contentData?.end_date), 23, 59, 59).selectedDate)
                    }
                  }
                : getDefaultFromAndToDateForOneYear(),
            dateAndTime: {
              selected: stdDateAndTime,
              utcFormat: stdDateAndTime ? getUTCDateAndTimeForSelectedData(stdDateAndTime) : null
            }
          })
        );
      }
    }
  }, [isModalShow, selectedObj, postsDetails]); // eslint-disable-line

  const handleGetSelectedLocation = () => {
    if (scheduleType) dispatch(setFranchisorScheduleLocType(scheduleType));
    const includedAccounts = (selectedObj?.includedAccounts || []).map((it) => ({ id: it.id, name: it.name }));
    dispatch(getFranScheduleTreeStats({ hubs: selectedObj?.franchisorIds?.length || 0, locationLists: selectedObj?.accountListIds?.length || 0 }));
    if (selectedObj?.franchisorIds?.length) selectedObj?.franchisorIds.forEach((datum) => dispatch(getDirectLocsForHubOrLocListRequest({ id: datum, type: FRANCHISORS_SELECTIONS, includedAccounts })));
    if (selectedObj?.accountListIds?.length) selectedObj?.accountListIds.forEach((datum) => dispatch(getDirectLocsForHubOrLocListRequest({ id: datum, type: LOCATION_LISTS, includedAccounts })));
    if (includedAccounts?.length && !selectedObj?.franchisorIds?.length && !selectedObj?.accountListIds?.length) dispatch(getDirectLocsForHubOrLocListResponse({ includedAccounts }));
  };

  const handleDateChange = (date: null | Date) => {
    dispatch(
      addPostGetSelectedContentObj({
        ...contentObj,
        dateAndTime: {
          selected: date,
          utcFormat: date ? getUTCDateAndTimeForSelectedData(date) : null
        }
      })
    );
  };

  const handleGetAverageLocSpendData = (actionType?: string) => {
    const includedAccounts = getIncludedAccounts(schLocationTree);
    const includedAccountIDs = [...new Set(includedAccounts.map((it) => it.id))];
    if (id && lifetimeSpend && duration && boostType) {
      dispatch(
        getAverageLocationsSpendRequest({
          franchisorId: Number(id),
          actionType,
          params: {
            lifetime_spend: lifetimeSpend * 100,
            duration,
            weight_function: boostType,
            ...(locationType !== FRAN_SCHEDULE_POST_TYPE.ALL_LOCATIONS && { account_ids: includedAccountIDs.join(',') })
          }
        })
      );
    }
  };

  const handleBoostDataReset = () => {
    if (isBoostEnabled) {
      dispatch(getAverageLocationsSpendResponse({ data: [], actionType: null }));
      dispatch(setCorporatePostBoostData({ isBoostEnabled: false, lifetimeSpend: 0, duration: 0, boostType: '', facebookDarkPost: false }));
    }
  };

  const handleResetData = () => {
    if (subNavPageName === SIDE_NAV_NAME.CONTENT_POSTS) {
      if (!optionalParams.length) dispatch(postDetailDataReset());
      dispatch(addPostResetAll());
      dispatch(addPostFranResetAll());
      dispatch(addPostActionsResetAll());
    }
    dispatch(franchisorScheduleTreeReset());
    handleModalClose();
  };

  const handleAddScheduleMultipost = (type: string) => {
    if (contentData?.id && dateAndTime.utcFormat) {
      const boostData = isBoostEnabled
        ? {
            duration,
            lifetime_budget: lifetimeSpend,
            spends: boostedLocData,
            facebook_dark_post: facebookDarkPost
          }
        : null;
      dispatch(
        addPostAddScheduledMultipostsRequest({
          actionType: type,
          reqPayload: getScheduledPostReqPayload(
            contentData?.id,
            id,
            isPermissionOverride,
            locationType,
            dropdownList?.onlyLocationList || [],
            schLocationTree,
            dateAndTime.utcFormat,
            type,
            boostData,
            corporateAccountId
          )
        })
      );
      handleBoostDataReset();
      if (openPostNowModal) setOpenPostNowModal(false);
      handleModalClose();
    }
  };

  const handleAddScheduleOrPostNowAction = (type: string) => {
    if (id && !disableButton) {
      if (!isBoostEnabled) {
        handleAddScheduleMultipost(type);
      } else {
        handleGetAverageLocSpendData(type);
      }
    }
  };

  const handleUpdateScheduleMultipost = (type: string) => {
    if (editData) {
      dispatch(
        calendarEditView({
          isEditEnabled: true,
          editId: 0,
          editData: {
            ...editData,
            scheduledTime: getFormatBasedOnExactDate(contentObj.dateAndTime.selected, YEAR_MONTH_DAY_WITH_TIME_FORMAT)
          }
        })
      );
    }
    if (id && selectedObj?.id && !disableButton) {
      const boostData = isBoostEnabled
        ? {
            duration,
            lifetime_budget: lifetimeSpend,
            spends: boostedLocData,
            facebook_dark_post: facebookDarkPost
          }
        : null;
      const schedulePayload = getScheduledPostReqPayload(
        selectedObj?.contentId || 0,
        selectedObj?.franchisorId || 0,
        isPermissionOverride,
        locationType,
        dropdownList?.onlyLocationList || [],
        schLocationTree,
        dateAndTime.utcFormat || selectedObj?.scheduledTime || '',
        type,
        boostData,
        corporateAccountId
      );
      dispatch(
        updateScheduledMultiPostsRequest({
          schedulePayload,
          id: selectedObj?.id,
          ...(subNavPageName === SIDE_NAV_NAME.CONTENT_CALENDAR &&
            !postsDetails && {
              franchisorId: id,
              ...(calendarDateRange.startDate &&
                calendarDateRange.endDate && {
                  dateRange: { startDate: utcTimeStampConverter(calendarDateRange.startDate), endDate: utcTimeStampConverter(calendarDateRange.endDate) }
                })
            }),
          franchisorId: schedulePayload.franchisor_id,
          refetch: true
        })
      );
      handleBoostDataReset();
      handleModalClose();
      if (postsDetails && handleUpdateContentData) handleUpdateContentData(AP_CONTENT_ACTION_TYPE.SCHEDULE);
    }
  };

  const handleContentMassUpdate = () => {
    if (contentData?.id) {
      dispatch(addPostMassUpdateForScheduledPostRequest(contentData?.id));
    }
  };

  const handleUpdateScheduleAction = (type: string) => {
    if (id && !disableButton) {
      if (!isBoostEnabled) {
        handleUpdateScheduleMultipost(type);
      } else {
        handleGetAverageLocSpendData(type);
      }
    }
  };

  return (
    <>
      <Modal
        // className={`creator-tag-modal-wrp schedule-post-modal-hubuser glbl__modal--wrp schedule-post__main cr-sm-mdl schCorpPost ${className || ''}`}
        className={`glbl__modal--wrp cr-sm-mdl schCorpPost swTabs ${className || ''}`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName="esDate-bckdrp"
        show={isModalShow && !openPostNowModal}
        animation={false}
        onHide={handleResetData}
      >
        <div className="mod__close--icon" onClick={handleResetData}>
          <ImageValidation isImgValid defaultImg={'close-creator'} customName={'close'} customClassname="mod__close--img" />
        </div>
        <Modal.Body>
          <h3 className="glbl__title--txt">Schedule</h3>

          <Card className="glbl__modal--card">
            <Card.Header>Where</Card.Header>
            <Card.Body>
              <ScheduleLocationSection isEditEnabled={isEditEnabled} selectedLocationsCount={selectedLocationsCount} />
            </Card.Body>
          </Card>

          <Card className={`glbl__modal--card ${isEditEnabled ? 's' : 'pointer-events-none'}`}>
            <Card.Header>When</Card.Header>
            <Card.Body>
              <div className="when-section-grid">
                <div className="section-01">
                  <CustomDatePicker
                    selectedDate={dateAndTime.selected}
                    dateRangeType={ICommonDateTypes.DATE_ONLY}
                    onChange={(date) => handleDateChange(date)}
                    minDate={defaultDate.fromDate.selected}
                    maxDate={defaultDate.toDate.selected}
                    dateFormat={DATEPICKER_DATE_ONLY_FORMAT}
                    customClassname="fltr-date-item fltr-from-date"
                    placeholderText="Date"
                    disabled={!isEditEnabled}
                  />
                </div>
                <div className="section-02">
                  <CustomDatePicker
                    selectedDate={dateAndTime.selected}
                    dateRangeType={ICommonDateTypes.TIME_ONLY}
                    onChange={(date) => handleDateChange(date)}
                    isShowTimeSelect
                    isShowTimeSelectOnly
                    timeInterval={PAGINATION_PER_PAGE_SMALL_GRID}
                    dateFormat={DATEPICKER_TIME_ONLY_FORMAT}
                    customClassname="fltr-date-item fltr-from-date fltr-pt-time"
                    placeholderText="Time"
                    disabled={!isEditEnabled}
                  />
                </div>
              </div>

              {subNavPageName !== SIDE_NAV_NAME.CONTENT_CALENDAR && (
                <div className="tip-section-wrp">
                  <div className="form-check">
                    <label className={`checkbox-item${isPermissionOverride ? ` active` : ``}`}>
                      <span className="checkbox-hover">
                        <input
                          type="checkbox"
                          name="isPermissionOverride"
                          className="option-input click-wave"
                          checked={isPermissionOverride}
                          onChange={(event) => dispatch(addPostGetSelectedContentObj({ ...contentObj, [event.target.name]: !isPermissionOverride }))}
                        />
                      </span>
                      <div className="label-txt">
                        Override Posting Permissions
                        <CustomTooltip
                          customPlacement={TOOLTIP_PLACEMENT.TOP}
                          customTooltipText={OVERRIDE_POSTING_PERMISSIONS}
                          customClassname={'custom-tooltip-long-text'}
                          customInput={() => (
                            <div className="info__tool--tip">
                              <ImageValidation isImgValid defaultImg="information-grey" customName={'activate'} />
                            </div>
                          )}
                        />
                      </div>
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
          {isFacebookActive ? <BrandBoostSection isEditEnabled={isEditEnabled} handleGetAverageLocSpendData={handleGetAverageLocSpendData} /> : null}
          <CorporatePostFooter
            isFetching={isFetching}
            isEditEnabled={isEditEnabled}
            disableButton={disableButton}
            isNotShowPost={isNotShowPost}
            isPostNowEnabled={isPostNowEnabled}
            setOpenPostNowModal={setOpenPostNowModal}
            handleAddScheduleOrPostNowAction={(actionType: string) => handleAddScheduleOrPostNowAction(actionType)}
            handleUpdateScheduleAction={(actionType: string) => handleUpdateScheduleAction(actionType)}
            handleContentMassUpdate={handleContentMassUpdate}
          />
        </Modal.Body>
      </Modal>
      <FranPostNowModal
        isModalShow={openPostNowModal}
        handleModalClose={() => setOpenPostNowModal(false)}
        disablePostButton={disableButton}
        handlePostData={(actionType: string) => handleAddScheduleOrPostNowAction(actionType)}
        setOpenPostNowModal={setOpenPostNowModal}
      />
    </>
  );
};
