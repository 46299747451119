import { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { CreatePostStats } from './CreatePostStats';
import { LeftCreatorPostSection } from './LeftCreatorPostSection';
import { RightCreatorPreviewSection } from './RightCreatorPreviewSection';
import { IStore } from 'types';
import { alertBoxCall, Loading } from 'components/Common';
import { useAccountSwitcherData, useActivePlatform, useNavBarData } from 'utils/hooks';
import {
  getAddContentList,
  dateObjBasedOnTimeZone,
  getFacebookBoostConfigData,
  getCurrentDate,
  getAddBoostForScheduledPostReqPayload,
  getPreviewSocialMediaList,
  utcTimeStampConverter,
  getStartAndEndOfUpcomingWeek,
  confirmEditingAccountPost,
  apiEventSourcePolyfillHandler,
  API,
  convertStringToEditorState
} from 'utils/helpers';
import {
  addPostSaveMessageReset,
  addPostSaveAsScheduledPostRequest,
  updateFBBoostConfigData,
  addPostSavedPostReset,
  addPostActionsResetAll,
  addPostUpdateSavedPostDetails,
  getFBAdsTargetingChoicesRequest,
  addPostGetActionTypeForPost,
  accountProfileReset,
  addPostResetAll,
  creatorSetComponentName,
  addPostAddBoostToScheduledPostRequest,
  addPostGetEditSavedPostObj,
  addPostSetEditConfirmForApprovedPost,
  addPostFranResetAll,
  calendarViewDateRange,
  toggleFilter,
  resetAIContentCreator,
  setAIPostContentOpen,
  getScheduledPostResponse,
  getScheduledPostRequest,
  resetAIImageGenerationImages,
  setCreatorTextAlertMsg,
  addPostUpdateCustomTextEditor
} from 'actions';
import {
  ALERT_BOX_TYPES,
  ADD_POST_ACTION_TYPE,
  CREATOR_EDIT_INIT_OBJ,
  AP_SAVED_POST_INIT_DETAILS,
  COMMON_SOCIAL_PROVIDER_TYPE,
  AP_ACTION_TYPES,
  CREATOR_USER_OWNERSHIP,
  ADD_POST_STATUS_NAME,
  USER_OWNERSHIP,
  POSTS_STATUS_TYPE,
  AP_EDIT_CONFIRM_INIT_OBJ,
  ICommonDateTypes,
  CALENDAR_VIEW_DATE_RANGE,
  AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT,
  SCHEDULE_PARAM_TYPE,
  CALENDAR_EXCEPTION_ERROR,
  SettingsHashtagsActionsVal,
  HASHTAG_BLOCK_DEFAULT_SPACING
} from 'utils/constants';
import { EditorStateContext } from '..';
import { AIContents } from '../AIContent';

export const AccountCreatePost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedNavBarData = useNavBarData();
  const { subNavPageName } = selectedNavBarData;
  const { id, userOwnership, isValidId } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const fbTargetingChoice = useSelector((state: IStore) => state.fbAdsTargeting.fbTargetingChoice);
  const facebookBoostConfig = useSelector((state: IStore) => state.fbAdsTargeting.facebookBoostConfig);
  const creatorCompName = useSelector((state: IStore) => state.common.creatorCompName);
  const isPostFetching = useSelector((state: IStore) => state.addPostAccount.isPostFetching);
  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const addPostMessage = useSelector((state: IStore) => state.addPostAccount.addPostMessage);
  const editSavedPost = useSelector((state: IStore) => state.addPostAccount.editSavedPost);
  const apActionType = useSelector((state: IStore) => state.addPostAccount.apActionType);
  const savedPostId = useSelector((state: IStore) => state.addPostAccount.savedPostId);
  const addPostAction = useSelector((state: IStore) => state.addPostAccount.addPostAction);
  const scheduledPostId = useSelector((state: IStore) => state.addPostAccount.addedBoostObj.scheduledPostId);
  const boostSuccessMsg = useSelector((state: IStore) => state.addPostAccount.addedBoostObj.boostSuccessMsg);
  const boostFailureMsg = useSelector((state: IStore) => state.addPostAccount.addedBoostObj.boostFailureMsg);
  const editConfirmObj = useSelector((state: IStore) => state.addPostAccount.editConfirmObj);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const isFranchisor = useSelector((state: IStore) => state.login.activeUser?.franchisor || false);
  const overriddenCustomPostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_custom_posts_locked || false);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const mobileFilter = useSelector((state: IStore) => state.common.mobileFilter);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  const creatorTextAlertMsg = useSelector((state: IStore) => state.common.creatorTextAlertMsg);
  const isCaptionValidating = useSelector((state: IStore) => state.aiContentCreator.isCaptionValidating);
  const isAICaptionTranslating = useSelector((state: IStore) => state.addPostFranchisorAction.isAICaptionTranslating);
  const hashTagsState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const editorStateArray = useContext(EditorStateContext);
  const setEditorState = editorStateArray[1];
  const addSavedHashtags = Boolean(hashTagsState.action_status === SettingsHashtagsActionsVal.IN_POST && hashTagsState.saved_hashtags?.length);

  const addContentList = useMemo(() => {
    if (savedPostDetails && activePlatforms) return getAddContentList(savedPostDetails, activePlatforms);
    else return [];
  }, [activePlatforms, savedPostDetails]);

  const previewList = useMemo(() => {
    if (savedPostDetails) return getPreviewSocialMediaList(savedPostDetails) || [];
    else return [];
  }, [savedPostDetails]);

  useEffect(() => {
    if (
      isValidId &&
      editSavedPost?.account?.id &&
      userOwnership &&
      creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT &&
      accountDetails?.social_media?.find((datum) => datum.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK)?.id
    ) {
      dispatch(getFBAdsTargetingChoicesRequest({ id: editSavedPost?.account?.id, userOwnership: CREATOR_USER_OWNERSHIP.ACCOUNT, initialLoad: true }));
    }
  }, [isValidId, id, creatorCompName, editSavedPost?.account?.id, accountDetails?.social_media?.length]); // eslint-disable-line

  useEffect(() => {
    if (addPostMessage) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, addPostMessage, { success: false });
      handleCreatorData();
    }
  }, [addPostMessage, !facebookBoostConfig?.isAddBoost]); // eslint-disable-line

  useEffect(() => {
    if (addPostMessage && mobileFilter) {
      dispatch(toggleFilter());
    }
  }, [addPostMessage, mobileFilter]); // eslint-disable-line

  useEffect(() => {
    if (boostSuccessMsg) {
      alertBoxCall(ALERT_BOX_TYPES.SUCCESS, boostSuccessMsg, { success: false });
      handleBoostDataReset();
    }
    if (boostFailureMsg) {
      alertBoxCall(ALERT_BOX_TYPES.ERROR, boostFailureMsg, { success: false });
      handleBoostDataReset();
    }
  }, [boostSuccessMsg, boostFailureMsg, !facebookBoostConfig?.isAddBoost]); // eslint-disable-line

  useEffect(() => {
    if (
      apActionType &&
      [ADD_POST_ACTION_TYPE.POST_NOW, ADD_POST_ACTION_TYPE.SCHEDULE_POST].includes(apActionType) &&
      savedPostId &&
      (apActionType === ADD_POST_ACTION_TYPE.POST_NOW || (apActionType === ADD_POST_ACTION_TYPE.SCHEDULE_POST && savedPostDetails?.schedule_date_time.utcFormat))
    ) {
      const scheduledPostObj = {
        ...(apActionType === ADD_POST_ACTION_TYPE.POST_NOW && { hidden: false }),
        ...(apActionType === ADD_POST_ACTION_TYPE.POST_NOW && { scheduled_for: null }),
        ...(apActionType === ADD_POST_ACTION_TYPE.SCHEDULE_POST && { scheduled_for: savedPostDetails?.schedule_date_time.utcFormat }),
        ...(facebookBoostConfig?.isAddBoost && { facebook_dark_post: facebookBoostConfig?.facebookDarkPost || false })
      };
      dispatch(
        addPostSaveAsScheduledPostRequest({
          savedPostId,
          scheduledPost: scheduledPostObj
        })
      );
    }
  }, [apActionType, savedPostId]); // eslint-disable-line

  useEffect(() => {
    if (apActionType && [ADD_POST_ACTION_TYPE.POST_NOW, ADD_POST_ACTION_TYPE.SCHEDULE_POST].includes(apActionType) && addPostMessage && scheduledPostId) {
      if (apActionType === ADD_POST_ACTION_TYPE.SCHEDULE_POST) handleSchedulePostList();
      if (facebookBoostConfig && facebookBoostConfig?.isAddBoost && savedPostDetails?.use_facebook) {
        dispatch(addPostAddBoostToScheduledPostRequest(getAddBoostForScheduledPostReqPayload(scheduledPostId, facebookBoostConfig)));
      }
    }
  }, [apActionType, addPostMessage, facebookBoostConfig?.isAddBoost, savedPostDetails?.use_facebook]); // eslint-disable-line

  const isConfirmEditing = useMemo(() => {
    if (addPostAction === AP_ACTION_TYPES.EDIT && editSavedPost?.id && !editConfirmObj.isEditConfirmed) {
      return confirmEditingAccountPost(editSavedPost.status_id, isAdmin, isFranchisor, isFranchisee, overriddenCustomPostsLocked, editConfirmObj.isEditConfirmed);
    }
    return false;
  }, [addPostAction, editSavedPost?.id, editSavedPost?.status_id, isAdmin, isFranchisor, isFranchisee, overriddenCustomPostsLocked, editConfirmObj.isEditConfirmed]);

  useEffect(() => {
    dispatch(addPostSetEditConfirmForApprovedPost({ ...editConfirmObj, isPostEditable: isConfirmEditing }));
  }, [isConfirmEditing]); // eslint-disable-line

  const handleCreatorData = () => {
    dispatch(addPostSaveMessageReset());
    if (id && editSavedPost && addPostAction === AP_ACTION_TYPES.EDIT && savedPostDetails?.status_name === ADD_POST_STATUS_NAME.AWAITING_APPROVAL) {
      handleNavigateToPostsDetail();
    } else {
      handleUpdateFBBoostConfigData();
      handleResetAllData();
    }
  };

  const handleNavigateToPostsDetail = () => {
    if (id && editSavedPost?.id) {
      navigate({
        pathname: `/${userOwnership}/${id}/content/posts/${editSavedPost?.id.toString()}/details`,
        search: `?${new URLSearchParams({
          status:
            creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT
              ? POSTS_STATUS_TYPE.AA_LOCATION_CONTENT
              : userOwnership === USER_OWNERSHIP.ACCOUNT
              ? POSTS_STATUS_TYPE.AWAITING_APPROVAL
              : POSTS_STATUS_TYPE.ALL
        }).toString()}`
      });
      handleResetAllData();
    }
  };

  const handleBoostDataReset = () => {
    dispatch(addPostSaveMessageReset());
    handleUpdateFBBoostConfigData();
    handleResetAllData();
  };

  const handleSchedulePostList = () => {
    const initialStartDate = getStartAndEndOfUpcomingWeek(getCurrentDate(), ICommonDateTypes.START_TIME);
    const initialEndDate = getStartAndEndOfUpcomingWeek(getCurrentDate(), ICommonDateTypes.END_TIME);
    if (subNavPageName === 'creator') {
      dispatch(
        calendarViewDateRange({
          ...CALENDAR_VIEW_DATE_RANGE,
          startDate: initialStartDate,
          endDate: initialEndDate
        })
      );
    }
    if ((id || editSavedPost?.account?.id) && userOwnership && initialStartDate && initialEndDate) {
      apiEventSourcePolyfillHandler(
        API.getScheduledPost.apiPath,
        {
          hidden: 0,
          order: SCHEDULE_PARAM_TYPE.SCHEDULE_FOR,
          account_id: creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT ? editSavedPost?.account?.id : id,
          date_range: [utcTimeStampConverter(initialStartDate), utcTimeStampConverter(initialEndDate)]
        },
        'scheduled_posts',
        () => dispatch(getScheduledPostRequest()),
        (data) => {
          dispatch(getScheduledPostResponse(data));
        },
        CALENDAR_EXCEPTION_ERROR
      );
    }
  };

  const handleUpdateFBBoostConfigData = () => {
    dispatch(updateFBBoostConfigData(getFacebookBoostConfigData(fbTargetingChoice)));
  };

  const handleResetAllData = () => {
    const defaultHashtagTextBlock = `${HASHTAG_BLOCK_DEFAULT_SPACING}${hashTagsState.saved_hashtags.join(' ')}`;
    if (isAiContentOpen) dispatch(setAIPostContentOpen(false));
    if (creatorTextAlertMsg) dispatch(setCreatorTextAlertMsg(null));
    dispatch(resetAIContentCreator());
    dispatch(resetAIImageGenerationImages());
    if (addPostAction === AP_ACTION_TYPES.EDIT) dispatch(addPostGetActionTypeForPost(AP_ACTION_TYPES.ADD));

    dispatch(addPostSavedPostReset());
    dispatch(addPostFranResetAll());
    dispatch(addPostActionsResetAll());
    dispatch(addPostGetEditSavedPostObj(CREATOR_EDIT_INIT_OBJ));
    dispatch(addPostSetEditConfirmForApprovedPost(AP_EDIT_CONFIRM_INIT_OBJ));
    if (userOwnership && creatorCompName === CREATOR_USER_OWNERSHIP.LOCATION_CONTENT) {
      dispatch(addPostResetAll());
      dispatch(creatorSetComponentName(userOwnership));
      dispatch(accountProfileReset());
      dispatch(getFBAdsTargetingChoicesRequest({ id, userOwnership, initialLoad: true }));
    }
    setEditorState(addSavedHashtags ? { ...AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT, [activePlatforms[0]]: convertStringToEditorState(defaultHashtagTextBlock) } : AP_INIT_EDITOR_STATE_FOR_CUSTOM_TEXT);
    dispatch(
      addPostUpdateSavedPostDetails({
        ...AP_SAVED_POST_INIT_DETAILS,
        current_tab: activePlatforms?.length ? activePlatforms[0] : COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
        schedule_date_time: dateObjBasedOnTimeZone(accountDetails?.account?.tzinfo_name || ''),
        account_schedule_date_time: dateObjBasedOnTimeZone(accountDetails?.account?.tzinfo_name || ''),
        ...(addSavedHashtags && { [`${activePlatforms[0]}_text`]: defaultHashtagTextBlock, [`use_${activePlatforms[0]}`]: true })
      })
    );
    if (addSavedHashtags) {
      dispatch(addPostUpdateCustomTextEditor({ key: activePlatforms[0], value: convertStringToEditorState(defaultHashtagTextBlock) }));
    }
  };

  return (
    <div className={`content__creator--main mbl-head--margin rcpm lpx vpy-20 ref-cpm create-post__main${isPostFetching ? ` pointer-events-none` : ''}`}>
      {isPostFetching || isCaptionValidating || isAICaptionTranslating ? <Loading /> : null}
      <CreatePostStats />
      <div className="addpost-section-main-wrp creator__main--content">
        <LeftCreatorPostSection activeContentList={addContentList} />
        {isAiContentOpen ? (
          <AIContents editedContent={savedPostDetails ? savedPostDetails[`${savedPostDetails?.current_tab}_text`] : ''} />
        ) : (
          <RightCreatorPreviewSection accessibleSocialMedia={previewList} />
        )}
      </div>
    </div>
  );
};
