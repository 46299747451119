import { useSelector, useDispatch } from 'react-redux';

import { setAISubscriptionDetailsModal, setAISubscriptionType } from 'actions';
import { IStore } from 'types';
import { useAISubscriptionPlan } from 'utils/hooks';
import { ImageValidation } from 'widgets/Media';

export const RallioSubscriptionDetail = ({ isAlreadySubscribed }: { isAlreadySubscribed: boolean }) => {
  const dispatch = useDispatch();

  const { planOptions } = useAISubscriptionPlan();
  const aiSubscriptionDetailsModal = useSelector((state: IStore) => state.aiContentCreator.aiSubscriptionDetailsModal);
  return (
    <div className="disconnect-social__wrp rppsWrap">
      <div className="inner__wrp">
        <div className="ai-sub-home">
          <div className="ai-promo-video d-flex justify-content-center align-items-center">
            <div className="ai-vs-top">
              <ImageValidation isImgValid defaultImg="logo" customName="subscription-logo" />
            </div>
          </div>
          <div className="ral-aip-main">
            <h2>Rallio Pricing &amp; Plans</h2>
            <p className="ai__pricing--txt">Jump in and experience how simple, easy, and fun your social media suddenly becomes with Rallio!.</p>
            <div className={`raip-item-group cc-rai-plans ${isAlreadySubscribed ? 'ai-as-options as-active' : 'ai-as-options as-inactive'}`}>
              {planOptions.map((it) => (
                <div className={it.class} key={it.value}>
                  <div className="raip-top">
                    <h4>{it.label}</h4>
                    <p>Per location</p>
                  </div>
                  <div className="raip-mid">
                    <div className="raipm-price-main">
                      <span className="raipm-dollar">$</span>
                      <span className="raipm-price">{`${it.price}`}</span>
                      <span className="raipm-monthly">/{it.billing_period}</span>
                    </div>
                  </div>

                  <button
                    // className="gnrc-btn blue-gnr"
                    className="ac-btn ac-primary"
                    onClick={() => {
                      dispatch(setAISubscriptionDetailsModal({ type: 'newSubscription', isOpen: true, isTrialPreiod: true, isCoachMark: aiSubscriptionDetailsModal?.isCoachMark || false }));
                      dispatch(setAISubscriptionType(it));
                    }}
                  >
                    <span>Subscribe Now</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
