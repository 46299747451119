import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { ImageValidation } from 'widgets/Media';
import {
  AP_ADD_TO_POST_TAB_NAMES,
  TOOLTIP_PLACEMENT,
  AP_ADD_TO_POST_TAB_TOOLTIP,
  COMMON_SOCIAL_PROVIDER_TYPE,
  CREATOR_PAGE_TAG_INFO_TOOLTIP,
  GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS,
  FB_CALL_TO_ACTION_SELECT_OPTIONS,
  ADD_POST_CONTENT_MAX_TEXT_LENGTH,
  AI_ICON_BG_IMG,
  SIDE_NAV_NAME,
  AI_COACHMARK,
  CREATOR_TEXT_EDITOR_ALERT_MSG,
  AP_ACTION_TYPES,
  SOCIAL_MEDIA_POST_TYPE_VALUES,
  IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM
} from 'utils/constants';
import { aiContentCreatorReducerTypes, apAccountContainerTypes, IStore } from 'types';
import { AddMediaModal, AddCouponModal, AddLink, AddTagPagesModal } from '../CommonModal';
import { CustomTooltip } from 'widgets/Tooltip';
import { /* convertEditorStateToString, */ creatorHasVideo, creatorPhotosCount, hasFranFullAccess } from 'utils/helpers';
import { useAIFeatureAccess, useAISubscriptionPlan, useAccountSwitcherData, useActivePlatform, useNavBarData, useSiteRestriction } from 'utils/hooks';
import {
  setAIPostContentOpen,
  setAISubscriptionDetailsModal,
  addPostIsEmojiOpen,
  setCreatorModalName,
  setCreatorTextAlertMsg,
  setAITextContent,
  setCustomizeLinkEnable,
  mgResetAll,
  mgResetCustomVideoThumbnailResponse,
  resetApMediaExpiration
} from 'actions';
import { AddSocialMediaCallToAction } from '../CommonModal/AddSocialMediaCallToAction';
import { AICoachMark } from 'components/Common/Modal/AICoachMark';
import { AddHashtag } from '../CommonModal/AddHashtag';

export const AddToYourPostSection = () => {
  const dispatch = useDispatch();

  const { aiRestriction } = useSiteRestriction();
  const { subscriptionPlan } = useAISubscriptionPlan();

  const { id, userOwnership } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();
  const { subNavPageName } = useNavBarData();
  const { aiCaptionAccess, aiImageAccess } = useAIFeatureAccess();

  const isFBPageTaggingEnabled = useSelector((state: IStore) => state.franchisors.franchisorDetails?.facebook_page_tagging_feature || false);
  const hasCoupons = useSelector((state: IStore) => state.franchisors.franchisorDetails?.has_coupons || false);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const contentWriters = useSelector((state: IStore) => state.login.activeUser?.content_writers || []);
  const isAdmin = useSelector((state: IStore) => state.login.activeUser?.admin || false);
  const creatorModalName = useSelector((state: IStore) => state.addPostFranchisorAction.creatorModalName);
  const isEmojiOpen = useSelector((state: IStore) => state.addPostFranchisorAction.isEmojiOpen);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);
  const isAiContentOpen = useSelector((state: IStore) => state.aiContentCreator.isAiContentOpen);
  // const textEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const isRallioai = useSelector((state: IStore) => state.accountSwitcher?.rallio_ai || false);
  const aiTextContent = useSelector((state: IStore) => state.aiContentCreator.aiTextContent);
  const pageType = useSelector((state: IStore) => state.addPostFranchisor.pageType);
  const response = useSelector((state: IStore) => state.mediaGallery.uploadCloudinaryMedia.response);

  const connectedToFacebook = activePlatforms.includes(COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK);
  const isCoachMark = [SIDE_NAV_NAME.CONTENT_CAMPAIGNS, SIDE_NAV_NAME.CONTENT_CALENDAR].includes(subNavPageName)
    ? false
    : contentObj?.currentTab
    ? pageType !== AP_ACTION_TYPES.ADD // old - convertEditorStateToString(textEditorState[contentObj?.currentTab])
      ? false
      : true
    : false;

  const isDisableEmoji = contentObj.isPostLock
    ? true
    : IDEAL_LENGTH_OF_SOCIAL_MEDIA_POSTS_BY_PLATFORM.includes(contentObj?.currentTab) &&
      contentObj?.customText[contentObj?.currentTab]?.length > ADD_POST_CONTENT_MAX_TEXT_LENGTH?.[contentObj?.currentTab.toUpperCase()] - 3
    ? true
    : false;

  useEffect(() => {
    if (pageType === AP_ACTION_TYPES.EDIT) dispatch(setCustomizeLinkEnable(linkData?.link_preview_customized ? true : false));
  }, [linkData?.link_preview_customized, pageType, dispatch]);

  const handleDisableImageTab = () => {
    if (contentObj.isPostLock) return true;
    else if (creatorHasVideo(selectedMediaList)) return true;
    else if (creatorPhotosCount(selectedMediaList) >= 500) return true;
    else return false;
  };

  const handleDisableVideoTab = () => {
    if (contentObj.isPostLock) return true;
    else if (creatorPhotosCount(selectedMediaList)) return true;
    else if (creatorHasVideo(selectedMediaList)) return true;
    else return false;
  };

  const handleDisableCouponTab = () => {
    if (contentObj.isPostLock) return true;
    else if (!(hasCoupons && hasFranFullAccess(isAdmin, id, contentWriters) && !linkData)) return true;
    else return false;
  };

  const handleDisableLinkTab = () => {
    if (contentObj.isPostLock) return true;
    else if (linkData?.tracpoint_coupon_id) return true;
    else return false;
  };

  const handleAiClick = () => {
    if (!isAISubscribed) {
      dispatch(setAISubscriptionDetailsModal({ type: subscriptionPlan as aiContentCreatorReducerTypes.IAISubscriptionPlans, isOpen: true }));
    } else {
      dispatch(setAIPostContentOpen(!isAiContentOpen));
      dispatch(setAITextContent({ ...aiTextContent, content: `${contentObj.customText[contentObj.currentTab]}` || '' }));
    }
    // navigate(ROUTES.CONTENT_CREATOR.replace(':type', userOwnership).replace(':id', id.toString()).replace('*', COMMON_PAGE_TYPE.NEW));
    // if (!isAiContentOpen)
  };

  const handleDisableCommonTab = () => contentObj.isPostLock;

  const handleTabAction = (data: string | null) => {
    dispatch(setCreatorTextAlertMsg(null));
    dispatch(setCreatorModalName(data));
    if (response.length) dispatch(mgResetAll());
  };

  const handleAddLinkModalClose = () => {
    handleTabAction(null);
  };

  const showLinkWarningForStoryOrReel = () => {
    dispatch(
      setCreatorTextAlertMsg(
        CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG_WITH_STORY_OR_REEL.replaceAll(
          `/post-type/`,
          contentObj?.facebook_story || contentObj?.instagram_story ? SOCIAL_MEDIA_POST_TYPE_VALUES.STORY : SOCIAL_MEDIA_POST_TYPE_VALUES.REELS
        )
      )
    );
  };
  const creatorMediaButtons: apAccountContainerTypes.IAPAddToPostMediaButton[] = useMemo(
    () => [
      {
        isShow: true,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.EMOJI,
        classname: 'add-emojis',
        defaultImg: 'add-emoji-dark-Blue',
        isDisabled: isDisableEmoji,
        imgName: 'Emoji',
        onClick: () => {
          if (
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) ||
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
          ) {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAG_ALERT_STORY_MSG));
          } else if (isEmojiOpen) {
            dispatch(addPostIsEmojiOpen(false));
          } else {
            dispatch(addPostIsEmojiOpen(true));
          }
        }
      },
      {
        isShow: [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.TWITTER].includes(contentObj?.currentTab),
        tooltipText: CREATOR_PAGE_TAG_INFO_TOOLTIP,
        classname: 'mic info-icon info-ins-twt',
        isDisabled: false,
        defaultImg: 'info-tooltip',
        imgName: 'Info'
      },
      {
        isShow: connectedToFacebook && isFBPageTaggingEnabled && contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.TAG_PAGES,
        classname: 'tags add-tags',
        isDisabled: handleDisableCommonTab(),
        defaultImg: 'add-tags',
        imgName: 'Tags',
        onClick: () => {
          if (
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) ||
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
          ) {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAG_ALERT_STORY_MSG));
          } else if (contentObj?.postStatus.facebook) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.TAG_PAGES);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAGS_ALERT_MSG));
          }
        }
      },
      {
        isShow: contentObj?.currentTab !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.IMAGES,
        classname: 'add-img',
        isDisabled: handleDisableImageTab(),
        defaultImg: 'add-image-color',
        imgName: 'Images',
        onClick: () => {
          if (!linkData && !contentObj?.google_call_to_action_type) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.PHOTOS);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: contentObj?.currentTab !== COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.VIDEOS,
        classname: 'add-vid',
        isDisabled: handleDisableVideoTab(),
        defaultImg: 'add-video-color',
        imgName: 'Video',
        onClick: () => {
          if (!linkData && !contentObj?.google_call_to_action_type) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.VIDEOS);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: contentObj?.currentTab !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.COUPONS,
        classname: 'coupons',
        isDisabled: handleDisableCouponTab(),
        defaultImg: 'coup_icon',
        imgName: 'Coupon',
        onClick: () => {
          if (
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) ||
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
          ) {
            showLinkWarningForStoryOrReel();
          } else if (!creatorPhotosCount(selectedMediaList) && !creatorHasVideo(selectedMediaList)) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.COUPON);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: contentObj?.currentTab !== COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.LINKS,
        classname: 'add-links add-hls',
        isDisabled: handleDisableLinkTab(),
        defaultImg: 'add-link-color',
        imgName: 'Link',
        onClick: () => {
          if (
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) ||
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
          ) {
            showLinkWarningForStoryOrReel();
          } else if (!creatorPhotosCount(selectedMediaList) && !creatorHasVideo(selectedMediaList)) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.LINK);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: [COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE, COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK].includes(contentObj?.currentTab || ''),
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.CALL_TO_ACTION,
        classname: 'add-gpost',
        isDisabled: handleDisableLinkTab(),
        defaultImg: 'google-post',
        imgName: 'GooglePost',
        onClick: () => {
          if (
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) ||
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
          ) {
            showLinkWarningForStoryOrReel();
          } else if (!creatorPhotosCount(selectedMediaList) && !creatorHasVideo(selectedMediaList)) {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.CALL_TO_ACTION);
          } else {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.LINK_ALERT_MSG));
          }
        }
      },
      {
        isShow: true,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.HASHTAGS,
        classname: 'add__post--icon add-links add-hts',
        defaultImg: 'hash-tags',
        isDisabled: handleDisableLinkTab(),
        imgName: 'Hashtags',
        onClick: () => {
          if (
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && (contentObj?.facebook_story || contentObj?.facebook_reel)) ||
            (contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
          ) {
            dispatch(setCreatorTextAlertMsg(CREATOR_TEXT_EDITOR_ALERT_MSG.TAG_ALERT_STORY_MSG));
          } else {
            handleTabAction(AP_ADD_TO_POST_TAB_NAMES.HASHTAGS);
          }
        }
      },
      {
        isShow: true,
        tooltipText: AP_ADD_TO_POST_TAB_TOOLTIP.AI_ASSISTANT,
        classname: `add-ai-feature ${isAiContentOpen ? 'ai-events-none' : ''} ${isCoachMark ? 'cm-enable' : ''}`,
        isDisabled: handleDisableCommonTab(),
        defaultImg: AI_ICON_BG_IMG.LIGHT_BLUISHGREY,
        imgName: 'AI',
        isAICoachMark: true
      }
    ],
    // eslint-disable-next-line
    [
      contentObj?.currentTab,
      connectedToFacebook,
      isFBPageTaggingEnabled,
      contentObj?.postStatus.facebook,
      selectedMediaList,
      isAdmin,
      id,
      contentWriters,
      linkData?.tracpoint_coupon_id,
      contentObj.isPostLock,
      isEmojiOpen,
      isDisableEmoji,
      contentObj?.google_call_to_action_type,
      contentObj?.facebook_story,
      contentObj?.facebook_reel,
      contentObj?.instagram_story
    ]
  );

  const handleAddMediaModalClose = () => {
    handleTabAction(null);
    dispatch(mgResetCustomVideoThumbnailResponse());
    dispatch(resetApMediaExpiration());
  };

  return (
    <>
      {creatorMediaButtons.map((it, index) => {
        return it.isShow ? (
          <CustomTooltip
            key={`acc-media-${index}`}
            customPlacement={TOOLTIP_PLACEMENT.TOP}
            customTooltipText={it.tooltipText}
            customClassname={'custom-tooltip-long-text'}
            // customInput={() => (
            //   // <div>
            //   <div className={`add__post--icon ${it.classname}${it?.isDisabled ? ` disabled` : ``}`} onClick={it.onClick}>
            //     <ImageValidation isImgValid defaultImg={it.defaultImg} customName={it.imgName} />
            //   </div>
            //   // </div>
            // )}
            customInput={() => (
              <div className={`add__post--icon emoji-20 ${it.classname}${it?.isDisabled ? ` disabled` : ``}`} onClick={it.onClick}>
                {isRallioai && (aiCaptionAccess || aiImageAccess) && it.isAICoachMark && !aiRestriction.button ? (
                  <AICoachMark coachMarkPosition={'right'} coachMarkDetails={AI_COACHMARK.find((it) => it.value === 'caption')} isCoachMark={isCoachMark}>
                    <div className={`ai-btn ai-creator-btn ai-anim-btn ${isAiContentOpen ? 'ai-events-none' : ''} ${isCoachMark ? 'cm-enable' : ''}`} onClick={handleAiClick}>
                      <ImageValidation isImgValid defaultImg={it.defaultImg} isNotSvgFormat customName={it.imgName} customClassname={'add__post--icon add-boost'} />
                    </div>
                  </AICoachMark>
                ) : (
                  <ImageValidation isImgValid defaultImg={it.defaultImg} customName={it.imgName} />
                )}
              </div>
            )}
          />
        ) : null;
      })}

      {creatorModalName === AP_ADD_TO_POST_TAB_NAMES.TAG_PAGES ? (
        <AddTagPagesModal isModalShow handleModalClose={() => handleTabAction(null)} />
      ) : creatorModalName && [AP_ADD_TO_POST_TAB_NAMES.PHOTOS, AP_ADD_TO_POST_TAB_NAMES.VIDEOS].includes(creatorModalName) ? (
        <AddMediaModal mediaName={creatorModalName || ''} handleClose={handleAddMediaModalClose} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.COUPON ? (
        <AddCouponModal isModalShow handleModalClose={() => handleTabAction(null)} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.LINK ? (
        <AddLink isModalShow handleModalClose={handleAddLinkModalClose} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.HASHTAGS ? (
        <AddHashtag isModalShow={creatorModalName === AP_ADD_TO_POST_TAB_NAMES.HASHTAGS} handleModalClose={() => handleTabAction(null)} id={id} />
      ) : creatorModalName === AP_ADD_TO_POST_TAB_NAMES.CALL_TO_ACTION ? (
        <AddSocialMediaCallToAction
          isShowModal
          onModalClose={handleAddLinkModalClose}
          actionTypeOptions={contentObj?.currentTab === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS : FB_CALL_TO_ACTION_SELECT_OPTIONS}
          currentTab={contentObj?.currentTab}
          userOwnership={userOwnership}
        />
      ) : null}
    </>
  );
};
