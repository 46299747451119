import { useSelector } from 'react-redux';
import { useLocation, matchPath, useMatch } from 'react-router';
import { useMemo } from 'react';

import { IStore } from 'types';
import { ReviewAnalyticsFilter, ProfileAnalyticsFilter, ContentAnalyticsFilter, OverviewFilter } from 'analytics/layouts';
import {
  PostFilter,
  MediaGalleryFilter,
  InboxFilter,
  ProfileImageryFilter,
  ReviewsFilter,
  AdvocacyOverviewFilter,
  AdvocacyLeaderboardFilter,
  AdvocacyOnboardingFilter,
  RewardsProgramsFilter,
  SettingsNotifications,
  FBAdsTargetingNotifications,
  DLProfileNotification,
  DLListingsFilter,
  CouponsFilter,
  TeamManagementListsFilter,
  NoFilter,
  CalendarFilter,
  ConnectionsFilter,
  TargetingFilter,
  RevvssoFilter,
  ContentCreatorFilter,
  CampaignsFilter,
  SCFilter,
  SalesFilter
} from './SubPages';
import { LeaderBoardAnalyticsFilter } from 'analytics/layouts/Filter/SubPages/LeaderBoardAnalyticsFilter';
import { showSalesPage, showConnectPlatformPage } from 'utils/helpers';
import { useNavBarData, useAccountSwitcherData, useActivePlatform } from 'utils/hooks';
import { AdminAreaFilter } from './SubPages/AdminAreaFilter';
import { CHILD_ROUTES, MAIN_NAV_BAR_NAME, ROUTES } from 'utils/constants';

export const Filter = () => {
  const { subNavPageName } = useNavBarData();
  const { currentRoute, optionalParams } = useAccountSwitcherData();
  const activePlatforms = useActivePlatform();

  const accountSwitcherError = useSelector((state: IStore) => state.accountSwitcher.error);
  const isFilterOpen = useSelector((state: IStore) => state.common.isFilterOpen);
  const isDataLoading = useSelector((state: IStore) => state.common.isDataLoading);
  const isRevvAuthenticated = useSelector((state: IStore) => state.revv.login.isAuthenticated);
  const showRevv = useSelector((state: IStore) => state.accountSwitcher.showRevv);
  const isRevvConnectedToCurrentFranchisor = useSelector((state: IStore) => state.revv.filter.isRevvConnectedToCurrentFranchisor);
  const isRallioContractSubscription = useSelector((state: IStore) => state.accountSwitcher.rallio_subscription_contract);
  const isAISubscribed = useSelector((state: IStore) => state.aiContentCreator.isAISubscribed);

  const salesPages = useSelector((state: IStore) => state.common.salesPages);
  const location = useLocation();
  const locationSales = `/${currentRoute}`;
  const communityRoute = useMatch(`${ROUTES.COMMUNITY}/inbox`);
  const reputationRoute = useMatch(`${ROUTES.REPUTATION}/reviews`);

  const routeMatch = (path: string, childRoute?: any): boolean => {
    const childRoutes = childRoute ? Object.values(childRoute).map((it) => `${path}/${it}`) : [];
    return matchPath(path, location.pathname)?.pathname ||
      childRoutes.reduce((acc, curr) => {
        if (matchPath(curr, location.pathname)?.pathname) {
          acc = true;
        }
        return acc;
      }, false)
      ? true
      : false;
  };

  const isRevvEnabled = useMemo(() => {
    const revvPath = ['analytics_leaderboard'];
    return !!revvPath.find((it) => it === subNavPageName) ? !isRevvAuthenticated && !showRevv && !isRevvConnectedToCurrentFranchisor : true;
  }, [isRevvAuthenticated, showRevv, subNavPageName, isRevvConnectedToCurrentFranchisor]);

  return isDataLoading || accountSwitcherError || (isRallioContractSubscription && !isAISubscribed) ? null : (
    <>
      {showSalesPage(salesPages, locationSales) ? (
        communityRoute?.pathname || reputationRoute?.pathname ? (
          <SalesFilter />
        ) : (
          <AdminAreaFilter />
        )
      ) : !activePlatforms?.length && showConnectPlatformPage(subNavPageName) && isRevvEnabled ? null : (
        <>
          {/* analytics */}
          {routeMatch(ROUTES.ANALYTICS_SOCIAL) ? <OverviewFilter /> : null}
          {routeMatch(ROUTES.ANALYTICS_CONTENT) ? <ContentAnalyticsFilter /> : null}
          {routeMatch(ROUTES.ANALYTICS_PAGE) ? <ProfileAnalyticsFilter /> : null}
          {routeMatch(ROUTES.ANALYTICS_REVIEW) ? <ReviewAnalyticsFilter /> : null}
          {routeMatch(ROUTES.ANALYTICS_LEADERBOARD, { OPERATIONS: 'operations' }) && optionalParams[1] !== 'locations' && optionalParams[1] !== 'teams' ? <LeaderBoardAnalyticsFilter /> : null}
          {/* content */}
          {routeMatch(ROUTES.CONTENT_CREATOR) && <ContentCreatorFilter />}
          {routeMatch(ROUTES.CONTENT_POSTS, CHILD_ROUTES.CONTENT_POSTS) && <PostFilter />}
          {routeMatch(ROUTES.CONTENT_CAMPAIGNS) && <CampaignsFilter />}
          {routeMatch(ROUTES.CONTENT_MEDIA) && <MediaGalleryFilter />}
          {routeMatch(ROUTES.CONTENT_FEED) && <NoFilter />}
          {routeMatch(ROUTES.CONTENT_SCHEDULER) && <NoFilter />}
          {routeMatch(ROUTES.CONTENT_COUPONS) && <CouponsFilter />}
          {routeMatch(ROUTES.CONTENT_RSS_FEED) ? <NoFilter /> : null}
          {routeMatch(ROUTES.CONTENT_CALENDAR) ? <CalendarFilter /> : null}
          {routeMatch(ROUTES.CONTENT_PROFILE_IMAGERY) && <ProfileImageryFilter />}
          {/* community */}
          {routeMatch(ROUTES.COMMUNITY_INBOX) && isFilterOpen ? <InboxFilter /> : null}
          {(routeMatch(ROUTES.COMMUNITY_SANDBOX) || routeMatch(ROUTES.REPUTATION_OUTBOX)) && isFilterOpen ? <SCFilter /> : null}
          {/* marketing */}
          {routeMatch(ROUTES.MARKETING_TARGETING) && <TargetingFilter />}
          {/* reputation */}
          {routeMatch(ROUTES.REPUTATION_REVIEWS) && isFilterOpen ? <ReviewsFilter /> : null}
          {routeMatch(ROUTES.REPUTATION_AI_REVIEW_RESPONDER) && <NoFilter />}
          {/* employee_advocacy */}
          {routeMatch(ROUTES.ADVOCACY_OVERVIEW) && isFilterOpen ? <AdvocacyOverviewFilter /> : null}
          {routeMatch(ROUTES.ADVOCACY_REWARD_PROGRAM, CHILD_ROUTES.ADVOCACY_REWARD_PROGRAM) && <RewardsProgramsFilter />}
          {routeMatch(ROUTES.ADVOCACY_LEADER_BOARD) && isFilterOpen && <AdvocacyLeaderboardFilter />}
          {/* directory_listings */}
          {routeMatch(ROUTES.DIRECTORY_LISTINGS_PROFILE, CHILD_ROUTES.DIRECTORY_LISTINGS_PROFILE) && <DLProfileNotification />}
          {routeMatch(ROUTES.DIRECTORY_LISTINGS_LISTINGS) ? <DLListingsFilter /> : null}
          {/* team_management */}
          {routeMatch(ROUTES.TEAM_MANAGEMENT_LOGINS) && isFilterOpen && <AdvocacyOnboardingFilter />}
          {routeMatch(ROUTES.TEAM_MANAGEMENT_CONNECTIONS, CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS) && <ConnectionsFilter />}
          {routeMatch(ROUTES.TEAM_MANAGEMENT_LISTS) && <TeamManagementListsFilter />}
          {/* team_management & settings */}
          {(routeMatch(ROUTES.TEAM_MANAGEMENT_PERMISSIONS) ||
            routeMatch(ROUTES.SETTINGS_REVIEW_TEMPLATE) ||
            routeMatch(ROUTES.SETTINGS_RELEASE_FORM) ||
            routeMatch(ROUTES.SETTINGS_RALLIO) ||
            routeMatch(ROUTES.SETTINGS_AI_PLAYBOOK) ||
            routeMatch(ROUTES.SETTINGS_HASHTAGS) ||
            routeMatch(ROUTES.VIDEO_TUTORIALS) ||
            routeMatch(ROUTES.SETTINGS_SOCIAL, CHILD_ROUTES.SETTINGS_SOCIAL)) && (
            <SettingsNotifications subNavPageName={location.pathname.includes(MAIN_NAV_BAR_NAME.VIDEO_TUTORIALS) ? MAIN_NAV_BAR_NAME.VIDEO_TUTORIALS : subNavPageName} />
          )}
          {/* Setting brand profile */}
          {routeMatch(ROUTES.SETTINGS_BRAND_PROFILE) ? <NoFilter /> : null}
          {/* marketing & settings */}
          {(routeMatch(ROUTES.MARKETING_FB_ADS) || routeMatch(ROUTES.SETTINGS_FB_ADS)) && <FBAdsTargetingNotifications />}
          {/* revv */}
          {/* {routeMatch(ROUTES.REVV) ? <RevvssoFilter /> : null} */}
          {routeMatch(ROUTES.REVV_DASHBOARD) ||
          routeMatch(ROUTES.REVV_SURVEYS) ||
          routeMatch(ROUTES.REVV_TEAMS) ||
          routeMatch(ROUTES.REVV_CUSTOMERS) ||
          routeMatch(ROUTES.REVV_LOCATION) ||
          routeMatch(ROUTES.REVV_EMPLOYEES) ||
          routeMatch(ROUTES.REVV_SETTINGS, CHILD_ROUTES.REVV_SETTINGS) ||
          routeMatch(ROUTES.REVV_REPORTS) ||
          routeMatch(ROUTES.REVV_ANALYTICS) ||
          routeMatch(ROUTES.ANALYTICS_LEADERBOARD, CHILD_ROUTES.ANALYTICS_LEADERBOARD) ||
          routeMatch(ROUTES.REVV_ANALYTICS_SURVEY_STATUS) ||
          routeMatch(ROUTES.REVV_ANALYTICS_SENTIMENT) ||
          routeMatch(ROUTES.REVV_ANALYTICS_REVIEW) ||
          routeMatch(ROUTES.REVV_ANALYTICS_AVERAGE_RATING) ? (
            <RevvssoFilter subNavName={subNavPageName} />
          ) : null}
          {/* Super Admin Admin Area */}
          {(routeMatch(ROUTES.ADMIN_ACCOUNTS, CHILD_ROUTES.ADMIN_ACCOUNTS) ||
            routeMatch(ROUTES.ADMIN_FRANCHISORS, CHILD_ROUTES.ADMIN_FRANCHISORS) ||
            routeMatch(ROUTES.ADMIN_USERS, CHILD_ROUTES.ADMIN_USERS) ||
            routeMatch(ROUTES.REVV_TEAMS_DETAIL) ||
            routeMatch(ROUTES.REVV)) && <AdminAreaFilter />}
        </>
      )}
    </>
  );
};
