import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RIPPLE_COLOR } from 'utils/constants';
import { ActivateAccount } from 'components/Common/Modal';
import { commonModalPopup, IStore } from 'types';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { setSwitchBrandStatus, ssoAccountSwitchBrandRequest, ssoAccountSwitchBrandResponse } from 'actions';
import { useAccountSwitcherData } from 'utils/hooks';

export const SSOAccountSwitchBrandModal = ({ isSwitchBrand, isOpenAccountActivate }: commonModalPopup.ISingleSignOnConfirmProps) => {
  const dispatch = useCallback(useDispatch(), []); // eslint-disable-line
  const navigate = useNavigate();

  const { id, userOwnership } = useAccountSwitcherData();

  const ssoAccountSwitchBrandMessage = useSelector((state: IStore) => state.accountSwitcher.ssoAccountSwitchBrandMessage);
  const ssoAccountSwitchBrand = useSelector((state: IStore) => state.ssoAccountSwitchBrand.ssoAccountSwitchBrand);
  const locationsHubList = useSelector((state: IStore) => state.operationsList.locationsGroupList);
  // const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);

  const [showSwitchBrandModal, setShowSwitchBrandModal] = useState(false);
  const [showActivateAccount, setShowActivateAccount] = useState(false);

  useEffect(() => {
    if (isSwitchBrand) {
      setShowSwitchBrandModal(true);
    }
  }, [isSwitchBrand]);

  useEffect(() => {
    if (ssoAccountSwitchBrand) {
      if (locationsHubList.length) navigate({ pathname: `/${userOwnership}/${id.toString()}/content/feed`, search: '' });
      else navigate({ pathname: `/${userOwnership}/${id.toString()}/content/calendar`, search: '' });
      dispatch(ssoAccountSwitchBrandResponse(null));
      dispatch(setSwitchBrandStatus(false));
    }
  }, [ssoAccountSwitchBrand, userOwnership, id]); // eslint-disable-line

  return (
    <>
      <Modal className="cnfrm__delete--modal glbl__delete--modal glbl__unsave--modal" size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={showSwitchBrandModal} animation={false}>
        <Modal.Header>
          <div className="modal-icon-wraps">
            <span className="modal-itm-wraps modal-danger-wraps modal-info-wraps" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="unsave__modal--txt">
              {ssoAccountSwitchBrandMessage || (
                <>
                  Virtuoso has invited you to join their Social Media Content Center to gain access to curated content feeds designed to inspire your followers to travel.
                  <br />
                  <br />
                  Please be aware that any scheduled posts in your calendar from other content suppliers will be removed with this transition and you will be choosing new Feeds for your calendar
                  during this process.
                  <br />
                  <br />
                  Click the "Confirm Move" button below to approve.
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete__btn--wrp">
            <CustomRippleButton rippleClass="ac-secondary-box edit-ripple__wrp" custColor={RIPPLE_COLOR.whiteGrey}>
              <button
                className="ac-btn ac-secondary-white ac-outline ac-block border-0"
                onClick={() => {
                  setShowSwitchBrandModal(false);
                  setShowActivateAccount(isOpenAccountActivate);
                  dispatch(setSwitchBrandStatus(false));
                }}
              >
                No
              </button>
            </CustomRippleButton>
            <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
              <button
                className="ac-btn ac-primary ac-block"
                onClick={() => {
                  dispatch(ssoAccountSwitchBrandRequest({ id }));
                  setShowSwitchBrandModal(false);
                }}
              >
                Confirm Move
              </button>
            </CustomRippleButton>
          </div>
        </Modal.Footer>
      </Modal>
      {showActivateAccount ? <ActivateAccount /> : null}
    </>
  );
};
