import { all, fork } from 'redux-saga/effects';

import {
  takeLoginRequest,
  takeforgotPasswordRequest,
  takeResetPasswordRequest,
  takeCommonRequest,
  takeAccountSwitcherRequest,
  takeMenuAccessiblityRequest,
  takePostsRequest,
  takeMediaGalleryRequest,
  takeAccountsProfileRequest,
  takeSettingsRallioProfileRequest,
  takeAddPostAccountRequest,
  takeAddPostFranchisorRequest,
  takeAddPostFranchisorActionsRequest,
  takeInboxRequest,
  takeContentCalendarRequest,
  takeReviewsRequest,
  takeFranchisorsProfileRequest,
  takeDirectoryListingsRequest,
  takeUserLoginsRequest,
  takeConnectionRequest,
  takeProfileImageryRequest,
  takeAdvocacyOnboardRequest,
  takeRewardProgramsRequest,
  takeFBAdsTargetingRequest,
  takeOverviewRequest,
  takeAdvocacyLeaderboardRequest,
  takeStripeRequest,
  takeSettingsSocialProfileRequest,
  takeCouponsRequest,
  takeChangePasswordRequest,
  takeAdminAreaRequest,
  takeRssFeedsRequest,
  takeOperationsListRequest,
  takeAllTargetingRequests,
  takeSalesRequest,
  takeCampaignsRequest,
  takeAIContenCreatorRequest,
  takeSocialContentsRequest,
  takeSettingsAIPlayBookRequest,
  takeAIImageGenerationRequest,
  takeCommunicationRequest,
  takeAIAssistantRequest,
  takeSettingsPartnerBannersRequest,
  takeAIReviewResponderRequest,
  takeSSOAccountSwitchBrandRequest,
  takeSettingsHashTagsRequest
} from 'sagas';

import {
  takeReviewAnalyticsRequest,
  takeOverviewAnalyticsSummaryRequest,
  takeKeyWordManagementRequest,
  takeReviewAnalyticsSummaryRequest,
  takeProfileAnalyticsRequest,
  takeContentAnalyticsRequest,
  takeOverviewAnalyticsRequest,
  takeLeaderBoardRequest
} from 'analytics/sagas';
import { revvRootSaga } from 'revv/sagas';

function* rootSaga() {
  yield all([
    fork(takeLoginRequest),
    fork(takeforgotPasswordRequest),
    fork(takeResetPasswordRequest),
    fork(takeCommonRequest),
    fork(takeAccountSwitcherRequest),
    fork(takeMenuAccessiblityRequest),
    fork(takePostsRequest),
    fork(takeCampaignsRequest),
    fork(takeMediaGalleryRequest),
    fork(takeAccountsProfileRequest),
    fork(takeSettingsRallioProfileRequest),
    fork(takeAddPostAccountRequest),
    fork(takeAddPostFranchisorRequest),
    fork(takeAddPostFranchisorActionsRequest),
    fork(takeInboxRequest),
    fork(takeContentCalendarRequest),
    fork(takeReviewsRequest),
    fork(takeFranchisorsProfileRequest),
    fork(takeDirectoryListingsRequest),
    fork(takeUserLoginsRequest),
    fork(takeConnectionRequest),
    fork(takeProfileImageryRequest),
    fork(takeAdvocacyOnboardRequest),
    fork(takeRewardProgramsRequest),
    fork(takeFBAdsTargetingRequest),
    fork(takeOverviewRequest),
    fork(takeAdvocacyLeaderboardRequest),
    fork(takeReviewAnalyticsRequest),
    fork(takeLeaderBoardRequest),
    fork(takeReviewAnalyticsSummaryRequest),
    fork(takeKeyWordManagementRequest),
    fork(takeProfileAnalyticsRequest),
    fork(takeContentAnalyticsRequest),
    fork(takeStripeRequest),
    fork(takeSettingsSocialProfileRequest),
    fork(takeCouponsRequest),
    fork(takeChangePasswordRequest),
    fork(takeAdminAreaRequest),
    fork(takeRssFeedsRequest),
    fork(takeOverviewAnalyticsRequest),
    fork(takeOverviewAnalyticsSummaryRequest),
    fork(takeOperationsListRequest),
    fork(takeAllTargetingRequests),
    fork(takeSalesRequest),
    fork(revvRootSaga),
    fork(takeAIContenCreatorRequest),
    fork(takeSocialContentsRequest),
    fork(takeSettingsAIPlayBookRequest),
    fork(takeAIImageGenerationRequest),
    fork(takeCommunicationRequest),
    fork(takeAIAssistantRequest),
    fork(takeSettingsPartnerBannersRequest),
    fork(takeAIReviewResponderRequest),
    fork(takeSSOAccountSwitchBrandRequest),
    fork(takeSettingsHashTagsRequest)
  ]);
}

export { rootSaga };
